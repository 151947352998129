import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DatatableHeaderInterface,
  DatatableOutputParameterInterface,
  ICustomSort,
  TTableHeightMode,
} from '../datatable/datatable.model';
import {
  ActivityLogsQueryParams,
  ActivityLogsTableHeaderInterface,
} from '../../../view/reports/activity-logs/activity-logs.model';
import { IOption } from 'ng-select';
import { TargetEndpoints } from '../filter/advanced-filter/advanced-filter.model';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import {
  IActivityLog,
  IActivityLogWithLastComment,
} from '../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import * as moment from 'moment';
import { HelperService } from '../../service/helper.service';
import * as _ from 'lodash';
import { ActivityLogsTableService } from './activity-logs-table.service';
import {
  CellTypes,
  ExcelColumnDefinitionInterface,
  ExcelColumnInfoConfigurationInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
} from '../../service/excel/excel-helper.service';
import { ValueType } from 'exceljs';
import * as ActivityLogsTableActions from '../../../store/activity-logs-table/activity-logs-table.actions';
import { Subscription } from 'rxjs';
import * as AppActions from '../../../store/app/actions';
import { ActivityLogsService } from '../../../store/reports/activity-logs/activity-logs.service';
import { BorderColors } from '../scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { CustomColors } from '../../service/color/color.model';
import { IActivityLogsFooterData } from '../../../store/reports/performance-deep-dive/performance-deep-dive.model';
import {
  ICommentCountWithLastCommentMessageRequestedObject,
  ECommentLogsObjectType,
  ECommentLogsCommentType,
  ICommentCountWithLastCommentMessage,
  ICommentMessage,
} from 'src/app/store/reports/comment-logs/comment-logs.model';
import * as CommentLogsActions from '../../../store/reports/comment-logs/comment-logs.actions';
import { ofType } from '@ngrx/effects';
import { IActivityLogExcelData } from "../../../store/reports/activity-logs/activity-logs.model";
import { ETooltipContentTypes } from '../../../standalone/table-cells/show-tooltip-button/show-tooltip-button.model';
import { DECIMAL_DEFAULT_SCALE_LIMIT, largeModal } from '../../../../constants';
import {
  ActivityHistoryAuditLogModalComponent
} from '../../../standalone/audit-log/activity-history-audit-log-modal/activity-history-audit-log-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../store/user/model';

@Component({
  selector: 'app-activity-logs-table',
  templateUrl: './activity-logs-table.component.html',
  styleUrls: ['./activity-logs-table.component.scss'],
})
export class ActivityLogsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public rawData: IActivityLogWithLastComment[];
  @Input() public footerData: IActivityLogsFooterData;
  @Input() public isElasticPage: boolean;
  @Input() public loading: boolean = false;
  @Input() showManyDataWarning: boolean = false;
  @Input() public pageName: string = 'activity-logs-table';
  @Input() public excelQueryParams: ActivityLogsQueryParams = {
    page: 1,
    offset: 10,
    isBusinessDate: true,
    lines: -1,
    shifts: -1,
    activities: -1,
    lineTypes: -1,
    workOrders: -1,
    advancedFilter: {
      filters: [],
      target: TargetEndpoints.Custom,
      page: this.pageName,
    },
  };
  @Input() tableHeightMode: TTableHeightMode = null;
  @Input() customColors: CustomColors;
  @Output() public onLastCommentsModalCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public idleTimeColor: BorderColors = BorderColors.idleTime;
  public runTimeColor: BorderColors = BorderColors.runtime;
  public downTimePlannedColor: BorderColors = BorderColors.plannedDownTime;
  public downTimeColor: BorderColors = BorderColors.unplannedDownTime;
  private readonly subscriptions: Subscription[] = [];
  public activityLogCount = 0;
  public tableQuery = {
    page: 1,
    pageSize: 10,
  };
  public tableColumns: string[];
  public tableHeaders: DatatableHeaderInterface[] = [];
  public customSorts: ICustomSort = {
    duration: { type: 'duration', sortColumnId: 'durationWithUserFormat' },
    durationMin: { type: 'number', sortColumnId: 'duration' },
    crewSize: { type: 'number', sortColumnId: 'crewSize' },
    quantityOrdered: { type: 'number', sortColumnId: 'quantityOrdered' },
  };
  public recordRangeOptions: IOption[];
  public fileDownloadStatus$: boolean;
  public isDataLoading$: boolean = false;
  public tableFooterContent: ActivityLogsTableHeaderInterface[] = [];
  public readonly tooltipContentTypes: typeof ETooltipContentTypes = ETooltipContentTypes;
  private activityHistoryAuditLogModalRef: NgbModalRef;
  private timezone$: string;
  private dateTimeFormat$: string;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly service: ActivityLogsTableService,
    private readonly activityLogsService: ActivityLogsService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
    private readonly excelHelper: ExcelHelperService,
    private readonly storeActions: ActionsSubject,
    private readonly ngbModal: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.setDatatableHeaders(this.service.defaultTableHeaders);
    this.fetchActivityLogCommentCounts();

    this.subscriptions.push(
      this.store.select('userConfigurationStore').subscribe((state) => {
        this.isDataLoading$ = state.userConfigurationDataLoading;

        if (state.userConfigurationDataLoaded && state.userConfigurationData.ActivityLogsComponent) {
          const activityLogsTableColumns = state.userConfigurationData.ActivityLogsComponent.find(
            ({ name }) => name === 'selectedColumns',
          );
          this.tableColumns = activityLogsTableColumns.value as string[];
          this.setDatatableHeaders(this.tableColumns);
          this.setDataTableFooterContent(this.tableColumns);
        }
      }),
      this.storeActions
        .pipe(ofType(CommentLogsActions.COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADED))
        .subscribe((response: CommentLogsActions.CommentLogsCountWithLastMessageDataLoaded) =>
          this.mapActivityCommentsWithLastMessageToActivityLogs(response.payload),
        ),
      this.store.select('user').subscribe((state: User) => {
        this.timezone$ = state.timezone;
        this.dateTimeFormat$ = state.dateTimeFormat;
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('rawData')) {
      if (!this.rawData) {
        return;
      }

      this.rawData = this.editDateFormatsOfData(changes['rawData'].currentValue);
    }
    if (changes.hasOwnProperty('footerData')) {
      if (!this.footerData) {
        return;
      }
      this.footerData = this.formatActivityLogsFooterData(changes['footerData'].currentValue);
    }
  }

  public editDateFormatsOfData(params: IActivityLog[]): IActivityLog[] {
    return params.map((item: IActivityLog) => {
      return {
        ...item,
        startWithUserFormat: !_.isNil(item.start)
          ? this.helperService.setUserDateTimeFormat(item.start, true, true, true)
          : null,
        endWithUserFormat:
          !_.isNil(item.end) && item.end !== 'Ongoing'
            ? this.helperService.setUserDateTimeFormat(item.end, true, true, true)
            : this.translate.instant('general.ongoing'),
        shiftDayWithUserFormat: item.shiftDay ? this.helperService.setUserDateTimeFormat(item.shiftDay) : null,
        durationWithUserFormat: !_.isNil(item.duration)
          ? this.helperService.formatDuration(Number(item.duration), true)
          : null,
        durationMin: !_.isNil(item.duration)
          ? this.decimalHelper.toFixedValue(
              moment
                .duration(Number(item.duration), 's')
                .as('m')
                .toString(),
            )
          : null,
        crewSize: !_.isNil(item?.crewSize) ? this.decimalHelper.toFixedValue(item?.crewSize.toString(), 0) : null,
        phaseName: this.activityLogsService.getPhaseName(item),
        targetDuration:
          item.activityTypeText === ActivityTypes.RUN_TIME || !item.targetDuration
            ? null
            : this.decimalHelper.toFixedValue(item.targetDuration.toString()),
        ucl: this.decimalHelper.toFixedValue(item.ucl),
        lcl: this.decimalHelper.toFixedValue(item.lcl),
        quantityOrdered: _.isNil(item.quantityOrdered)
          ? undefined
          : this.decimalHelper.toFixedValue(
              item.quantityOrdered,
              item.siteDecimalScaleLimit ?? DECIMAL_DEFAULT_SCALE_LIMIT,
            ),
      };
    });
  }

  public setDatatableHeaders(tableColumns: string[]): void {
    this.tableHeaders = [];
    tableColumns.forEach((column: string) => {
      if (column !== 'totalDuration') {
        const formattedColumn = {
          value: column,
          formattedValue:
            column === 'start' || column === 'end' || column === 'shiftDay' || column === 'duration'
              ? `${column}WithUserFormat`
              : column,
          name: column === 'duration'
            ? this.translate.instant('general.duration',
    { durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds')})
            : this.translate.instant(`general.dataTable.header.${column}`),
          selected: true,
          sortable: column !== 'description',
        };

        switch (column) {
          case 'siteName': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.site');
            break;
          }
          case 'lineType': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.department');
            break;
          }
          case 'start': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.startDate');
            break;
          }
          case 'end': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.endDate');
            break;
          }
          case 'userFullName': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.user');
            break;
          }
          case 'activityTypeText': {
            formattedColumn.name = this.translate.instant('activitiesPage.tableField.activityType');
            break;
          }
          case 'description': {
            formattedColumn.name = this.translate.instant('activityLogs.tableHeaders.activityComments');
            break;
          }
          case 'productInfo': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.product');
            break;
          }
          case 'targetDuration': {
            formattedColumn.name = this.translate.instant('activityLogs.tableHeaders.taskTargetDuration');
            break;
          }
          case 'processOrder': {
            formattedColumn.name = this.translate.instant('shiftSummary.table.headers.processOrder');
            break;
          }
          case 'jobNumber': {
            formattedColumn.name = this.translate.instant('scheduler.listView.jobNumber');
            break;
          }
          case 'crewSize': {
            formattedColumn.name = this.translate.instant('activityHistory.form.crewSize');
            break;
          }
          case 'commentTags': {
            formattedColumn.name = this.translate.instant('activityLogs.tableHeaders.commentTags');
            formattedColumn.sortable = false;
            break;
          }
          case 'quantityOrdered': {
            formattedColumn.name = this.translate.instant('general.dataTable.header.quantityOrdered');
            break;
          }
        }

        this.tableHeaders.push(formattedColumn);
      }
    });
  }

  public onDataRequestHandler(params: DatatableOutputParameterInterface): void {
    this.tableQuery.page = params.page;
    this.tableQuery.pageSize = params.rowsPerPage;
  }

  private downloadExcel(): void {
    const worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][] = [];
    const excelColumnDefinitions: DatatableHeaderInterface[] = [
      ...this.tableHeaders.filter(
        (header: ActivityLogsTableHeaderInterface) => header.value !== 'description' && header.value !== 'commentTags',
      ),
    ];

    const excelColumnInfoConfiguration: ExcelColumnInfoConfigurationInterface = {
      dateTimeFields: ['start', 'end'],
      dateFields: ['shiftDay'],
      decimalFields: ['ucl', 'lcl', 'targetDuration', 'initialCount', 'goodCount', 'durationMin'],
    };

    worksheetsColumnDefinitions.push(
      excelColumnDefinitions.reduce(
        (excelColumns: ExcelColumnDefinitionInterface[], column: ActivityLogsTableHeaderInterface) => {
          if (column.selected) {
            excelColumns.push({
              header: column.name === 'Site' ? this.translate.instant('general.excel.column.siteName') : column.name,
              key: column.value,
              width: ExcelColumnWidthEnum.DEFAULT,
              type: ValueType.String,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                formulae: [],
                showErrorMessage: false,
                showInputMessage: false,
              },
            });
          }

          return excelColumns;
        },
        [],
      ),
    );
    worksheetsColumnDefinitions.push(
      excelColumnDefinitions.reduce(
        (excelColumns: ExcelColumnDefinitionInterface[], column: ActivityLogsTableHeaderInterface) => {
          if (column.selected) {
            excelColumns.push({
              header: column.name === 'Site' ? this.translate.instant('general.excel.column.siteName') : column.name,
              key: column.value,
              width: ExcelColumnWidthEnum.DEFAULT,
              style: { numFmt: '@' },
              dataValidation: {
                type: CellTypes.CUSTOM,
                formulae: [],
                showErrorMessage: false,
                showInputMessage: false,
              },
              ...this.excelHelper.getExcelColumnInfo(column.value, excelColumnInfoConfiguration),
            });
          }

          return excelColumns;
        },
        [],
      ),
    );

    const rawDataWithLastCommentMessage: IActivityLogExcelData[] = this.rawData.map(
      (activityLog: IActivityLogWithLastComment): IActivityLogExcelData => {
        activityLog.commentCountWithLastCommentMessage?.allCommentMessages?.map((commentMessage: ICommentMessage, index: number): void => {
          activityLog[`message${index + 1}`] = commentMessage.message;
        });

        return { ...activityLog };
      },
    );

    this.store.dispatch(
      new ActivityLogsTableActions.ActivityLogsTableDownloadExcel(
        {
          ...this.excelQueryParams,
          page: 1,
          offset: this.rawData.length,
          isQuantityBased: false,
        },
        worksheetsColumnDefinitions,
        rawDataWithLastCommentMessage,
      ),
    );
  }

  public onDownloadExcelButtonClicked(): void {
    this.store.dispatch(new AppActions.ShowTopLoader());
    const limit: number = 10000;

    if (this.rawData.length <= limit) {
      this.downloadExcel();
      return;
    }

    this.helperService.showWaitMessage();

    setTimeout(() => {
      this.downloadExcel();
    }, 1000);
  }

  public onLastCommentsModalClose(userHasTakenAction: boolean): void {
    this.onLastCommentsModalCloseEvent.emit(userHasTakenAction);
  }

  private setDataTableFooterContent(tableFooters: string[]): void {
    tableFooters.forEach((column: string) => {
      if (column === 'totalDuration') {
        this.tableFooterContent = [
          {
            value: column,
            name: this.translate.instant('general.totalDuration'),
          },
        ];
      }
    });
  }

  private formatActivityLogsFooterData(data: IActivityLogsFooterData): IActivityLogsFooterData {
    return {
      totalDuration: !_.isNil(data.totalDuration)
        ? this.helperService.formatDuration(Number(data.totalDuration))
        : null,
    };
  }

  private fetchActivityLogCommentCounts(): void {
    if (!this.rawData || this.rawData.length === 0) {
      return;
    }

    const objectsToFetchCommentCounts: ICommentCountWithLastCommentMessageRequestedObject[] = this.rawData.map(
      (activityLog: IActivityLog) => ({
        objectId: Number(activityLog.id),
        objectType: Boolean(Number(activityLog.isLine))
          ? ECommentLogsObjectType.lines
          : ECommentLogsObjectType.activityHistory,
      }),
    );

    this.store.dispatch(
      new CommentLogsActions.CommentLogsCountWithLastMessageDataLoading({
        commentType: ECommentLogsCommentType.activityComment,
        requestedObjects: objectsToFetchCommentCounts,
        containAllCommentMessages: true,
      }),
    );
  }

  private mapActivityCommentsWithLastMessageToActivityLogs(
    commentCountsWithLastMessage: ICommentCountWithLastCommentMessage[],
  ): void {
    if (!commentCountsWithLastMessage) {
      return;
    }

    this.rawData.forEach((activityLog: IActivityLogWithLastComment, index: number) => {
      const isLine: boolean = Boolean(Number(activityLog.isLine));
      const objectType: ECommentLogsObjectType = isLine
        ? ECommentLogsObjectType.lines
        : ECommentLogsObjectType.activityHistory;
      const objectId: number = Number(activityLog.id);

      const commentCountWithLastMessage: ICommentCountWithLastCommentMessage | null =
        commentCountsWithLastMessage.find(
          (countWithLastCommentMessage: ICommentCountWithLastCommentMessage) =>
            Number(countWithLastCommentMessage.objectId) === Number(objectId) &&
            countWithLastCommentMessage.objectType === objectType,
        ) ?? null;

      this.rawData[index].commentCountWithLastCommentMessage = commentCountWithLastMessage
        ? commentCountWithLastMessage
        : { lastCommentMessage: '', totalCommentCount: 0, objectId, objectType };
    });
  }

  public openActivityHistoryAuditLogModal(uuid: string): void {
    this.activityHistoryAuditLogModalRef = this.ngbModal.open(ActivityHistoryAuditLogModalComponent, largeModal);
    this.activityHistoryAuditLogModalRef.componentInstance.dateTimeFormat$ = this.dateTimeFormat$;
    this.activityHistoryAuditLogModalRef.componentInstance.timezone$ = this.timezone$;
    this.activityHistoryAuditLogModalRef.componentInstance.auditLogUuid = uuid;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
