import { Component, Input, OnInit } from '@angular/core';
import {IDeviceAndSensors, ISensorStatus} from '../../../store/sensor-statuses/sensor-statuses.model';
import { SensorTypeEnum } from '../../../../constants';
import { take } from 'rxjs';
import { User } from '../../../store/user/model';
import { OeeAppState } from '../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sensor-status-indicator',
  templateUrl: './sensor-status-indicator.component.html',
  styleUrls: ['./sensor-status-indicator.component.scss'],
})
export class SensorStatusIndicatorComponent implements OnInit {
  @Input() data: IDeviceAndSensors;

  public SensorTypeEnum = SensorTypeEnum;
  public isBadgesExtended: boolean = false;

  public isSensorHasStation: boolean = false;
  protected isDeviceHasBadge: boolean = false;
  protected readonly sensorInfoBadges: Record<string, { icon: string; text: string; tooltip?: string }[]> = {};
  private siteDecimalScaleLimit$: number;

  private readonly sensorTypeOrder: SensorTypeEnum[] = [
    SensorTypeEnum.GOOD_COUNTER,
    SensorTypeEnum.MID_COUNTER,
    SensorTypeEnum.TOTAL_COUNTER,
    SensorTypeEnum.VIBRATION,
    SensorTypeEnum.CARD_READER,
    SensorTypeEnum.TEMPERATURE,
    SensorTypeEnum.HUMIDITY,
    SensorTypeEnum.SUB_SENSOR,
  ];

  private readonly stationBadgeIcon: string = 'fa-th-large';
  private readonly multiplicationFactorBadgeIcon: string = 'fa-times';

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
      });

    this.data.sensorDetails.forEach((sensor: ISensorStatus) => {
      this.sensorInfoBadges[sensor.id] = [];

      if (
        (sensor.effectiveSensorData?.stations?.length > 0 || sensor.stations?.length > 0) &&
        !this.isSensorHasStation
      ) {
        this.isSensorHasStation = true;
      }

      const stationNames: string[] =
        (sensor.effectiveSensorData?.stations?.length > 0 ? sensor.effectiveSensorData?.stations : sensor.stations).map(
          (station) => station.name,
        ) ?? [];
      const multiplicationFactor: string | null = sensor.effectiveSensorData?.multiplicationFactor ?? null;

      stationNames.forEach((stationName: string) => {
        this.sensorInfoBadges[sensor.id].push({ icon: this.stationBadgeIcon, text: stationName });
        this.isDeviceHasBadge = true;
      });

      if (multiplicationFactor && !this.decimalHelper.isEqual(multiplicationFactor, '1')) {
        this.sensorInfoBadges[sensor.id].push({
          icon: this.multiplicationFactorBadgeIcon,
          text: this.decimalHelper.toFixedValue(multiplicationFactor, this.siteDecimalScaleLimit$),
          tooltip: this.translate.instant('settings.multiplicationFactor'),
        });

        this.isDeviceHasBadge = true;
      }
    });

    this.data?.sensorDetails?.sort((a, b) => {
      return (
        this.sensorTypeOrder.indexOf(a.parentSensorType ?? a.sensorType) -
        this.sensorTypeOrder.indexOf(b.parentSensorType ?? b.sensorType)
      );
    });
  }

  public onExtendOrCollapseBadgesClick(): void {
    this.isBadgesExtended = !this.isBadgesExtended;
  }
}
