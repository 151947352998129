import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ChangeActivityActions from './change-activity.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { ChangeActivityService } from './change-activity.service';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import { IChangeActivityRow } from '../../../../view/settings/opc-tag-automation-settings/change-activity/change-activity.model';

@Injectable()
export class ChangeActivityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ChangeActivityService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.CHANGE_ACTIVITY_DATA_LOADING),
      switchMap((payload: ChangeActivityActions.ChangeActivityLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: payload.params.siteIds } });
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: payload.params.lineIds } });
        }

        if (payload.params?.searchText) {
          andConditions.push({
            $or: [
              {
                machineCodes: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
              {
                takeActionStates: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
              {
                historyCleanerStates: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
            ],
          });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        if (payload.params.orderBy && payload.params.orderDesc !== undefined) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new ChangeActivityActions.ChangeActivityLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ChangeActivityActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.GET_CHANGE_ACTIVITY),
      switchMap((objectData: ChangeActivityActions.GetChangeActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneWithDropdowns(objectData.recordId, new HttpParams()).pipe(
          switchMap(
            (response: {
              changeActivityRecord: BaseOneResponseInterface<IChangeActivityRow>;
              lines: GetManyResponseInterface<LineCRUDInterface>;
              sites: SiteCRUDInterface[];
              activities: ActivityCRUDInterface[];
              tasks: TaskFilterCRUDInterface[];
              extendedTasks: TaskFilterCRUDInterface[];
            }) => {
              return of(
                new ChangeActivityActions.SiteDataLoaded(response.sites),
                new ChangeActivityActions.LineDataCompleted(response.lines),
                new ChangeActivityActions.ActivitiesDataCompleted(response.activities),
                new ChangeActivityActions.TasksDataCompleted(response.tasks),
                new ChangeActivityActions.ExtendedTasksDataCompleted(response.extendedTasks),
                new ChangeActivityActions.GetChangeActivityCompleted(response.changeActivityRecord),
                new AppActions.HideLoader(),
              );
            },
          ),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.ADD_CHANGE_ACTIVITY),
      switchMap((objectData: ChangeActivityActions.AddChangeActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.changeActivity).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeActivityRow>) => {
            const actions = [
              new ChangeActivityActions.AddChangeActivityCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.EDIT_CHANGE_ACTIVITY),
      switchMap((objectData: ChangeActivityActions.EditChangeActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeActivityRow>) => {
            const actions = [
              new ChangeActivityActions.EditChangeActivityCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.DELETE_CHANGE_ACTIVITY),
      switchMap((objectData: ChangeActivityActions.DeleteChangeActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ChangeActivityActions.DeleteChangeActivityCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: ChangeActivityActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            return of(new ChangeActivityActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: ChangeActivityActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new ChangeActivityActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.ACTIVITIES_DATA_LOADING),
      switchMap((objectData: ChangeActivityActions.ActivitiesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          active: 1,
          id: { $in: objectData.activityIds },
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.activityService.getData(httpParams)).pipe(
          switchMap((response: ActivityCRUDInterface[]) => {
            return of(new ChangeActivityActions.ActivitiesDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.TASKS_DATA_LOADING),
      switchMap((objectData: ChangeActivityActions.TasksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          activityId: objectData.activityId,
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.tasksService.getData(httpParams)).pipe(
          switchMap((response: TaskFilterCRUDInterface[]) => {
            return of(new ChangeActivityActions.TasksDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getExtendedTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeActivityActions.ActionTypes.EXTENDED_TASKS_DATA_LOADING),
      switchMap((objectData: ChangeActivityActions.ExtendedTasksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          activityId: objectData.activityId,
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.tasksService.getData(httpParams)).pipe(
          switchMap((response: TaskFilterCRUDInterface[]) => {
            return of(new ChangeActivityActions.ExtendedTasksDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
