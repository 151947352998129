import * as BurstActions from './burst.actions';
import { BurstState } from './burst.model';

export const burstState: BurstState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getBurstLoading: false,
  getBurstLoaded: false,
  burst: null,
  addBurstLoading: false,
  addBurstLoaded: false,
  editBurstLoading: false,
  editBurstLoaded: false,
  deleteBurstLoading: false,
  deleteBurstLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
};

export function burstReducer(state: BurstState = burstState, action: BurstActions.BurstActions): BurstState {
  switch (action.type) {
    case BurstActions.ActionTypes.BURST_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case BurstActions.ActionTypes.BURST_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case BurstActions.ActionTypes.GET_BURST:
      return {
        ...state,
        ...{
          getBurstLoading: true,
          getBurstLoaded: false,
        },
      };
    case BurstActions.ActionTypes.GET_BURST_COMPLETED:
      return {
        ...state,
        ...{
          burst: action.payload.data,
          getBurstLoading: false,
          getBurstLoaded: true,
        },
      };
    case BurstActions.ActionTypes.ADD_BURST:
      return {
        ...state,
        addBurstLoading: true,
        addBurstLoaded: false,
      };
    case BurstActions.ActionTypes.ADD_BURST_COMPLETED:
      return {
        ...state,
        addBurstLoading: false,
        addBurstLoaded: true,
      };
    case BurstActions.ActionTypes.EDIT_BURST:
      return {
        ...state,
        editBurstLoading: true,
        editBurstLoaded: false,
      };
    case BurstActions.ActionTypes.EDIT_BURST_COMPLETED:
      return {
        ...state,
        editBurstLoading: false,
        editBurstLoaded: true,
      };

    case BurstActions.ActionTypes.DELETE_BURST:
      return {
        ...state,
        deleteBurstLoading: true,
        deleteBurstLoaded: false,
      };
    case BurstActions.ActionTypes.DELETE_BURST_COMPLETED:
      return {
        ...state,
        deleteBurstLoading: false,
        deleteBurstLoaded: true,
      };
    case BurstActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case BurstActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case BurstActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case BurstActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case BurstActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
