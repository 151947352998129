import * as TagLoggerActions from './tag-logger.actions';
import { TagLoggerState } from './tag-logger.model';

export const tagLoggerState: TagLoggerState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getTagLoggerLoading: false,
  getTagLoggerLoaded: false,
  tagLogger: null,
  addTagLoggerLoading: false,
  addTagLoggerLoaded: false,
  editTagLoggerLoading: false,
  editTagLoggerLoaded: false,
  deleteTagLoggerLoading: false,
  deleteTagLoggerLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
};

export function tagLoggerReducer(
  state: TagLoggerState = tagLoggerState,
  action: TagLoggerActions.TagLoggerActions,
): TagLoggerState {
  switch (action.type) {
    case TagLoggerActions.ActionTypes.TAG_LOGGER_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case TagLoggerActions.ActionTypes.TAG_LOGGER_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case TagLoggerActions.ActionTypes.GET_TAG_LOGGER:
      return {
        ...state,
        ...{
          getTagLoggerLoading: true,
          getTagLoggerLoaded: false,
        },
      };
    case TagLoggerActions.ActionTypes.GET_TAG_LOGGER_COMPLETED:
      return {
        ...state,
        ...{
          tagLogger: action.payload.data,
          getTagLoggerLoading: false,
          getTagLoggerLoaded: true,
        },
      };
    case TagLoggerActions.ActionTypes.ADD_TAG_LOGGER:
      return {
        ...state,
        addTagLoggerLoading: true,
        addTagLoggerLoaded: false,
      };
    case TagLoggerActions.ActionTypes.ADD_TAG_LOGGER_COMPLETED:
      return {
        ...state,
        addTagLoggerLoading: false,
        addTagLoggerLoaded: true,
      };
    case TagLoggerActions.ActionTypes.EDIT_TAG_LOGGER:
      return {
        ...state,
        editTagLoggerLoading: true,
        editTagLoggerLoaded: false,
      };
    case TagLoggerActions.ActionTypes.EDIT_TAG_LOGGER_COMPLETED:
      return {
        ...state,
        editTagLoggerLoading: false,
        editTagLoggerLoaded: true,
      };

    case TagLoggerActions.ActionTypes.DELETE_TAG_LOGGER:
      return {
        ...state,
        deleteTagLoggerLoading: true,
        deleteTagLoggerLoaded: false,
      };
    case TagLoggerActions.ActionTypes.DELETE_TAG_LOGGER_COMPLETED:
      return {
        ...state,
        deleteTagLoggerLoading: false,
        deleteTagLoggerLoaded: true,
      };
    case TagLoggerActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case TagLoggerActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case TagLoggerActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case TagLoggerActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case TagLoggerActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
