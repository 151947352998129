import { Component, Input } from '@angular/core';
import { BorderColors } from './scw-mat-border-coloring.model';

@Component({
  selector: 'scw-mat-border-coloring',
  templateUrl: './scw-mat-border-coloring.component.html',
  styleUrls: ['./scw-mat-border-coloring.component.scss'],
})
export class ScwMatBorderColoringComponent {
  @Input() type: BorderColors = BorderColors.active;
  @Input() customColor: string;
  @Input() text: string;
  @Input() subText: string = null;
  @Input() hyperlink: string[] = [];
}
