import { SafeResourceUrl } from '@angular/platform-browser';
import { ICamera } from '../../../view/settings/departments-lines-stations/lines/lines.model';

export interface ILineCamera extends ICamera {
  timezone: string;
  lineName: string;
  safeUrl?: SafeResourceUrl;
}

export interface ICameraViewTree {
  name: string;
  cameras: ILineCamera[];
}

export interface ICameraViewLineDropdown {
  id: number;
  name: string;
  timezone: string;
}

export enum ECameraViewSource {
  globalView = 'globalView',
  lineView = 'lineView',
  siteView = 'siteView',
}

export interface CameraList {
  timezone: string;
  name: string;
  lineName: string;
  url: string;
}
