import { PaginationInterface, ResponseInterface } from '../../../shared/service/filter/service.class';
import { CheckInLogReportTypes } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { BulkResponseDataInterface } from '../../settings/products/products.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { IObjectWithEntityTranslations } from 'src/app/shared/service/entity-translator/entity-translator.model';

export interface CheckInLogStateInterface {
  isCardDataLoading: boolean;
  isCardDataLoaded: boolean;
  isTableDataLoading: boolean;
  isTableDataLoaded: boolean;
  isLinesLoading: boolean;
  isLinesLoaded: boolean;
  lines: LineInterface[];
  isSubmitCheckInLogPending: boolean;
  isSubmitCheckInLogCompleted: boolean;
  isDeleteCheckInLogPending: boolean;
  isDeleteCheckInLogCompleted: boolean;
  cardData: CheckInLogCardDataInterface;
  tableData: CheckInLogTableResponseInterface;
  filters: CheckInLogDataRequestParameterInterface;
  tableSettings: TabRowInterface[];
}

export interface CheckInLogTableResponseInterface extends PaginationInterface {
  data: CheckInTableDataCommonInterface[] | CheckInTableGroupedByNoneInterface[] | CheckInTableGroupedByLineInterface[];
}

export interface CheckInLogCardResponseInterface extends ResponseInterface {
  data: CheckInLogCardDataInterface;
}

export interface CheckInLogCardDataInterface {
  objectCount: number;
  objectMinutes: number;
}

export enum GroupedByTypes {
  NONE = 'none',
  OBJECT = 'object',
  LINE = 'line',
  CALENDAR_DATE = 'calendarDate',
  BUSINESS_DATE = 'businessDate',
  STATION = 'station'
}

export interface CheckInDeleteResponseInterface {
  data?: BulkResponseDataInterface[];
  date: Date;
}

export interface CheckInTableDataCommonInterface extends IObjectWithEntityTranslations {
  duration: number;
  formattedDuration?: string;
  sourceObjectName: string;
  sourceObjectShortName: string;
  sourceObjectViewName: string;
  line: string;
  lineId?: number;
}

export interface CheckInTableGroupedByNoneInterface extends CheckInTableDataCommonInterface {
  startDate: string;
  endDate: string | null;
  calculatedStartDate: string;
  calculatedEndDate: string;
  isOngoing: boolean;
  status: number;
  station: string;
  sourceObjectId: number;
  sourceTypeId: number;
  destinationObjectId: number;
  destinationTypeId: number;
  siteId?: number;
  siteName?: string;
  shiftName?: string;
  calendarStartDate: string;
  calendarEndDate: string;
}

export interface CheckInTableGroupedByLineInterface extends CheckInTableDataCommonInterface {
  userCount: number;
}

export interface CheckInLogDataRequestParameterInterface {
  sourceTypeId: CheckInLogReportTypes;
  destinationTypeId: string;
  siteIds: string;
  objectIds: string;
  shiftIds: string;
  startDate: string;
  endDate: string;
  offset?: number;
  limit?: number;
  sort?: string;
}

export interface LineInterface {
  id: number;
  siteId: number;
  title: string;
}

export interface CheckInLogInterface {
  id: number;
  checkInTime: string;
  checkOutTime: string;
  objectId: number;
  userId: number;
  typeId: number;
}
