import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './tag-logger.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';

export enum ActionTypes {
  TAG_LOGGER_DATA_LOADING = '[TAG_LOGGER] TAG LOGGER DATA LOADING',
  TAG_LOGGER_DATA_LOADED = '[TAG_LOGGER] TAG LOGGER DATA LOADED',
  GET_TAG_LOGGER = '[TAG_LOGGER] GET TAG LOGGER LOADING',
  GET_TAG_LOGGER_COMPLETED = '[TAG_LOGGER] GET TAG LOGGER COMPLETED',
  ADD_TAG_LOGGER = '[TAG_LOGGER] ADD TAG LOGGER',
  ADD_TAG_LOGGER_COMPLETED = '[TAG_LOGGER] ADD TAG LOGGER COMPLETED',
  EDIT_TAG_LOGGER = '[TAG_LOGGER] EDIT TAG LOGGER',
  EDIT_TAG_LOGGER_COMPLETED = '[TAG_LOGGER] EDIT TAG LOGGER COMPLETED',
  DELETE_TAG_LOGGER = '[TAG_LOGGER] DELETE TAG LOGGER',
  DELETE_TAG_LOGGER_COMPLETED = '[TAG_LOGGER] DELETE TAG LOGGER COMPLETED',
  SITES_DATA_LOADING = '[TAG_LOGGER] SITES DATA LOADING',
  SITES_DATA_LOADED = '[TAG_LOGGER] SITES DATA LOADED',
  LINES_DATA_LOADING = '[TAG_LOGGER] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[TAG_LOGGER] LINES DATA COMPLETED',
  FETCH_ERROR = '[TAG_LOGGER] FETCH ERROR',
}

export class TagLoggerLoading implements Action {
  readonly type = ActionTypes.TAG_LOGGER_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class TagLoggerLoaded implements Action {
  readonly type = ActionTypes.TAG_LOGGER_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetTagLogger implements Action {
  readonly type = ActionTypes.GET_TAG_LOGGER;
  constructor(public recordId: number) {}
}

export class GetTagLoggerCompleted implements Action {
  readonly type = ActionTypes.GET_TAG_LOGGER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddTagLogger implements Action {
  readonly type = ActionTypes.ADD_TAG_LOGGER;
  constructor(public tagLogger: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddTagLoggerCompleted implements Action {
  readonly type = ActionTypes.ADD_TAG_LOGGER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditTagLogger implements Action {
  readonly type = ActionTypes.EDIT_TAG_LOGGER;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditTagLoggerCompleted implements Action {
  readonly type = ActionTypes.EDIT_TAG_LOGGER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteTagLogger implements Action {
  readonly type = ActionTypes.DELETE_TAG_LOGGER;
  constructor(public payload: number[]) {}
}

export class DeleteTagLoggerCompleted implements Action {
  readonly type = ActionTypes.DELETE_TAG_LOGGER_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type TagLoggerActions =
  | TagLoggerLoading
  | TagLoggerLoaded
  | GetTagLogger
  | GetTagLoggerCompleted
  | AddTagLogger
  | AddTagLoggerCompleted
  | EditTagLogger
  | EditTagLoggerCompleted
  | DeleteTagLogger
  | DeleteTagLoggerCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | FetchError;
