import { Action, Store } from '@ngrx/store';
import {
  CostFactorInterface,
  LineAvailabilityCostFactorDataInterface,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityShiftDataInterface,
  PlanInterface,
  SelectedPlanInterface,
  ShiftInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Subscription } from 'rxjs';
import { InputLimit } from '../../model/enum/input-limit';
import { AddButtonCustomizerModel, DatatableHeaderInterface } from '../datatable/datatable.model';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OeeAppState } from '../../../store/oee.reducer';
import sweetalert2 from 'sweetalert2';
import { IOption } from 'ng-select';
import { inject } from '@angular/core';

export type FormType = PlanInterface | ShiftInterface | CostFactorInterface;
export type ItemType =
  | LineAvailabilityShiftDataInterface
  | LineAvailabilityPlanDataInterface
  | LineAvailabilityCostFactorDataInterface;

interface LineAvailabilityInterface {
  rowsPerPage: number;
  currentPage: number;
  isLoading$: boolean;
  lineAvailabilityStoreSubscription: Subscription;
  formDisplayStatus: boolean;
  form: FormType;
  addButtonStyle: AddButtonCustomizerModel;
  tableHeaders: DatatableHeaderInterface[];
  addButtonCls: string;
  addButtonIconCls: string;
  formHasErrors: boolean;
  prepareCostFactorsOptions?(): void;
  setSelectedPlan?(item: SelectedPlanInterface): void;
}

export class LineAvailabilityClass implements LineAvailabilityInterface {
  public rowsPerPage = 1000;
  public currentPage = 1;
  public isLoading$ = false;
  public lineAvailabilityStoreSubscription;
  public formDisplayStatus = false;
  public InputLimit = InputLimit;
  public editedDataId: number = null;
  form: FormType;
  addButtonStyle: AddButtonCustomizerModel;
  public tableHeaders: DatatableHeaderInterface[];
  public translate: TranslateService = inject(TranslateService);
  public store: Store<OeeAppState> = inject(Store<OeeAppState>);
  public addButtonCls = 'btn btn-gray btn-sm';
  public addButtonIconCls = 'fas fa-plus';
  public formHasErrors: boolean = false;
  public tableQuery: TableQueryParams = {
    page: 1,
    pageSize: 1000,
  };
  public subscriptions: Subscription[];

  constructor() {
    this.translate = inject(TranslateService);
    this.store =  inject(Store<OeeAppState>);
  }

  changeFormStatus(displayStatus: boolean = true, disableStatus: boolean = true): void {
    this.formDisplayStatus = displayStatus;
    this.addButtonStyle.disabled = disableStatus;
  }
  submitForm(addDataForm: NgForm, action: Action, formResetStatus: boolean = true): void {
    if (this.checkFormHasErrors(addDataForm)) {
      return;
    }

    this.store.dispatch(action);
    if (formResetStatus) {
      addDataForm.reset();
    }

    this.formHasErrors = false;
    this.changeFormStatus(false, false);
  }
  cancelDataCreate(addDataForm: NgForm): void {
    if (addDataForm) {
      addDataForm.reset();
      this.formHasErrors = false;
    }

    this.changeFormStatus(false, false);
  }

  public editData(item: ItemType): void {
    this.editedDataId = item.id;
    item.oldName = item.name;
    this.changeFormStatus(false, true);
  }

  deleteData(action: Action, showPopup: boolean, popupTitle: string): void {
    if (showPopup) {
      sweetalert2({
        text: popupTitle,
        title: this.translate.instant('general.question'),
        showCancelButton: true,
        confirmButtonText: this.translate.instant('general.yes'),
        cancelButtonText: this.translate.instant('general.no'),
        showLoaderOnConfirm: true,
        type: 'question',
        allowOutsideClick: () => !sweetalert2.isLoading(),
      }).then((result) => {
        if (result.value) {
          this.store.dispatch(action);
        }
        sweetalert2.noop;
      });
    } else {
      this.store.dispatch(action);
    }
  }
  saveData(action: Action): void {
    this.editedDataId = null;
    this.store.dispatch(action);
    this.addButtonStyle.disabled = false;
  }
  cancelEditData(): void {
    this.editedDataId = null;
    this.addButtonStyle.disabled = false;
  }
  onDataRequestHandler(params): void {
    this.tableQuery = {
      page: 1,
      pageSize: 10,
    };

    if (params.page) {
      this.tableQuery.page = params.page;
    }

    if (params.rowsPerPage) {
      this.tableQuery.pageSize = params.rowsPerPage;
    }

    if (params.search) {
      this.tableQuery.search = params.search;
    }

    if (params.sort && params.sort.type !== 'none') {
      this.tableQuery.orderBy = params.sort.column;

      if (params.sort.type === 'ascending') {
        this.tableQuery.orderDesc = false;
      }

      if (params.sort.type === 'descending') {
        this.tableQuery.orderDesc = true;
      }
    }
  }

  checkFormHasErrors(addDataForm: NgForm): boolean {
    if (addDataForm.invalid) {
      this.formHasErrors = true;
      sweetalert2({
        title: this.translate.instant('lineAvailability.invalidFormMessage'),
        type: 'warning',
      }).catch(sweetalert2.noop);
      return true;
    }

    return false;
  }

  public prepareSiteOptions(sites): {
    siteOptions: IOption[];
    indexedSites: string[];
    siteList: number[];
  } {
    const siteOptions: IOption[] = [];
    const indexedSites: string[] = [];
    const siteList: number[] = [];

    for (const site of sites) {
      siteOptions.push({
        value: String(site.id),
        label: site.name,
      });

      indexedSites[site.id] = site.name;
      siteList.push(site.id);
    }

    return {
      siteOptions,
      indexedSites,
      siteList,
    };
  }
}
