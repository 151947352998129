import { TranslateService } from '@ngx-translate/core';
import * as oeeAppReducer from '../../oee.reducer';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ICapacityFilters, ICapacityResponseInterface, ITableCapacityResult } from './capacity.model';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { mysqlDateFormat } from '../../../shared/helper/date';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { ValueType } from 'exceljs';
import * as ObjectActions from './capacity.actions';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../../shared/service/helper.service';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from 'src/constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CapacityService {
  private readonly routes = {
    capacity: `${this.baseUrl}/lines/capacity`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private decimalScale$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  constructor(
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;
          this.decimalScale$ = state.decimalScaleLimit;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public fetchCapacityData(params: HttpParams): Observable<GetManyResponseInterface<ICapacityResponseInterface>> {
    return this.http.get<GetManyResponseInterface<ICapacityResponseInterface>>(`${this.routes.capacity}`, {
      params,
    });
  }

  public formatCapacityResult(
    capacity: ICapacityResponseInterface[],
    siteDecimalScaleLimit: number,
  ): ITableCapacityResult[] {
    return capacity.map((data: ICapacityResponseInterface) => {
      return {
        weekNumber: data.intervalName,
        capacityScheduledMax: !_.isNil(data.capacityScheduledMax)
          ? this.decimalHelper.toFixedValue(data.capacityScheduledMax.toString())
          : '0',
        productionCapacityRunTimeMax:
          data.potentialQuantityBasedOnLineSpeedxActualRunTime !== undefined
            ? this.decimalHelper.toFixedValue(data.potentialQuantityBasedOnLineSpeedxActualRunTime)
            : '-',
        productionGoodQuantityActual: !_.isNil(data.goodQuantity)
          ? this.decimalHelper.toFixedValue(data.goodQuantity.toString(), siteDecimalScaleLimit)
          : '-',
        utilizationRunTimeMax:
          !_.isNil(data.potentialQuantityBasedOnLineSpeedxActualRunTime) && !_.isNil(data.capacityScheduledMax)
            ? this.decimalHelper.calculateRate(
                data.potentialQuantityBasedOnLineSpeedxActualRunTime,
                data.capacityScheduledMax.toString(),
                DECIMAL_DEFAULT_SCALE_LIMIT,
              )
            : '-',
        utilizationActualEffective:
          !_.isNil(data.goodQuantity) && !_.isNil(data.capacityScheduledMax)
            ? this.decimalHelper.calculateRate(
                data.goodQuantity.toString(),
                data.capacityScheduledMax.toString(),
                DECIMAL_DEFAULT_SCALE_LIMIT,
              )
            : '-',
      };
    });
  }

  public formatCapacityParams(params: ICapacityFilters): HttpParams {
    return new HttpParams()
      .set('sites', params.sites.join(','))
      .set('lines', params.lines.join(','))
      .set('start', moment(params.start).format(mysqlDateFormat))
      .set('end', moment(params.end).format(mysqlDateFormat));
  }

  private getCapacityExcelColumns(
    withErrorColumn: boolean,
    isDataAnalysisFormat: boolean = false,
  ): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('capacity.header.weekNumber'),
          key: 'weekNumber',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('capacity.header.capacityScheduled'),
          key: 'capacityScheduledMax',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: isDataAnalysisFormat ? ValueType.Number : ValueType.String,
          style: { numFmt: isDataAnalysisFormat ? '0.000000000000000###############' : '@' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('capacity.header.effectiveProductionCapacity'),
          key: 'productionCapacityRunTimeMax',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: isDataAnalysisFormat ? ValueType.Number : ValueType.String,
          style: { numFmt: isDataAnalysisFormat ? '0.000000000000000###############' : '@' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('capacity.header.accumulatedGoodProduction'),
          key: 'productionGoodQuantityActual',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: isDataAnalysisFormat ? ValueType.Number : ValueType.String,
          style: { numFmt: isDataAnalysisFormat ? '0.000000000000000###############' : '@' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('capacity.header.runTimeEfficiency'),
          key: 'utilizationRunTimeMax',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: isDataAnalysisFormat ? ValueType.Number : ValueType.String,
          style: { numFmt: isDataAnalysisFormat ? '0.000000000000000###############' : '@' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('capacity.header.productionEfficiency'),
          key: 'utilizationActualEffective',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: isDataAnalysisFormat ? ValueType.Number : ValueType.String,
          style: { numFmt: isDataAnalysisFormat ? '0.000000000000000###############' : '@' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public downloadCapacityExcel(withData: boolean = false, data: ITableCapacityResult[]): void {
    const sheetTitle = this.translate.instant('excel.items.capacity');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.timezone)
      .format(this.dateFormat$)}`;

    const excelTemplateFormatOptions: CreateExcelInterface = this.getCapacityExcelColumns(false);
    const excelDataAnalysisFormatOptions: CreateExcelInterface = this.getCapacityExcelColumns(false, true);

    excelTemplateFormatOptions.data = this.formatExcelValues(data);
    excelDataAnalysisFormatOptions.data = this.formatExcelValues(data);

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        withData: true,
        addDateTimeFormula: undefined,
      },
    ];

    this.excelHelper.createExcel(excelName, { name: 'capacity', withData: true }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadCapacityExcelCompleted());
      },
      () => {
        this.store.dispatch(new ObjectActions.FetchError({}));
      },
    );
  }

  public formatExcelValues(excelData: ITableCapacityResult[] = []): any[] {
    const formattedExcelValues = _.cloneDeep(excelData);

    formattedExcelValues.forEach((row: ITableCapacityResult) => {
      row.capacityScheduledMax = this.sanitizeAndFormat(row.capacityScheduledMax);
      row.productionCapacityRunTimeMax = this.sanitizeAndFormat(row.productionCapacityRunTimeMax);
      row.productionGoodQuantityActual = this.sanitizeAndFormat(row.productionGoodQuantityActual);
      row.utilizationRunTimeMax = this.sanitizeAndFormat(row.utilizationRunTimeMax);
      row.utilizationActualEffective = this.sanitizeAndFormat(row.utilizationActualEffective);
    });
    return formattedExcelValues;
  }

  private sanitizeAndFormat(value: any): string {
    if (_.isNil(value) || value === 'NaN' || value === '-' || Number.isNaN(value)) {
      return '';
    }

    return String(this.decimalHelper.formatDecimalValueForExcel(value));
  }
}
