export enum EPageMode {
  Regular = 'regular',
  Dashboard = 'dashboard',
}

export interface PageHeaderStateInterface {
  isDashboardMode: boolean;
  isFilterBarVisible: boolean;
  isHeaderVisible: boolean;
  isPrintAllowed: boolean;
  isPrintButtonDisabled: boolean;
  lastTimeout: Date | null;
  hideHeaderActions: boolean;
  isEditMode: boolean;
  isRootCauseAnalysisComparisonMode: boolean;
  isCountdownWaitingToReset: boolean;
  isCountdownWaitingToStart: boolean;
  isCountdownWaitingToPause: boolean;
  isCountdownWaitingToDisable: boolean;
  isCountdownWaitingToEnable: boolean;
  isCountdownExpectedToStartWithReset: boolean;
  headerExceptionIndicator: IHeaderExceptionIndicator | null;
  isElementVisible: boolean;
  isVisibilityButtonDisabled: boolean;
  initialCountdown: number | null;
}

export enum EHeaderExceptionType {
  WARNING = 'warning',
}

export interface IHeaderExceptionIndicator {
  message: string;
  type: EHeaderExceptionType;
}

export const pageHeaderExceptionCustomClasses: Record<EHeaderExceptionType, string[]> = {
  [EHeaderExceptionType.WARNING]: ['page-header-warning-mode'],
};

export enum EPageHeaderEditModeOperation {
  Save = 'save',
  Cancel = 'cancel',
  Add = 'add',
  ModeChange = 'modeChange',
}
