export const commonExceptionalCases = {
  noWorkOrder: {
    id: 'noWorkOrder',
    translateKey: 'customDashboards.widget.exceptionalCaseInformation.noWorkOrder',
    iconClass: 'b-fa-solid b-fa-rectangle-list',
  },
  noDataAvailable: {
    id: 'noDataAvailable',
    translateKey: 'customDashboards.widget.exceptionalCaseInformation.noDataAvailable',
    iconClass: 'b-fa-solid b-fa-rectangle-list',
  },
  unauthorizedError: {
    id: 'unauthorizedError',
    translateKey: 'customDashboards.widget.exceptionalCaseInformation.unauthorizedError',
    iconClass: 'fa-solid fa-circle-exclamation',
  },
  loadingError: {
    id: 'loadingError',
    translateKey: 'customDashboards.widget.exceptionalCaseInformation.loadingError',
    iconClass: 'fa-solid fa-circle-exclamation',
  },
  missingConfiguration: {
    id: 'missingConfiguration',
    translateKey: 'customDashboards.widget.exceptionalCaseInformation.missingConfiguration',
    iconClass: 'fa-solid fa-circle-exclamation',
  },
} as const;

export interface IWidgetExceptionalCaseInformation {
  id: string;
  message: string;
  iconClass: string;
}
