import { TimeZoneOptions } from '../../../shared/model/enum/timezone-list';
import { SortOrderType } from '../../../shared/component/datatable/datatable.model';

export interface IStdTableItemOptions {
  isChecked: boolean;
}

export type StdTableItem<T> = IStdTableItemOptions & T;

export interface ISiteRequestParams {
  page: number;
  pageSize: number;
  sort?: {
    column: string;
    type: SortOrderType;
  };
}

export enum ESitesFields {
  name = 'name',
  timezone = 'timezone',
  statusId = 'statusId',
  multipleCheckIns = 'multipleCheckIns',
  preRunPhaseName = 'preRunPhaseName',
  runPhaseName = 'runPhaseName',
  postRunPhaseName = 'postRunPhaseName',
  address = 'address',
  contactPerson = 'contactPerson',
  location = 'location',
  latitude = 'latitude',
  longitude = 'longitude',
  web = 'web',
  tel = 'tel',
  description = 'description',
  weeklyWorkingHours = 'weeklyWorkingHours',
  decimalScaleLimit = 'decimalScaleLimit',
  gmpDisclaimer = 'gmpDisclaimer',
  isIpRestrictionEnabled = 'isIpRestrictionEnabled',
  allowedIpBlocks = 'allowedIpBlocks',
  numberFormat = 'numberFormat',
  isExcelDateTimeFormat = 'isExcelDateTimeFormat',
  woDueDateExcelFormat = 'woDueDateExcelFormat',
  weekStartDay = 'weekStartDay',
}

export interface IFormValue {
  isChecked: boolean;
  value: unknown;
}

export interface ITimezoneSelect {
  id: number;
  name: TimeZoneOptions;
}

export type ISitesForm = {
  [key in ESitesFields]: IFormValue;
};

export interface ISelectItem {
  id: number;
  name: string;
}

export enum EStartDaysOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Saturday = 'Saturday',
}

export interface IStartDayOfWeekSelect {
  id: number;
  name: EStartDaysOfWeek;
}
