import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { HTTPHelperService } from '../../../../shared/service/http/http.helper.service';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { HelperService } from '../../../../shared/service/helper.service';
import { catchError, distinctUntilChanged, Observable, switchMap, tap } from 'rxjs';
import { EMPTY_ACTION, HideLoader, ShowLoader } from '../../../../store/app/actions';
import { isEqual } from 'lodash';
import { LinesService } from '../../../../store/settings/departments-lines-stations/lines/lines.service';
import { ILines } from '../../../../store/settings/departments-lines-stations/lines/lines.model';
import { BaseOneResponseInterface } from '../../../model/interface/crud-response-interface.model';
import { HttpParams } from '@angular/common/http';

export interface ITaskSelectionComponentState {
  lineDataLoading: boolean;
  lineDataLoaded: boolean;
  lineData: BaseOneResponseInterface<Partial<ILines>>;
}

const defaultState: ITaskSelectionComponentState = {
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
};

@Injectable()
export class TaskSelectionComponentStore extends ComponentStore<ITaskSelectionComponentState> implements OnStoreInit {
  constructor(
    private readonly linesService: LinesService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly store: Store<OeeAppState>,
  ) {
    super(HelperService.cloneDeep(defaultState));
  }

  public ngrxOnStoreInit(): void {}

  // Effects
  public readonly getLineData = this.effect((trigger$: Observable<{ lineId: number }>) => {
    return trigger$.pipe(
      tap(() => {
        this.store.dispatch(new ShowLoader());
        this.patchState({ lineDataLoading: true, lineDataLoaded: false, lineData: null });
      }),
      switchMap((parameters) => {
        const httpParams: HttpParams = new HttpParams().set('fields', 'isEquipmentBasedModeForTaskSelect');

        return this.linesService.getLine(parameters.lineId, httpParams).pipe(
          tapResponse(
            (response) =>
              this.patchState({
                lineDataLoading: false,
                lineDataLoaded: true,
                lineData: HelperService.cloneDeep(response),
              }),
            () => {
              this.patchState(HelperService.cloneDeep(defaultState));
            },
            () => {
              this.store.dispatch(new HideLoader());
            },
          ),
          catchError(() => {
            this.store.dispatch(new HideLoader());

            return EMPTY_ACTION;
          }),
        );
      }),
      catchError(() => {
        this.store.dispatch(new HideLoader());

        return EMPTY_ACTION;
      }),
    );
  });

  // Selectors
  public readonly lineData$ = this.select((state: ITaskSelectionComponentState) => {
    return {
      lineDataLoading: state.lineDataLoading,
      lineDataLoaded: state.lineDataLoaded,
      lineData: state.lineData,
    };
  }).pipe(distinctUntilChanged(isEqual));
}
