<div class="row m-t-10 cost-factors-list-table">
  <div class="col">
    <div class="datatable-buttons">
      <div class="row" [style.pointer-events]="costFactorLoading$ ? 'none' : 'all'">
        <div class="col-lg-3">
          <div class="left-grp">
            <scw-mat-small-button
              (onClick)="
                helperService.selectOrUnselectAllCheckboxes(
                  costFactorData$.length !== selectedCostFactors.length,
                  checkBoxElementRef,
                  'cost-factor',
                  costFactorData$
                )
              "
              [disabled]="costFactorData$.length === 0"
            >
              {{
                costFactorData$.length === selectedCostFactors.length && costFactorData$.length !== 0
                  ? ('general.unselectAll' | translate)
                  : ('general.selectAll' | translate)
              }}
            </scw-mat-small-button>
            <scw-mat-small-button
              [iconButton]="true"
              (onClick)="showCostFactorTransactionsModals(ETableModalTypes.edit, add_edit_modal)"
              [disabled]="selectedCostFactors.length === 0 || selectedCostFactors.length > 1"
            >
              <em class="fas fa-pen"></em>
            </scw-mat-small-button>
            <scw-mat-small-button
              [iconButton]="true"
              (onClick)="
                selectedCostFactors.length > 1
                  ? showCostFactorDeleteModal(bulk_delete_modal)
                  : showCostFactorDeleteModal(delete_modal)
              "
              [disabled]="selectedCostFactors.length === 0"
            >
              <em class="fas fa-trash"></em>
            </scw-mat-small-button>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="right-grp">
            <scw-mat-search
              placeholder="{{ 'general.search' | translate }}"
              behavior="delayed"
              [minLength]="0"
              size="xs"
              [block]="true"
              [(inputModel)]="searchBoxText"
              (onSearch)="onKeyUpSearchBox($event)"
            ></scw-mat-search>
            <scw-mat-small-button (onClick)="showCostFactorTransactionsModals(ETableModalTypes.add, add_edit_modal)">
              <em class="fas fa-plus"></em>
              {{ 'lineAvailability.addCostFactor' | translate }}
            </scw-mat-small-button>
          </div>
        </div>
      </div>
    </div>
    <datatable
      theme="gray"
      paginationSize="sm"
      noDataText="{{ 'datatable.noData' | translate }}"
      [serverSide]="true"
      [headers]="tableHeaders"
      [items]="costFactorData$"
      [itemsCount]="costFactors$?.total"
      [currentPage]="tableQuery.page"
      [rowsPerPage]="tableQuery.pageSize"
      [addButtonCustomizer]="addButtonStyle"
      [stickyLeadColumn]="true"
      [isLoading]="costFactorLoading$"
      [shouldPaginate]="false"
      (onDataRequest)="onDataRequestHandler($event)"
      heightMode="fill"
    >
      <ng-template let-item let-i=" index">
        <td *ngFor="let header of tableHeaders; index as i" [class.sticky-left-col]="i === 0">
          <scw-mat-checkbox
            *ngIf="i === 0"
            [id]="'cost-factor-checkbox-' + item.id"
            [value]="item.id"
            [(inputModel)]="item.isChecked"
            (onChange)="onChangeCostFactorTableCheckboxes($event)"
          >
          </scw-mat-checkbox>
          <ng-container *ngIf="i !== 0">
            <ng-container *ngIf="header.value === 'sites'">
              <ng-template
                [ngIf]="
                  indexedSites &&
                  item.schedulerCostFactorsSiteAssignment &&
                  item.schedulerCostFactorsSiteAssignment.length === 1
                "
                [ngIfElse]="displaySelectedCount"
              >
                {{ indexedSites[item.schedulerCostFactorsSiteAssignment[0].siteId] }}
              </ng-template>
              <ng-template #displaySelectedCount>
                {{ item.schedulerCostFactorsSiteAssignment.length }} {{ 'lineAvailability.selected' | translate }}
              </ng-template>
            </ng-container>
            <ng-container *ngIf="(['sites'] | indexOf: header.value) === -1">
              {{ this.decimalHelper.isValid(item[header.value]) ? ( item[header.value] | toFixed: decimalScale$) : (item[header.value]) }}
            </ng-container>
          </ng-container>
        </td>
      </ng-template>
    </datatable>
  </div>
</div>

<ng-template #add_edit_modal let-modal>
  <scw-mat-modal [isForm]="true" (onClickClose)="onCloseModal(modal)" (onFormSubmit)="onAddEditSubmit($event)">
    <h3 title class="modal-title scw-header">
      <ng-template [ngIf]="openedModalType === ETableModalTypes.add" [ngIfElse]="saveChanges">
        {{ 'lineAvailability.addCostFactor' | translate }}
      </ng-template>
      <ng-template #saveChanges>
        {{ 'modal.header.editCostFactor' | translate }}
      </ng-template>
    </h3>

    <div body>
      <h6 class="scw-header"><em class="fas fa-link"></em>&nbsp;{{ 'general.subtitles.assignment' | translate }}</h6>
      <scw-mat-select
        class="scw_mat-add_edit_modal-item-input"
        label="{{ 'lineAvailability.site.label' | translate }}*"
        [rules]="costFactorFormRules.siteIds"
        [data]="sites$"
        [singleSelection]="false"
        [showCheckbox]="true"
        [(inputModel)]="costFactorForm.siteIds.value"
        [enableSearchFilter]="true"
        [enableCheckAll]="true"
        searchBehavior="live"
      ></scw-mat-select>
      <hr class="scw-hr" />
      <h6 class="scw-header">
        <em class="fas fa-file-alt"></em>&nbsp;{{ 'general.subtitles.generalInformation' | translate }}
      </h6>
      <scw-mat-input
        label="{{ 'costFactorManagement.costFactorName.label' | translate }}*"
        [rules]="costFactorFormRules.name"
        [(inputModel)]="costFactorForm.name.value"
      >
      </scw-mat-input>
      <scw-mat-input
        label="{{ 'lineAvailability.costFactor.costFactorName' | translate }}*"
        [rules]="costFactorFormRules.factor"
        [(inputModel)]="costFactorForm.factor.value"
      >
      </scw-mat-input>
    </div>

    <div footer class="modal-footer-buttons">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          <ng-template [ngIf]="openedModalType === ETableModalTypes.add" [ngIfElse]="saveChangesButton">
            {{ 'lineAvailability.addCostFactor' | translate }}
          </ng-template>
          <ng-template #saveChangesButton>
            {{ 'general.saveChanges' | translate }}
          </ng-template>
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #delete_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deleteCostFactor' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.deleteCostFactor' | translate: { name: selectedCostFactor?.name }"
      ></scw-mat-modal-text-with-icon>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onDeleteSubmit()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #bulk_delete_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deleteCostFactors' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.deleteCostFactors' | translate: { count: selectedCostFactors.length }"
      ></scw-mat-modal-text-with-icon>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onBulkDeleteSubmit()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #bulk_delete_result_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deleteCostFactors' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.bulkCostFactorsResult' | translate: { count: successfullyDeletedCostFactorCount }"
      ></scw-mat-modal-text-with-icon>

      <scw-mat-modal-failed-items
        [failText]="'modal.info.bulkCostFactorsFailedResult' | translate"
        [failedItems]="failedBulkDeleteCostFactorNames"
      ></scw-mat-modal-failed-items>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button (onClick)="onCloseModal(modal)">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>
