import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, SiteCRUDResponseInterface } from './service.class';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ISiteCrudJoins, SiteCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<SiteCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseUrl}/sites`, { params: options }).subscribe((response: SiteCRUDResponseInterface) => {
        if (response.hasOwnProperty('data')) {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }

  public getSites(params?: HttpParams): Observable<GetManyResponseInterface<SiteCRUDInterface & ISiteCrudJoins>> {
    return this.http.get<GetManyResponseInterface<SiteCRUDInterface & ISiteCrudJoins>>(`${this.baseUrl}/sites`, {
      params,
    });
  }

  /** @deprecated replaced with {@link getSiteWithOptions } */
  public getOneSite(params: HttpParams, id: number): Observable<BaseOneResponseInterface<SiteCRUDInterface>> {
    return this.http.get<BaseOneResponseInterface<SiteCRUDInterface>>(`${this.baseUrl}/sites/${id}`, {
      params,
    });
  }

  public getSite(id: number, params?: HttpParams): Observable<BaseOneResponseInterface<SiteCRUDInterface>> {
    return this.http.get<BaseOneResponseInterface<SiteCRUDInterface>>(`${this.baseUrl}/sites/${id}?fields=id,name`, {
      params,
    });
  }

  public getSiteWithOptions(
    id: number,
    options: Parameters<HttpClient['get']>[1] = {},
  ): Observable<BaseOneResponseInterface<SiteCRUDInterface>> {
    return this.http.get<BaseOneResponseInterface<SiteCRUDInterface>>(`${this.baseUrl}/sites/${id}`, options);
  }
}
