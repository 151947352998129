import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  CreateTaskGroupResponseInterface,
  DeleteTaskGroupResponseInterface, ITaskGroupsData,
  MoveTaskResponseInterface,
  SiteDataInterface,
  TaskDataInterface,
  TaskGroupsAndRemainingTasksDataResponseInterface, TaskGroupsDataInterface,
  UpdateTaskGroupResponseInterface,
} from '../../../store/task-groups/task-groups.model';
import { forkJoin, Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TaskGroupsService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  private api: string = '/api';
  private routes = {
    siteDropdownData: '/sites',
    tasks: '/tasks',
    taskGroups: '/task-groups',
    moveTask: '/tasks/group-assignments',
  };

  public loadSiteDropdownData(): Observable<GetManyResponseInterface<SiteDataInterface>> {
    const params = new HttpParams().append('limit', '1000').set('fields', 'name');
    return this.http.get<GetManyResponseInterface<SiteDataInterface>>(
      `${this.baseUrl}${this.routes.siteDropdownData}`,
      {
        params,
      },
    );
  }

  public loadTaskGroupsAndRemainingTasksData(
    siteId: number,
  ): Observable<TaskGroupsAndRemainingTasksDataResponseInterface> {
    const baseHttpParams = new HttpParams().append('overrideLimit', 'true');
    const tasksHttpParams = baseHttpParams
      .append('join', 'activity||name')
      .append('join', 'equipment||equipmentName')
      .append('fields', 'title,activityId')
      .append('sort', 'title,DESC')
      .append('sort', 'activity.name,DESC')
      .append('groupBy', 'title,activity_id,equipment_id')
      .set(
        's',
        JSON.stringify({
          siteId: { $eq: siteId },
          taskGroupId: { $isnull: true },
          'activity.activityType': { $ne: 'runTime' },
        }),
      );
    const taskGroupHttpParams = baseHttpParams
      .append('join', 'tasks||title,activityId')
      .append('join', 'tasks.activity||name')
      .append('join', 'tasks.equipment||equipmentName')
      .append('sort', 'id,DESC')
      .append('sort', 'tasks.title,DESC')
      .append('sort', 'tasks.activity.name,DESC')
      .append('groupBy', 'TaskGroup.id,tasks.title,tasks.activity_id,tasks.equipment_id')
      .set('s', JSON.stringify({ siteId: { $eq: siteId } }));
    const observables: Observable<GetManyResponseInterface<TaskDataInterface | TaskGroupsDataInterface>>[]  = [
      this.http.get<GetManyResponseInterface<TaskDataInterface>>(`${this.baseUrl}${this.routes.tasks}`, {
        params: tasksHttpParams,
      }),
      this.http.get<GetManyResponseInterface<TaskGroupsDataInterface>>(`${this.baseUrl}${this.routes.taskGroups}`, {
        params: taskGroupHttpParams,
      }),
    ];

    return forkJoin(observables).pipe(
      map((array) => {
        const [tasksResponse, taskGroupsResponse] = array;

        return {
          data: {
            remainingTasks: (tasksResponse as GetManyResponseInterface<TaskDataInterface>).data,
            taskGroups: (taskGroupsResponse as GetManyResponseInterface<TaskGroupsDataInterface>).data,
          },
          success: tasksResponse.success && taskGroupsResponse.success,
        };
      }),
    );
  }

  public deleteTaskGroup(id: number): Observable<DeleteTaskGroupResponseInterface> {
    return this.http.delete<DeleteTaskGroupResponseInterface>(`${this.baseUrl}${this.routes.taskGroups}/${id}`);
  }

  public updateTaskGroup(id: number, name: string): Observable<UpdateTaskGroupResponseInterface> {
    return this.http.patch<UpdateTaskGroupResponseInterface>(`${this.baseUrl}${this.routes.taskGroups}/${id}`, {
      name,
    });
  }

  public createTaskGroup(siteId: number, name: string): Observable<CreateTaskGroupResponseInterface> {
    return this.http.post<CreateTaskGroupResponseInterface>(`${this.baseUrl}${this.routes.taskGroups}`, {
      siteId,
      name,
    });
  }

  public moveTask(
    siteId: number,
    taskGroupId: number,
    taskName: string,
    activityId: number,
    equipmentId: number,
  ): Observable<MoveTaskResponseInterface> {
    return this.http.patch<MoveTaskResponseInterface>(`${this.baseUrl}${this.routes.moveTask}`, {
      siteId,
      taskGroupId,
      activityId,
      equipmentId,
      title: taskName,
    });
  }

  public getTaskGroup(params: HttpParams): Observable<ITaskGroupsData> {
    return this.http.get<ITaskGroupsData>(`${this.baseUrl}${this.routes.taskGroups}`, {
      params,
    });
  }
}
