import { SchedulerActions, SchedulerActionTypes } from './scheduler.actions';
import { ScheduleStateInterface } from './scheduler.model';
import * as _ from 'lodash';
import { HelperService } from '../../shared/service/helper.service';

const defaultSchedulerState = {
  downTimeActivitiesIsLoading: false,
  downTimeActivitiesIsLoaded: false,
  downTimeActivities: [],
  scenarioItemsLoading: false,
  scenarioItemsLoaded: false,
  plannedItems: [],
  deployedItems: [],
  actualItems: [],
  scenarioItems: [],
  uncreatedWorkOrders: null,
  optimizationAlgorithmsLoading: false,
  optimizationAlgorithmsLoaded: false,
  optimizationAlgorithms: null,
  groupedJobsForUncreatedWorkOrders: null,
  scenarioItemsSaveLoading: false,
  scenarioItemsSaveLoaded: false,
  selectedOptimizationItemsSaveLoading: false,
  selectedOptimizationItemsSaveLoaded: false,
  uncreatedWorkOrdersSaveLoading: false,
  uncreatedWorkOrdersSaveLoaded: false,
  scenarioItemsMetaData: null,
  scenarioItemsDeployLoading: false,
  scenarioItemsDeployLoaded: false,
  scenarioItemsDeployData: null,
  scenarioItemsDeploySuccess: false,
  isUnsavedChangeInGantt: false,
  shiftPlansLoading: false,
  shiftPlansLoaded: false,
  shiftPlans: [],
  saveManyScenarioWorkOrderRulesLoading: false,
  saveManyScenarioWorkOrderRulesLoaded: false,
  scenarioAdvancedFilterLoading: false,
  scenarioAdvancedFilterLoaded: false,
  kpiMetricInfoLoading: false,
  kpiMetricInfoLoaded: false,
  kpiMetricInfo: null,
  shouldSaveKpiCardData: false,
  schedulerOptimizationsLoading: false,
  schedulerOptimizationsLoaded: false,
  isShowSchedulerOptimizationMode: false,
  schedulerAllOptimizationsData: null,
  isSavingSchedulerKpiCardData: false,
  linePathLoading: false,
  linePathLoaded: false,
  linePaths: [],
  checkForDeployItemsLoading: false,
  checkForDeployItemsLoaded: false,
  checkForDeployItems: null,
  checkForUncreatedWorkOrders: null,
  checkForIncompatibleWorkOrdersLoading: false,
  checkForIncompatibleWorkOrdersLoaded: false,
  checkForIncompatibleWorkOrders: null,
  updateSchedulerScenarioLoading: false,
  updateSchedulerScenarioLoaded: false,
  updateSchedulerScenario: null,
};

export const schedulerState: ScheduleStateInterface = {
  scenario: null,
  scenarioLoading: false,
  scenarioLoaded: false,
  showApplyButton: false,
  kpiCardData: null,
  isOnHoldSaveKpiCardData: false,
  viewStatus: 'gantt-view',
  unplannedWorkOrdersIsLoading: false,
  unplannedWorkOrdersIsLoaded: false,
  unplannedWorkOrders: [],
  ...defaultSchedulerState,
};

export function schedulerReducer(
  state: ScheduleStateInterface = schedulerState,
  action: SchedulerActions,
): ScheduleStateInterface {
  switch (action.type) {
    case SchedulerActionTypes.LoadScenario:
      return {
        ...state,
        scenarioLoading: true,
        scenarioLoaded: false,
      };

    case SchedulerActionTypes.ScenarioLoaded:
      return {
        ...state,
        scenario: action.payload.data,
        scenarioLoading: false,
        scenarioLoaded: true,
      };

    case SchedulerActionTypes.ClearInitialSchedulerData:
      return {
        ...state,
        scenario: null,
        scenarioLoading: true,
        scenarioLoaded: false,
        unplannedWorkOrdersIsLoaded: false,
        unplannedWorkOrdersIsLoading: false,
        uncreatedWorkOrders: [],
        optimizationAlgorithms: [],
        optimizationAlgorithmsLoaded: false,
        optimizationAlgorithmsLoading: false,
      };

    case SchedulerActionTypes.LoadScenarioItems:
      return {
        ...state,
        scenarioItemsLoading: true,
        scenarioItemsLoaded: false,
      };

    case SchedulerActionTypes.ScenarioItemsLoaded:
      return {
        ...state,
        plannedItems: action.payload.plannedItems.data,
        deployedItems: action.payload.deployedItems.data,
        actualItems: action.payload.actualItems.data,
        uncreatedWorkOrders: action.payload.uncreatedWorkOrders.data,
        groupedJobsForUncreatedWorkOrders: action.payload.groupedJobsForUncreatedWorkOrders.data,
        scenarioItemsLoading: false,
        scenarioItemsLoaded: true,
      };

    case SchedulerActionTypes.SaveScenarioItems:
      return {
        ...state,
        scenarioItemsSaveLoading: true,
        scenarioItemsSaveLoaded: false,
      };

    case SchedulerActionTypes.ScenarioItemsSaved:
      return {
        ...state,
        scenarioItems: action.payload.data,
        scenarioItemsMetaData: action.scenarioItemsMetaData,
        scenarioItemsSaveLoading: false,
        scenarioItemsSaveLoaded: true,
        shouldSaveKpiCardData: true,
        kpiCardData: null,
      };

    case SchedulerActionTypes.DeployScenarioItems:
      return {
        ...state,
        scenarioItemsDeployLoading: true,
        scenarioItemsDeployLoaded: false,
        scenarioItemsDeploySuccess: null,
        scenarioItemsDeployData: null,
      };

    case SchedulerActionTypes.ScenarioItemsDeployed:
      return {
        ...state,
        scenarioItemsDeployLoading: false,
        scenarioItemsDeployLoaded: true,
        scenarioItemsDeploySuccess: action.success,
        scenarioItemsDeployData: action.data,
        shouldSaveKpiCardData: true,
        kpiCardData: null,
      };

    case SchedulerActionTypes.HideShowApplyButton:
      return {
        ...state,
        showApplyButton: action.payload,
      };

    case SchedulerActionTypes.LoadDownTimeActivitiesData:
      return {
        ...state,
        downTimeActivitiesIsLoading: true,
        downTimeActivitiesIsLoaded: false,
      };

    case SchedulerActionTypes.LoadUnplannedWorkOrderData:
      return {
        ...state,
        unplannedWorkOrdersIsLoading: true,
        unplannedWorkOrdersIsLoaded: false,
      };

    case SchedulerActionTypes.DownTimeActivitiesDataLoaded:
      return {
        ...state,
        downTimeActivitiesIsLoaded: true,
        downTimeActivitiesIsLoading: false,
        downTimeActivities: action.payload,
      };

    case SchedulerActionTypes.UnplannedWorkOrderDataLoaded:
      return {
        ...state,
        unplannedWorkOrdersIsLoading: false,
        unplannedWorkOrdersIsLoaded: true,
        unplannedWorkOrders: action.payload,
      };

    case SchedulerActionTypes.ClearSchedulerData:
      return {
        ...state,
        ...defaultSchedulerState,
      };

    case SchedulerActionTypes.GanttUnsavedChangeStatus:
      return {
        ...state,
        isUnsavedChangeInGantt: action.isUnsavedChangeInGantt,
      };

    case SchedulerActionTypes.LoadScenarioShiftPlans:
      return {
        ...state,
        shiftPlansLoading: true,
        shiftPlansLoaded: false,
      };

    case SchedulerActionTypes.ScenarioShiftPlansLoaded:
      return {
        ...state,
        shiftPlans: action.payload,
        shiftPlansLoading: false,
        shiftPlansLoaded: true,
      };

    case SchedulerActionTypes.SaveManyScenarioWorkOrderRules:
      return {
        ...state,
        saveManyScenarioWorkOrderRulesLoading: true,
        saveManyScenarioWorkOrderRulesLoaded: false,
      };

    case SchedulerActionTypes.ScenarioWorkOrderRulesAdded:
      return {
        ...state,
        saveManyScenarioWorkOrderRulesLoading: false,
        saveManyScenarioWorkOrderRulesLoaded: true,
      };

    case SchedulerActionTypes.AddScenarioAdvancedFilter:
      return {
        ...state,
        scenarioAdvancedFilterLoading: true,
        scenarioAdvancedFilterLoaded: false,
      };

    case SchedulerActionTypes.ScenarioAdvancedFilterAdded:
      return {
        ...state,
        scenario: {
          ...action.payload.data,
        },
        scenarioLoading: false,
        scenarioLoaded: true,
        scenarioAdvancedFilterLoading: false,
        scenarioAdvancedFilterLoaded: true,
      };

    case SchedulerActionTypes.LoadSchedulerOptimizations:
      return {
        ...state,
        schedulerOptimizationsLoading: true,
        schedulerOptimizationsLoaded: false,
        schedulerAllOptimizationsData: null,
      };

    case SchedulerActionTypes.SchedulerOptimizationsLoaded:
      return {
        ...state,
        ...{
          schedulerAllOptimizationsData: action.payload,
        },
        schedulerOptimizationsLoading: false,
        schedulerOptimizationsLoaded: true,
      };

    case SchedulerActionTypes.KpiMetricInfoLoad:
      return {
        ...state,
        ...{
          kpiMetricInfoLoading: true,
          kpiMetricInfoLoaded: false,
        },
      };

    case SchedulerActionTypes.KpiMetricInfoLoaded:
      return {
        ...state,
        ...{
          kpiMetricInfoLoading: false,
          kpiMetricInfoLoaded: true,
          kpiMetricInfo: action.payload,
        },
      };

    case SchedulerActionTypes.SaveSchedulerKpiCardData:
      return {
        ...state,
        ...{
          shouldSaveKpiCardData: false,
          kpiCardData: null,
          isOnHoldSaveKpiCardData: false,
          isSavingSchedulerKpiCardData: true,
        },
      };

    case SchedulerActionTypes.PartialUpdateKpiCardData:
      const kpiCardData = _.cloneDeep(state.kpiCardData) ?? {};
      kpiCardData.components = {
        ...kpiCardData.components,
        [action.payload.component]: true,
      };
      kpiCardData.properties = {
        ...kpiCardData.properties,
        ...action.payload.properties,
      };

      return {
        ...state,
        ...{
          kpiCardData,
        },
      };

    case SchedulerActionTypes.SetViewStatus:
      return {
        ...state,
        viewStatus: action.viewStatus,
      };

    case SchedulerActionTypes.ShowSchedulerOptimizationMode:
      return {
        ...state,
        isShowSchedulerOptimizationMode: action.isShowSchedulerOptimizationMode,
      };

    case SchedulerActionTypes.OnHoldSaveKpiCardData:
      return {
        ...state,
        isOnHoldSaveKpiCardData: action.isOnHoldSaveKpiCardData,
      };

    case SchedulerActionTypes.SchedulerKpiCardDataSaved:
      return {
        ...state,
        isSavingSchedulerKpiCardData: false,
        scenario: {
          ...HelperService.cloneDeep(state.scenario),
          ...action.payload.data,
        },
      };

    case SchedulerActionTypes.LoadOptimizationAlgorithms:
      return {
        ...state,
        optimizationAlgorithmsLoading: true,
        optimizationAlgorithmsLoaded: false,
      };

    case SchedulerActionTypes.OptimizationAlgorithmsLoaded:
      return {
        ...state,
        optimizationAlgorithmsLoading: false,
        optimizationAlgorithmsLoaded: true,
        optimizationAlgorithms: action.response,
      };

    case SchedulerActionTypes.LoadLinePathData:
      return {
        ...state,
        linePathLoading: true,
        linePathLoaded: false,
      };

    case SchedulerActionTypes.LinePathDataLoaded:
      return {
        ...state,
        linePaths: action.payload.data,
        linePathLoading: false,
        linePathLoaded: true,
      };

    case SchedulerActionTypes.CheckForDeployItems:
      return {
        ...state,
        checkForDeployItemsLoading: true,
        checkForDeployItemsLoaded: false,
      };

    case SchedulerActionTypes.CheckForDeployItemsCompleted:
      return {
        ...state,
        checkForDeployItemsLoading: false,
        checkForDeployItemsLoaded: true,
        checkForDeployItems: action.payload.checkForDeployItems,
        checkForUncreatedWorkOrders: action.payload.checkForUncreatedWorkOrders,
      };

    case SchedulerActionTypes.CheckForIncompatibleWorkOrders:
      return {
        ...state,
        checkForIncompatibleWorkOrdersLoading: true,
        checkForIncompatibleWorkOrdersLoaded: false,
      };

    case SchedulerActionTypes.CheckForIncompatibleWorkOrdersCompleted:
      return {
        ...state,
        checkForIncompatibleWorkOrdersLoading: false,
        checkForIncompatibleWorkOrdersLoaded: true,
        checkForIncompatibleWorkOrders: action.payload.data,
      };

    case SchedulerActionTypes.UpdateSchedulerScenario:
      return {
        ...state,
        updateSchedulerScenarioLoading: true,
        updateSchedulerScenarioLoaded: false,
      };

    case SchedulerActionTypes.SchedulerScenarioUpdated:
      return {
        ...state,
        updateSchedulerScenarioLoading: false,
        updateSchedulerScenarioLoaded: true,
        updateSchedulerScenario: action.payload,
      };

    case SchedulerActionTypes.SaveSelectedOptimizationItems:
      return {
        ...state,
        selectedOptimizationItemsSaveLoading: true,
        selectedOptimizationItemsSaveLoaded: false,
      };

    case SchedulerActionTypes.SelectedOptimizationItemsSaved:
      return {
        ...state,
        selectedOptimizationItemsSaveLoading: false,
        selectedOptimizationItemsSaveLoaded: true,
        shouldSaveKpiCardData: true,
        kpiCardData: null,
      };

    case SchedulerActionTypes.SaveScenarioUncreatedWorkOrders:
      return {
        ...state,
        uncreatedWorkOrdersSaveLoading: true,
        uncreatedWorkOrdersSaveLoaded: false,
      };

    case SchedulerActionTypes.ScenarioUncreatedWorkOrdersSaved:
      return {
        ...state,
        uncreatedWorkOrdersSaveLoading: false,
        uncreatedWorkOrdersSaveLoaded: true,
      };

    default:
      return state;
  }
}
