import { Pipe, PipeTransform } from '@angular/core';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from 'src/constants';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import * as _ from 'lodash';

@Pipe({
  name: 'toFixed',
})
export class ToFixedPipe implements PipeTransform {
  constructor(private readonly decimalHelper: DecimalHelper) {}

  /**
   * @param precision Provide -1 to use user's preference
   */
  public transform(
    value: string | number,
    precision: number = DECIMAL_DEFAULT_SCALE_LIMIT,
    isString: boolean = true,
  ): string | number {
    if (_.isNil(value)) {
      return value;
    }

    if (precision === -1) {
      precision = this.decimalHelper.decimalScaleLimit;
    }

    const fixedValue: string = this.decimalHelper.toFixedValue(value.toString(), precision);
    return isString ? fixedValue : this.decimalHelper.formattedNumberToNumber(fixedValue);
  }
}
