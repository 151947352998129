<div
  class="scw-mat-select-container"
  [class.has-errors]="hasErrors"
  [class.no-padding]="noPadding"
  [class.select-xs]="size === 'xs'"
  [class.select-sm]="size === 'sm'"
  [class.select-md]="size === 'md'"
>
  <label class="select-label" *ngIf="label">{{ label }}</label>
  <angular2-multiselect
    [id]="dropdownId"
    [class.color-select]="colorSelect"
    [class.preserve-item-style]="preserveItemStyle"
    [class.reset-width]="resetSelectionWidth"
    [data]="!search || searchBehavior !== 'live' ? data : searchResult"
    [settings]="settings"
    [(ngModel)]="inputModel"
    (ngModelChange)="onNgModelChange()"
    (onClose)="onCloseEmitter($event)"
    (onSelect)="onSelectEmitter($event)"
    (onDeSelect)="onDeSelectEmitter($event)"
    (onSelectAll)="onSelectAllEmitter($event)"
    (onDeSelectAll)="onDeSelectAllEmitter($event)"
    (onGroupSelect)="onGroupSelectEmitter($event)"
    (onGroupDeSelect)="onGroupDeSelectEmitter($event)"
    (onScrollToEnd)="onScrollToEndEmitter($event)"
    (onOpen)="onOpenEmitter($event)"
    #angularMultiSelect
  >
    <c-search>
      <ng-template>
        <scw-mat-search
          [buttonPosition]="searchButtonPosition"
          [placeholder]="settings.searchPlaceholderText"
          [behavior]="searchBehavior"
          [searchDelay]="searchDelay"
          [minLength]="searchMinLength"
          [maxLength]="searchMaxLength"
          [rules]="searchRules"
          [hasErrors]="hasSearchErrors"
          [preventSameSearchString]="searchPreventSameSearchString"
          [(inputModel)]="searchModel"
          (inputModelChange)="onSearchModelEmitter($event)"
          (onSearch)="onSearchString($event)"
          #searchComponent
          ngbTooltip="{{ 'filterCard.dropdown.findHere' | translate }}"
          triggers="manual"
          [placement]="['left', 'right']"
          #searchTooltip="ngbTooltip"
        ></scw-mat-search>
        <div class="scw-mat-small-button-wrapper">
          <scw-mat-small-button
            *ngIf="createComponent"
            [ngClass]="{ 'd-flex': isCreateSearchData, 'd-none': !isCreateSearchData }"
            [className]="['scw-mat-small-button-add-icon-button']"
            [disabled]="hasSearchErrors"
            [iconButton]="true"
            (onClick)="onCreateEmitter()"
          >
            <em class="fas fa-plus"></em>
          </scw-mat-small-button>
        </div>
        <ng-template [ngIf]="advancedSelect">
          <scw-mat-small-button
            [className]="['scw-mat-small-button-refresh-icon-button']"
            [disabled]="disabled"
            [iconButton]="true"
            (onClick)="onSearchStringRefresh()"
          >
            <em class="fas fa-sync-alt"></em>
          </scw-mat-small-button>
          <scw-mat-small-button
            [className]="['scw-mat-small-button-add-icon-button']"
            [disabled]="disabled"
            [iconButton]="true"
            (onClick)="onAddEmitter()"
          >
            <em class="fas fa-plus"></em>
          </scw-mat-small-button>
        </ng-template>
        <span
          class="list-message noDataLabel"
          *ngIf="createComponent"
          [ngClass]="{
            'd-flex': isCreateSearchData,
            'd-none': !isCreateSearchData,
            'create-item-label': this.isCreateSearchData && this.enableCheckAll && this.data?.length > 0
          }"
          >{{ createItemLabel }}</span
        >
        <scw-mat-button
          type="standard"
          [className]="['select-load-more']"
          [disabled]="disabled"
          *ngIf="showLoadMoreButton && settings.lazyLoading"
          (onClick)="onLoadMoreButtonClicked(searchTooltip)"
        >
          {{ 'filterCard.dropdown.loadMore' | translate }}
        </scw-mat-button>
      </ng-template>
    </c-search>
    <c-badge *ngIf="cItemTemplate && showItemTemplateInSelectedValue">
      <ng-template let-item="item">
        <ng-template *ngTemplateOutlet="cItemTemplate; context: { data: item }"> </ng-template>
      </ng-template>
    </c-badge>
    <ng-template [ngIf]="enableSearchResultList">
      <c-item>
        <ng-template let-item="item">
          <ng-container
            [ngTemplateOutlet]="searchResultsListTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        </ng-template>
      </c-item>
    </ng-template>
    <c-item *ngIf="cItemTemplate">
      <ng-template let-item="item">
        <ng-template *ngTemplateOutlet="cItemTemplate; context: { data: item }"> </ng-template>
      </ng-template>
    </c-item>
  </angular2-multiselect>
  <mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="!isLoading"></mat-progress-bar>
  <ng-template [ngIf]="errorText || hint">
    <div class="sub-text" [class.error]="errorText" [class.hint]="!errorText && hint">
      <em
        class="sub-text-icon fas"
        [class.fa-exclamation-circle]="errorText"
        [class.fa-info-circle]="!errorText && hint"
      ></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
      <ng-template [ngIf]="!errorText && hint"><span [innerHTML]="hint"></span></ng-template>
    </div>
  </ng-template>
</div>
