import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {combineLatest, from, Observable, of, switchMap} from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { IFaultCodeMappingRow } from '../../../../view/settings/opc-tag-automation-settings/fault-code-mapping/fault-code-mapping.model';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FaultCodeMappingService {
  private readonly routes = {
    faultCodeMapping: `${this.baseUrl}/opc-tag-automation-settings/fault-code-mapping`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<any>> {
    return this.http.get<GetManyResponseInterface<any>>(this.routes.faultCodeMapping, {
      params,
    });
  }

  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    faultCodeMappingRecord: BaseOneResponseInterface<IFaultCodeMappingRow>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    activities: ActivityCRUDInterface[];
    tasks: TaskFilterCRUDInterface[];
  }> {
    return this.http
      .get<BaseOneResponseInterface<IFaultCodeMappingRow>>(`${this.routes.faultCodeMapping}/${recordId}`, {
        params,
      })
      .pipe(
        switchMap((faultCodeMappingRecord) => {
          const search = {
            statusId: 1,
            siteId: faultCodeMappingRecord.data.siteId,
          };
          const lineHttpParams = new HttpParams()
            .set('s', JSON.stringify(search))
            .set('limit', '1000')
            .set('join', 'lineTypeName');

          const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

          const searchTask = {
            statusId: 1,
            activityId: faultCodeMappingRecord.data.activityId,
          };

          const tasksHttpParams = new HttpParams().set('s', JSON.stringify(searchTask)).set('limit', '1000');

          return combineLatest([
            this.lineService.getLines(lineHttpParams),
            this.siteService.getData(siteHttpParams),
            this.tasksService.getData(tasksHttpParams),
          ]).pipe(
            switchMap(([lineRecords, siteRecords, tasksRecords]) => {
              const selectedLine = _.find(lineRecords.data, { id: faultCodeMappingRecord.data.lineId });
              const activitiesSearch = {
                active: 1,
                id: { $in: selectedLine.activityIds.split(',') ?? [] },
              };

            const activitiesHttpParams = new HttpParams()
              .set('s', JSON.stringify(activitiesSearch))
              .set('limit', '1000');

            return from(this.activityService.getData(activitiesHttpParams)).pipe(switchMap((activityRecords) => {
              return of({
                    faultCodeMappingRecord,
                    sites: siteRecords,
                    lines: lineRecords,
                    activities: activityRecords,
                    tasks: tasksRecords,
                  });
            }));
          }),
        );
      }),
    );
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<any>> {
    return this.http.get<BaseOneResponseInterface<any>>(`${this.routes.faultCodeMapping}/${recordId}`, {
      params,
    });
  }

  public addOne(record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.post<BaseOneResponseInterface<any>>(`${this.routes.faultCodeMapping}`, record);
  }

  public editOne(recordId: number, record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.patch<BaseOneResponseInterface<any>>(`${this.routes.faultCodeMapping}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          faultCodeMappingIds: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.faultCodeMapping}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.faultCodeMapping}/${recordIds[0]}`);
  }
}
