import { Injectable } from '@angular/core';
import { HelperService } from '../../service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { LimitOptionsInterface } from '../../../view/reports/activity-logs/activity-logs.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogsTableService {
  public defaultTableHeaders: string[] = [
    'siteName',
    'lineType',
    'lineTitle',
    'activityName',
    'taskName',
    'start',
    'end',
    'duration',
    'shiftDay',
    'shiftName',
  ];

  constructor(private readonly helperService: HelperService, private readonly translate: TranslateService) {}

  public updateLimitOptionLabels(
    limitOptions: LimitOptionsInterface[],
    activityLogsTableDataCount: number,
  ): LimitOptionsInterface[] {
    return limitOptions.map((option) => {
      const rangeOptions = this.helperService.getRecordRangeOptions(activityLogsTableDataCount, Number(option.value));

      option.label = this.translate.instant(
        `activityLogs.download${option.isQuantityBased ? 'With' : 'Without'}SensorCount`,
        {
          fileCount: rangeOptions?.length,
        },
      );

      return option;
    });
  }
}
