import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  ETableType,
  ESqlMode,
  IExpectedSpeed,
  IExpectedSpeedFilter,
  IOeeCalculationRequest,
  IOeeCalculationResult,
  IPeriodicOeeCalculationParams,
  IPeriodicOeeCalculationTableRows,
  IWorkOrderComment,
  IWorkOrderLaborAssetInformation,
  IWorkOrderLaborAssetTableInformation,
  IWorkOrderPerformanceFilter,
  IWorkOrderPhaseInformation,
} from './work-order-performance.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  ActivityLogsResponseInterface,
  IActivityLog,
  IActivityLogsRequest,
} from '../root-cause-analysis/root-cause-analysis.model';
import { GetWorkOrderResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
} from '../../../shared/service/excel/excel-helper.service';
import * as ObjectActions from '../capacity/capacity.actions';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { ValueType } from 'exceljs';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../constants';
import { IPeriodicOeeCalculationData } from '../periodic-oee-calculation-review/periodic-oee-calculation-review.model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderPerformanceService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly workOrders: string = '/work-orders/';
  private readonly routes = {
    activityLogs: '/activity-histories/activity-logs',
    workOrders: `${this.workOrders}`,
    calculateOee: '/oee-calculation/calculate-oee',
    laborAndAssetDurations: `${this.workOrders}labor-and-asset-durations`,
    phaseInformation: `${this.workOrders}phase-information`,
    workOrderCloseFeedback: '/work-order-close-feedbacks/',
    comments: '/comments',
    periodicOeeCalculations: '/periodic-oee-calculations',
  };

  public getWorkOrderActivityLogs(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    const body: IActivityLogsRequest = {
      isBusinessDate: true,
      sites: -1,
      lines: -1,
      lineTypes: -1,
      activities: -1,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'work-order-performance',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: params.workOrderId,
    };

    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
      `${this.api}${this.routes.activityLogs}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getWorkOrderInformation(params: IWorkOrderPerformanceFilter): Observable<GetWorkOrderResponseInterface> {
    const httpParams: HttpParams = new HttpParams()
      .append('join', 'product||productId,description,packageSize,productSpeed,unit')
      .append('join', 'workOrderCloseFeedback')
      .append('join', 'scheduledLineDetail')
      .append('join', 'actualLineDetail')
      .append('join', 'site')
      .append('join', 'workOrderSplitNumber')
      .append('join', 'childWorkOrderSplitNumbers')
      .append('join', 'lastLineDetail')
      .append('join', 'job');

    return this.http
      .get<GetWorkOrderResponseInterface>(`${this.api}${this.routes.workOrders}${_.first(params.workOrderId)}`, {
        params: httpParams,
      })
      .pipe(
        mergeMap((workOrder: GetWorkOrderResponseInterface) => {
          if (workOrder.data.hasChildWorkOrder || workOrder.data.hasParentWorkOrder) {
            const workOrderIds: number[] = [
              workOrder.data.id,
              workOrder.data.workOrderSplitNumber?.parentWorkOrderId,
              ...(workOrder.data.childWorkOrderSplitNumbers ?? []).map((i) => i.childWorkOrderId),
            ];

            return this.http
              .get<GetManyResponseInterface<GetWorkOrderResponseInterface>>(`${this.api}${this.routes.workOrders}`, {
                params: httpParams
                  .set(
                    's',
                    JSON.stringify({
                      $or: [
                        {
                          id: {
                            $in: workOrderIds,
                          },
                        },
                        {
                          'workOrderSplitNumber.parentWorkOrderId': {
                            $in: workOrderIds,
                          },
                        },
                      ],
                    }),
                  )
                  .set('limit', STATIC_MAX_LIMIT_OF_CRUD),
              })
              .pipe(
                map((relatedWorkOrders) => {
                  workOrder.data['relatedWorkOrders'] = relatedWorkOrders?.data ?? [];

                  return workOrder;
                }),
              );
          }

          return of(workOrder);
        }),
      );
  }

  public getOeeCalculationResult(
    params: IWorkOrderPerformanceFilter,
  ): Observable<GetManyResponseInterface<IOeeCalculationResult>> {
    const body: IOeeCalculationRequest = {
      workOrderId: _.first(params.workOrderId),
      sqlMode: ESqlMode.BATCH,
      useReplica: true,
      cumulativeBatchMode: params.cumulativeBatchMode,
    };

    return this.http.post<GetManyResponseInterface<IOeeCalculationResult>>(
      `${this.api}${this.routes.calculateOee}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getPhaseInformation(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<IWorkOrderPhaseInformation>> {
    return this.http.post<BaseOneResponseInterface<IWorkOrderPhaseInformation>>(
      `${this.api}${this.routes.phaseInformation}`,
      {
        workOrderIds: params.workOrderId,
        useReplica: true,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getLaborInformation(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>> {
    return this.http.post<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>>(
      `${this.api}${this.routes.laborAndAssetDurations}`,
      {
        workOrderIds: params.workOrderId,
        useReplica: true,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getWorkOrderPeriodicOeeCalculations(
    objectData: IPeriodicOeeCalculationParams,
  ): Observable<GetManyResponseInterface<IPeriodicOeeCalculationData>> {
    let periodicOeeCalculationsParams: HttpParams = new HttpParams()
      .append('join', 'workOrderSchedule')
      .append('join', 'workOrderSchedule.scheduledLineDetail')
      .append('join', 'workOrderSchedule.lastLineDetail')
      .append('page', objectData.page ?? 1)
      .append('limit', objectData.rowsPerPage ?? 1000)
      .append('groupBy', 'site_id,line_id,work_order_schedule_id,scheduler_shift_id,start_date,end_date')
      .append('sort', 'startDate,DESC');

    const periodicOeeCalculationsFilter: object = {};

    if (objectData.workOrderId) {
      periodicOeeCalculationsFilter['work_order_schedule_id'] = { $in: objectData.workOrderId };
    }

    periodicOeeCalculationsParams = periodicOeeCalculationsParams.append(
      's',
      JSON.stringify(periodicOeeCalculationsFilter),
    );

    return this.http.get<GetManyResponseInterface<IPeriodicOeeCalculationData>>(
      `${this.api}${this.routes.periodicOeeCalculations}`,
      { params: periodicOeeCalculationsParams },
    );
  }

  public getWorkOrderComments(workOrderIds: number[]): Observable<GetManyResponseInterface<IWorkOrderComment>> {
    const httpParams: HttpParams = new HttpParams().set(
      's',
      JSON.stringify({
        workOrderId: {
          $in: workOrderIds,
        },
      }),
    );

    return this.http.get<GetManyResponseInterface<IWorkOrderComment>>(`${this.api}${this.routes.comments}`, {
      params: httpParams,
    });
  }

  public downloadExcel(
    data: IWorkOrderLaborAssetTableInformation[] | IPeriodicOeeCalculationTableRows[],
    excelType: ETableType,
  ): void {
    let excelName: string;

    switch (excelType) {
      case ETableType.LABOR:
        excelName = this.translate.instant('excel.laborLogs.excelName', {
          date: moment().tz(this.timezone).format(this.dateFormat$),
        });
        break;
      case ETableType.ASSET:
        excelName = this.translate.instant('excel.assetLogs.excelName', {
          date: moment().tz(this.timezone).format(this.dateFormat$),
        });
        break;
      case ETableType.COUNT:
        excelName = this.translate.instant('excel.workOrderPerformance.productionHistoryTableName', {
          date: moment().tz(this.timezone).format(this.dateFormat$),
        });
        break;
    }

    let sheetTitle: string;

    switch (excelType) {
      case ETableType.ASSET:
        sheetTitle = this.translate.instant('excel.assetLogs.excelSheetName');
        break;
      case ETableType.LABOR:
        sheetTitle = this.translate.instant('excel.laborLogs.excelSheetName');
        break;
      case ETableType.COUNT:
        sheetTitle = this.translate.instant('workOrderPerformance.productionHistoryTable.header');
        break;
    }

    const excelOptions: CreateExcelInterface = this.getExcelColumns(excelType);

    excelOptions.data = data;

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle,
        params: excelOptions,
        withData: true,
      },
    ];

    this.excelHelper.createExcel(excelName, { name: 'workOrderPerformance' }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadCapacityExcelCompleted());
      },
      () => {
        this.store.dispatch(new ObjectActions.FetchError({}));
      },
    );
  }

  private getExcelColumns(excelType: ETableType): CreateExcelInterface {
    const excelColumns: CreateExcelInterface =
      excelType !== ETableType.COUNT
        ? {
            columns: [
              {
                header: this.translate.instant('general.dataTable.header.siteName'),
                key: 'siteName',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('main.workOrder.line'),
                key: 'lineName',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('general.input.lineStation.label'),
                key: 'stationName',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header:
                  excelType === ETableType.LABOR
                    ? this.translate.instant('cico.modules.laborTracker.name')
                    : this.translate.instant('cico.modules.assetTracker.name'),
                key: 'sourceObjectName',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('general.dataTable.header.duration'),
                key: 'duration',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
            ],
          }
        : {
            columns: [
              {
                header: this.translate.instant('lineView.tableHeaders.workOrderName'),
                key: 'workOrder',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('lineView.tableHeaders.actualLine'),
                key: 'actualLine',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('lineView.tableHeaders.scheduledLine'),
                key: 'scheduledLine',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('main.workOrder.manuelCountHistoryTable.timestamp'),
                key: 'observedAt',
                width: ExcelColumnWidthEnum.DATE_TIME,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('excel.workOrderSchedule.oeeInitialCount.header'),
                key: 'initialCount',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('main.workOrder.goodCount'),
                key: 'goodCount',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
              {
                header: this.translate.instant('excel.workOrderSchedule.oeeScrapCount.header'),
                key: 'scrapCount',
                width: ExcelColumnWidthEnum.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dataValidation: {
                  type: CellTypes.CUSTOM,
                },
              },
            ],
          };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, false);

    return excelColumns;
  }

  public setWorkOrderPerformanceTaskDescription(activityLog: IActivityLog): void {
    if (activityLog.activityTypeText === ActivityTypes.RUN_TIME) {
      activityLog.taskName = activityLog.productDescription;
    }
  }
}
