import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  SimpleChanges,
} from '@angular/core';
import {
  DropdownOptionsInterface,
  LineAvailabilityPlanCostFactorInterface,
  LineAvailabilityQueryParams,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  LineAvailabilityStateInterface,
  ShiftInterface,
  LineAvailabilityCostFactorDataInterface,
} from '../../../store/line-availability/line-availability.model';
import { ActionsSubject } from '@ngrx/store';
import { LineAvailabilityClass } from '../Scheduler/LineAvailability.class';
import { ofType } from '@ngrx/effects';
import { Scheduler } from '@bryntum/schedulerpro';
import { ToastrService } from 'ngx-toastr';
import { SitesInterface } from '../../../store/work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import { ScwModalSize } from '../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { IShiftForm } from '../../../view/line-availability/line-availability.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as LineAvailabilityActions from '../../../store/line-availability/line-availability.actions';
import { BaseCrudResponse } from '../../model/interface/crud-response-interface.model';
import { StdTableItem } from '../../../view/settings/sites/sites.model';
import { HelperService } from '../../service/helper.service';
import { ScwMatSelectRule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ETableModalTypes } from '../../service/datatable/datatable.model';
import { FilterSiteState } from '../../../store/filter/site/site.reducer';
import { IInitialSiteLine } from '../../model/interface/generic.model';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';

@OnDestroyDecorator
@Component({
  selector: 'app-shift-management',
  templateUrl: './shift-management.component.html',
  styleUrls: [
    './shift-management.component.scss',
    '../../../../scss/custom.scss',
    '../plan-management/plan-management.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ShiftManagementComponent extends LineAvailabilityClass implements OnInit, OnDestroy {
  @ViewChild('bulk_delete_result_modal', { static: false }) BULK_DELETE_RESULT_MODAL_TEMPLATE: TemplateRef<void>;
  @Input() defaultWeekScheduler: Scheduler;
  @Input() exceptionScheduler: Scheduler;
  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  public shifts: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface> = {
    count: 0,
    data: [],
    date: '',
    page: 0,
    pageCount: 0,
    success: false,
    total: 0,
  };
  public indexedSites;
  public costFactors$: LineAvailabilityPlanCostFactorInterface;
  public costFactorsData: LineAvailabilityCostFactorDataInterface[] = [];
  public selectedShifts: number[] = [];
  public selectedShift: ShiftInterface;
  public openedModalType: ETableModalTypes;
  public shiftForm: IShiftForm;
  public ETableModalTypes = ETableModalTypes;
  public addEditModalRef: NgbModalRef;
  public crudModalRef: NgbModalRef;
  public bulkErrorModalRef: NgbModalRef;
  public shiftFormRules = this.getFormRules();
  public searchBoxText: string = '';
  public failedBulkDeleteShiftNames: string[] = [];
  public successfullyDeletedShiftCount: number = 0;
  public isWorkingShiftData: DropdownOptionsInterface[] = [
    {
      id: 1,
      name: this.translate.instant('general.no'),
    },
    {
      id: 0,
      name: this.translate.instant('general.yes'),
    },
  ];
  public initialShiftForm: IShiftForm = {
    name: { isChecked: false, value: null },
    siteIds: { isChecked: false, value: null },
    costFactorId: { isChecked: false, value: null },
    isNotAvailable: { isChecked: false, value: null },
  };
  public queryParams: LineAvailabilityQueryParams = {
    page: 1,
    pageSize: 1000,
    search: null,
  };
  public sites$: SitesInterface[];
  public siteList: number[] = [];

  constructor(
    private readonly lineAvailabilityActions: ActionsSubject,
    private readonly toast: ToastrService,
    public readonly checkBoxElementRef: ElementRef,
    private readonly ngbModal: NgbModal,
    public readonly helperService: HelperService,
  ) {
    super();
    this.tableHeaders = [
      {
        value: null,
        name: '',
        selected: true,
        sortable: false,
        width: '39px',
      },
      {
        value: 'sites',
        name: this.translate.instant('general.site'),
        sortable: false,
      },
      {
        value: 'name',
        name: this.translate.instant('lineAvailability.shift.shiftTable.shiftName'),
      },
      {
        value: 'costFactor.name',
        name: this.translate.instant('lineAvailability.costFactor.costFactorName'),
      },
      {
        value: 'isNotAvailable',
        name: this.translate.instant('lineAvailability.shift.shiftTable.workingShift'),
      },
    ];
  }

  ngOnInit(): void {
    this.tableQuery.join = 'costFactor';
    this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityShiftsData());
    this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityCostFactorsData());
    this.subscriptions = [
      this.store.select('lineAvailabilityScenarioStore').subscribe((state: LineAvailabilityStateInterface) => {
        this.isLoading$ = state.shiftsLoading;

        if (!state.shiftsLoading && state.shiftsLoaded) {
          this.shifts = HelperService.cloneDeep(state.shiftManagement);

          this.helperService.closeModalsOnMissingIdentifier(this.selectedShift, this.openedModalType, [
            this.crudModalRef,
            this.addEditModalRef,
          ]);

          const shifts = _.clone(this.shifts.data);
          for (const shift of shifts) {
            let isVisible = false;
            shift.isNotAvailable = String(shift.isNotAvailable);
            for (const site of shift.schedulerShiftsSiteAssignment) {
              if (_.indexOf(this.siteList, site.siteId) > -1) {
                isVisible = true;
              }
            }
            if (isVisible === false) {
              _.pull(this.shifts.data, shift);
            }
          }
        }

        if (state.costFactorLoaded === true && state.costFactorLoading === false) {
          this.costFactors$ = HelperService.cloneDeep(state.costFactors);
        }
      }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.CreateShiftLoaded))
        .subscribe(() => {
          this.loadData();
          if (this.addEditModalRef) {
            this.addEditModalRef.dismiss();
          }
          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
        }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.DeleteShiftCompleted))
        .subscribe(() => {
          this.loadData();
          if (this.crudModalRef) {
            this.crudModalRef.dismiss();
          }

          this.helperService.selectOrUnselectAllCheckboxes(
            false,
            this.checkBoxElementRef,
            'shift-management',
            this.shifts.data,
          );
          this.selectedShifts = [];
          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
        }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.EditShiftCompleted))
        .subscribe((response: LineAvailabilityActions.EditShiftCompleted) => {
          this.loadData();

          if (this.defaultWeekScheduler !== undefined) {
            this.defaultWeekScheduler.eventStore.findByField('shiftId', response.payload.id).forEach((item: any) => {
              item.data.set('name', response.payload.name);
              item.data.set('isNotAvailable', response.payload.isNotAvailable);
              item.data.cls = response.payload.isNotAvailable ? 'not-available' : '';
            });
          }

          if (this.exceptionScheduler !== undefined) {
            this.exceptionScheduler.eventStore.findByField('shiftId', response.payload.id).forEach((item: any) => {
              item.data.set('name', response.payload.name);
              item.data.set('isNotAvailable', response.payload.isNotAvailable);
              item.data.cls = response.payload.isNotAvailable ? 'not-available' : '';
            });
          }

          if (this.addEditModalRef) {
            this.addEditModalRef.dismiss();
          }

          this.helperService.selectOrUnselectAllCheckboxes(
            false,
            this.checkBoxElementRef,
            'shift-management',
            this.shifts.data,
          );
          this.selectedShifts = [];
          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
        }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.FetchError))
        .subscribe((payload: LineAvailabilityActions.FetchError) => {
          if (payload.actionType === LineAvailabilityActions.LineAvailabilityActionTypes.EditShift) {
            if (this.addEditModalRef) {
              this.addEditModalRef.dismiss();
            }

            this.helperService.selectOrUnselectAllCheckboxes(
              false,
              this.checkBoxElementRef,
              'shift-management',
              this.shifts.data,
            );
            this.selectedShifts = [];
          }
        }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.DeleteShiftsCompleted))
        .subscribe((objectData: { response: { data: BaseCrudResponse[] } }) => {
          const data: BaseCrudResponse[] = HelperService.cloneDeep(objectData.response.data);
          const checkedShifts: LineAvailabilityShiftDataInterface[] = this.shifts.data.filter(
            (shift: StdTableItem<LineAvailabilityShiftDataInterface>) => shift.isChecked,
          );

          if (data.some((response: BaseCrudResponse) => !response.success)) {
            this.successfullyDeletedShiftCount = data.filter((response: BaseCrudResponse) => response.success).length;
            this.failedBulkDeleteShiftNames = data
              .map((response: BaseCrudResponse, index: number) => ({ ...response, index }))
              .filter((response: BaseCrudResponse) => !response.success)
              .map((response: BaseCrudResponse & { index: number }) => checkedShifts[response.index].name);
            this.bulkErrorModalRef = this.ngbModal.open(this.BULK_DELETE_RESULT_MODAL_TEMPLATE, {
              keyboard: false,
              backdrop: 'static',
              windowClass: ScwModalSize.small,
            });
          } else {
            this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          }

          this.helperService.selectOrUnselectAllCheckboxes(
            false,
            this.checkBoxElementRef,
            'shift-management',
            this.shifts.data,
          );
          this.selectedShifts = [];
          this.loadData();
        }),

      this.store.select('siteFilter').subscribe((state: FilterSiteState) => {
        if (!state.isLoading && state.isLoaded) {
          this.sites$ = HelperService.cloneDeep(state.data).sort(this.helperService.dropdownOptionCompareFunction);
          const options = this.prepareSiteOptions(this.sites$);
          this.siteList = options.siteList;
          this.indexedSites = options.indexedSites;
        }
      }),
    ];
  }

  public override onDataRequestHandler(params): void {
    this.selectedShifts = [];
    this.helperService.selectOrUnselectAllCheckboxes(
      false,
      this.checkBoxElementRef,
      'shift-management',
      this.shifts.data,
    );

    if (this.tableQuery.search.trim()) {
      params.search = this.tableQuery.search.trim();
    }

    super.onDataRequestHandler(params);
    this.tableQuery.join = 'costFactor';
    this.loadData();
  }

  public showShiftDeleteModal(templateRef: TemplateRef<any>): void {
    this.openedModalType = ETableModalTypes.delete;
    this.crudModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.small,
    });
  }

  public showShiftTransactionsModals(type: ETableModalTypes, templateRef: TemplateRef<any>): void {
    if (!this.costFactors$?.data) {
      this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityCostFactorsData());
    }

    this.shiftForm = this.initialShiftForm;
    switch (type) {
      case ETableModalTypes.add:
        this.resetForm();
        break;
      case ETableModalTypes.edit:
        this.castCheckedShiftToForm();
        break;
    }

    this.openedModalType = type;
    this.addEditModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: `scw-modal-all-scrollable ${ScwModalSize.small}`,
    });
  }

  public onCloseModal(modal: NgbModalRef): void {
    modal.dismiss();
  }

  private resetForm(): void {
    this.shiftForm.siteIds.value = null;
    this.shiftForm.name.value = null;
    this.shiftForm.costFactorId.value = null;
    this.shiftForm.isNotAvailable.value = null;

    const siteLineSelection: IInitialSiteLine = this.helperService.getAddModalSiteLineSelection();
    this.shiftForm.siteIds.value = siteLineSelection.site;
    if (this.shiftForm.siteIds.value.length) {
      this.onSiteChange(null, [this.shiftForm.siteIds.value[0].id]);
    }
  }

  private castCheckedShiftToForm(): void {
    const schedulerShiftSiteAssignments = _.get(this.selectedShift, 'schedulerShiftsSiteAssignment', null);

    if (schedulerShiftSiteAssignments) {
      this.shiftForm.siteIds.value = schedulerShiftSiteAssignments.map((schedulerShiftSiteAssignment) =>
        this.sites$.find((data: SitesInterface) => data.id === schedulerShiftSiteAssignment.siteId),
      );
    }

    const selectedSites: number[] = [];
    this.shiftForm.siteIds.value.forEach((sites: { id: number }) => {
      selectedSites.push(sites.id);
    });
    this.onSiteChange(null, selectedSites);

    this.shiftForm.costFactorId.value = [
      this.costFactors$.data.find((costFactor) => costFactor.id === _.get(this.selectedShift, 'costFactorId', null)),
    ];
    this.shiftForm.isNotAvailable.value = [
      this.isWorkingShiftData.find(
        (isNotAvability) => isNotAvability.id === Number(_.get(this.selectedShift, 'isNotAvailable', null)),
      ),
    ];
    this.shiftForm.name.value = _.get(this.selectedShift, 'name', null);
  }

  public onSiteChange(changes: SimpleChanges[], selectedSite: number[]): void {
    const selectedSiteList: number[] = changes?.map((item: SimpleChanges) => Number(item['id'])) || selectedSite;
    this.shiftForm.costFactorId.value = null;
    if (selectedSiteList.length > 0) {
      this.costFactorsData = this.costFactors$.data.filter((item: LineAvailabilityCostFactorDataInterface) => {
        let isInclude: boolean = true;
        selectedSiteList.forEach((selectedSiteID: number) => {
          if (!item.schedulerCostFactorsSiteAssignment.some(scf => scf.siteId === selectedSiteID)) {
            isInclude = false;
            return;
          }
        });

        return isInclude;
      });
    } else {
      this.costFactorsData = [];
    }
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.tableQuery.search = searchText;
    this.loadData();
    this.helperService.selectOrUnselectAllCheckboxes(
      false,
      this.checkBoxElementRef,
      'shift-management',
      this.shifts.data,
    );
    this.selectedShifts = [];
  }

  private getFormRules(): {
    costFactorId: ScwMatSelectRule[];
    name: ScwMatInputRule[];
    isNotAvailable: ScwMatSelectRule[];
    siteIds: ScwMatSelectRule[];
  } {
    return {
      name: [
        {
          required: true,
        },
      ],
      siteIds: [
        {
          required: true,
        },
      ],
      costFactorId: [
        {
          required: true,
        },
      ],
      isNotAvailable: [
        {
          required: true,
        },
      ],
    };
  }

  public onDeleteSubmit(): void {
    this.store.dispatch(new LineAvailabilityActions.DeleteShift(...HelperService.argumentClone(this.selectedShift.id)));
    this.crudModalRef.close();
  }

  public onBulkDeleteSubmit(): void {
    this.store.dispatch(new LineAvailabilityActions.DeleteShifts(...HelperService.argumentClone(this.selectedShifts)));
    this.crudModalRef.close();
  }

  public onAddEditSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const payload: ShiftInterface = ShiftManagementComponent.createPayloadFromForm(this.shiftForm);

    if (this.openedModalType === ETableModalTypes.add) {
      this.store.dispatch(
        new LineAvailabilityActions.CreateShift(
          ...HelperService.argumentClone({
            siteIds: payload.siteIds,
            name: payload.name,
            costFactorId: payload.costFactorId,
            isNotAvailable: payload.isNotAvailable,
          }),
        ),
      );
      return;
    }

    const selectedSiteIdsNumber = [];

    for (const site of payload.siteIds) {
      selectedSiteIdsNumber.push({ siteId: Number(site) });
    }
    this.store.dispatch(
      new LineAvailabilityActions.EditShift(
        ...HelperService.argumentClone({
          id: this.selectedShift.id,
          schedulerShiftsSiteAssignment: selectedSiteIdsNumber,
          name: payload.name,
          isNotAvailable: payload.isNotAvailable,
          costFactorId: payload.costFactorId,
        }),
      ),
    );
  }

  private static createPayloadFromForm(shiftForm: IShiftForm): ShiftInterface {
    const name: string = _.get(shiftForm.name, 'value');

    return {
      name: name ? name.trim() : name,
      siteIds: _.get(shiftForm.siteIds, 'value').map((site) => site.id),
      costFactorId: _.get(shiftForm.costFactorId, 'value')[0].id,
      isNotAvailable: _.get(shiftForm.isNotAvailable, 'value')[0].id === 1,
    };
  }

  public onChangeShiftTableCheckboxes(event): void {
    const inputValue = Number(event.source.value);

    if (event.checked) {
      this.selectedShifts.push(inputValue);
    } else {
      const index = this.selectedShifts?.indexOf(inputValue);
      this.selectedShifts.splice(index, 1);
    }

    this.successfullyDeletedShiftCount = this.selectedShifts.length;
    this.selectedShift = _.find(this.shifts.data, { id: this.selectedShifts[0] });
  }

  private loadData(): void {
    this.store.dispatch(
      new LineAvailabilityActions.LoadLineAvailabilityShiftsData(...HelperService.argumentClone(this.tableQuery)),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
