import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ChangeStationActivityActions from './change-station-activity.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { ChangeStationActivityService } from './change-station-activity.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { IChangeStationActivityRow } from '../../../../view/settings/opc-tag-automation-settings/change-station-activity/change-station-activity.model';
import { LineStationService } from '../../../../shared/service/line-station/line-station.service';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';

@Injectable()
export class ChangeStationActivityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ChangeStationActivityService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly stationService: LineStationService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.CHANGE_STATION_ACTIVITY_DATA_LOADING),
      switchMap((payload: ChangeStationActivityActions.ChangeStationActivityLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: payload.params.siteIds } });
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: payload.params.lineIds } });
        }

        if (payload.params?.searchText) {
          andConditions.push({
            $or: [
              {
                takeActionStates: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
            ],
          });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        if (payload.params.orderBy && payload.params.orderDesc !== undefined) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new ChangeStationActivityActions.ChangeStationActivityLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ChangeStationActivityActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeStationActivityActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.GET_CHANGE_STATION_ACTIVITY),
      switchMap((objectData: ChangeStationActivityActions.GetChangeStationActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneWithDropdowns(objectData.recordId, new HttpParams()).pipe(
          switchMap(
            (response: {
              changeStationActivityRecord: BaseOneResponseInterface<IChangeStationActivityRow>;
              lines: GetManyResponseInterface<LineCRUDInterface>;
              sites: SiteCRUDInterface[];
              stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
            }) => {
              return of(
                new ChangeStationActivityActions.SiteDataLoaded(response.sites),
                new ChangeStationActivityActions.LineDataCompleted(response.lines),
                new ChangeStationActivityActions.StationsDataCompleted(response.stations),
                new ChangeStationActivityActions.GetChangeStationActivityCompleted(
                  response.changeStationActivityRecord,
                ),
                new AppActions.HideLoader(),
              );
            },
          ),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.ADD_CHANGE_STATION_ACTIVITY),
      switchMap((objectData: ChangeStationActivityActions.AddChangeStationActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.changeStationActivity).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeStationActivityRow>) => {
            const actions = [
              new ChangeStationActivityActions.AddChangeStationActivityCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.EDIT_CHANGE_STATION_ACTIVITY),
      switchMap((objectData: ChangeStationActivityActions.EditChangeStationActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<IChangeStationActivityRow>) => {
            const actions = [
              new ChangeStationActivityActions.EditChangeStationActivityCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeStationActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.DELETE_CHANGE_STATION_ACTIVITY),
      switchMap((objectData: ChangeStationActivityActions.DeleteChangeStationActivity) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ChangeStationActivityActions.DeleteChangeStationActivityCompleted(
                objectData.payload.length > 1,
                response,
              ),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: ChangeStationActivityActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            return of(new ChangeStationActivityActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ChangeStationActivityActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeStationActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: ChangeStationActivityActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new ChangeStationActivityActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeStationActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getStations = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeStationActivityActions.ActionTypes.STATIONS_DATA_LOADING),
      switchMap((objectData: ChangeStationActivityActions.StationsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.stationService.getLineStationData(objectData.lineId)).pipe(
          switchMap((response: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) => {
            return of(new ChangeStationActivityActions.StationsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ChangeStationActivityActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ChangeStationActivityActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
