import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { LineAvailabilityClass } from '../Scheduler/LineAvailability.class';
import {
  CostFactorInterface,
  LineAvailabilityCostFactorDataInterface,
  LineAvailabilityPlanCostFactorInterface,
  LineAvailabilityQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as LineAvailabilityActions from '../../../store/line-availability/line-availability.actions';
import { ofType } from '@ngrx/effects';
import { SitesInterface } from '../../../store/work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import { ICostFactorForm } from '../../../view/line-availability/line-availability.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StdTableItem } from '../../../view/settings/sites/sites.model';
import { ScwModalSize } from '../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { BaseCrudResponse } from '../../model/interface/crud-response-interface.model';
import { HelperService } from '../../service/helper.service';
import { ScwMatRulesInterface, ScwMatSelectRule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { DecimalHelper } from 'src/app/shared/helper/decimal/decimal-helper';
import { User } from 'src/app/store/user/model';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from 'src/constants';
import { FilterSiteState } from '../../../store/filter/site/site.reducer';
import { SiteCRUDInterface } from '../filter/filter.class';
import { IInitialSiteLine } from '../../model/interface/generic.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ETableModalTypes } from '../../service/datatable/datatable.model';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';

@OnDestroyDecorator
@Component({
  selector: 'app-cost-factor-management',
  templateUrl: './cost-factor-management.component.html',
  styleUrls: ['./cost-factor-management.component.scss', '../plan-management/plan-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CostFactorManagementComponent extends LineAvailabilityClass implements OnInit, OnDestroy {
  @ViewChild('bulk_delete_result_modal', { static: false }) BULK_DELETE_RESULT_MODAL_TEMPLATE: TemplateRef<void>;
  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  public costFactors$: LineAvailabilityPlanCostFactorInterface;
  public costFactorData$: LineAvailabilityCostFactorDataInterface[] = [];
  public costFactorForm: ICostFactorForm;
  public formPattern: string;
  public costFactorLoading$: boolean = false;
  public sites$: SiteCRUDInterface[];
  public indexedSites;
  public selectedCostFactors: number[] = [];
  public selectedCostFactor: CostFactorInterface;
  public openedModalType: ETableModalTypes;
  public ETableModalTypes = ETableModalTypes;
  public crudModalRef: NgbModalRef;
  public bulkErrorModalRef: NgbModalRef;
  public addEditModalRef: NgbModalRef;
  public searchBoxText: string = '';
  public costFactorFormRules = this.getFormRules();
  public failedBulkDeleteCostFactorNames: string[] = [];
  public successfullyDeletedCostFactorCount: number = 0;
  public initialCostFactorForm: ICostFactorForm = {
    name: { isChecked: false, value: null },
    siteIds: { isChecked: false, value: null },
    factor: { isChecked: false, value: null },
  };
  public queryParams: LineAvailabilityQueryParams = {
    page: 1,
    pageSize: 1000,
    search: null,
  };
  public decimalSeparator$;
  public decimalScale$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  constructor(
    private readonly lineAvailabilityActions: ActionsSubject,
    public readonly checkBoxElementRef: ElementRef,
    private readonly ngbModal: NgbModal,
    public readonly helperService: HelperService,
    public readonly decimalHelper: DecimalHelper,
  ) {
    super();
    this.tableHeaders = [
      {
        value: null,
        name: '',
        selected: true,
        sortable: false,
        width: '39px',
      },
      {
        value: 'sites',
        name: this.translate.instant('general.site'),
        sortable: false,
      },
      {
        value: 'name',
        name: this.translate.instant('costFactorManagement.costFactorName.label'),
      },
      {
        value: 'factor',
        name: this.translate.instant('lineAvailability.costFactor.costFactorName'),
      },
    ];
  }

  ngOnInit(): void {
    this.loadData();
    this.subscriptions = [
      this.store.select('user').subscribe((state: User) => {
        this.decimalSeparator$ = state.decimalSeparator;
        this.decimalScale$ = state.decimalScaleLimit;
        this.formPattern = `^([0-9]{1,})(([${this.decimalSeparator$}]{1})([0-9]{1,}))?$`;
      }),
      this.store.select('lineAvailabilityScenarioStore').subscribe((state) => {
        if (!state.costFactorLoading && state.costFactorLoaded) {
          this.costFactors$ = HelperService.cloneDeep(state.costFactors);
          this.costFactorData$ = HelperService.cloneDeep(this.costFactors$?.data);
        }

        this.costFactorLoading$ = state.costFactorLoading;

        this.helperService.closeModalsOnMissingIdentifier(this.selectedCostFactor, this.openedModalType, [
          this.crudModalRef,
          this.addEditModalRef,
        ]);
      }),

      this.lineAvailabilityActions
        .pipe(
          ofType(
            LineAvailabilityActions.LineAvailabilityActionTypes.CreateCostFactorLoaded,
            LineAvailabilityActions.LineAvailabilityActionTypes.EditCostFactorCompleted,
            LineAvailabilityActions.LineAvailabilityActionTypes.DeleteCostFactorCompleted,
          ),
        )
        .subscribe(() => {
          this.loadData();
          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          if (this.addEditModalRef) {
            this.addEditModalRef.dismiss();
          }
          this.helperService.selectOrUnselectAllCheckboxes(
            false,
            this.checkBoxElementRef,
            'cost-factor',
            this.costFactors$.data,
          );
          this.selectedCostFactors = [];
        }),
      this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.DeleteCostFactorsCompleted))
        .subscribe((objectData: { response: { data: BaseCrudResponse[] } }) => {
          const data: BaseCrudResponse[] = HelperService.cloneDeep(objectData.response.data);
          const checkedCostFactors: LineAvailabilityCostFactorDataInterface[] = this.costFactorData$.filter(
            (costFactor: StdTableItem<LineAvailabilityCostFactorDataInterface>) => costFactor.isChecked,
          );

          if (data.some((response: BaseCrudResponse) => !response.success)) {
            this.successfullyDeletedCostFactorCount = data.filter(
              (response: BaseCrudResponse) => response.success,
            ).length;

            this.failedBulkDeleteCostFactorNames = data
              .map((response: BaseCrudResponse, index: number) => ({ ...response, index }))
              .filter((response: BaseCrudResponse) => !response.success)
              .map((response: BaseCrudResponse & { index: number }) => checkedCostFactors[response.index].name);

            this.bulkErrorModalRef = this.ngbModal.open(this.BULK_DELETE_RESULT_MODAL_TEMPLATE, {
              keyboard: false,
              backdrop: 'static',
              windowClass: ScwModalSize.small,
            });
          } else {
            this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          }

          this.loadData();
          this.selectedCostFactors = [];
          this.helperService.selectOrUnselectAllCheckboxes(
            false,
            this.checkBoxElementRef,
            'cost-factor',
            this.costFactors$.data,
          );
        }),

        this.lineAvailabilityActions
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.FetchError))
        .subscribe(() => {
          this.costFactorData$ = this.costFactorData$.map(
            (costFactorDatum: LineAvailabilityCostFactorDataInterface) => {
              return {
                ...costFactorDatum,
                isChecked: this.selectedCostFactors.includes(costFactorDatum.id),
              };
            },
          );
        }),

      this.store.select('siteFilter').subscribe((state: FilterSiteState) => {
        if (!state.isLoading && state.isLoaded) {
          this.sites$ = HelperService.cloneDeep(state.data).sort(this.helperService.dropdownOptionCompareFunction);
          const options = this.prepareSiteOptions(this.sites$);
          this.indexedSites = options.indexedSites;
        }
      }),
    ];
  }

  private loadData(): void {
    this.store.dispatch(
      new LineAvailabilityActions.LoadLineAvailabilityCostFactorsData(...HelperService.argumentClone(this.tableQuery)),
    );
  }

  public override onDataRequestHandler(params): void {
    this.selectedCostFactors = [];
    this.helperService.selectOrUnselectAllCheckboxes(
      false,
      this.checkBoxElementRef,
      'cost-factor',
      this.costFactors$.data,
    );

    if (this.tableQuery.search) {
      params.search = this.tableQuery.search;
    }

    super.onDataRequestHandler(params);
    this.loadData();
  }

  private castCheckedCostFactorToForm(): void {
    const schedulerCostFactorsSiteAssignments = _.get(
      this.selectedCostFactor,
      'schedulerCostFactorsSiteAssignment',
      null,
    );

    if (schedulerCostFactorsSiteAssignments) {
      this.costFactorForm.siteIds.value = schedulerCostFactorsSiteAssignments.map(
        (schedulerCostFactorsSiteAssignment) =>
          this.sites$.find((data: SitesInterface) => data.id === schedulerCostFactorsSiteAssignment.siteId),
      );
    }

    this.costFactorForm.factor.value = this.decimalHelper.replaceDecimalSeparator(
      this.decimalHelper.removeTrailingZeros(_.get(this.selectedCostFactor, 'factor', null)),
    );
    this.costFactorForm.name.value = _.get(this.selectedCostFactor, 'name', null);
  }

  private resetForm(): void {
    this.costFactorForm.siteIds.value = null;
    this.costFactorForm.factor.value = null;
    this.costFactorForm.name.value = null;

    const siteLineSelection: IInitialSiteLine = this.helperService.getAddModalSiteLineSelection();
    this.costFactorForm.siteIds.value = siteLineSelection.site;
  }

  public showCostFactorTransactionsModals(type: ETableModalTypes, templateRef: TemplateRef<any>): void {
    this.costFactorForm = this.initialCostFactorForm;

    switch (type) {
      case ETableModalTypes.add:
        this.resetForm();
        break;
      case ETableModalTypes.edit:
        this.castCheckedCostFactorToForm();
        break;
    }

    this.openedModalType = type;
    this.addEditModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: `${ScwModalSize.small} scw-modal-all-scrollable`,
    });
  }

  public showCostFactorDeleteModal(templateRef: TemplateRef<any>): void {
    this.openedModalType = ETableModalTypes.delete;

    this.crudModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.small,
    });
  }

  public onAddEditSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const payload: CostFactorInterface = CostFactorManagementComponent.createPayloadFromForm(this.costFactorForm);

    if (this.openedModalType === ETableModalTypes.add) {
      this.store.dispatch(
        new LineAvailabilityActions.CreateCostFactor({
          siteIds: payload.siteIds,
          name: payload.name,
          factor: this.decimalHelper.sanitizeString(payload.factor),
        }),
      );
      return;
    }

    const selectedSiteIdsNumber = [];

    for (const site of payload.siteIds) {
      selectedSiteIdsNumber.push({ siteId: Number(site) });
    }
    this.store.dispatch(
      new LineAvailabilityActions.EditCostFactor({
        id: this.selectedCostFactor.id,
        schedulerCostFactorsSiteAssignment: selectedSiteIdsNumber,
        name: payload.name,
        factor: this.decimalHelper.sanitizeString(payload.factor),
      }),
    );
  }

  private static createPayloadFromForm(costFactorForm: ICostFactorForm): CostFactorInterface {
    const name: string = _.get(costFactorForm.name, 'value');
    return {
      name: name ? name.trim() : name,
      siteIds: _.get(costFactorForm.siteIds, 'value').map((site) => site.id),
      factor: _.get(costFactorForm.factor, 'value'),
    };
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.tableQuery.search = searchText;
    this.loadData();
    this.helperService.selectOrUnselectAllCheckboxes(
      false,
      this.checkBoxElementRef,
      'cost-factor',
      this.costFactors$.data,
    );
    this.selectedCostFactors = [];
  }

  public onCloseModal(modal: NgbModalRef): void {
    modal.dismiss();
  }

  public onChangeCostFactorTableCheckboxes(event): void {
    const inputValue = Number(event.source.value);

    if (event.checked) {
      this.selectedCostFactors.push(inputValue);
    } else {
      const index = this.selectedCostFactors?.indexOf(inputValue);
      this.selectedCostFactors.splice(index, 1);
    }
    this.successfullyDeletedCostFactorCount = this.selectedCostFactors.length;
    this.selectedCostFactor = _.find(this.costFactorData$, { id: this.selectedCostFactors[0] });
  }

  public onDeleteSubmit(): void {
    this.store.dispatch(new LineAvailabilityActions.DeleteCostFactor(this.selectedCostFactor.id));
    this.crudModalRef.close();
  }

  public onBulkDeleteSubmit(): void {
    this.store.dispatch(new LineAvailabilityActions.DeleteCostFactors(this.selectedCostFactors));
    this.crudModalRef.close();
  }

  private getFormRules(): { name: ScwMatInputRule[]; siteIds: ScwMatSelectRule[]; factor: ScwMatInputRule[] } {
    return {
      name: [
        {
          required: true,
        },
      ],
      factor: [
        this.decimalHelper.getDecimalNonZeroInputRule(),
        {
          required: true,
        },
      ],
      siteIds: [
        {
          required: true,
        },
      ],
    };
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
