import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './custom-dashboards.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { of } from 'rxjs';
import { ICustomDashboard } from './custom-dashboards.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { HttpParams } from '@angular/common/http';
import { CustomDashboardService } from './custom-dashboards.service';
import { HTTPHelperService } from '../../../shared/service/http/http.helper.service';

@Injectable()
export class CustomDashboardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: CustomDashboardService,
    private readonly httpHelperService: HTTPHelperService,
  ) {}

  $fetchCustomDashboardData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.FetchCustomDashboardsData),
      switchMap((objectData: ObjectActions.FetchCustomDashboardsData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters(objectData.parameters);
        return this.service.getCustomDashboardData(params).pipe(
          switchMap((response: GetManyResponseInterface<ICustomDashboard>) => {
            return of(new ObjectActions.FetchCustomDashboardsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $updateCustomDashboard = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.UpdateCustomDashboard),
      switchMap((objectData: ObjectActions.UpdateCustomDashboard) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateCustomDashboard(objectData.id, objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<ICustomDashboard>) => {
            return of(new ObjectActions.UpdateCustomDashboardCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $createNewCustomDashboard = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.CreateNewCustomDashboard),
      switchMap((objectData: ObjectActions.CreateNewCustomDashboard) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createNewCustomDashboard(objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<ICustomDashboard>) => {
            return of(new ObjectActions.CreateNewCustomDashboardCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteCustomDashboard = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.DeleteCustomDashboard),
      switchMap((objectData: ObjectActions.DeleteCustomDashboard) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteCustomDashboard(objectData.id).pipe(
          switchMap((response: GetManyResponseInterface<ICustomDashboard>) => {
            return of(new ObjectActions.DeleteCustomDashboardCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteCustomDashboards = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.BulkDeleteCustomDashboard),
      switchMap((objectData: ObjectActions.BulkDeleteCustomDashboard) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteCustomDashboards(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteCustomDashboardCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $cloneCustomDashboard = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardsActionTypes.CloneCustomDashboard),
      switchMap((objectData: ObjectActions.CloneCustomDashboard) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.cloneCustomDashboard(objectData.id, objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<ICustomDashboard>) => {
            return of(new ObjectActions.CloneCustomDashboardCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
