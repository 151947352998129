import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { HttpClient } from '@angular/common/http';
import {
  IOeeCalculationRequest,
  IOeeCalculationResult,
} from '../../../store/reports/work-order-performance/work-order-performance.model';

@Injectable({
  providedIn: 'root',
})
export class OeeCalculationService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  public getData(
    params: IOeeCalculationRequest,
    options: Parameters<HttpClient['get']>[1] = {},
  ): Observable<GetManyResponseInterface<IOeeCalculationResult>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResult>>(
      `${this.api}/oee-calculation/calculate-oee`,
      params,
      {
        ...options,
        headers: {
          ...options.headers,
          'X-HTTP-Method': 'GET',
        },
      },
    );
  }
}
