<ng-container *ngFor="let header of headers; trackBy: datatableHeaderTrackBy">
  <ng-container
    *ngTemplateOutlet="
      cellTemplate;
      context: { $implicit: (header | datatableCellFormatter : row : formatter | async), header: header }
    "
  ></ng-container>
</ng-container>

<ng-template #cellTemplate let-context let-header="header">
  <td [ngStyle]="context?.wrapper?.style" [ngClass]="context?.wrapper?.class">
    <ng-container *ngIf="!isLoading && context; else loading">
      <ng-container *ngIf="context?.customElement; else ngContentHeader">
        <ng-template scwDynamicComponent [context]="context"></ng-template>
      </ng-container>
      <ng-template #ngContentHeader>
        <ng-container
          *ngIf="
            ngContentHeaders?.length && (ngContentHeaders | callFunction : isHeaderExistsInContentHeaders : [header]);
            else basic
          "
        >
          <ng-container
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: context, header: header }"
          ></ng-container>
        </ng-container>
      </ng-template>
      <ng-template #basic>
        {{ context.value }}
      </ng-template>
    </ng-container>
    <ng-template #loading>
      <div class="data-table-row-item-loading-indicator"></div>
    </ng-template>
  </td>
</ng-template>
