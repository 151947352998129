<mat-card class="scw-mat-wizard-step" *ngIf="expanded">
  <mat-card-content>
    <div class="content-container">
      <div class="content-left">
        <img class="image" [src]="descriptionImagePath" [alt]="title" />
        <div class="text-content">
          <h3 class="title scw-header">{{ title }}</h3>
          <p class="description">{{ description }}</p>
        </div>
      </div>
      <div class="content-right" *ngIf="link">
        <scw-mat-button (onClick)="onClickLink(link)">{{ linkButtonText }}</scw-mat-button>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="scw-mat-wizard-step-loader">
    <mat-progress-bar mode="indeterminate" [hidden]="!loading"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
<div class="scw-mat-wizard-step-container" *ngIf="!expanded">
  <div class="completed-circle">
    <em class="icon fas fa-check" [class.disabled]="!completed"></em>
  </div>
  <mat-card class="scw-mat-wizard-step collapsed" [class.no-link]="!link" (click)="onClickCollapsedCard(id)">
    <mat-card-content>
      <div class="content-container">
        <div class="content-left">
          <em class="icon" [class.disabled]="disabled" [ngClass]="titleIcon"></em>
          <h4 class="title scw-header" [class.disabled]="disabled">{{ title }}</h4>
        </div>
        <div class="content-right" *ngIf="link">
          <scw-mat-button type="ghost" [fab]="true" [disabled]="disabled" (onClick)="onClickLink(link)">
            <em class="ghost-button-icon fas fa-chevron-right" [class.disabled]="disabled"></em>
          </scw-mat-button>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="scw-mat-wizard-step-loader">
      <mat-progress-bar mode="indeterminate" [hidden]="!loading"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
