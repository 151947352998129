import { Component, Input } from '@angular/core';

@Component({
  selector: 'scw-mat-modal-failed-items',
  templateUrl: './scw-mat-modal-failed-items.component.html',
  styleUrls: ['./scw-mat-modal-failed-items.component.scss'],
})
export class ScwMatModalFailedItemsComponent {
  @Input() failText: string = '';
  @Input() failedItems: string[] = [];
}
