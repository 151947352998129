<div class="datatable-buttons" *ngIf="!isElasticPage">
  <div class="row m-b-15" [style.pointer-events]="isDataLoading$ ? 'none' : 'all'">
    <div class="col-lg-12 text-danger" [hidden]="!showManyDataWarning">
      <div class="text-left">
        <em class="fas fa-exclamation-circle p-r-5"></em>
        <label class="m-b-0 f-12">{{ 'rootCauseAnalysis.warning.narrowFilter' | translate }}</label>
      </div>
    </div>
    <div class="col-lg-10">
      <div class="text-left align-items-center">
        <em class="fas fa-info-circle p-r-5"></em>
        <label class="m-b-0 f-12">
          {{ 'rootCauseAnalysis.datatable.info' | translate }}
          <a class="link" [routerLink]="['/reports/activity-logs']" target="_blank">{{
            'menus.navigation_reports_activitylogs' | translate
          }}</a>
        </label>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="text-right">
        <scw-mat-small-button (onClick)="onDownloadExcelButtonClicked()" [disabled]="loading">
          <em [ngClass]="loading ? 'fas fa-circle-notch fa-spin' : 'fas fa-download'"></em>
          {{ 'general.downloadFileType' | translate : { fileType: 'Excel' } }}
        </scw-mat-small-button>
      </div>
    </div>
  </div>
</div>
<datatable
  noDataText="{{ 'datatable.noData' | translate }}"
  [clientSide]="true"
  [headers]="tableHeaders"
  [items]="rawData"
  [itemsCount]="activityLogCount"
  [currentPage]="tableQuery.page"
  [rowsPerPage]="tableQuery.pageSize"
  [customSorts]="customSorts"
  [isLoading]="isDataLoading$"
  (onDataRequest)="onDataRequestHandler($event)"
  [heightMode]="tableHeightMode"
  [footerContent]="footerContent"
>
  <ng-template let-item>
    <td
      *ngFor="let header of tableHeaders"
      [class.activityLogCellWithBorderColor]="['activityName', 'activityTypeText'].indexOf(header.value) !== -1"
    >
      <ng-container *ngIf="header.value === 'activityName'; else elseIf1">
        <ng-template [ngIf]="item['activityTypeText'] === 'idleTime'">
          <scw-mat-border-coloring [type]="idleTimeColor" [text]="item[header.value]"></scw-mat-border-coloring>
        </ng-template>
        <ng-template [ngIf]="item['activityTypeText'] === 'runTime'">
          <scw-mat-border-coloring [type]="runTimeColor" [text]="item[header.value]"></scw-mat-border-coloring>
        </ng-template>
        <ng-template [ngIf]="item['activityTypeText'] === 'downTime'">
          <scw-mat-border-coloring [type]="downTimeColor" [text]="item[header.value]"></scw-mat-border-coloring>
        </ng-template>
        <ng-template [ngIf]="item['activityTypeText'] === 'downTimePlanned'">
          <scw-mat-border-coloring
            [type]="downTimePlannedColor"
            [customColor]="customColors?.activity.plannedDownTime.card"
            [text]="item[header.value]"
          ></scw-mat-border-coloring>
        </ng-template>
      </ng-container>
      <ng-template #elseIf1>
        <ng-container *ngIf="header.value === 'activityTypeText'; else elseIf2">
          <ng-template [ngIf]="item['activityTypeText'] === 'idleTime'">
            <scw-mat-border-coloring
              [type]="idleTimeColor"
              [text]="'general.activityType.idleTime' | translate"
            ></scw-mat-border-coloring>
          </ng-template>
          <ng-template [ngIf]="item['activityTypeText'] === 'runTime'">
            <scw-mat-border-coloring
              [type]="runTimeColor"
              [text]="'general.activityType.runTime' | translate"
            ></scw-mat-border-coloring>
          </ng-template>
          <ng-template [ngIf]="item['activityTypeText'] === 'downTime'">
            <scw-mat-border-coloring
              [type]="downTimeColor"
              [text]="'general.activityType.downTime' | translate"
            ></scw-mat-border-coloring>
          </ng-template>
          <ng-template [ngIf]="item['activityTypeText'] === 'downTimePlanned'">
            <scw-mat-border-coloring
              [type]="downTimePlannedColor"
              [text]="'general.activityType.downTimePlanned' | translate"
              [customColor]="customColors?.activity.plannedDownTime.card"
            ></scw-mat-border-coloring>
          </ng-template>
        </ng-container>
      </ng-template>
      <ng-template #elseIf2>
        <div *ngIf="header.value === 'description'; else elseIf3">
          <app-last-comment
            [activityLog]="item"
            [lastComment]="item.commentCountWithLastCommentMessage?.lastCommentMessage ?? ''"
            [totalComments]="item.commentCountWithLastCommentMessage?.totalCommentCount ?? 0"
            (onCommentModalClosedEvent)="onLastCommentsModalClose($event)"
          ></app-last-comment>
        </div>
      </ng-template>
      <ng-template #elseIf3>
        <div *ngIf="header.value === 'commentTags'; else elseIf4">
          <show-tooltip-button
            class="multiple-tag d-flex align-items-center justify-content-center"
            [list]="item.commentCountWithLastCommentMessage?.allCommentTags ?? []"
            [showLength]="true"
            [tooltipTopLabel]="'activityLogs.commentTags.popOverContentText' | translate"
            [tooltipContentType]="tooltipContentTypes.ColorTaggedList"
          ></show-tooltip-button>
        </div>
      </ng-template>
      <ng-template #elseIf4>
        <div *ngIf="header.value === 'quantityOrdered'; else elseIf5">
          {{ item[header.value] }}
        </div>
      </ng-template>
      <ng-template #elseIf5>
        <div *ngIf="header.value === 'workOrderNumber'; else elseIf6">
          <a
            class="cursor-pointer link"
            [routerLink]="['/reports/work-order-performance/' + item?.workOrderId]"
            target="_blank"
            >{{ item[header.value] }}</a
          >
        </div>
      </ng-template>
      <ng-template #elseIf6>
        <div *ngIf="header.value === 'jobNumber'; else elseIf7">
          <a
            class="cursor-pointer link"
            [routerLink]="['/reports/job-performance/' + item?.jobId]"
            target="_blank"
            >{{ item[header.value] }}</a
          >
        </div>
      </ng-template>
      <ng-template #elseIf7>
        <div *ngIf="header.value === 'auditLogs'; else elseIf8">
          <scw-mat-button size="xs" *ngIf="item.auditLogUuid" (onClick)="openActivityHistoryAuditLogModal(item.auditLogUuid)" type="standard">
            <span aria-hidden="true">
              <i class="fas fa-history icon-font-size mr-1"></i>
              {{ 'activityLogs.listView.auditLogsButton' | translate }}
            </span>
          </scw-mat-button>
        </div>
      </ng-template>
      <ng-template #elseIf8>
        <ng-container>
          {{ item[header.formattedValue] }}
        </ng-container>
      </ng-template>
    </td>
  </ng-template>
</datatable>

<ng-template #footerContent>
  <tfoot *ngIf="tableFooterContent?.length">
    <tr>
      <ng-container *ngFor="let row of tableFooterContent">
        <ng-container *ngIf="row?.value && footerData && footerData[row.value]">
          <td [colSpan]="tableHeaders?.length">
            <div class="d-flex flex-wrap" style="gap: 1rem !important">
              <span>{{ row.name }}: {{ footerData[row.value] }}</span>
            </div>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </tfoot>
</ng-template>
