export enum ECardAssignmentObjectTypeEnum {
  // USER = 1,
  // EQUIPMENT = 2,
  JOB = 3,
  WORK_ORDER = 4,
}

export interface IDataParams {
  siteId: number;
  jobId?: number;
  workOrderId?: number;
}

export interface IWorkOrderAdditionalData {
  siteId: number;
  cardId: string;
  assignedObjectType: number;
  assignedObjectId: number;
  macAddress: string;
}

export interface IRfidLine {
  id: number;
  title: string;
  activityIds: string;
  workOrderId: number;
  lineType: number;
  siteId: number;
  currentWorkOrder?: { woNumber: string };
}

export interface IRfidActivity {
  id: number;
  activityType: string;
}

export interface IRfidWorkOrder {
  id: number;
  woNumber: string;
  completed: number;
  hasOnGoingActivity: number;
  departmentId: number;
  siteId: number;
}

export interface IRfidWorkOrderMapped extends IRfidWorkOrder {
  selectable: boolean;
}

export interface IRfidLineMapped extends IRfidLine {
  selectable: boolean;
}

export interface IRfidJob {
  id: number;
  jobName: string;
}
