import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './burst.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { IBurst, IBurstRow } from '../../../../view/settings/opc-tag-automation-settings/burst/burst.model';

export enum ActionTypes {
  BURST_DATA_LOADING = '[BURST] BURST DATA LOADING',
  BURST_DATA_LOADED = '[BURST] BURST DATA LOADED',
  GET_BURST = '[BURST] GET_BURST LOADING',
  GET_BURST_COMPLETED = '[BURST] GET_BURST COMPLETED',
  ADD_BURST = '[BURST] ADD_BURST',
  ADD_BURST_COMPLETED = '[BURST] ADD_BURST COMPLETED',
  EDIT_BURST = '[BURST] EDIT_BURST',
  EDIT_BURST_COMPLETED = '[BURST] EDIT_BURST COMPLETED',
  DELETE_BURST = '[BURST] DELETE_BURST',
  DELETE_BURST_COMPLETED = '[BURST] DELETE_BURST COMPLETED',
  SITES_DATA_LOADING = '[BURST] SITES DATA LOADING',
  SITES_DATA_LOADED = '[BURST] SITES DATA LOADED',
  LINES_DATA_LOADING = '[BURST] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[BURST] LINES DATA COMPLETED',
  FETCH_ERROR = '[BURST] FETCH ERROR',
}

export class BurstLoading implements Action {
  readonly type = ActionTypes.BURST_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class BurstLoaded implements Action {
  readonly type = ActionTypes.BURST_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IBurst>) {}
}

export class GetBurst implements Action {
  readonly type = ActionTypes.GET_BURST;
  constructor(public recordId: number) {}
}

export class GetBurstCompleted implements Action {
  readonly type = ActionTypes.GET_BURST_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IBurst>) {}
}

export class AddBurst implements Action {
  readonly type = ActionTypes.ADD_BURST;
  constructor(public burst: IBurstRow, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddBurstCompleted implements Action {
  readonly type = ActionTypes.ADD_BURST_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IBurst>) {}
}

export class EditBurst implements Action {
  readonly type = ActionTypes.EDIT_BURST;
  constructor(public recordId: number, public record: IBurstRow, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditBurstCompleted implements Action {
  readonly type = ActionTypes.EDIT_BURST_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IBurst>) {}
}

export class DeleteBurst implements Action {
  readonly type = ActionTypes.DELETE_BURST;
  constructor(public payload: number[]) {}
}

export class DeleteBurstCompleted implements Action {
  readonly type = ActionTypes.DELETE_BURST_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type BurstActions =
  | BurstLoading
  | BurstLoaded
  | GetBurst
  | GetBurstCompleted
  | AddBurst
  | AddBurstCompleted
  | EditBurst
  | EditBurstCompleted
  | DeleteBurst
  | DeleteBurstCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | FetchError;
