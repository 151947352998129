<div class="modal-header scw-modal-header">
  <h3 class="modal-title scw-header">
    {{ 'cameraView.modal.header' | translate }}
  </h3>
  <button type="button" class="close" aria-label="Close" (click)="closeCameraViewModal()">
    <span aria-hidden="true">
      <em class="fas fa-times"></em>
    </span>
  </button>
</div>
<div class="modal-body">
  <div
    [ngClass]="source === ECameraViewSource.globalView ? 'd-flex flex-row justify-content-between' : 'd-none'"
    class="row filter-row"
  >
    <scw-mat-select
      [text]="'cameraView.modal.lines.dropdown' | translate"
      [clearAll]="false"
      [data]="lineData"
      [singleSelection]="false"
      [showCheckbox]="true"
      [enableSearchFilter]="true"
      [searchMinLength]="0"
      [clearSearchOnOpen]="true"
      [(inputModel)]="selectedLines"
      [limitSelection]="3"
      (onSelect)="getCameraDataOfLine()"
      (onDeSelect)="getCameraDataOfLine()"
      class="col-md-3 pl-0 pr-0"
    >
    </scw-mat-select>

    <scw-mat-search
      placeholder="{{ 'general.search' | translate }}"
      behavior="delayed"
      [minLength]="0"
      [block]="true"
      [(inputModel)]="searchText"
      (onSearch)="searchEvent($event)"
    ></scw-mat-search>
  </div>

  <div class="d-flex row select-line-info" *ngIf="selectRowData.length === 0">
    <span><i class="fas fa-info-circle"></i> {{ 'cameraView.modal.selectLine.message' | translate }}</span>
  </div>
  <div class="d-flex row camera-content" *ngIf="selectRowData.length !== 0">
    <div class="camera-list-card-body">
      <div class="d-flex flex-column card-item" *ngFor="let item of selectRowData">
        <ng-container [ngTemplateOutlet]="itemRow" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        <div *ngFor="let camera of item?.cameras">
          <ng-container
            [ngTemplateOutlet]="itemRow"
            [ngTemplateOutletContext]="{ $implicit: camera, titleClass: 'pl-4' }"
          ></ng-container>
        </div>
      </div>
    </div>

    <div class="m-r-20 w-100">
      <div class="d-flex flex-row justify-content-between camera-content-information">
        <span>
          <i class="fas fa-camera"></i>
          {{ selectedCamera?.lineName + ' - ' + selectedCamera?.name }}
        </span>

        <span>
          <i class="fas fa-calendar"></i>
          <span class="ml-1" #siteBasedTimeOfTheCameraRef></span>
        </span>
      </div>
      <div class="video-content">
        <img
          #imgFeed
          [src]="selectedCamera?.safeUrl"
          *ngIf="!isDemoCamera; else demoVideo"
          height="100%"
          width="100%"
          id="singleVideo"
          alt=" "
          (error)="handeImageError()"
        />
        <ng-template #demoVideo>
          <video
            width="100%"
            height="100%"
            [src]="selectedCamera?.safeUrl"
            autoplay
            loop
            (canplaythrough)="canPlayThrough()"
            [hidden]="!videoCanPlayTrough"
            disableRemotePlayback
            x-webkit-airplay="deny"
            [muted]="true"
            [controls]="false"
          ></video>
        </ng-template>
        <div
          class="card-block d-flex align-items-center justify-content-center h-100 w-100 m-0 p-0"
          *ngIf="videoHasError || !videoCanPlayTrough"
        >
          <div class="loader-block">
            <svg id="loader2" viewBox="0 0 100 100">
              <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer modal-footer-buttons">
  <scw-mat-button [type]="'standard'" (onClick)="closeCameraViewModal()">
    {{ 'general.cancel' | translate }}
  </scw-mat-button>
</div>

<ng-template #itemRow let-titleClass="titleClass" let-item>
  <div class="d-flex flex-row w-100 justify-content-between align-items-center">
    <div class="d-inline-flex flex-row col-12 px-0 justify-content-start align-items-center" [ngClass]="titleClass">
      <span
        (click)="setSelectedCameraData(item, !titleClass)"
        class="camera-option-label fw-700-i"
        [ngClass]="!titleClass ? 'main-title' : ''"
        >{{ item?.name }}</span
      >
    </div>
  </div>
  <scw-mat-modal-separator separatorClass="my-0 kpi-card-separator"></scw-mat-modal-separator>
</ng-template>
