import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IAddEditProductTransitionMatrix,
  IBulkEditProductTransitionMatrix,
  IProductTransitionMatrix,
  IProductTransitionMatrixBulkResponse,
  IProductTransitionMatrixParams,
  IProductTransitionMatrixResponse,
} from './product-transition-matrix.model';
import { IDownloadExcelFilters } from '../../../view/settings/product-transition-matrix/product-transition-matrix.model';
import { IProductFamily } from '../product-family/product-family.model';
import { ProductInterface } from '../products/products.model';
import {
  IProductTransitionPreviewFilter,
  IProductTransitionPreviewPayload,
  IProductTransitionPreviewResponse,
} from '../../../view/settings/product-transition-matrix/product-transition-visual-matrix/product-transition-visual-matrix.model';

export const PRODUCT_TRANSITION_MATRIX_DATA_LOADING =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX DATA LOADING';
export const PRODUCT_TRANSITION_MATRIX_DATA_LOADED =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX DATA LOADED';
export const ADD_PRODUCT_TRANSITION_MATRIX = '[PRODUCT_TRANSITION_MATRIX] ADD PRODUCT_TRANSITION_MATRIX';
export const ADD_PRODUCT_TRANSITION_MATRIX_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] ADD PRODUCT_TRANSITION_MATRIX COMPLETED';
export const EDIT_PRODUCT_TRANSITION_MATRIX = '[PRODUCT_TRANSITION_MATRIX] EDIT PRODUCT_TRANSITION_MATRIX';
export const EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] EDIT PRODUCT_TRANSITION_MATRIX COMPLETED';
export const BULK_EDIT_PRODUCT_TRANSITION_MATRIX = '[PRODUCT_TRANSITION_MATRIX] BULK EDIT PRODUCT_TRANSITION_MATRIX';
export const BULK_EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] BULK EDIT PRODUCT_TRANSITION_MATRIX COMPLETED';
export const DELETE_PRODUCT_TRANSITION_MATRIX = '[PRODUCT_TRANSITION_MATRIX] DELETE PRODUCT_TRANSITION_MATRIX';
export const DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] DELETE PRODUCT_TRANSITION_MATRIX COMPLETED';
export const BULK_DELETE_PRODUCT_TRANSITION_MATRIX =
  '[PRODUCT_TRANSITION_MATRIX] BULK DELETE PRODUCT TRANSITION MATRIX';
export const BULK_DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] BULK DELETE PRODUCT FAMILIES COMPLETED';
export const DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL =
  '[PRODUCT_TRANSITION_MATRIX] DOWNLOAD PRODUCT_TRANSITION_MATRIX EXCEL';
export const DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] DOWNLOAD PRODUCT_TRANSITION_MATRIX EXCEL COMPLETED';
export const UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL =
  '[PRODUCT_TRANSITION_MATRIX] UPLOAD PRODUCT TRANSITION MATRIX EXCEL';
export const UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED =
  '[PRODUCT_TRANSITION_MATRIX] UPLOAD PRODUCT TRANSITION MATRIX EXCEL COMPLETED';
export const PRODUCT_TRANSITION_MATRIX_COUNT_LOAD = '[PRODUCT_TRANSITION_MATRIX] PRODUCT TRANSITION MATRIX COUNT LOAD';
export const PRODUCT_TRANSITION_MATRIX_COUNT_LOADED =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT TRANSITION MATRIX COUNT LOADED';
export const PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADING =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PRODUCT DATA LOADING';

export const PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADED =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PRODUCT DATA LOADED';

export const PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADING =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PRODUCT FAMILY DATA LOADING';

export const PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADED =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PRODUCT FAMILY DATA LOADED';

export const PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADING =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PREVIEW DATA LOADING';

export const PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADED =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PREVIEW DATA LOADED';

export const PRODUCT_TRANSITION_MATRIX_RESET_PREVIEW_DATA =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION RESET PREVIEW DATA';

export const PRODUCT_TRANSITION_MATRIX_PREVIEW_SET_FILTER =
  '[PRODUCT_TRANSITION_MATRIX] PRODUCT_TRANSITION_MATRIX ACTION PREVIEW SET FILTER';

export const FETCH_ERROR = '[PRODUCT_TRANSITION_MATRIX] FETCH ERROR';

export class ProductTransitionMatrixDataLoading implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_DATA_LOADING;

  constructor(public params: IProductTransitionMatrixParams) {}
}

export class ProductTransitionMatrixDataLoaded implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<IProductTransitionMatrixResponse>) {}
}

export class AddProductTransitionMatrix implements Action {
  readonly type = ADD_PRODUCT_TRANSITION_MATRIX;

  constructor(public productTransitionMatrix: IAddEditProductTransitionMatrix) {}
}

export class AddProductTransitionMatrixCompleted implements Action {
  readonly type = ADD_PRODUCT_TRANSITION_MATRIX_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditProductTransitionMatrix>) {}
}

export class EditProductTransitionMatrix implements Action {
  readonly type = EDIT_PRODUCT_TRANSITION_MATRIX;

  constructor(public productTransitionMatrix: IAddEditProductTransitionMatrix, public id: number) {}
}

export class EditProductTransitionMatrixCompleted implements Action {
  readonly type = EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditProductTransitionMatrix>) {}
}

export class BulkEditProductTransitionMatrix implements Action {
  readonly type = BULK_EDIT_PRODUCT_TRANSITION_MATRIX;

  constructor(public productTransitionMatrix: IBulkEditProductTransitionMatrix[]) {}
}

export class BulkEditProductTransitionMatrixCompleted implements Action {
  readonly type = BULK_EDIT_PRODUCT_TRANSITION_MATRIX_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IProductTransitionMatrixBulkResponse[]>) {}
}

export class DeleteProductTransitionMatrix implements Action {
  readonly type = DELETE_PRODUCT_TRANSITION_MATRIX;

  constructor(public payload: number) {}
}

export class DeleteProductTransitionMatrixCompleted implements Action {
  readonly type = DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkDeleteProductTransitionMatrix implements Action {
  readonly type = BULK_DELETE_PRODUCT_TRANSITION_MATRIX;

  constructor(public productTransitionMatrixIds: number[]) {}
}

export class BulkDeleteProductTransitionMatrixCompleted implements Action {
  readonly type = BULK_DELETE_PRODUCT_TRANSITION_MATRIX_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class DownloadProductTransitionMatrixExcel implements Action {
  readonly type = DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL;

  constructor(public withData: boolean = false, public filters: IDownloadExcelFilters) {}
}

export class DownloadProductTransitionMatrixExcelCompleted implements Action {
  readonly type = DOWNLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED;
}

export class UploadProductTransitionMatrixExcel implements Action {
  readonly type = UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL;

  constructor(public productTransitionMatrix: IAddEditProductTransitionMatrix[]) {}
}

export class UploadProductTransitionMatrixExcelCompleted implements Action {
  readonly type = UPLOAD_PRODUCT_TRANSITION_MATRIX_EXCEL_COMPLETED;

  constructor(public payload: (IProductTransitionMatrix & { errorMessages?: string })[]) {}
}

export class ProductTransitionMatrixCountLoad implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_COUNT_LOAD;

  constructor(
    public params: IProductTransitionMatrixParams,
  ) {}
}

export class ProductTransitionMatrixCountLoaded implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_COUNT_LOADED;

  constructor(public payload: BaseOneResponseInterface<IProductTransitionMatrixResponse>) {}
}

export class ProductTransitionMatrixProductDataLoading implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADING;

  constructor(public siteId: number, public productSearchText?: string) {}
}

export class ProductTransitionMatrixProductDataLoaded implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PRODUCTS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ProductInterface>) {}
}

export class ProductTransitionMatrixProductFamilyDataLoading implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADING;

  constructor(public siteId: number) {}
}

export class ProductTransitionMatrixProductFamilyDataLoaded implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PRODUCT_FAMILY_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IProductFamily>) {}
}

export class ProductTransitionMatrixPreviewDataLoading implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADING

  constructor(public payload: IProductTransitionPreviewPayload) {}
}

export class ProductTransitionMatrixPreviewDataLoaded implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PREVIEW_DATA_LOADED;

  constructor(public response: IProductTransitionPreviewResponse[]) {}
}

export class ProductTransitionMatrixPreviewSetFilter implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_PREVIEW_SET_FILTER;

  constructor(public filters: IProductTransitionPreviewFilter) {}
}

export class ProductTransitionMatrixResetPreviewData implements Action {
  readonly type = PRODUCT_TRANSITION_MATRIX_RESET_PREVIEW_DATA;

  constructor() {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ProductTransitionMatrixActions =
  | ProductTransitionMatrixDataLoading
  | ProductTransitionMatrixDataLoaded
  | AddProductTransitionMatrix
  | AddProductTransitionMatrixCompleted
  | ProductTransitionMatrixProductDataLoading
  | ProductTransitionMatrixProductDataLoaded
  | ProductTransitionMatrixProductFamilyDataLoading
  | ProductTransitionMatrixProductFamilyDataLoaded
  | EditProductTransitionMatrix
  | EditProductTransitionMatrixCompleted
  | BulkEditProductTransitionMatrix
  | BulkEditProductTransitionMatrixCompleted
  | DeleteProductTransitionMatrix
  | DeleteProductTransitionMatrixCompleted
  | BulkDeleteProductTransitionMatrix
  | BulkDeleteProductTransitionMatrixCompleted
  | DownloadProductTransitionMatrixExcel
  | DownloadProductTransitionMatrixExcelCompleted
  | UploadProductTransitionMatrixExcel
  | UploadProductTransitionMatrixExcelCompleted
  | ProductTransitionMatrixCountLoad
  | ProductTransitionMatrixCountLoaded
  | ProductTransitionMatrixPreviewDataLoading
  | ProductTransitionMatrixPreviewDataLoaded
  | ProductTransitionMatrixPreviewSetFilter
  | ProductTransitionMatrixResetPreviewData
  | FetchError;
