import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentDatePipe } from '../../pipes/moment-date.pipe';
import { ScwMatButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ComponentUtilities } from '../../../shared/helper/component-utilities';
import {
  ActivityHistoryAuditLogModalComponentStore,
  IActivityHistoryAuditLogState,
} from './activity-history-audit-log-modal-component.store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PhaseOptionNames, PhaseOptions } from '../../../shared/model/enum/phase-options';
import { IActivityHistoryAuditLog } from './activity-history-audit-log-modal-component.model';
import { HelperService } from '../../../shared/service/helper.service';
import { provideComponentStore } from '@ngrx/component-store';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import { FormatDecimalToUserScalePipe } from '../../../shared/pipe/format-decimal-to-user-scale';
import { ConvertTimezonePipe } from '../../pipes/convert-timezone.pipe';

@OnDestroyDecorator
@Component({
  selector: 'scw-activity-history-audit-log-modal',
  standalone: true,
  imports: [
    CommonModule,
    MomentDatePipe,
    ScwMatButtonModule,
    TranslateModule,
    FormatDecimalToUserScalePipe,
    ConvertTimezonePipe,
  ],
  templateUrl: './activity-history-audit-log-modal.component.html',
  styleUrls: ['./activity-history-audit-log-modal.component.scss'],
  providers: [provideComponentStore(ActivityHistoryAuditLogModalComponentStore)],
})
export class ActivityHistoryAuditLogModalComponent implements OnInit, OnDestroy {
  @Input() dateTimeFormat$: string;
  @Input() timezone$: string;
  @Input() auditLogUuid: string;
  @Input() isOngoingActivity?: boolean;

  public subscriptions: Subscription[] = [];
  public activityHistoryAuditLog: IActivityHistoryAuditLog[] = [];
  public readonly genericTrackByIdFunction = ComponentUtilities.genericTrackByIdFunction;
  public noValueText: string = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly componentStore: ActivityHistoryAuditLogModalComponentStore,
    public readonly activeModal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    this.componentStore.getActivityHistoryAuditLogs(this.auditLogUuid);
    this.componentStore.auditLogData$.subscribe((activityHistoryAuditLogs$: Partial<IActivityHistoryAuditLogState>) => {
      if (!activityHistoryAuditLogs$.auditLogDataLoading && activityHistoryAuditLogs$.auditLogDataLoaded) {
        if (!activityHistoryAuditLogs$.auditLogData.data.auditLog) {
          this.noValueText = this.translate.instant('activityHistoryAuditLog.noValueText');

          return;
        }

        this.activityHistoryAuditLog = HelperService.cloneDeep(
          activityHistoryAuditLogs$.auditLogData.data.auditLog,
        ).reverse();

        this.activityHistoryAuditLog.forEach((log: IActivityHistoryAuditLog) => {
          this.setPhaseName(log.before);
          this.setPhaseName(log.after);
        });
      }
    });
  }

  public setPhaseName(log: any): void {
    const phaseOptions = {
      [PhaseOptionNames[PhaseOptions.NO_PHASE]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.noPhase',
      ),
      [PhaseOptionNames[PhaseOptions.PRE_RUN]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.pre',
      ),
      [PhaseOptionNames[PhaseOptions.RUN]]: this.translate.instant('activityCards.activityEditForm.phaseOptions.run'),
      [PhaseOptionNames[PhaseOptions.POST_RUN]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.post',
      ),
    };

    switch (log.phaseId) {
      case 1:
        log.phaseName =
          log.sitePhase[log.phaseId] && log.sitePhase[log.phaseId].trim() !== ''
            ? log.sitePhase[log.phaseId]
            : phaseOptions[PhaseOptionNames[log.phaseId]];
        break;
      case 2:
        log.phaseName =
          log.sitePhase[log.phaseId] && log.sitePhase[log.phaseId].trim() !== ''
            ? log.sitePhase[log.phaseId]
            : phaseOptions[PhaseOptionNames[log.phaseId]];
        break;
      case 3:
        log.phaseName =
          log.sitePhase[log.phaseId] && log.sitePhase[log.phaseId].trim() !== ''
            ? log.sitePhase[log.phaseId]
            : phaseOptions[PhaseOptionNames[log.phaseId]];
        break;
      default:
        log.phaseName = phaseOptions[PhaseOptionNames[PhaseOptions.NO_PHASE]];
        break;
    }
  }

  public closeActivityHistoryAuditLogModal(): void {
    this.activeModal.close();
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
