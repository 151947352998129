import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './tag-definition.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  ITagDefinition,
  ITagDefinitionRow,
} from '../../../../view/settings/opc-tag-automation-settings/tag-definitions/tag-definitions.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';

export enum ActionTypes {
  TAG_DEFINITIONS_DATA_LOADING = '[TAG_DEFINITIONS] TAG_DEFINITIONS DATA LOADING',
  TAG_DEFINITIONS_DATA_LOADED = '[TAG_DEFINITIONS] TAG_DEFINITIONS DATA LOADED',
  GET_TAG_DEFINITION = '[TAG_DEFINITIONS] GET_TAG_DEFINITION LOADING',
  GET_TAG_DEFINITION_COMPLETED = '[TAG_DEFINITIONS] GET_TAG_DEFINITION COMPLETED',
  ADD_TAG_DEFINITION = '[TAG_DEFINITIONS] ADD_TAG_DEFINITION',
  ADD_TAG_DEFINITION_COMPLETED = '[TAG_DEFINITIONS] ADD_TAG_DEFINITION COMPLETED',
  EDIT_TAG_DEFINITION = '[TAG_DEFINITIONS] EDIT_TAG_DEFINITION',
  EDIT_TAG_DEFINITION_COMPLETED = '[TAG_DEFINITIONS] EDIT_TAG_DEFINITION COMPLETED',
  DELETE_TAG_DEFINITIONS = '[TAG_DEFINITIONS] DELETE_TAG_DEFINITION',
  DELETE_TAG_DEFINITIONS_COMPLETED = '[TAG_DEFINITIONS] DELETE_TAG_DEFINITION COMPLETED',
  SITES_DATA_LOADING = '[TAG_DEFINITIONS] SITES DATA LOADING',
  SITES_DATA_LOADED = '[TAG_DEFINITIONS] SITES DATA LOADED',
  LINES_DATA_LOADING = '[TAG_DEFINITIONS] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[TAG_DEFINITIONS] LINES DATA COMPLETED',
  STATIONS_DATA_LOADING = '[TAG_DEFINITIONS] STATIONS DATA LOADED',
  STATIONS_DATA_COMPLETED = '[TAG_DEFINITIONS] STATIONS DATA COMPLETED',
  FETCH_ERROR = '[TAG_DEFINITIONS] FETCH ERROR',
}

export class TagDefinitionsLoading implements Action {
  readonly type = ActionTypes.TAG_DEFINITIONS_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class TagDefinitionsLoaded implements Action {
  readonly type = ActionTypes.TAG_DEFINITIONS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ITagDefinition>) {}
}

export class GetTagDefinition implements Action {
  readonly type = ActionTypes.GET_TAG_DEFINITION;
  constructor(public recordId: number) {}
}

export class GetTagDefinitionCompleted implements Action {
  readonly type = ActionTypes.GET_TAG_DEFINITION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<ITagDefinition>) {}
}

export class AddTagDefinition implements Action {
  readonly type = ActionTypes.ADD_TAG_DEFINITION;
  constructor(public tagDefinition: ITagDefinitionRow, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddTagDefinitionCompleted implements Action {
  readonly type = ActionTypes.ADD_TAG_DEFINITION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<ITagDefinition>) {}
}

export class EditTagDefinition implements Action {
  readonly type = ActionTypes.EDIT_TAG_DEFINITION;
  constructor(
    public recordId: number,
    public record: ITagDefinitionRow,
    public hideLoaderAfterSuccess: boolean = true,
  ) {}
}

export class EditTagDefinitionCompleted implements Action {
  readonly type = ActionTypes.EDIT_TAG_DEFINITION_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<ITagDefinition>) {}
}

export class DeleteTagDefinitions implements Action {
  readonly type = ActionTypes.DELETE_TAG_DEFINITIONS;
  constructor(public payload: number[]) {}
}

export class DeleteTagDefinitionsCompleted implements Action {
  readonly type = ActionTypes.DELETE_TAG_DEFINITIONS_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class StationsDataLoading implements Action {
  readonly type = ActionTypes.STATIONS_DATA_LOADING;
  constructor(public lineId: number) {}
}

export class StationsDataCompleted implements Action {
  readonly type = ActionTypes.STATIONS_DATA_COMPLETED;
  constructor(public payload: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type TagDefinitionActions =
  | TagDefinitionsLoading
  | TagDefinitionsLoaded
  | GetTagDefinition
  | GetTagDefinitionCompleted
  | AddTagDefinition
  | AddTagDefinitionCompleted
  | EditTagDefinition
  | EditTagDefinitionCompleted
  | DeleteTagDefinitions
  | DeleteTagDefinitionsCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | StationsDataLoading
  | StationsDataCompleted
  | FetchError;
