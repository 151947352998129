import { DatePickerInterFace, IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import { DailyProductionPerformanceDataInterface } from '../../dashboards/daily-production-performance/daily-production-performance.model';
import { IObjectWithEntityTranslations } from 'src/app/shared/service/entity-translator/entity-translator.model';

export interface IPerformanceDeepDiveState {
  isPerformanceDeepDiveActivityLogsLoading: boolean;
  isPerformanceDeepDiveActivityLogsLoaded: boolean;
  isPerformanceDeepDiveShiftDayDataLoading: boolean;
  isPerformanceDeepDiveShiftDayDataLoaded: boolean;
  activityLogs: IActivityLog[];
}

export interface IPerformanceDeepDiveFilter {
  dateRange?: DatePickerInterFace;
  siteId: number[];
  lineId: number[];
}

export interface IProductionPerformanceDeepDiveRequest {
  startDate: string;
  endDate: string;
}

export interface IProductionPerformanceDeepDiveResponse {
  productionPerformance: DailyProductionPerformanceDataInterface;
  rootCauseData: IRootCauseAnalysisData[];
}

export interface IRootCauseAnalysisData extends IObjectWithEntityTranslations {
  activityName?: string;
  activityId?: number;
  activityType: string;
  taskId?: number;
  taskName?: string;
  activityFrequency: string;
  totalDuration: string;
}

export interface ButtonDisableInterface {
  monthButtonDisabled: boolean;
  weekButtonDisabled: boolean;
  dayButtonDisabled: boolean;
}

export enum EPerformanceChartMode {
  OEE_BASED = 'oee_based',
  PERFORMANCE_BASED = 'performance_based',
}

export interface IProductionPerformanceDeepDiveShiftDayResponse {
  shiftId: string;
  shiftName: string;
  start: string;
  end: string;
  calendarDate: string;
  year: string;
  month: string;
  week: string;
  weekDayStart: string;
}

export interface IProductionPerformanceDeepDiveShiftDayRequest {
  startDate: string;
  endDate: string;
  groupBy: EProductionPerformanceDeepDiveShiftDayGroupBy;
}

export enum EProductionPerformanceDeepDiveShiftDayGroupBy {
  CALENDAR_DATE = 'calendarDate',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  START = 'start',
}

export interface IActivityLogsFooterData {
  totalDuration: string | number;
}
