import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ComponentNamesForUserConfiguration,
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as WorkOrderScheduleActions from '../../../store/work-order-schedule/work-order-schedule.actions';
import * as ProductsActions from '../../../store/settings/products/products.actions';
import * as TaskActions from '../../../store/settings/tasks/tasks.actions';
import * as UserActions from '../../../store/settings/users/users.actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { PageHeaderInterface } from '../../service/page-header/page-header.model';
import {
  TableColumnInterface,
  WidgetDataInterface,
} from '../../../store/line-view-work-order-summary/line-view-work-order-summary.model';
import * as LineViewWorkOrderSummaryActions from '../../../store/line-view-work-order-summary/line-view-work-order-summary.actions';
import * as AssetViewActions from '../../../store/asset-view/asset-view.actions';
import { Subscription } from 'rxjs';
import {
  ChartConfigurationInterface,
  EWidgetType,
  ICustomSectionConfiguration,
  ICustomSectionOrderConfiguration,
  ICustomSectionRow,
  IPageConfigurationGroup,
  PageConfigurationDataType,
  PageConfigurationInterface,
  SectionTypes,
  TableConfigurationInterface,
  WidgetConfigurationInterface,
} from './page-configuration.model';
import * as SchedulerScenarioActions from '../../../store/scheduler-scenario/scheduler-scenario.actions';
import * as _ from 'lodash';
import { HelperService } from '../../service/helper.service';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import * as ProductionReviewActions from '../../../store/production-review/production-review.actions';
import { TabRowInterface } from '../side-config-bar/side-config-bar.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivityLogsTableHeaderInterface } from '../../../view/reports/activity-logs/activity-logs.model';
import * as ActivityLogsActions from '../../../store/reports/activity-logs/activity-logs.actions';
import * as CheckInLogActions from '../../../store/reports/check-in-log/check-in-log.actions';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import * as PageHeaderActions from '../../../store/page-header/page-header.actions';
import * as KpiViewActions from '../../../store/dashboards/kpi-view/kpi-view.actions';
import * as ScheduleAdherenceActions from '../../../store/reports/schedule-adherence/schedule-adherence.actions';
import { KpiViewComponents } from '../../../store/dashboards/kpi-view/kpi-view.model';
import * as ShiftSummaryActions from '../../../store/shift-summary/shift-summary.actions';
import { EShiftSummaryGroups } from '../../../view/shift-summary/shift-summary.model';
import { ShiftSummaryService } from '../../../store/shift-summary/shift-summary.service';
import { ofType } from '@ngrx/effects';
import { User, UserLevels } from '../../../store/user/model';
import { AlertsTableHeaderInterface } from '../../../store/settings/alerts/alerts.model';
import * as AlertsActions from '../../../store/settings/alerts/alerts.actions';
import { take } from 'rxjs/operators';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { CALENDAR_ALT_ICON, EDIT_ICON } from '../../../../constants';
import { EWorkOrdersGroups } from '../../../view/work-orders-new-design/work-orders-new-design.model';
import * as MaintenancePerformanceActions from '../../../store/reports/maintenance-performance/maintenance-performance.actions';
import { MaintenancePerformanceTableHeaderInterface } from '../../../view/reports/maintenance-performance/maintenance-performance.model';
import { ScwMatRadioGroupOption } from '../scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';
import * as LaborAssetViewActions from '../../../store/dashboards/labor-asset-view/labor-asset-view.actions';
import { LaborAssetViewService } from '../../../store/dashboards/labor-asset-view/labor-asset-view.service';
import { ELaborAssetViewDataType } from '../../../view/dashboards/labor-asset-view/labor-asset-view.model';
import * as CommentLogsActions from '../../../store/reports/comment-logs/comment-logs.actions';
import { PageConfigurationUtilities } from './page-configuration.utilities';
import { ScheduleAdherenceUtilities } from '../../../view/reports/schedule-adherence/schedule-adherence.utilities';
import { iotDeviceReportDefaultTableHeaders } from '../../../view/reports/iot-device-report/iot-device-report.model';
import * as IotDeviceReportActions from '../../../store/iot-device-report/iot-device-report.actions';
import { AssetViewUtilities } from '../../../view/asset-view/asset-view.utilities';

@OnDestroyDecorator
@Component({
  selector: 'app-page-configuration',
  templateUrl: './page-configuration.component.html',
  styleUrls: ['./page-configuration.component.scss'],
})
export class PageConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild('close_button') closeButtonRef: ViewChild;
  @ViewChild('unsaved_modal') unsavedChangesModalTemplateRef: ViewChildDecorator;
  @ViewChild('config_modal') configModalTemplateRef: ViewChildDecorator;

  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };
  @Input() large: boolean = false;
  public mode: boolean = false;

  public configModalRef: NgbModalRef;
  public modalArray: NgbModalRef[] = [];
  public changesMade: boolean = false;
  private readonly scwModalSm: string = 'scw-modal-sm';
  public userConfigurationData: IUserConfiguration = null;
  public saveChangedModalRef: NgbModalRef;
  private readonly storeSubscriptions: Subscription[] = [];
  public tabs: PageConfigurationInterface[] = [
    {
      table: [],
      widget: [],
      chart: [],
      custom: [],
    },
  ];
  public initialTabs: PageConfigurationInterface[] = [
    {
      table: [],
      widget: [],
      chart: [],
      custom: [],
    },
  ];
  public dashboardModeTranslation: string = this.translate.instant('shiftReview.productionReview.dashboardMode');
  public regularModeTranslation: string = this.translate.instant('shiftReview.productionReview.regularMode');
  public widgetLabelTranslation: string = this.translate.instant('pageConfiguration.modal.widgetLabel');
  public tableLabelTranslation: string = this.translate.instant('pageConfiguration.modal.tableLabel');
  public roundupTableLabelTranslation: string = this.translate.instant('pageConfiguration.modal.roundupTableLabel');
  public chartLabelTranslation: string = this.translate.instant('pageConfiguration.modal.chartLabel');
  public productionReviewInfoMessage: string = this.translate.instant(
    'pageConfiguration.modal.productionReview.info.message',
    { pageMode: this.mode ? this.dashboardModeTranslation : this.regularModeTranslation },
  );
  public tabsToBeAtTheEnd: TableConfigurationInterface[] = [];
  public widgetsToBeAtTheEnd: WidgetConfigurationInterface<EWidgetType.WIDGET>[] = [];
  public rows: TableConfigurationInterface[] = [];
  public widgets: WidgetDataInterface[] = [];
  public charts = [];
  public chartsToBeAtTheEnd = [];
  public tableColumns$: TableColumnInterface[] = [];
  public tabsToSubmit: TabRowInterface[] = [];
  public isSortable: boolean = true;
  public addDashboardModeSwitch: boolean;
  public hasGroupedHeaders: boolean = false;
  public hasRoundupTable: boolean = false;
  private applyModeChange: boolean = true;
  public isVerifiedByAdmin: boolean = false;
  private readonly scwDomain: string = '@supplychainwizard.com';
  private readonly scwAIDomain: string = '@scw.ai';
  public isValidDomain: boolean = false;
  public isAdmin: boolean = false;
  public isWidgetLimitEnabled: boolean = false;
  public widgetLimit: number = 0;
  public selectedWidgetCount: number = 0;
  public initialSelectedWidgetCount: number = 0;

  public workOrderScheduleTableHeaderDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'woNumber',
      name: this.translate.instant('workOrderSchedule.listView.headers.workOrder'),
      disabled: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'siteName',
      name: this.translate.instant('workOrderSchedule.listView.headers.site'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productId',
      name: this.translate.instant('workOrderSchedule.listView.headers.productId'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productDescription',
      name: this.translate.instant('workOrderSchedule.listView.headers.productDescription'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'completed',
      name: this.translate.instant('workOrderSchedule.listView.headers.status'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'missingDataCount',
      name: this.translate.instant('workOrderSchedule.listView.headers.missingDataNumber'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'woDueDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.workOrderDueDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productVersionName',
      name: this.translate.instant('workOrderSchedule.listView.headers.productVersion'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'quantityOrdered',
      name: this.translate.instant('workOrderSchedule.listView.headers.quantityOrdered'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalQuantityOrdered',
      name: this.translate.instant('workOrderSchedule.listView.headers.totalQuantityOrdered'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'woTargetSpeed',
      name: this.translate.instant('workOrderSchedule.listView.headers.targetSpeed'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledDepartmentTitle',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledDepartment'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledLinePathName',
      name: this.translate.instant('general.dataTable.header.scheduledLinePath'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledLineTitle',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledLine'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledRunDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledStartDatetime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledEndDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledEndDatetime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lastDepartmentTitle',
      name: this.translate.instant('workOrderSchedule.listView.headers.lastDepartment'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lastLineTitle',
      name: this.translate.instant('workOrderSchedule.listView.headers.lastLine'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actualRunDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.actualStartDatetime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actualEndDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.actualEndDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'assignedLinePathName',
      name: this.translate.instant('general.dataTable.header.assignedLinePath'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'assignedLineWithinLinePathTitle',
      name: this.translate.instant('general.dataTable.header.assignedLineIdWithinLinePath'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeGoodCount',
      name: this.translate.instant('workOrderSchedule.listView.headers.physicalGoodCount'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeScrapCount',
      name: this.translate.instant('workOrderSchedule.listView.headers.oeeScrapCount'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'packageSize',
      name: this.translate.instant('workOrderSchedule.listView.headers.packageSize'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeInitialCount',
      name: this.translate.instant('workOrderSchedule.listView.headers.physicalInitialCount'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeTotalRunDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.totalRunDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeTotalDownDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.totalDownDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeTotalIdleTimeDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.totalIdleDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeOveralloee',
      name: this.translate.instant('workOrderSchedule.listView.headers.overallOee'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledPreDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledPreRunDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledRunDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledRunDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledPostDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledPostRunDuration'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobName',
      name: this.translate.instant('main.workOrder.workOrderForm.job.label'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'releaseDate',
      name: this.translate.instant('workOrderSchedule.listView.headers.releaseDate'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeA',
      name: this.translate.instant('workOrderSchedule.listView.headers.availability'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeP',
      name: this.translate.instant('workOrderSchedule.listView.headers.performance'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeQ',
      name: this.translate.instant('workOrderSchedule.listView.headers.quality'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'processOrder',
      name: this.translate.instant('workOrderSchedule.listView.headers.processOrder'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'operationNumber',
      name: this.translate.instant('workOrderSchedule.listView.headers.operationNumber'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledPreRunLaborHours',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledPreRunLaborHours'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledRunLaborHours',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledRunLaborHours'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scheduledPostRunLaborHours',
      name: this.translate.instant('workOrderSchedule.listView.headers.scheduledPostRunLaborHours'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'minimumWaitingDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.minimumWaitingDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'maximumWaitingDuration',
      name: this.translate.instant('workOrderSchedule.listView.headers.maximumWaitingDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: EWorkOrdersGroups.PHASES,
      name: this.translate.instant('workOrderSchedule.listView.headers.phases'),
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
    {
      value: EWorkOrdersGroups.TASK_GROUPS,
      name: this.translate.instant('workOrderSchedule.listView.headers.taskGroups'),
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
    {
      value: EWorkOrdersGroups.WORK_ORDER_DURATION,
      name: this.translate.instant('workOrderSchedule.listView.headers.workOrderDuration'),
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
    {
      value: 'canceled',
      name: this.translate.instant('main.workOrder.cancellation'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'relatedRecords',
      name: this.translate.instant('workOrderSchedule.listView.headers.relatedRecords'),
      type: PageConfigurationTypes.TABLE,
      isHiddenOnDashboardMode: true,
    },
    {
      value: 'closedAt',
      name: this.translate.instant('workOrderSchedule.listView.headers.closedAt'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'oeeCalculationFinishedAt',
      name: this.translate.instant('workOrderSchedule.listView.headers.oeeCalculationFinishedAt'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'salesOrderInformation',
      name: this.translate.instant('workOrderSchedule.listView.headers.salesOrderInformation'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'departmentName',
      name: this.translate.instant('workOrderSchedule.listView.headers.departmentName'),
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: EWorkOrdersGroups.ASSIGNMENT,
      name: this.translate.instant('general.assignment'),
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
  ];

  public readonly productsTableHeadersDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'siteName',
      name: this.translate.instant('products.listView.headers.site'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productId',
      name: this.translate.instant('products.listView.headers.productId'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'description',
      name: this.translate.instant('products.listView.headers.description'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'packageSize',
      name: this.translate.instant('products.listView.headers.packageSize'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedProductSpeed',
      name: this.translate.instant('products.listView.headers.standardSpeed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productStructure',
      name: this.translate.instant('products.listView.headers.productStructure'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'customerName',
      name: this.translate.instant('products.listView.headers.customer'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productFamily',
      name: this.translate.instant('products.listView.headers.productFamily'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'materialTypeName',
      name: this.translate.instant('products.listView.headers.materialType'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'unitName',
      name: this.translate.instant('products.listView.headers.unit'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'planningTypeName',
      name: this.translate.instant('products.listView.headers.planningType'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'planningGroup',
      name: this.translate.instant('products.listView.headers.planningGroup'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedMinimumWaitingDuration',
      name: this.translate.instant('products.listView.headers.minimumWaitingDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedMaximumWaitingDuration',
      name: this.translate.instant('products.listView.headers.maximumWaitingDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];
  public scenarioHeadersDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'site.name',
      name: this.translate.instant('schedulerScenario.table.site'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scenarioNumber',
      name: this.translate.instant('schedulerScenario.table.scenarioNumber'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'name',
      name: this.translate.instant('schedulerScenario.table.scenarioName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'owner.fullName',
      name: this.translate.instant('schedulerScenario.table.owner'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'changedAt',
      name: this.translate.instant('schedulerScenario.table.lastUpdate'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'schSetting.name',
      name: this.translate.instant('schedulerScenario.table.resources'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isActive',
      name: this.translate.instant('schedulerScenario.table.status'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'notes',
      name: this.translate.instant('schedulerScenario.table.description'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'planned',
      name: this.translate.instant('schedulerScenario.table.planned'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'deployed',
      name: this.translate.instant('schedulerScenario.table.deployed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'schedule',
      name: this.translate.instant('schedulerScenario.table.schedule'),
      selected: true,
      disabled: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ordered',
      name: this.translate.instant('schedulerScenario.table.workOrder.ordered'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'woKpiPlanned',
      name: this.translate.instant('schedulerScenario.table.woKpiPlanned'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'woKpiDeployed',
      name: this.translate.instant('schedulerScenario.table.woKpiDeployed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actual',
      name: this.translate.instant('schedulerScenario.table.workOrder.actual'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'plannedOrdered',
      name: this.translate.instant('schedulerScenario.table.workOrder.plannedOrdered'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'deployedOrdered',
      name: this.translate.instant('schedulerScenario.table.workOrder.deployedOrdered'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actualOrdered',
      name: this.translate.instant('schedulerScenario.table.workOrder.actualOrdered'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'deployedPlanned',
      name: this.translate.instant('schedulerScenario.table.workOrder.deployedPlanned'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actualPlanned',
      name: this.translate.instant('schedulerScenario.table.workOrder.actualPlanned'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actualDeployed',
      name: this.translate.instant('schedulerScenario.table.workOrder.actualDeployed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalCapacityUtilization',
      name: this.translate.instant('schedulerScenario.table.totalCapacityUtilization'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalCost',
      name: this.translate.instant('schedulerScenario.table.totalCost'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalOeePrediction',
      name: this.translate.instant('schedulerScenario.table.totalOeePrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalAPrediction',
      name: this.translate.instant('schedulerScenario.table.totalAPrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalPPrediction',
      name: this.translate.instant('schedulerScenario.table.totalPPrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalQPrediction',
      name: this.translate.instant('schedulerScenario.table.totalQPrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalOtifPrediction',
      name: this.translate.instant('schedulerScenario.table.totalOtifPrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalOnTimePrediction',
      name: this.translate.instant('schedulerScenario.table.totalOnTimePrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'totalInFullPrediction',
      name: this.translate.instant('schedulerScenario.table.totalInFullPrediction'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'makeSpan',
      name: this.translate.instant('schedulerScenario.table.makeSpan'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'countOfViolation',
      name: this.translate.instant('schedulerScenario.table.workOrder.countOfViolation'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'countOfWarning',
      name: this.translate.instant('schedulerScenario.table.countOfWarning'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'expectedFailurePercentage',
      name: this.translate.instant('schedulerScenario.table.expectedFailurePercentage'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobOrdered',
      name: this.translate.instant('schedulerScenario.table.jobs.ordered'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobPlanned',
      name: this.translate.instant('schedulerScenario.table.jobs.planned'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobActual',
      name: this.translate.instant('schedulerScenario.table.jobs.actual'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobDeployed',
      name: this.translate.instant('schedulerScenario.table.jobs.deployed'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobPlannedOrdered',
      name: this.translate.instant('schedulerScenario.table.jobs.plannedOrdered'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobDeployedOrdered',
      name: this.translate.instant('schedulerScenario.table.jobs.deployedOrdered'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobActualOrdered',
      name: this.translate.instant('schedulerScenario.table.jobs.actualOrdered'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobDeployedPlanned',
      name: this.translate.instant('schedulerScenario.table.jobs.deployedPlanned'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobActualPlanned',
      name: this.translate.instant('schedulerScenario.table.jobs.actualPlanned'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobActualDeployed',
      name: this.translate.instant('schedulerScenario.table.jobs.actualDeployed'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobCountOfViolation',
      name: this.translate.instant('schedulerScenario.table.jobs.countOfViolation'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public lineViewWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] =
    PageConfigurationUtilities.getLineViewWidgets(this.translate);

  public taskTableHeaderDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'siteName',
      name: this.translate.instant('tasks.listView.headers.siteName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineTitle',
      name: this.translate.instant('tasks.listView.headers.lineTitle'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityName',
      name: this.translate.instant('tasks.listView.headers.activity'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'equipmentEquipmentName',
      name: this.translate.instant('tasks.listView.headers.equipment'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'title',
      name: this.translate.instant('tasks.listView.headers.task'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'taskPhaseName',
      name: this.translate.instant('tasks.taskPhase.label'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'statusId',
      name: this.translate.instant('tasks.listView.headers.status'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'hideOnHomePage',
      name: this.translate.instant('general.dataTable.header.hideOnHomePage'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'order',
      name: this.translate.instant('tasks.listView.headers.order'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'description',
      name: this.translate.instant('tasks.listView.headers.description'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'rootCauseGroupName',
      name: this.translate.instant('tasks.listView.headers.rootCauseGroup'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedTarget',
      name: this.translate.instant('tasks.listView.headers.target'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isDurationExceedCommentRequired',
      name: this.translate.instant('task.taskInformationForm.commentRequiredOnTargetExceed.label'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedLcl',
      name: this.translate.instant('tasks.listView.headers.lcl'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formattedUcl',
      name: this.translate.instant('tasks.listView.headers.ucl'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'batchFinalizer',
      name: this.translate.instant('tasks.listView.headers.batchFinalizer'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ignoreSensor',
      name: this.translate.instant('tasks.listView.headers.ignoreSensor'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ignorePhase',
      name: this.translate.instant('tasks.listView.headers.ignorePhase'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isMissingData',
      name: this.translate.instant('tasks.listView.headers.missingData'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isCommentRequired',
      name: this.translate.instant('tasks.listView.headers.isCommentRequired'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'folderName',
      name: this.translate.instant('settings.taskFolder.name'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'withoutWorkOrder',
      name: this.translate.instant('task.taskInformationForm.withoutWorkOrder.label'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'treeNodeParentName',
      name: this.translate.instant('tasks.listView.headers.taskFolderParentName'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public readonly usersTableHeadersDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'fullName',
      name: this.translate.instant('general.dataTable.header.fullName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'userName',
      name: this.translate.instant('general.dataTable.header.username'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'levelId',
      name: this.translate.instant('general.dataTable.header.level'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'email',
      name: this.translate.instant('general.dataTable.header.email'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isActive',
      name: this.translate.instant('general.dataTable.header.status'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'phone',
      name: this.translate.instant('general.dataTable.header.phone'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isCheckable',
      name: this.translate.instant('general.dataTable.header.enableCheckIn'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'checkInCardId',
      name: this.translate.instant('general.dataTable.header.checkInCardId'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'multipleCheckIns',
      name: this.translate.instant('general.dataTable.header.multipleCheckIns'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'userLanguage',
      name: this.translate.instant('general.dataTable.header.userLanguage'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'locale',
      name: this.translate.instant('general.dataTable.header.locale'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'dateFormat',
      name: this.translate.instant('general.dataTable.header.dateFormat'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'currentSite',
      name: this.translate.instant('general.dataTable.header.currentSite'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'currentLine',
      name: this.translate.instant('general.dataTable.header.currentLine'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'defaultSite',
      name: this.translate.instant('general.dataTable.header.defaultSite'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'timeZone',
      name: this.translate.instant('general.dataTable.header.timezone'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'numberFormat',
      name: this.translate.instant('general.dataTable.header.numberFormat'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'contactType',
      name: this.translate.instant('general.dataTable.header.contactType'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'decimalScaleLimit',
      name: this.translate.instant('general.dataTable.header.users.decimalScaleLimit'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'details',
      name: this.translate.instant('general.dataTable.header.details'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'emailVerificationStatus',
      name: this.translate.instant('general.dataTable.header.emailVerificationStatus'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'accountType',
      name: this.translate.instant('general.dataTable.header.accountType'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'isIpRestrictionEnabled',
      name: this.translate.instant('general.dataTable.header.isIpRestrictionEnabled'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lastLoginDate',
      name: this.translate.instant('general.dataTable.header.lastLoginDate'),
      selected: false,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public laborLogTableHeadersDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      width: '39px',
    },
    {
      value: 'line',
      name: this.translate.instant('reports.checkInLogs.tableColumn.lineName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'station',
      name: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'sourceObjectViewName',
      name: this.translate.instant('reports.checkInLogs.tableColumn.user'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'role',
      name: this.translate.instant('reports.checkInLogs.tableColumn.role'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'status',
      name: this.translate.instant('reports.checkInLogs.tableColumn.status'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'startDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.checkIn'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'endDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.checkOut'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'calculatedStartDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.calculatedStartDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'calculatedEndDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.calculatedEndDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'duration',
      name: this.translate.instant('reports.checkInLogs.tableColumn.laborHours'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'siteName',
      name: this.translate.instant('general.siteName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'details',
      name: this.translate.instant('reports.checkInLogs.tableColumn.details'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftName',
      name: this.translate.instant('commentLogs.header.shiftName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public assetLogTableHeadersDefaults: ITableHeader[] = [
    {
      value: null,
      name: '',
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      width: '39px',
    },
    {
      value: 'line',
      name: this.translate.instant('reports.checkInLogs.tableColumn.lineName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'station',
      name: this.translate.instant('reports.checkInLogs.tableColumn.stationName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'sourceObjectViewName',
      name: this.translate.instant('reports.checkInLogs.tableColumn.asset'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'startDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.checkIn'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'endDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.checkOut'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'calculatedStartDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.calculatedStartDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'calculatedEndDate',
      name: this.translate.instant('reports.checkInLogs.tableColumn.calculatedEndDate'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'duration',
      name: this.translate.instant('reports.checkInLogs.tableColumn.duration'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'siteName',
      name: this.translate.instant('general.siteName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftName',
      name: this.translate.instant('commentLogs.header.shiftName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public productionListViewHeaders: ITableHeader[] = [
    {
      value: 'comments',
      name: this.translate.instant('shiftSummary.table.headers.comments'),
      selected: true,
      sortable: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'initial_and_scrap',
      name: this.translate.instant('shiftReview.productionReview.initialAndScrap'),
      selected: false,
      sortable: true,
      opposedTo: [
        'initial_and_good',
        'good_and_scrap',
        'manual_initial_count',
        'manual_scrap_count',
        'manual_good_count',
      ],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'initial_and_good',
      name: this.translate.instant('shiftReview.productionReview.initialAndGood'),
      selected: false,
      sortable: true,
      opposedTo: [
        'initial_and_scrap',
        'good_and_scrap',
        'manual_initial_count',
        'manual_scrap_count',
        'manual_good_count',
      ],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'good_and_scrap',
      name: this.translate.instant('shiftReview.productionReview.goodAndScrap'),
      selected: true,
      sortable: true,
      opposedTo: [
        'initial_and_scrap',
        'initial_and_good',
        'manual_initial_count',
        'manual_scrap_count',
        'manual_good_count',
      ],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'manual_initial_count',
      name: this.translate.instant('shiftReview.productionReview.initialCount'),
      selected: false,
      sortable: true,
      opposedTo: ['initial_and_scrap', 'initial_and_good', 'good_and_scrap'],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'manual_good_count',
      name: this.helperService.isTouchDevice
        ? this.translate.instant('shiftReview.productionReview.actualCountTouchDevice')
        : this.translate.instant('shiftReview.productionReview.actualCount'),
      selected: false,
      opposedTo: ['initial_and_scrap', 'initial_and_good', 'good_and_scrap'],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'cumulative_total',
      name: this.helperService.isTouchDevice
        ? this.translate.instant('shiftReview.productionReview.shiftCountTouchDevice')
        : this.translate.instant('shiftReview.productionReview.shiftCount'),
      selected: true,
      sortable: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'manual_scrap_count',
      name: this.helperService.isTouchDevice
        ? this.translate.instant('shiftReview.productionReview.actualScrapCountTouchDevice')
        : this.translate.instant('shiftReview.productionReview.actualScrapCount'),
      selected: false,
      sortable: true,
      opposedTo: ['initial_and_scrap', 'initial_and_good', 'good_and_scrap'],
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'batch_number',
      name: this.translate.instant('shiftReview.productionReview.workOrder'),
      selected: true,
      sortable: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'target_work_order_number',
      name: this.translate.instant('shiftReview.productionReview.targetWorkOrder'),
      selected: false,
      sortable: true,
      linkedTo: 'target_count',
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'target_count',
      name: this.translate.instant('shiftReview.productionReview.targetCount'),
      selected: false,
      sortable: true,
      linkedTo: 'target_work_order_number',
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public productionListViewRoundupTableHeaders: ITableHeader[] = [
    {
      value: 'summary.work_order',
      name: this.translate.instant('shiftReview.productionReview.workOrder'),
      disabled: true,
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'product_id_description',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.product'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'process_order',
      name: this.translate.instant('shiftReview.productionReview.processOrder'),
      sortable: false,
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'job',
      name: this.translate.instant('shiftReview.productionReview.job'),
      sortable: false,
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shift_start_count',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.shiftStartCount'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shift_production',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.shiftProduction'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shift_end_count',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.shiftEndCount'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'remaining_by_shift_end',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.remainingByShiftEnd'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'final_work_order_count',
      name: this.translate.instant('shiftReview.productionReview.workOrderSummaryTable.finalWorkOrderCount'),
      sortable: false,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
  ];
  public productionReviewWidgets: WidgetConfigurationInterface<EWidgetType.WIDGET>[] =
    PageConfigurationUtilities.getProductionReviewWidgets(this.translate);

  private readonly activityLogsTableHeadersDefaults: ActivityLogsTableHeaderInterface[] = [
    {
      value: 'siteName',
      name: this.translate.instant('general.site'),
      selected: true,
    },
    {
      value: 'lineType',
      name: this.translate.instant('general.department'),
      selected: true,
    },
    {
      value: 'lineTitle',
      name: this.translate.instant('general.line'),
      selected: true,
    },
    {
      value: 'activityName',
      name: this.translate.instant('general.activity'),
      selected: true,
      disabled: true,
    },
    {
      value: 'taskName',
      name: this.translate.instant('general.task'),
      selected: true,
    },
    {
      value: 'start',
      name: this.translate.instant('general.excel.column.startDate'),
      selected: true,
    },
    {
      value: 'end',
      name: this.translate.instant('general.excel.column.endDate'),
      selected: true,
    },
    {
      value: 'duration',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds'),
      }),
      selected: true,
    },
    {
      value: 'shiftDay',
      name: this.translate.instant('general.shiftDay'),
      selected: true,
    },
    {
      value: 'shiftName',
      name: this.translate.instant('general.shift'),
      selected: true,
    },
    {
      value: 'ucl',
      name: this.translate.instant('general.ucl'),
      selected: false,
    },
    {
      value: 'lcl',
      name: this.translate.instant('general.lcl'),
      selected: false,
    },
    {
      value: 'userFullName',
      name: this.translate.instant('general.user'),
      selected: false,
    },
    {
      value: 'description',
      name: this.translate.instant('activityLogs.tableHeaders.activityComments'),
      selected: false,
      sortable: false,
    },
    {
      value: 'equipmentName',
      name: this.translate.instant('excel.headers.equipment'),
      selected: false,
    },
    {
      value: 'workOrderNumber',
      name: this.translate.instant('general.workOrder'),
      selected: false,
    },
    {
      value: 'durationMin',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.minutes'),
      }),
      selected: false,
    },
    {
      value: 'phaseName',
      name: this.translate.instant('general.phaseName'),
      selected: false,
      sortable: false,
    },
    {
      value: 'totalDuration',
      name: this.translate.instant('general.totalDuration'),
      selected: true,
      isTableRow: true,
    },
    {
      value: 'activityTypeText',
      name: this.translate.instant('activitiesPage.tableField.activityType'),
      selected: false,
    },
    {
      value: 'productInfo',
      name: this.translate.instant('general.dataTable.header.product'),
      selected: false,
    },
    {
      value: 'targetDuration',
      name: this.translate.instant('activityLogs.tableHeaders.taskTargetDuration'),
      selected: false,
    },
    {
      value: 'processOrder',
      name: this.translate.instant('shiftSummary.table.headers.processOrder'),
      selected: false,
    },
    {
      value: 'jobNumber',
      name: this.translate.instant('scheduler.listView.jobNumber'),
      selected: false,
    },
    {
      value: 'crewSize',
      name: this.translate.instant('activityHistory.form.crewSize'),
      selected: false,
    },
    {
      value: 'commentTags',
      name: this.translate.instant('activityLogs.tableHeaders.commentTags'),
      selected: false,
      sortable: false,
    },
    {
      value: 'quantityOrdered',
      name: this.translate.instant('general.dataTable.header.quantityOrdered'),
      selected: false,
    },
    {
      value: 'auditLogs',
      name: this.translate.instant('general.dataTable.header.auditLogs'),
      selected: false,
      sortable: false,
    },
  ];
  public tableConfigHasRow: boolean = false;

  public lineViewExtendedTableHeaders: ITableHeader[] = [
    {
      value: 'siteName',
      name: this.translate.instant('lineView.tableHeaders.siteName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'departmentName',
      name: this.translate.instant('lineView.tableHeaders.departmentName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineName',
      name: this.translate.instant('lineView.tableHeaders.lineName'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineState',
      name: this.translate.instant('lineView.tableHeaders.lineState'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityName',
      name: this.translate.instant('lineView.tableHeaders.activityName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityStartTime',
      name: this.translate.instant('lineView.tableHeaders.activityStartTime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityDuration',
      name: this.translate.instant('lineView.tableHeaders.activityDuration'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderName',
      name: this.translate.instant('lineView.tableHeaders.workOrderName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderPhase',
      name: this.translate.instant('lineView.tableHeaders.workOrderPhase'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderPhaseDuration',
      name: this.translate.instant('lineView.tableHeaders.workOrderPhaseDuration'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'meta.missingDataCount',
      name: this.translate.instant('lineView.tableHeaders.missingDataCount'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderGoodCount',
      name: this.translate.instant('lineView.tableHeaders.workOrderGoodCount'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'averageSpeed',
      name: this.translate.instant('lineView.tableHeaders.averageSpeed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderDuration',
      name: this.translate.instant('lineView.tableHeaders.workOrderDuration'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'runTimeAverageSpeed',
      name: this.translate.instant('lineView.tableHeaders.runTimeAverageSpeed'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'scrapQuantity',
      name: this.translate.instant('lineView.tableHeaders.scrapQuantity'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'preRunVersusTargetPercentage',
      name: this.translate.instant('lineView.tableHeaders.preRunVersusTargetPercentage'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'runVersusTargetPercentage',
      name: this.translate.instant('lineView.tableHeaders.runVersusTargetPercentage'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'postRunVersusTargetPercentage',
      name: this.translate.instant('lineView.tableHeaders.postRunVersusTargetPercentage'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'preRunVersusTargetNumerical',
      name: this.translate.instant('lineView.tableHeaders.preRunVersusTargetNumerical'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'runVersusTargetNumerical',
      name: this.translate.instant('lineView.tableHeaders.runVersusTargetNumerical'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'postRunVersusTargetNumerical',
      name: this.translate.instant('lineView.tableHeaders.postRunVersusTargetNumerical'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderPhaseStart',
      name: this.translate.instant('lineView.tableHeaders.workOrderPhaseStart'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'operatorName',
      name: this.translate.instant('lineView.tableHeaders.operatorName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'taskIssueName',
      name: this.translate.instant('lineView.tableHeaders.taskIssueName'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'todayOEE',
      name: this.translate.instant('lineView.tableHeaders.todayOEE'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'availability',
      name: this.translate.instant('lineView.tableHeaders.availability'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'performance',
      name: this.translate.instant('lineView.tableHeaders.performance'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'quality',
      name: this.translate.instant('lineView.tableHeaders.quality'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productId',
      name: this.translate.instant('lineView.tableHeaders.productId'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productDescription',
      name: this.translate.instant('lineView.tableHeaders.productDescription'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'PreRunDuration',
      name: this.translate.instant('lineView.tableHeaders.PreRunDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'RunDuration',
      name: this.translate.instant('lineView.tableHeaders.RunDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'PostRunDuration',
      name: this.translate.instant('lineView.tableHeaders.PostRunDuration'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'nameOfCheckInUsers',
      name: this.translate.instant('lineView.tableHeaders.nameOfCheckInUsers'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'numberOfCheckInUsers',
      name: this.translate.instant('lineView.tableHeaders.numberOfCheckInUsers'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'nameOfCheckInAssets',
      name: this.translate.instant('lineView.tableHeaders.nameOfCheckInAssets'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'numberOfCheckInAssets',
      name: this.translate.instant('lineView.tableHeaders.numberOfCheckInAssets'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'processOrder',
      name: this.translate.instant('lineView.tableHeaders.processOrder'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'job',
      name: this.translate.instant('lineView.tableHeaders.job'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'nameOfCheckInMaintenanceUsers',
      name: this.translate.instant('lineView.tableHeaders.nameOfCheckInMaintenanceUsers'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'liveCamera',
      name: this.translate.instant('lineView.tableHeaders.liveCamera'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
    {
      value: 'laborCapacity',
      name: this.translate.instant('lineView.tableHeaders.laborCapacity'),
      selected: false,
      type: PageConfigurationTypes.TABLE,
      sortable: false,
    },
  ];

  public assetViewTableHeaders: ITableHeader[] = [
    {
      value: 'assetName',
      name: this.translate.instant('assetView.tableHeaders.assetName'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'locationName',
      name: this.translate.instant('assetView.tableHeaders.locationName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineStationName',
      name: this.translate.instant('assetView.tableHeaders.lineStationName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'status',
      name: this.translate.instant('assetView.tableHeaders.status'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'checkInTime',
      name: this.translate.instant('assetView.tableHeaders.checkInTime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'checkOutTime',
      name: this.translate.instant('assetView.tableHeaders.checkOutTime'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ongoingWoNumber',
      name: this.translate.instant('assetView.tableHeaders.ongoingWoNumber'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ongoingProductDescription',
      name: this.translate.instant('assetView.tableHeaders.ongoingProductDescription'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'equipmentType',
      name: this.translate.instant('assetView.tableHeaders.equipmentType'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'equipmentBrand',
      name: this.translate.instant('assetView.tableHeaders.equipmentBrand'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'equipmentModel',
      name: this.translate.instant('assetView.tableHeaders.equipmentModel'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'equipmentDescription',
      name: this.translate.instant('assetView.tableHeaders.equipmentDescription'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public assetViewWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = AssetViewUtilities.getKpiCardData(
    this.translate,
  );

  public workOrderWidgets: WidgetConfigurationInterface<EWidgetType.WIDGET>[] =
    PageConfigurationUtilities.getWorkOrderWidgets();

  private readonly workOrderCustomSections: ICustomSectionConfiguration[] = [
    {
      name: 'dynamicDateFilter',
      bordered: false,
      header: this.translate.instant('pageConfiguration.modal.dynamicDateHeader'),
      headerIconClass: CALENDAR_ALT_ICON,
      label: this.translate.instant('pageConfiguration.modal.dynamicDateLabel'),
      isRadio: true,
      defaultRadioButtonValue: 'woDueDateDateType',
      radioGroup: [
        {
          value: 'woDueDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.workOrderDueDate'),
          disabled: false,
          checked: true,
        },
        {
          value: 'releaseDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.releaseDate'),
          disabled: false,
          checked: false,
        },
        {
          value: 'scheduledRunDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.scheduledStartDatetime'),
          disabled: false,
          checked: false,
        },
        {
          value: 'scheduledEndDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.scheduledEndDatetime'),
          disabled: false,
          checked: false,
        },
        {
          value: 'actualRunDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.actualStartDatetime'),
          disabled: false,
          checked: false,
        },
        {
          value: 'actualEndDateDateType',
          text: this.translate.instant('workOrderSchedule.listView.headers.actualEndDate'),
          disabled: false,
          checked: false,
        },
      ],
      rows: [],
      sortable: false,
      hasRadioButtonBehaviour: true,
      disableDefaultSort: true,
    },
    {
      name: 'recalculateWorkOrders',
      bordered: false,
      header: this.translate.instant('pageConfiguration.modal.workOrderRecalculation'),
      headerIconClass: 'fas fa-solid fa-calculator',
      show: false,
      rows: [
        {
          name: 'recalculate',
          selected: false,
          title: this.translate.instant('pageConfiguration.workOrderRecalculation.recalculate'),
          disabled: false,
        },
      ],
      sortable: false,
    },
  ];

  public dashboardModeButtons: ScwMatButtonGroupButtons[] = [
    {
      value: false,
      text: this.translate.instant('general.regularMode'),
    },
    {
      value: true,
      text: this.translate.instant('general.dashboardMode'),
    },
  ];

  private initialMode: boolean;
  private widgetDataIndex: number = 2;
  private tableDataIndex: number = 1;
  public configurationGroups: IPageConfigurationGroup<EShiftSummaryGroups>[] = [];
  public initialConfigurationGroups: IPageConfigurationGroup<EShiftSummaryGroups>[] = [];
  public readonly pageConfigurationTypes = PageConfigurationTypes;
  public defaultSectionOrder: ICustomSectionOrderConfiguration[] = [
    {
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      type: PageConfigurationTypes.BUTTON_GROUP,
    },
    {
      type: PageConfigurationTypes.WIDGET,
    },
    {
      type: PageConfigurationTypes.TABLE,
    },
    {
      type: PageConfigurationTypes.CHART,
    },
    {
      type: PageConfigurationTypes.CUSTOM,
      index: 0,
    },
  ];
  public sectionOrder: ICustomSectionOrderConfiguration[] = this.defaultSectionOrder;

  private readonly shiftSummaryCustomSections: ICustomSectionConfiguration[] = [
    {
      name: 'erpCalculation',
      bordered: false,
      header: this.translate.instant('pageConfiguration.modal.erpCalculation'),
      headerIconClass: 'fas fa-solid fa-calculator',
      rows: [
        {
          name: 'erpCalculationUseCheckedIn',
          selected: false,
          title: this.translate.instant('pageConfiguration.erpCalculation.useCheckedIn'),
          disabled: false,
        },
      ],
      sortable: false,
    },
  ];

  private readonly usersCustomSections: ICustomSectionConfiguration[] = [
    {
      name: 'systemUser',
      bordered: false,
      header: null,
      headerIconClass: null,
      rows: [
        {
          name: 'showSystemUser',
          selected: false,
          title: this.translate.instant('pageConfiguration.users.showSystemUser'),
          disabled: false,
        },
      ],
      sortable: false,
    },
  ];

  private readonly kpiViewWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] =
    PageConfigurationUtilities.getKpiViewWidgets(this.translate);
  private readonly kpiViewCharts: ChartConfigurationInterface[] = [
    {
      name: KpiViewComponents.oeeTrend,
      title: this.translate.instant('kpiView.chart.oeeTrend.label'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.CHART,
    },
    {
      name: KpiViewComponents.workOrderTrend,
      title: this.translate.instant('kpiView.chart.workOrderTrend.label'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.CHART,
    },
    {
      name: KpiViewComponents.goodCountTrend,
      title: this.translate.instant('kpiView.chart.goodCountTrend.label'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.CHART,
    },
    {
      name: KpiViewComponents.laborHoursTrend,
      title: this.translate.instant('kpiView.chart.laborHoursTrend.label'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.CHART,
    },
  ];

  private readonly scheduleAdherenceCustomField: ICustomSectionConfiguration[] = [
    {
      name: 'activityTypesMods',
      bordered: false,
      header: this.translate.instant('pageConfiguration.modal.scheduleAdherence.modSectionHeader'),
      headerIconClass: EDIT_ICON,
      label: this.translate.instant('pageConfiguration.modal.scheduleAdherence.modSectionInformation'),
      isRadio: true,
      defaultRadioButtonValue: 'allTypesOfActivities',
      radioGroup: [
        {
          value: 'allTypesOfActivities',
          checked: true,
          text: this.translate.instant('pageConfiguration.modal.scheduleAdherence.modSection.allTypeMode'),
          disabled: false,
        },
        {
          value: 'excludeUnplannedDownTimeActivities',
          checked: false,
          text: this.translate.instant(
            'pageConfiguration.modal.scheduleAdherence.modSection.excludeUnplannedDowntimeMode',
          ),
          disabled: false,
        },
      ],
      rows: [],
      sortable: false,
      hasRadioButtonBehaviour: true,
      disableDefaultSort: true,
    },
  ];

  private readonly scheduleAdherenceWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] =
    ScheduleAdherenceUtilities.getScheduleAdherenceWidgets(this.translate);

  private readonly scheduleAdherenceTableHeaderDefaults: ITableHeader[] =
    ScheduleAdherenceUtilities.getScheduleAdherencePageConfigHeaders(this.translate);

  public sectionTypes = SectionTypes;

  public readonly alertsTableHeadersDefaults: AlertsTableHeaderInterface[] = [
    {
      value: 'name',
      name: this.translate.instant('alertSettings.table.name'),
      sortable: true,
      selected: true,
      disabled: true,
    },
    {
      value: 'function',
      name: this.translate.instant('alertSettings.table.function'),
      sortable: false,
      selected: true,
    },
    {
      value: 'errorTitle',
      name: this.translate.instant('alertSettings.table.errorTitle'),
      sortable: true,
      selected: true,
    },
    {
      value: 'message',
      name: this.translate.instant('alertSettings.table.message'),
      sortable: true,
      selected: true,
    },
    {
      value: 'active',
      name: this.translate.instant('alertSettings.table.active'),
      sortable: true,
      selected: true,
    },
  ];
  public defaultMaintenancePerformanceTableHeaders: MaintenancePerformanceTableHeaderInterface[] = [
    {
      value: 'siteNames',
      name: this.translate.instant('workOrderSchedule.listView.headers.site'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'departmentName',
      name: this.translate.instant('general.dataTable.header.departmentName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineName',
      name: this.translate.instant('general.dataTable.header.lineName'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'numberOfFailures',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.number_of_failures'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'MTTF',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.mttf'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'MTTR',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.mttr'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'MTBF',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.mtbf'),
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'inspectColumn',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.inspect'),
      selected: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'ignoreIdleActivities',
      name: this.translate.instant('general.dataTable.header.maintenance_performance.ignore_idle_activities'),
      selected: true,
      isTableRow: true,
    },
  ];

  public commentLogsTableHeaders: ITableHeader[] = [
    {
      value: 'siteName',
      name: this.translate.instant('commentLogs.header.siteName'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrderName',
      name: this.translate.instant('commentLogs.header.workOrderName'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'objectType',
      name: this.translate.instant('commentLogs.header.objectType'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'objectName',
      name: this.translate.instant('commentLogs.header.objectName'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'commentType',
      name: this.translate.instant('commentLogs.header.commentType'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'commentMessage',
      name: this.translate.instant('commentLogs.header.commentMessage'),
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftName',
      name: this.translate.instant('commentLogs.header.shiftName'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'tags',
      name: this.translate.instant('general.dataTable.header.tags'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'createdBy',
      name: this.translate.instant('commentLogs.header.createdBy'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'commentDate',
      name: this.translate.instant('commentLogs.header.commentDate'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lastChangedBy',
      name: this.translate.instant('commentLogs.header.lastChangedBy'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lastChangedAt',
      name: this.translate.instant('commentLogs.header.lastChangedAt'),
      selected: true,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'lineName',
      name: this.translate.instant('general.dataTable.header.line'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'departmentName',
      name: this.translate.instant('general.dataTable.header.department'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'jobName',
      name: this.translate.instant('commentLogs.header.job'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityName',
      name: this.translate.instant('general.dataTable.header.activityName'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'taskName',
      name: this.translate.instant('general.dataTable.header.taskName'),
      selected: false,
      disabled: false,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly actionsSubject: ActionsSubject,
    private readonly ngbModal: NgbModal,
    private readonly helperService: HelperService,
    private readonly shiftSummaryService: ShiftSummaryService,
    private readonly laborAssetViewService: LaborAssetViewService,
  ) {}

  public ngOnInit(): void {
    const pagesToAddDashboardModeSwitch: string[] = ['work_orders', 'production_review', 'kpi_view'];
    const pagesWithRoundupTable: string[] = ['production_review'];
    const pagesWithGroupedHeaders: string[] = ['shift_summary'];
    this.addDashboardModeSwitch = pagesToAddDashboardModeSwitch.includes(this.pageHeader.titleKey);
    this.hasGroupedHeaders = pagesWithGroupedHeaders.includes(this.pageHeader.titleKey);
    this.hasRoundupTable = pagesWithRoundupTable.includes(this.pageHeader.titleKey);

    this.storeSubscriptions.push(
      this.store.select('userConfigurationStore').subscribe((state: UserConfigurationStateInterface) => {
        if (state.userConfigurationDataLoaded && !state.userConfigurationDataLoading) {
          this.userConfigurationData = HelperService.cloneDeep(state.userConfigurationData);
          this.getTabs(this.pageHeader.titleKey, state.userConfigurationData, false);
        }
      }),
      this.actionsSubject
        .pipe(ofType(ShiftSummaryActions.ShiftSummaryActionTypes.GetShiftDataAndGroupsLoaded))
        .subscribe((responseReadOnly: ShiftSummaryActions.GetShiftDataAndGroupsLoaded) => {
          const response = HelperService.cloneDeep(responseReadOnly);
          const phases: IPageConfigurationGroup<EShiftSummaryGroups> = _.find(this.configurationGroups, {
            id: EShiftSummaryGroups.PHASES,
          });
          const phaseLaborHours: IPageConfigurationGroup<EShiftSummaryGroups> = _.find(this.configurationGroups, {
            id: EShiftSummaryGroups.PHASE_LABOR_HOURS,
          });

          this.shiftSummaryService.setPhaseNames(
            this.shiftSummaryService.shiftSummaryTableDefaults,
            HelperService.cloneDeep(response.payload.columnNames),
          );
          this.shiftSummaryService.setPhaseNames(
            phases.values.concat(phaseLaborHours.values),
            HelperService.cloneDeep(response.payload.columnNames),
          );
        }),

      this.actionsSubject
        .pipe(ofType(LaborAssetViewActions.ELaborAssetHoursActionTypes.SET_PAGE_CONFIGURATION))
        .subscribe((): void => {
          this.getTabs('labor_asset_view', this.userConfigurationData, false);
          this.apply(false);
        }),

      this.store.select('user').subscribe((state: User) => {
        if (this.pageHeader.titleKey === 'users') {
          this.tabs[0].table = state.isNonVerifiedUserPassActive
            ? _.reject(this.tabs[0].table, { name: 'emailVerificationStatus' })
            : this.tabs[0].table;

          this.validateUserForRecalculation(state);
          this.apply(false);
        }
        if (this.pageHeader.titleKey === 'work_orders') {
          this.isVerifiedByAdmin = state.isVerifiedByAdmin;
          state.email.includes(this.scwDomain) || state.email.includes(this.scwAIDomain)
            ? (this.isValidDomain = true)
            : (this.isValidDomain = false);
          this.isAdmin = Number(state.level.parentLevelId ?? state.userLevelId) === UserLevels.ADMIN;
        }
      }),
    );
  }

  drop($event: CdkDragDrop<string[]>, data): void {
    this.changesMade = true;
    moveItemInArray(data, $event.previousIndex, $event.currentIndex);
  }

  public showModal(templateRef: TemplateRef<any>): void {
    this.configModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
    this.modalArray.push(this.configModalRef);
    this.tableConfigHasRow = this.tabs[0].table.some((item) => item?.isTableRow);
  }

  public getTabs(pageHeaderKey: string, userConfigurationData: IUserConfiguration, modeChange: boolean): void {
    let checkedItemIndex: number = -1;
    this.resetParameters();
    this.sectionOrder = this.defaultSectionOrder;

    switch (pageHeaderKey) {
      case 'work_orders':
        if (!modeChange) {
          this.mode = !!_.find(userConfigurationData.WorkOrdersComponent, { name: 'isDashboardModeDefault' })?.value;
          this.initialMode = _.cloneDeep(this.mode);
        }
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state: User) => {
            this.validateUserForRecalculation(state);
          });

        this.sectionOrder = [
          {
            type: PageConfigurationTypes.HEADER_GROUP,
          },
          {
            type: PageConfigurationTypes.BUTTON_GROUP,
          },
          {
            type: PageConfigurationTypes.CUSTOM,
            index: 0,
          },
          {
            type: PageConfigurationTypes.WIDGET,
          },
          {
            type: PageConfigurationTypes.TABLE,
          },
          {
            type: PageConfigurationTypes.CHART,
          },
        ];

        _.set(_.find(this.workOrderWidgets, { name: 'kpiCards' }), 'selected', this.mode);
        this.iterateOverDefault(
          this.workOrderScheduleTableHeaderDefaults,
          this.workOrderWidgets,
          userConfigurationData,
          this.mode
            ? ComponentNamesForUserConfiguration.WorkOrderScheduleDashboardMode
            : ComponentNamesForUserConfiguration.WorkOrderSchedule,
          [],
          false,
          this.mode ? [this.workOrderCustomSections[0]] : [this.workOrderCustomSections[1]],
        );

        break;
      case 'products':
        this.iterateOverDefault(
          this.productsTableHeadersDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.ProductSettings,
        );
        break;
      case 'tasks':
        this.iterateOverDefault(
          this.taskTableHeaderDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.TaskSettings,
        );
        break;
      case 'users':
        this.iterateOverDefault(
          this.usersTableHeadersDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.UserSettings,
          [],
          false,
          this.usersCustomSections
        );
        break;
      case 'scheduler_scenario':
        this.iterateOverDefault(
          this.scenarioHeadersDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.SchedulerScenario,
        );
        break;
      case 'activity_logs':
        checkedItemIndex = this.arrangeHeaders(
          this.activityLogsTableHeadersDefaults,
          userConfigurationData,
          'ActivityLogsComponent',
          checkedItemIndex,
        );
        break;
      case 'alerts':
        checkedItemIndex = this.arrangeHeaders(
          this.alertsTableHeadersDefaults,
          userConfigurationData,
          ComponentNamesForUserConfiguration.AlertSettings,
          checkedItemIndex,
        );
        break;
      case 'production_review':
        if (!modeChange) {
          this.mode = !!_.find(userConfigurationData.ProductionReviewRegularModeComponent, {
            name: 'isDashboardModeDefault',
          })?.value;
          this.initialMode = _.cloneDeep(this.mode);
        }
        this.isSortable = true;
        this.tableLabelTranslation = this.translate.instant('pageConfiguration.modal.productionReview.tableLabel', {
          pageMode: this.mode ? this.dashboardModeTranslation : this.regularModeTranslation,
        });
        this.widgetLabelTranslation = this.translate.instant('pageConfiguration.modal.productionReview.widgetLabel', {
          pageMode: this.mode ? this.dashboardModeTranslation : this.regularModeTranslation,
        });
        this.roundupTableLabelTranslation = this.translate.instant(
          'pageConfiguration.modal.productionReview.roundupTableLabel',
          {
            pageMode: this.mode ? this.dashboardModeTranslation : this.regularModeTranslation,
          },
        );
        if (!this.mode) {
          this.iterateOverDefault(
            [...this.productionListViewHeaders, ...this.productionListViewRoundupTableHeaders],
            this.productionReviewWidgets,
            userConfigurationData,
            ComponentNamesForUserConfiguration.ProductionReviewRegularMode,
          );
        } else if (this.mode) {
          this.iterateOverDefault(
            [...this.productionListViewHeaders, ...this.productionListViewRoundupTableHeaders],
            this.productionReviewWidgets,
            userConfigurationData,
            ComponentNamesForUserConfiguration.ProductionReviewDashboardMode,
          );
        }
        break;
      case 'line_view':
        this.iterateOverDefault(
          this.lineViewExtendedTableHeaders,
          this.lineViewWidgets,
          userConfigurationData,
          ComponentNamesForUserConfiguration.LineViewWorkOrderSummary,
          [],
          true,
        );
        break;
      case 'asset_view':
        this.iterateOverDefault(
          this.assetViewTableHeaders,
          this.assetViewWidgets,
          userConfigurationData,
          ComponentNamesForUserConfiguration.AssetView,
          [],
          true,
        );
        break;
      case 'shift_summary':
        this.iterateOverDefault(
          [],
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.ShiftSummary,
          [],
          false,
          this.shiftSummaryCustomSections,
        );
        this.prepareConfigurationGroups(
          _.cloneDeep(this.shiftSummaryService.shiftSummaryTableGroups),
          _.cloneDeep(this.shiftSummaryService.shiftSummaryTableDefaults),
          ComponentNamesForUserConfiguration.ShiftSummary,
        );
        break;
      case 'kpi_view':
        if (!modeChange) {
          this.mode = Boolean(
            _.find(userConfigurationData.KpiViewComponent, { name: 'isDashboardModeDefault' })?.value ?? true,
          );
          this.initialMode = _.cloneDeep(this.mode);
        }

        this.iterateOverDefault(
          [],
          this.kpiViewWidgets,
          userConfigurationData,
          this.mode
            ? ComponentNamesForUserConfiguration.KpiViewComponentDashboardMode
            : ComponentNamesForUserConfiguration.KpiViewComponent,
          this.kpiViewCharts,
        );
        break;
      case 'schedule_adherence_report':
        this.isWidgetLimitEnabled = true;
        this.widgetLimit = 4;

        if (!modeChange) {
          this.mode = !!_.find(userConfigurationData.ScheduleAdherenceComponent, { name: 'isDashboardModeDefault' })
            ?.value;
          this.initialMode = _.cloneDeep(this.mode);
        }

        this.sectionOrder = [
          {
            type: PageConfigurationTypes.HEADER_GROUP,
          },
          {
            type: PageConfigurationTypes.BUTTON_GROUP,
          },
          {
            type: PageConfigurationTypes.CUSTOM,
            index: 0,
          },
          {
            type: PageConfigurationTypes.WIDGET,
          },
          {
            type: PageConfigurationTypes.TABLE,
          },
        ];

        this.iterateOverDefault(
          this.scheduleAdherenceTableHeaderDefaults,
          this.scheduleAdherenceWidgets,
          userConfigurationData,
          ComponentNamesForUserConfiguration.ScheduleAdherenceComponent,
          [],
          false,
          this.scheduleAdherenceCustomField,
        );
        break;
      case 'labor_logs_report':
        this.iterateOverDefault(
          this.laborLogTableHeadersDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.LaborLogs,
        );
        break;
      case 'asset_logs_report':
        this.iterateOverDefault(
          this.assetLogTableHeadersDefaults,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.AssetLogs,
        );
        break;
      case 'maintenance_performance':
        checkedItemIndex = this.arrangeHeaders(
          this.defaultMaintenancePerformanceTableHeaders,
          userConfigurationData,
          ComponentNamesForUserConfiguration.MaintenancePerformanceComponent,
          checkedItemIndex,
        );
        break;
      case 'labor_asset_view':
        if (!modeChange) {
          this.mode =
            !!_.find(userConfigurationData.LaborAssetViewComponent, { name: 'isDashboardModeDefault' })?.value ?? true;
          this.initialMode = _.cloneDeep(this.mode);
        }

        const pageMode: ELaborAssetViewDataType = LaborAssetViewService.pageModeSubject.getValue();

        this.iterateOverDefault(
          this.laborAssetViewService.getLaborAssetViewHeaders(pageMode),
          this.laborAssetViewService.getLaborAssetViewWidgets(pageMode),
          userConfigurationData,
          ComponentNamesForUserConfiguration.LaborAssetView,
          [],
        );
        break;
      case 'comment_logs':
        this.iterateOverDefault(
          this.commentLogsTableHeaders,
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.CommentLogs,
        );
        break;
      case 'iot_device_report':
        this.iterateOverDefault(
          iotDeviceReportDefaultTableHeaders(this.translate),
          [],
          userConfigurationData,
          ComponentNamesForUserConfiguration.IotDeviceReport,
        );
        break;
    }

    this.tabsToBeAtTheEnd.forEach((tab) => {
      this.tabs[0].table[this.tabs[0].table.length] = {
        name: tab.name,
        selected: tab.selected,
        title: tab.title,
        disabled: tab.disabled ? tab.disabled : false,
        sortable: tab?.sortable,
        isTableRow: tab?.isTableRow,
        linkedTo: tab.linkedTo ? tab.linkedTo : null,
        type: PageConfigurationTypes.TABLE,
        opposedTo: tab?.opposedTo,
      };
    });

    this.widgetsToBeAtTheEnd.forEach((tab) => {
      this.tabs[0].widget[this.tabs[0].widget.length] = {
        type: tab.type,
        name: tab.name,
        selected: tab.selected,
        disabled: tab.disabled,
        content: tab.content,
      };
    });

    this.chartsToBeAtTheEnd.forEach((tab) => {
      this.tabs[0].chart[this.tabs[0].chart.length] = {
        name: tab.name,
        selected: tab.selected,
        title: tab.title,
        disabled: tab.disabled,
        type: tab.type,
        componentName: tab.componentName,
      };
    });

    this.rows?.forEach((tab) => {
      this.tabs[0].table.push({
        name: tab.name,
        selected: tab.selected,
        title: tab.title,
        disabled: tab.disabled ? tab.disabled : false,
        sortable: tab?.sortable,
        isTableRow: tab?.isTableRow,
      });
    });

    // this.tabs = HelperService.cloneDeep(this.tabs);

    if (this.applyModeChange) {
      this.initialTabs = _.cloneDeep(this.tabs);
      this.initialConfigurationGroups = _.cloneDeep(this.configurationGroups);

      this.apply(false);
      this.applyModeChange = false;
    }
    if (pageHeaderKey === 'production_review' || pageHeaderKey === 'work_orders') {
      this.tabs[0][this.sectionTypes.Table] = PageConfigurationComponent.sortTableColumns(
        this.tabs[0][this.sectionTypes.Table],
        this.isSortable,
        true,
      );
    }

    this.selectedWidgetCount = _.filter(this.tabs[0].widget, 'selected').length;
    this.initialSelectedWidgetCount = this.selectedWidgetCount;
  }

  private arrangeHeaders(
    defaultHeaders: any[],
    userConfigurationData: any,
    component: string,
    checkedItemIndex: number,
  ): number {
    const componentDataFromUserConfig = _.get(userConfigurationData, component);
    let result = checkedItemIndex;

    for (const tab of defaultHeaders) {
      if (componentDataFromUserConfig) {
        const selectedColumns = componentDataFromUserConfig.find((item) => item.name === 'selectedColumns')?.value;

        if (selectedColumns) {
          result = selectedColumns.findIndex((item: string) => item === tab.value);
        } else {
          result = -1;
        }
      }

      if (result !== -1 && !tab?.isTableRow) {
        this.tabs[0].table[result] = {
          name: tab.value,
          selected: true,
          title: tab.name,
          disabled: tab.disabled,
          sortable: tab?.sortable,
          isTableRow: tab?.isTableRow,
        };
        continue;
      }

      if (result !== -1 && tab?.isTableRow) {
        this.rows.push({
          name: tab.value,
          selected: true,
          title: tab.name,
          disabled: tab.disabled,
          sortable: tab?.sortable,
          isTableRow: tab?.isTableRow,
        });
        continue;
      }

      if (checkedItemIndex === -1 && tab.value !== null) {
        this.tabsToBeAtTheEnd.push({
          name: tab.value,
          selected: _.isNil(componentDataFromUserConfig) ? tab?.selected ?? false : false,
          title: tab.name,
          disabled: tab.disabled,
          sortable: tab?.sortable,
          isTableRow: tab?.isTableRow,
        });
      }
    }

    return result;
  }

  public apply(setAsDefault: boolean): void {
    this.applyModeChange = true;
    this.changesMade = false;
    this.tabsToSubmit = _.cloneDeep([...this.tabs[0].table, ...this.tabs[0].widget, ...this.tabs[0].chart]);
    this.configModalRef?.close();
    this.initialTabs = _.cloneDeep(this.tabs);
    this.initialConfigurationGroups = _.cloneDeep(this.configurationGroups);
    this.initialMode = this.mode;
    this.initialSelectedWidgetCount = _.cloneDeep(this.selectedWidgetCount);

    if (this.saveChangedModalRef) {
      this.saveChangedModalRef.close();
    }

    if (this.addDashboardModeSwitch) {
      this.store.dispatch(new PageHeaderActions.UpdatePageMode(this.mode));
      this.store.dispatch(new PageHeaderActions.UpdateFilterBarVisibility(!this.mode));
    }

    switch (this.pageHeader.titleKey) {
      case 'work_orders':
        const selectedDateType: string = _.find(
          _.get(this.tabs[0].custom, '0.radioGroup', []) as ScwMatRadioGroupOption[],
          {
            checked: true,
          },
        )?.value;
        const recalculateMode: boolean = _.get(this.tabs[0].custom, '0.rows.0.selected');
        this.store.dispatch(
          new WorkOrderScheduleActions.SetTableSettings(
            ...HelperService.argumentClone(this.tabsToSubmit, setAsDefault, {
              selectedDateType,
              recalculateMode,
            }),
          ),
        );
        break;
      case 'products':
        this.store.dispatch(
          new ProductsActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'tasks':
        this.store.dispatch(
          new TaskActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'users':
        const customSection: ICustomSectionConfiguration[] = this.tabs[0].custom;
        this.store.dispatch(
          new UserActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault, customSection)),
        );
        break;
      case 'scheduler_scenario':
        this.store.dispatch(
          new SchedulerScenarioActions.SetTableSettings(
            ...HelperService.argumentClone(this.tabsToSubmit, setAsDefault),
          ),
        );
        break;
      case 'activity_logs':
        this.store.dispatch(
          new ActivityLogsActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'production_review':
        this.store.dispatch(
          new ProductionReviewActions.SetTableSettings(
            ...HelperService.argumentClone(this.tabsToSubmit, setAsDefault, this.mode),
          ),
        );
        break;
      case 'kpi_view':
        this.store.dispatch(
          new KpiViewActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'schedule_adherence_report':
        this.isWidgetLimitEnabled = true;
        this.widgetLimit = 6;

        const checkedModeType: string = _.find(
          _.get(this.tabs[0].custom, '0.radioGroup', []) as ScwMatRadioGroupOption[],
          {
            checked: true,
          },
        )?.value;

        this.store.dispatch(
          new ScheduleAdherenceActions.SetTableSettings(
            ...HelperService.argumentClone(this.tabsToSubmit, setAsDefault, [
              {
                name: this.tabs[0].custom[0].name,
                value: [checkedModeType],
              },
            ]),
          ),
        );
        break;
      case 'labor_logs_report':
        this.store.dispatch(
          new CheckInLogActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'asset_logs_report':
        this.store.dispatch(
          new CheckInLogActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'shift_summary':
        let columns: ITableHeader[] = [];

        this.configurationGroups
          .slice(2)
          .forEach((configurationGroup: IPageConfigurationGroup<EShiftSummaryGroups>) => {
            columns = columns.concat(configurationGroup.values);
          });

        this.store.dispatch(
          new ShiftSummaryActions.SetTableSettings(
            ...HelperService.argumentClone(
              [...this.configurationGroups[0].values],
              columns,
              _.get(this.tabs[0].custom, '0.rows.0.selected', false),
              setAsDefault,
            ),
          ),
        );
        break;
      case 'line_view':
        this.store.dispatch(new LineViewWorkOrderSummaryActions.SetTableSettings(this.tabsToSubmit, setAsDefault));
        break;
      case 'asset_view':
        this.store.dispatch(
          new AssetViewActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'alerts':
        this.store.dispatch(
          new AlertsActions.SetTableSettings(...HelperService.argumentClone(this.tabsToSubmit, setAsDefault)),
        );
        break;
      case 'maintenance_performance':
        this.store.dispatch(new MaintenancePerformanceActions.SetTableSettings(this.tabsToSubmit, setAsDefault));
        break;
      case 'labor_asset_view':
        this.store.dispatch(new LaborAssetViewActions.SetTableSettings(this.tabsToSubmit, setAsDefault));
        break;
      case 'comment_logs':
        this.store.dispatch(new CommentLogsActions.SetTableSettings(this.tabsToSubmit, setAsDefault));
        break;
      case 'iot_device_report':
        this.store.dispatch(new IotDeviceReportActions.SetTableSettings(this.tabsToSubmit, setAsDefault));
        break;
    }

    if (this.addDashboardModeSwitch) {
      this.store.dispatch(new PageHeaderActions.FetchPageData());
    }
  }

  public dontApply(): void {
    this.changesMade = false;
    this.selectedWidgetCount = this.initialSelectedWidgetCount;

    if (this.initialMode !== this.mode) {
      this.mode = this.initialMode;
    }

    this.saveChangedModalRef.close();
    this.onCloseClick();

    this.tabs = _.cloneDeep(this.initialTabs);
    if (this.pageHeader?.titleKey === 'production_review') {
      this.tabs[0][this.sectionTypes.Table] = PageConfigurationComponent.sortTableColumns(
        this.tabs[0][this.sectionTypes.Table],
        this.isSortable,
        true,
      );
    }
    this.configurationGroups = _.cloneDeep(this.initialConfigurationGroups);
  }

  public changeCheckBox(
    event: MatCheckboxChange,
    sectionType: SectionTypes,
    arrayIndexOfCustom: number = 0,
    isProductionViewPage: boolean = false,
  ): void {
    this.selectedWidgetCount = _.filter(this.tabs[0].widget, 'selected').length;
    this.changesMade = true;

    if (sectionType === SectionTypes.Custom) {
      PageConfigurationComponent.checkLinkedCheckbox(
        event.source.id.substring(22),
        this.tabs[0].custom[arrayIndexOfCustom].rows,
      );

      if (this.tabs[0].custom[arrayIndexOfCustom].hasRadioButtonBehaviour) {
        this.tabs[0].custom[arrayIndexOfCustom].rows = this.tabs[0].custom[arrayIndexOfCustom].rows.map((row) => {
          return { ...row, selected: row.name === event.source.id.substring(22) };
        });
      }

      if (!this.tabs[0].custom[arrayIndexOfCustom].disableDefaultSort) {
        this.tabs[0].custom[arrayIndexOfCustom].rows = PageConfigurationComponent.sortTableColumns(
          this.tabs[0].custom[arrayIndexOfCustom].rows,
          this.isSortable,
        );
      }

      return;
    }

    PageConfigurationComponent.checkLinkedCheckbox(event.source.id.substring(22), this.tabs[0][sectionType]);
    PageConfigurationComponent.uncheckOpposingCheckboxes(event.source.id.substring(22), this.tabs[0][sectionType]);
    if (isProductionViewPage) {
      this.tabs[0][sectionType] = PageConfigurationComponent.sortTableColumns(
        this.tabs[0][sectionType],
        this.isSortable,
        true,
      );
      return;
    }
    this.tabs[0][sectionType] = PageConfigurationComponent.sortTableColumns(
      this.tabs[0][sectionType],
      this.isSortable,
      this.pageHeader.titleKey === 'work_orders',
    );
  }

  public changeCheckBoxWithGroupLogic(event: MatCheckboxChange): void {
    this.changesMade = true;

    const targetHeaderName: string = event.source.id.substring(22);
    const targetHeaderParentName: string | undefined = _.find(this.shiftSummaryService.shiftSummaryTableDefaults, {
      value: targetHeaderName,
    })?.parentId;
    const configurationGroup: IPageConfigurationGroup<EShiftSummaryGroups> = _.find(this.configurationGroups, {
      id: targetHeaderParentName,
    });
    const targetHeader: ITableHeader = _.find(configurationGroup.values, {
      value: targetHeaderName,
    });
    const isHeaderGroup: boolean = targetHeader.type === PageConfigurationTypes.HEADER_GROUP;
    const childConfigurationGroup: IPageConfigurationGroup<EShiftSummaryGroups> | null = isHeaderGroup
      ? _.find(this.configurationGroups, {
          id: targetHeaderName,
        })
      : null;

    if (isHeaderGroup) {
      configurationGroup.values = PageConfigurationComponent.sortTableColumns(configurationGroup.values, true, true);

      if (targetHeaderName === 'taskGroups') {
        return;
      }

      childConfigurationGroup.isVisible = event.checked;

      childConfigurationGroup.values.forEach((header: ITableHeader) => (header.selected = event.checked));
    }
  }

  public changeChartCheckBox(event: MatCheckboxChange): void {
    PageConfigurationComponent.checkLinkedCheckbox(event.source.id.substring(22), this.tabs[0].chart);
    this.tabs[0].chart = PageConfigurationComponent.sortTableColumns(this.tabs[0].chart, this.isSortable);
  }

  private static sortTableColumns(columnArray, isSortable: boolean, sortOnlySortables: boolean = false): any[] {
    if (!isSortable) {
      return columnArray;
    }

    const tableRows = columnArray.filter((c: any) => c.isTableRow);
    const tableColumns = columnArray
      .filter((c: any) => (!sortOnlySortables && !c.isTableRow) || (sortOnlySortables && !c.isTableRow && c.sortable))
      .sort((a, b) => (a.selected === b.selected ? b.sortable - a.sortable : a.selected ? -1 : 1));
    const nonSortableColumns: ITableHeader[] = sortOnlySortables
      ? columnArray.filter((header: ITableHeader) => !header.sortable)
      : [];

    return [...tableColumns, ...nonSortableColumns, ...tableRows];
  }

  private static getCopyOfFilterArray(filterArray): WidgetDataInterface[] {
    return filterArray.map((filterConfiguration) => {
      if ('children' in filterConfiguration && filterConfiguration.children.length > 0) {
        return {
          ...filterConfiguration,
          children: filterConfiguration.children.map((child) => ({ ...child })),
        };
      }

      return { ...filterConfiguration };
    });
  }

  public onCloseClick(): void {
    if (this.changesMade) {
      this.saveChangedModalRef = this.ngbModal.open(this.unsavedChangesModalTemplateRef, {
        keyboard: false,
        backdrop: 'static',
        windowClass: this.scwModalSm,
      });
      this.modalArray.push(this.saveChangedModalRef);
    } else {
      this.configModalRef.close();
    }
  }

  public cdkDropListEnterPredicateColumn($event: any): boolean {
    return !Boolean($event?.isTableRow);
  }

  private removeMissingColumnsFromUserConfiguration(
    defaultConfigurations: (ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>)[],
    userConfigurationData: IUserConfiguration,
    page: ComponentNamesForUserConfiguration,
    selectedColumnIndex: number,
    isViewPage: boolean,
    configurationType: PageConfigurationTypes,
  ): IUserConfiguration {
    if (!userConfigurationData[page] || selectedColumnIndex === -1) {
      return userConfigurationData;
    }

    if (!isViewPage) {
      userConfigurationData[page][selectedColumnIndex].value = this.configurationIncorrectValueFilter(
        defaultConfigurations,
        userConfigurationData,
        page,
        selectedColumnIndex,
        configurationType,
      );

      return userConfigurationData;
    }

    userConfigurationData[page][selectedColumnIndex].value = this.configurationIncorrectValueFilterForIsViewPage(
      defaultConfigurations,
      userConfigurationData,
      page,
      selectedColumnIndex,
    );

    return userConfigurationData;
  }

  private iterateOverDefault(
    headerDefaults: ITableHeader[],
    widgetDefaults: WidgetConfigurationInterface<EWidgetType.KPI_CARD | EWidgetType.WIDGET>[],
    userConfigurationData: IUserConfiguration,
    page: ComponentNamesForUserConfiguration,
    chartDefaults: any[] = [],
    isViewPage: boolean = false,
    customDefaults: ICustomSectionConfiguration[] = [],
  ): void {
    this.resetParameters();
    let checkedItemIndex: number = -1;
    let selectedColumnIndex: number = -1;

    if (userConfigurationData[page] && !isViewPage) {
      selectedColumnIndex = userConfigurationData[page].findIndex(
        (item: IComponentConfiguration) => item.name === 'selectedColumns',
      );
    } else if (userConfigurationData[page] && isViewPage) {
      selectedColumnIndex = this.tableDataIndex;
    }

    const userConfigurationDataWithOutMissingColumns: IUserConfiguration =
      this.removeMissingColumnsFromUserConfiguration(
        headerDefaults,
        _.cloneDeep(userConfigurationData),
        page,
        selectedColumnIndex,
        isViewPage,
        PageConfigurationTypes.TABLE,
      );

    headerDefaults.forEach((tab: ITableHeader, index: number) => {
      if (tab.isHiddenOnDashboardMode && this.mode) {
        return;
      }

      if (userConfigurationDataWithOutMissingColumns[page] && selectedColumnIndex !== -1 && !isViewPage) {
        checkedItemIndex = (
          userConfigurationDataWithOutMissingColumns[page][selectedColumnIndex].value as TabRowInterface[]
        ).findIndex((item: TabRowInterface) => item.name === tab.value);
      } else if (userConfigurationDataWithOutMissingColumns[page] && selectedColumnIndex !== -1 && isViewPage) {
        checkedItemIndex = (
          userConfigurationDataWithOutMissingColumns[page][selectedColumnIndex].value as string[]
        ).findIndex((item: string) => item === tab.value);
      }
      const sortableFlag: boolean = page.includes('WorkOrders') ? !(tab.sortable === false) : tab?.sortable;

      if (checkedItemIndex !== -1 && this.isSortable) {
        this.tabs[0].table[checkedItemIndex] = {
          name: tab.value,
          selected: true,
          title: tab.name,
          disabled: tab.disabled,
          linkedTo: tab.linkedTo ? tab.linkedTo : null,
          opposedTo: tab.opposedTo || null,
          type: PageConfigurationTypes.TABLE,
          sortable: sortableFlag,
        };
      } else if (
        checkedItemIndex === -1 &&
        tab.value !== null &&
        tab.type === PageConfigurationTypes.TABLE &&
        this.isSortable
      ) {
        this.tabsToBeAtTheEnd.push({
          name: tab.value,
          selected: selectedColumnIndex === -1 ? headerDefaults[index].selected : false,
          title: tab.name,
          disabled: tab.disabled,
          linkedTo: tab.linkedTo ? tab.linkedTo : null,
          opposedTo: tab.opposedTo || null,
          type: tab.type,
          sortable: sortableFlag,
        });
      } else if (!this.isSortable && checkedItemIndex === -1) {
        this.tabs[0].table[index] = {
          name: tab.value,
          selected: selectedColumnIndex === -1 ? headerDefaults[index].selected : false,
          title: tab.name,
          disabled: tab.disabled,
          linkedTo: tab.linkedTo ? tab.linkedTo : null,
          opposedTo: tab.opposedTo || null,
          type: PageConfigurationTypes.TABLE,
        };
      } else if (!this.isSortable && checkedItemIndex !== -1) {
        this.tabs[0].table[index] = {
          name: tab.value,
          selected: true,
          title: tab.name,
          disabled: tab.disabled,
          linkedTo: tab.linkedTo ? tab.linkedTo : null,
          opposedTo: tab.opposedTo || null,
          type: PageConfigurationTypes.TABLE,
        };
      }
    });

    if (userConfigurationData[page] && !isViewPage) {
      selectedColumnIndex = userConfigurationData[page].findIndex(
        (item: IComponentConfiguration) => item.name === 'selectedColumns',
      );
    } else if (userConfigurationData[page] && isViewPage) {
      selectedColumnIndex = this.widgetDataIndex;
    }

    const userConfigurationDataWithOutMissingWidgets: IUserConfiguration =
      this.removeMissingColumnsFromUserConfiguration(
        widgetDefaults,
        _.cloneDeep(userConfigurationData),
        page,
        selectedColumnIndex,
        isViewPage,
        PageConfigurationTypes.WIDGET,
      );

    const widgetNames = widgetDefaults.map((widget: WidgetConfigurationInterface<EWidgetType.WIDGET>) => widget.name);
    let onlyWidgets: IComponentConfiguration[] = [];
    let viewPageWidgets: string[] = [];

    if (userConfigurationDataWithOutMissingWidgets[page] && selectedColumnIndex !== -1 && !isViewPage) {
      onlyWidgets = (
        userConfigurationDataWithOutMissingWidgets[page][selectedColumnIndex].value as TabRowInterface[]
      ).filter((defaultRow: IComponentConfiguration) => widgetNames.includes(defaultRow.name));
    } else if (userConfigurationDataWithOutMissingWidgets[page] && selectedColumnIndex !== -1 && isViewPage) {
      onlyWidgets = userConfigurationDataWithOutMissingWidgets[page][selectedColumnIndex].value as TabRowInterface[];
      viewPageWidgets = onlyWidgets.map((widget: IComponentConfiguration) =>
        typeof widget === 'string' ? widget : (widget.value as string),
      );
    }

    widgetDefaults.forEach((tab: WidgetConfigurationInterface<EWidgetType.WIDGET>, index: number) => {
      if (userConfigurationDataWithOutMissingWidgets[page] && !isViewPage) {
        checkedItemIndex = onlyWidgets.findIndex((item: IComponentConfiguration) => item.name === tab.name);
      } else if (userConfigurationDataWithOutMissingWidgets[page] && isViewPage) {
        checkedItemIndex = viewPageWidgets.findIndex((item: string) => item === tab.name);
      }

      if (checkedItemIndex !== -1 && this.isSortable) {
        this.tabs[0].widget[checkedItemIndex] = {
          type: PageConfigurationTypes.WIDGET,
          name: tab.name,
          selected: true,
          disabled: tab.disabled,
          content: tab.content,
        };
      } else if (
        checkedItemIndex === -1 &&
        tab.name !== null &&
        tab.type === PageConfigurationTypes.WIDGET &&
        this.isSortable
      ) {
        this.widgetsToBeAtTheEnd.push({
          type: PageConfigurationTypes.WIDGET,
          name: tab.name,
          selected: selectedColumnIndex === -1 ? widgetDefaults[index].selected : false,
          disabled: tab.disabled,
          content: tab.content,
        });
      } else if (!this.isSortable && checkedItemIndex === -1) {
        this.tabs[0].widget[index] = {
          type: PageConfigurationTypes.WIDGET,
          name: tab.name,
          selected: selectedColumnIndex === -1 ? widgetDefaults[index].selected : false,
          disabled: tab.disabled,
          content: tab.content,
        };
      } else if (!this.isSortable && checkedItemIndex !== -1) {
        this.tabs[0].widget[index] = {
          type: PageConfigurationTypes.WIDGET,
          name: tab.name,
          selected: true,
          disabled: tab.disabled,
          content: tab.content,
        };
      }
    });

    const chartNames = chartDefaults.map((chart) => chart.name);
    let onlyCharts: IComponentConfiguration[] = [];

    if (userConfigurationData[page] && selectedColumnIndex !== -1) {
      onlyCharts = (userConfigurationData[page][selectedColumnIndex].value as TabRowInterface[]).filter(
        (defaultRow: IComponentConfiguration) => chartNames.includes(defaultRow.name),
      );
    }

    chartDefaults.forEach((tab, index) => {
      if (userConfigurationData[page]) {
        checkedItemIndex = onlyCharts.findIndex((item: IComponentConfiguration) => item.name === tab.name);
      }
      if (checkedItemIndex !== -1 && this.isSortable) {
        this.tabs[0].chart[checkedItemIndex] = {
          name: tab.name,
          selected: true,
          title: tab.title,
          disabled: tab.disabled,
          type: PageConfigurationTypes.CHART,
        };
      } else if (
        checkedItemIndex === -1 &&
        tab.name !== null &&
        tab.type === PageConfigurationTypes.CHART &&
        this.isSortable
      ) {
        this.chartsToBeAtTheEnd.push({
          name: tab.name,
          selected: selectedColumnIndex === -1 ? chartDefaults[index].selected : false,
          title: tab.title,
          disabled: tab.disabled,
          type: tab.type,
          componentName: tab.componentName,
        });
      } else if (!this.isSortable && checkedItemIndex === -1) {
        this.tabs[0].chart[index] = {
          name: tab.name,
          selected: selectedColumnIndex === -1 ? chartDefaults[index].selected : false,
          title: tab.title,
          disabled: tab.disabled,
          type: PageConfigurationTypes.CHART,
        };
      } else if (!this.isSortable && checkedItemIndex !== -1) {
        this.tabs[0].chart[index] = {
          name: tab.name,
          selected: true,
          title: tab.title,
          disabled: tab.disabled,
          type: PageConfigurationTypes.CHART,
        };
      }
    });

    this.tabs[0].custom = this.getUserConfigurationAppliedCustom(customDefaults, page, userConfigurationData);
  }

  private getUserConfigurationAppliedCustom(
    customDefaults: ICustomSectionConfiguration[],
    page: ComponentNamesForUserConfiguration,
    userConfigurationData: IUserConfiguration,
  ): ICustomSectionConfiguration[] {
    return customDefaults.map((customDefault: ICustomSectionConfiguration) => {
      if (customDefault.name === 'recalculateWorkOrders') {
        customDefault.show = true;
      }

      if (!(page in userConfigurationData)) {
        return customDefault;
      }

      const configuration: IComponentConfiguration = _.find(userConfigurationData[page], {
        name: customDefault.name,
      });

      if (!configuration) {
        return customDefault;
      }

      const formattedConfiguration: ICustomSectionConfiguration = {
        ...customDefault,
        rows: customDefault.rows.map((row: ICustomSectionRow): ICustomSectionRow => {
          return {
            ..._.find(customDefault.rows, { name: row.name }),
            selected: (configuration.value as string[]).includes(row.name),
          };
        }),
        radioGroup: customDefault.radioGroup?.map((radio: ScwMatRadioGroupOption): ScwMatRadioGroupOption => {
          return {
            ...radio,
            checked: (configuration.value as string[]).includes(radio.value),
          };
        }),
        defaultRadioButtonValue:
          _.find(customDefault.radioGroup, { value: configuration.value[0] })?.value ??
          customDefault.defaultRadioButtonValue,
      };

      return customDefault.disableDefaultSort
        ? formattedConfiguration
        : { ...formattedConfiguration, rows: _.sortBy(formattedConfiguration.rows, 'selected') };
    });
  }

  private static checkLinkedCheckbox(clickedItemName: string, data: PageConfigurationDataType[]): void {
    const clickedItem = _.find(data, { name: clickedItemName });

    if (clickedItem?.linkedTo) {
      const checkbox = _.head(
        document.getElementById(`table-config-checkbox-${clickedItem.linkedTo}`)?.getElementsByTagName('input'),
      );
      checkbox?.click();
    }
  }

  private static uncheckOpposingCheckboxes(clickedItemName: string, data: PageConfigurationDataType[]): void {
    const clickedItem: PageConfigurationDataType = _.find(data, { name: clickedItemName });

    if (!clickedItem?.selected || !('opposedTo' in clickedItem)) {
      return;
    }

    for (const opposingItemName of clickedItem.opposedTo ?? []) {
      const opposingItem = _.find(data, { name: opposingItemName });

      if (!opposingItem?.selected) {
        continue;
      }

      const checkbox = _.head(
        document.getElementById(`table-config-checkbox-${opposingItemName}`)?.getElementsByTagName('input'),
      );
      checkbox?.click();
    }
  }

  public pageModeChange(isDashboardMode: boolean): void {
    this.changesMade = true;
    this.applyModeChange = false;
    this.mode = isDashboardMode;

    this.getTabs(this.pageHeader.titleKey, this.userConfigurationData, true);
  }

  public resetParameters(): void {
    this.tabsToBeAtTheEnd = [];
    this.widgetsToBeAtTheEnd = [];
    this.chartsToBeAtTheEnd = [];
    this.tabs[0].table = [];
    this.tabs[0].widget = [];
    this.tabs[0].chart = [];
    this.rows = [];
  }

  private prepareConfigurationGroups(
    groups: IPageConfigurationGroup<EShiftSummaryGroups>[],
    defaultColumns: ITableHeader[],
    page: ComponentNamesForUserConfiguration,
  ): void {
    const userConfiguration: IComponentConfiguration[] | undefined = this.userConfigurationData[page];

    if (!userConfiguration) {
      const defaultHiddenColumnGroup: string = EShiftSummaryGroups.SHIFT_OVERVIEW;

      defaultColumns.forEach((column: ITableHeader) => {
        const group: IPageConfigurationGroup<EShiftSummaryGroups> | undefined = _.find(groups, { id: column.parentId });

        if (page === ComponentNamesForUserConfiguration.ShiftSummary) {
          this.shiftSummaryService.setPageConfigurationTranslations(column);
        }

        group.values.push(column);
      });

      this.configurationGroups = groups.map((group: IPageConfigurationGroup<EShiftSummaryGroups>) => {
        return { ...group, isVisible: group.id !== defaultHiddenColumnGroup };
      });

      return;
    }

    if (page === ComponentNamesForUserConfiguration.ShiftSummary) {
      this.configurationGroups = this.shiftSummaryService.prepareConfigurationGroups(userConfiguration);
    }
  }

  private configurationIncorrectValueFilter(
    defaultConfigurations: (ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>)[],
    userConfigurationData: IUserConfiguration,
    page: ComponentNamesForUserConfiguration,
    selectedColumnIndex: number,
    configurationType: PageConfigurationTypes,
  ): TabRowInterface[] {
    return (userConfigurationData[page][selectedColumnIndex].value as TabRowInterface[]).filter(
      (configuration: TabRowInterface) => {
        if (
          defaultConfigurations.find(
            (defaultConfiguration: ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>) => {
              return (
                PageConfigurationComponent.getDefaultConfigurationValue(defaultConfiguration) === configuration.name &&
                configuration.type === configurationType
              );
            },
          )
        ) {
          return configuration;
        }

        return false;
      },
    );
  }

  private configurationIncorrectValueFilterForIsViewPage(
    defaultConfigurations: (ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>)[],
    userConfigurationData: IUserConfiguration,
    page: ComponentNamesForUserConfiguration,
    selectedColumnIndex: number,
  ): string[] {
    return (userConfigurationData[page][selectedColumnIndex].value as string[]).filter((configuration: string) => {
      if (
        defaultConfigurations.find(
          (defaultConfiguration: ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>) => {
            return PageConfigurationComponent.getDefaultConfigurationValue(defaultConfiguration) === configuration;
          },
        )
      ) {
        return configuration;
      }

      return false;
    });
  }

  private validateUserForRecalculation(state: User): void {
    this.isVerifiedByAdmin = state.isVerifiedByAdmin;
    state.email.includes(this.scwDomain) || state.email.includes(this.scwAIDomain)
      ? (this.isValidDomain = true)
      : (this.isValidDomain = false);
    this.isAdmin = Number(state.level.parentLevelId ?? state.userLevelId) === UserLevels.ADMIN;
  }

  private static getDefaultConfigurationValue(
    defaultConfiguration: ITableHeader | WidgetConfigurationInterface<EWidgetType.WIDGET>,
  ): string {
    return defaultConfiguration['value'] ?? defaultConfiguration.name;
  }

  public ngOnDestroy(): void {
    this.modalArray.forEach((modal: NgbModalRef) => {
      modal.close();
    });
    this.storeSubscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

  public setSelectedRadio(event, tabIndex): void {
    this.tabs[0].custom[tabIndex].radioGroup = this.tabs[0].custom[tabIndex].radioGroup.map((row) => {
      return { ...row, checked: row.value === event };
    });
  }
}
