import * as StationStateMappingActions from './station-state-mapping.actions';
import { StationStateMappingState } from './station-state-mapping.model';

export const stationStateMappingState: StationStateMappingState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getStationStateMappingLoading: false,
  getStationStateMappingLoaded: false,
  stationStateMapping: null,
  addStationStateMappingLoading: false,
  addStationStateMappingLoaded: false,
  editStationStateMappingLoading: false,
  editStationStateMappingLoaded: false,
  deleteStationStateMappingLoading: false,
  deleteStationStateMappingLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  stationsDataLoading: false,
  stationsDataLoaded: false,
  stationsData: [],
  activitiesDataLoading: false,
  activitiesDataLoaded: false,
  activitiesData: [],
  tasksDataLoading: false,
  tasksDataLoaded: false,
  tasksData: [],
  lineTasksDataLoading: false,
  lineTasksDataLoaded: false,
  lineTasksData: [],
  extendedTasksDataLoading: false,
  extendedTasksDataLoaded: false,
  extendedTasksData: [],
};

export function stationStateMappingReducer(
  state: StationStateMappingState = stationStateMappingState,
  action: StationStateMappingActions.StationStateMappingActions,
): StationStateMappingState {
  switch (action.type) {
    case StationStateMappingActions.ActionTypes.STATION_STATE_MAPPING_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.STATION_STATE_MAPPING_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.GET_STATION_STATE_MAPPING:
      return {
        ...state,
        ...{
          getStationStateMappingLoading: true,
          getStationStateMappingLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.GET_STATION_STATE_MAPPING_COMPLETED:
      return {
        ...state,
        ...{
          stationStateMapping: action.payload.data,
          getStationStateMappingLoading: false,
          getStationStateMappingLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.ADD_STATION_STATE_MAPPING:
      return {
        ...state,
        addStationStateMappingLoading: true,
        addStationStateMappingLoaded: false,
      };
    case StationStateMappingActions.ActionTypes.ADD_STATION_STATE_MAPPING_COMPLETED:
      return {
        ...state,
        addStationStateMappingLoading: false,
        addStationStateMappingLoaded: true,
      };
    case StationStateMappingActions.ActionTypes.EDIT_STATION_STATE_MAPPING:
      return {
        ...state,
        editStationStateMappingLoading: true,
        editStationStateMappingLoaded: false,
      };
    case StationStateMappingActions.ActionTypes.EDIT_STATION_STATE_MAPPING_COMPLETED:
      return {
        ...state,
        editStationStateMappingLoading: false,
        editStationStateMappingLoaded: true,
      };

    case StationStateMappingActions.ActionTypes.DELETE_STATION_STATE_MAPPING:
      return {
        ...state,
        deleteStationStateMappingLoading: true,
        deleteStationStateMappingLoaded: false,
      };
    case StationStateMappingActions.ActionTypes.DELETE_STATION_STATE_MAPPING_COMPLETED:
      return {
        ...state,
        deleteStationStateMappingLoading: false,
        deleteStationStateMappingLoaded: true,
      };
    case StationStateMappingActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.STATIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          stationsDataLoading: true,
          stationsDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.STATIONS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          stationsData: action.payload.data,
          stationsDataLoading: false,
          stationsDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.ACTIVITIES_DATA_LOADING:
      return {
        ...state,
        ...{
          activitiesDataLoading: true,
          activitiesDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.ACTIVITIES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          activitiesData: action.payload,
          activitiesDataLoading: false,
          activitiesDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          tasksDataLoading: true,
          tasksDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          tasksData: action.payload,
          tasksDataLoading: false,
          tasksDataLoaded: true,
        },
      };

    case StationStateMappingActions.ActionTypes.LINE_TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          lineTasksDataLoading: true,
          lineTasksDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.LINE_TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineTasksData: action.payload,
          lineTasksDataLoading: false,
          lineTasksDataLoaded: true,
        },
      };

    case StationStateMappingActions.ActionTypes.EXTENDED_TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          extendedTasksDataLoading: true,
          extendedTasksDataLoaded: false,
        },
      };
    case StationStateMappingActions.ActionTypes.EXTENDED_TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          extendedTasksData: action.payload,
          extendedTasksDataLoading: false,
          extendedTasksDataLoaded: true,
        },
      };
    case StationStateMappingActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
