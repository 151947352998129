import { Action } from '@ngrx/store';
import { ICustomDashboardSavePayload } from '../../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/custom-dashboard-widget.interface';
import { ICustomDashboardContentsAndColor } from './custom-dashboard-content.model';

export enum CustomDashboardContentActionTypes {
  FetchCustomDashboardContents = '[CustomDashboardContent] Fetch Custom Dashboard Contents',
  FetchCustomDashboardContentsCompleted = '[CustomDashboardContent] Fetch Custom Dashboard Contents Completed',
  SaveCustomDashboardContent = '[CustomDashboardContent] Save Custom Dashboard Content',
  SaveCustomDashboardContentCompleted = '[CustomDashboardContent] Save Custom Dashboard Content Completed',
  FetchError = '[CustomDashboardContent] Fetch Error',
}

export class FetchCustomDashboardContents implements Action {
  readonly type = CustomDashboardContentActionTypes.FetchCustomDashboardContents;

  constructor(public itemId: number) {}
}

export class FetchCustomDashboardContentsCompleted implements Action {
  readonly type = CustomDashboardContentActionTypes.FetchCustomDashboardContentsCompleted;

  constructor(public payload: ICustomDashboardContentsAndColor) {}
}

export class SaveCustomDashboardContent implements Action {
  readonly type = CustomDashboardContentActionTypes.SaveCustomDashboardContent;

  constructor(public payload: ICustomDashboardSavePayload) {}
}

export class SaveCustomDashboardContentCompleted implements Action {
  readonly type = CustomDashboardContentActionTypes.SaveCustomDashboardContentCompleted;
}

export class FetchError implements Action {
  readonly type = CustomDashboardContentActionTypes.FetchError;

  constructor(public actionType: CustomDashboardContentActionTypes) {}
}

export type TCustomDashboardContentActions =
  | FetchCustomDashboardContents
  | FetchCustomDashboardContentsCompleted
  | FetchError;
