<scw-mat-button
  type="standard"
  [block]="block"
  [disabled]="disabled"
  [className]="className"
  [size]="size"
  (onClick)="onClickEmitter($event)"
>
  <ng-content></ng-content>
</scw-mat-button>
