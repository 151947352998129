import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as AppActions from '../../../store/app/actions';
import { IConstantLookUp } from '../../../view/settings/users/users.model';
import { TimeZoneOptions } from '../../model/enum/timezone-list';
import { DateRanges } from '../../../../constants';
import * as _ from 'lodash';
import { User } from '../../../store/user/model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateHelperService {
  private timezone?: string;

  static userDatePref: { dateFormat: string; timeFormat: string; weekFormat: string };

  constructor(
    private readonly translate: TranslateService,
    private readonly toast: ToastrService,
    private readonly store: Store<OeeAppState>,
  ) {
    this.store.select('user').subscribe(this.userObserver);
  }

  public static getDateTimePref(timeFormat: string, dateFormat: string): void {
    const userDatePref = {
      timeFormat: 'h:mm a',
      dateFormat: 'ddd MM/DD',
      weekFormat: 'w.W MMM/YYYY',
    };

    if (timeFormat === 'MMM D, YYYY H:mm') {
      userDatePref.timeFormat = 'H:mm';
    }

    if (dateFormat === 'MM/DD/YYYY') {
      userDatePref.dateFormat = 'ddd DD/MM';
    }

    DateHelperService.userDatePref = userDatePref;
  }

  public checkIsValidDateOrFail(date: unknown, format: string): void {
    const momentDate = moment(date, format, true);

    if (!momentDate.isValid()) {
      this.toast.error(this.translate.instant('general.incorrectDateFormat'), this.translate.instant('general.error'), {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });

      this.store.dispatch(new AppActions.HideTopLoader());
      this.store.dispatch(new AppActions.HideLoader());

      throw new Error('Invalid Date Format');
    }
  }

  public formatDuration(duration: number, withSecond: boolean = false, suffix: boolean = false): string {
    let actualDuration = duration;
    let negative = '';

    if (actualDuration === null) {
      return '';
    }

    if (actualDuration < 0) {
      actualDuration = actualDuration * -1;
      negative = '-';
    }

    const hours: string = Math.floor(actualDuration / 3600)
      .toString()
      .padStart(2, '0');
    const minutes: string = Math.floor((actualDuration / 60) % 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (Math.floor(actualDuration) % 60).toString().padStart(2, '0');

    let shorteningHr = '';
    let shorteningMin = '';
    let shorteningSec = '';
    let separator = ':';

    if (suffix) {
      shorteningHr = this.translate.instant('general.shortHour');
      shorteningMin = this.translate.instant('general.shortMinute');
      shorteningSec = this.translate.instant('general.shortSecond');
      separator = ' ';
    }

    if (withSecond) {
      return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}${separator}${seconds}${shorteningSec}`;
    }

    return `${negative}${hours}${shorteningHr}${separator}${minutes}${shorteningMin}`;
  }

  public static formattedDurationToSeconds(formattedDuration: string): number {
    if (typeof formattedDuration !== 'string') {
      return 0;
    }

    const split: string[] = formattedDuration.split(':');

    if (!split || split.length !== 2 || isNaN(Number(split[0])) || isNaN(Number(split[1]))) {
      return 0;
    }

    return Number(split[0]) * 3600 + Number(split[1]) * 60;
  }

  public minutesToFormattedHm(duration: number): string {
    let formattedDuration: string = '0';
    let hours: number = Math.floor(duration / 3600);

    const shortDay: string = this.translate.instant('general.shortDay');
    const shortHour: string = this.translate.instant('general.shortHour');
    const shortMinute: string = this.translate.instant('general.shortMinute');
    const days: number = Math.floor(duration / 86400);
    const minutes: number = Math.floor((duration - hours * 3600) / 60);

    if (days > 0) {
      hours = hours - 24 * days;

      formattedDuration = `${days}${shortDay} ${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (hours > 0) {
      formattedDuration = `${hours}${shortHour} ${minutes}${shortMinute}`;
    } else if (minutes > 0) {
      formattedDuration = `${minutes}${shortMinute}`;
    }

    return formattedDuration;
  }

  public static removeYear(moment: moment.Moment, dateString: string): string {
    const regex: RegExp = new RegExp(`[^]${moment.format('Y')}`);
    return dateString.replace(regex, '');
  }

  public static getTimezones(): IConstantLookUp[] {
    return Object.entries(TimeZoneOptions).map(([key, value]) => ({ id: key, name: value })) as IConstantLookUp[];
  }

  public getDefaultRanges(range: DateRanges): DateRanges {
    const temporaryRanges: DateRanges = {};

    Object.entries(_.cloneDeep(range) as DateRanges).forEach(([index, [start, end]]) => {
      const key = this.translate.instant(`dateRangePicker.ranges.${index}`);
      temporaryRanges[key] = [start.startOf('day'), end];
    });

    return temporaryRanges;
  }

  public getDateRangesWithTimezone(dateRangeOptions: (keyof DateRanges)[]): DateRanges {
    const allRanges: DateRanges = {
      today: [moment().tz(this.timezone).startOf('day'), moment().tz(this.timezone)],
      todayWhole: [moment().tz(this.timezone).startOf('day'), moment().tz(this.timezone).endOf('day')],
      yesterday: [
        moment().tz(this.timezone).subtract(1, 'days').startOf('day'),
        moment().tz(this.timezone).subtract(1, 'days').endOf('day'),
      ],
      thisWeek: [moment().tz(this.timezone).startOf('week'), moment().tz(this.timezone).endOf('week')],
      lastWeek: [
        moment().tz(this.timezone).subtract(1, 'weeks').startOf('week'),
        moment().tz(this.timezone).subtract(1, 'weeks').endOf('week'),
      ],
      nextSevenDays: [moment().tz(this.timezone), moment().tz(this.timezone).add(7, 'day')],
      thisMonth: [moment().tz(this.timezone).startOf('month'), moment().tz(this.timezone).endOf('month')],
      lastMonth: [
        moment().tz(this.timezone).subtract(1, 'month').startOf('month'),
        moment().tz(this.timezone).subtract(1, 'month').endOf('month'),
      ],
      nextThirtyDays: [moment().tz(this.timezone), moment().tz(this.timezone).add(30, 'day')],
      lastThreeMonths: [
        moment().tz(this.timezone).startOf('month').subtract(3, 'month'),
        moment().tz(this.timezone).endOf('month').subtract(1, 'month'),
      ],
      thisYear: [moment().tz(this.timezone).startOf('year'), moment().tz(this.timezone).endOf('year')],
      lastYear: [
        moment().tz(this.timezone).subtract(1, 'year').startOf('year'),
        moment().tz(this.timezone).subtract(1, 'year').endOf('year'),
      ],
    };

    return Object.keys(allRanges)
      .filter((range: string) => dateRangeOptions.includes(range as keyof DateRanges))
      .reduce((obj, range) => {
        return {
          ...obj,
          [range]: allRanges[range],
        };
      }, {});
  }

  private userObserver(user: User): void {
    this.timezone = user.timezone;
  }
}
