import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ScwMatButtonSize } from '../scw-mat-button/scw-mat-button.model';

@Component({
  selector: 'scw-mat-small-button',
  templateUrl: './scw-mat-small-button.component.html',
  styleUrls: ['./scw-mat-small-button.component.scss'],
})
export class ScwMatSmallButtonComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() iconButton: boolean = false;
  @Input() size: ScwMatButtonSize = 'md';
  @Input() className: string[] | {
    [klass: string]: any;
  };
  @Output() onClick: EventEmitter<void | Event> = new EventEmitter<void | Event>();

  private readonly defaultSmallButtonClass: string = 'scw-mat-small-button';
  private readonly iconButtonClass: string = 'icon-button';

  constructor() {}

  public onClickEmitter($event): void {
    this.onClick.emit($event);
  }

  private addInitialClassNames(): void {
    if (Array.isArray(this.className)) {
      this.className.push(this.defaultSmallButtonClass);

      if (this.iconButton) {
        this.className.push(this.iconButtonClass);
      }
    } else {
      this.className = {
        ...this.className,
        ...{
          [this.defaultSmallButtonClass]: true,
          'icon-button': this.iconButton,
        },
      };
    }
  }

  public ngOnChanges(): void {
    this.addInitialClassNames();
  }

  public ngOnInit(): void {
    this.addInitialClassNames();
  }
}
