import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as FaultCodeMappingActions from './fault-code-mapping.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { FaultCodeMappingService } from './fault-code-mapping.service';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import {
  IFaultCodeMappingRow
} from "../../../../view/settings/opc-tag-automation-settings/fault-code-mapping/fault-code-mapping.model";

@Injectable()
export class FaultCodeMappingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: FaultCodeMappingService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.FAULT_CODE_MAPPING_DATA_LOADING),
      switchMap((payload: FaultCodeMappingActions.FaultCodeMappingLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: payload.params.siteIds } });
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: payload.params.lineIds } });
        }

        if (payload.params?.searchText) {
          andConditions.push({
            $or: [
              {
                faultId: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
              {
                machineCode: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
            ],
          });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        if (payload.params.orderBy && payload.params.orderDesc !== undefined) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new FaultCodeMappingActions.FaultCodeMappingLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new FaultCodeMappingActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.GET_FAULT_CODE_MAPPING),
      switchMap((objectData: FaultCodeMappingActions.GetFaultCodeMapping) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneWithDropdowns(objectData.recordId, new HttpParams()).pipe(
          switchMap(
            (response: {
              faultCodeMappingRecord: BaseOneResponseInterface<IFaultCodeMappingRow>;
              lines: GetManyResponseInterface<LineCRUDInterface>;
              sites: SiteCRUDInterface[];
              activities: ActivityCRUDInterface[];
              tasks: TaskFilterCRUDInterface[];
            }) => {
              return of(
                new FaultCodeMappingActions.SiteDataLoaded(response.sites),
                new FaultCodeMappingActions.LineDataCompleted(response.lines),
                new FaultCodeMappingActions.ActivitiesDataCompleted(response.activities),
                new FaultCodeMappingActions.TasksDataCompleted(response.tasks),
                new FaultCodeMappingActions.GetFaultCodeMappingCompleted(response.faultCodeMappingRecord),
                new AppActions.HideLoader(),
              );
            },
          ),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );

      }),
      catchError((error) => {
        return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.ADD_FAULT_CODE_MAPPING),
      switchMap((objectData: FaultCodeMappingActions.AddFaultCodeMapping) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.tagLogger).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new FaultCodeMappingActions.AddFaultCodeMappingCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.EDIT_FAULT_CODE_MAPPING),
      switchMap((objectData: FaultCodeMappingActions.EditFaultCodeMapping) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new FaultCodeMappingActions.EditFaultCodeMappingCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.DELETE_FAULT_CODE_MAPPING),
      switchMap((objectData: FaultCodeMappingActions.DeleteFaultCodeMapping) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new FaultCodeMappingActions.DeleteFaultCodeMappingCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: FaultCodeMappingActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            return of(new FaultCodeMappingActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: FaultCodeMappingActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new FaultCodeMappingActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.ACTIVITIES_DATA_LOADING),
      switchMap((objectData: FaultCodeMappingActions.ActivitiesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          active: 1,
          id: { $in: objectData.activityIds },
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.activityService.getData(httpParams)).pipe(
          switchMap((response: ActivityCRUDInterface[]) => {
            return of(new FaultCodeMappingActions.ActivitiesDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(FaultCodeMappingActions.ActionTypes.TASKS_DATA_LOADING),
      switchMap((objectData: FaultCodeMappingActions.TasksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          activityId: objectData.activityId,
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.tasksService.getData(httpParams)).pipe(
          switchMap((response: TaskFilterCRUDInterface[]) => {
            return of(new FaultCodeMappingActions.TasksDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new FaultCodeMappingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new FaultCodeMappingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
