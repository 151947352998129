import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ICustomDashboard, ICustomDashboardRequestPayload } from './custom-dashboards.model';

export enum CustomDashboardsActionTypes {
  FetchCustomDashboardsData = '[CustomDashboards] Fetch CustomDashboards Data',
  FetchCustomDashboardsDataCompleted = '[CustomDashboards] Fetch CustomDashboards Data Completed',
  CreateNewCustomDashboard = '[CustomDashboards] Create New Custom Dashboard',
  CreateNewCustomDashboardCompleted = '[CustomDashboards] Create New Custom Dashboard Completed',
  UpdateCustomDashboard = '[CustomDashboards] Update Custom Dashboard',
  UpdateCustomDashboardCompleted = '[CustomDashboards] Update Custom Dashboard Completed',
  DeleteCustomDashboard = '[CustomDashboards] Delete Custom Dashboard',
  DeleteCustomDashboardCompleted = '[CustomDashboards] Delete Custom Dashboard Completed',
  CloneCustomDashboard = '[CustomDashboards] Clone Custom Dashboard',
  CloneCustomDashboardCompleted = '[CustomDashboards] Clone Custom Dashboard Completed',
  BulkDeleteCustomDashboard = '[CustomDashboards] Bulk Delete Custom Dashboards',
  BulkDeleteCustomDashboardCompleted = '[CustomDashboards] Bulk Delete Custom Dashboards Completed',
  FetchError = '[CustomDashboards] Fetch Error',
}

export class FetchCustomDashboardsData implements Action {
  readonly type = CustomDashboardsActionTypes.FetchCustomDashboardsData;

  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class FetchCustomDashboardsDataCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.FetchCustomDashboardsDataCompleted;

  constructor(public payload: GetManyResponseInterface<ICustomDashboard>) {}
}

export class CreateNewCustomDashboard implements Action {
  readonly type = CustomDashboardsActionTypes.CreateNewCustomDashboard;

  constructor(public requestPayload: ICustomDashboardRequestPayload) {}
}

export class CreateNewCustomDashboardCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.CreateNewCustomDashboardCompleted;

  constructor(public payload: BaseOneResponseInterface<ICustomDashboard>) {}
}

export class UpdateCustomDashboard implements Action {
  readonly type = CustomDashboardsActionTypes.UpdateCustomDashboard;

  constructor(public id: number, public requestPayload: ICustomDashboardRequestPayload) {}
}

export class UpdateCustomDashboardCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.UpdateCustomDashboardCompleted;

  constructor(public payload: BaseOneResponseInterface<ICustomDashboard>) {}
}

export class DeleteCustomDashboard implements Action {
  readonly type = CustomDashboardsActionTypes.DeleteCustomDashboard;

  constructor(public id: number) {}
}

export class DeleteCustomDashboardCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.DeleteCustomDashboardCompleted;

  constructor(public response: GetManyResponseInterface<ICustomDashboard>) {}
}

export class BulkDeleteCustomDashboard implements Action {
  readonly type = CustomDashboardsActionTypes.BulkDeleteCustomDashboard;

  constructor(public ids: number[]) {}
}

export class BulkDeleteCustomDashboardCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.BulkDeleteCustomDashboardCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class CloneCustomDashboard implements Action {
  readonly type = CustomDashboardsActionTypes.CloneCustomDashboard;

  constructor(public id: number, public requestPayload: Omit<ICustomDashboardRequestPayload, 'id'>) {}
}

export class CloneCustomDashboardCompleted implements Action {
  readonly type = CustomDashboardsActionTypes.CloneCustomDashboardCompleted;

  constructor(public payload: BaseOneResponseInterface<ICustomDashboard>) {}
}

export class FetchError implements Action {
  readonly type = CustomDashboardsActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type TCustomDashboardsActions =
  | FetchCustomDashboardsData
  | FetchCustomDashboardsDataCompleted
  | CreateNewCustomDashboard
  | CreateNewCustomDashboardCompleted
  | UpdateCustomDashboard
  | UpdateCustomDashboardCompleted
  | DeleteCustomDashboard
  | DeleteCustomDashboardCompleted
  | BulkDeleteCustomDashboard
  | BulkDeleteCustomDashboardCompleted
  | CloneCustomDashboard
  | CloneCustomDashboardCompleted
  | FetchError;
