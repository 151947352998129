import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './work-order-setting.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';

export enum ActionTypes {
  WORK_ORDER_SETTING_DATA_LOADING = '[WORK_ORDER_SETTING] WORK ORDER SETTING DATA LOADING',
  WORK_ORDER_SETTING_DATA_LOADED = '[WORK_ORDER_SETTING] WORK ORDER SETTING DATA LOADED',
  GET_WORK_ORDER_SETTING = '[WORK_ORDER_SETTING] GET WORK ORDER SETTING LOADING',
  GET_WORK_ORDER_SETTING_COMPLETED = '[WORK_ORDER_SETTING] GET WORK ORDER SETTING COMPLETED',
  ADD_WORK_ORDER_SETTING = '[WORK_ORDER_SETTING] ADD WORK ORDER SETTING',
  ADD_WORK_ORDER_SETTING_COMPLETED = '[WORK_ORDER_SETTING] ADD WORK ORDER SETTING COMPLETED',
  EDIT_WORK_ORDER_SETTING = '[WORK_ORDER_SETTING] EDIT WORK ORDER SETTING',
  EDIT_WORK_ORDER_SETTING_COMPLETED = '[WORK_ORDER_SETTING] EDIT WORK ORDER SETTING COMPLETED',
  DELETE_WORK_ORDER_SETTING = '[WORK_ORDER_SETTING] DELETE WORK ORDER SETTING',
  DELETE_WORK_ORDER_SETTING_COMPLETED = '[WORK_ORDER_SETTING] DELETE WORK ORDER SETTING COMPLETED',
  SITES_DATA_LOADING = '[WORK_ORDER_SETTING] SITES DATA LOADING',
  SITES_DATA_LOADED = '[WORK_ORDER_SETTING] SITES DATA LOADED',
  LINES_DATA_LOADING = '[WORK_ORDER_SETTING] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[WORK_ORDER_SETTING] LINES DATA COMPLETED',
  ACTIVITIES_DATA_LOADING = '[WORK_ORDER_SETTING] ACTIVITIES DATA LOADED',
  ACTIVITIES_DATA_COMPLETED = '[WORK_ORDER_SETTING] ACTIVITIES DATA COMPLETED',
  TASKS_DATA_LOADING = '[WORK_ORDER_SETTING] TASKS DATA LOADED',
  TASKS_DATA_COMPLETED = '[WORK_ORDER_SETTING] TASKS DATA COMPLETED',
  FETCH_ERROR = '[WORK_ORDER_SETTING] FETCH ERROR',
}

export class WorkOrderSettingLoading implements Action {
  readonly type = ActionTypes.WORK_ORDER_SETTING_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class WorkOrderSettingLoaded implements Action {
  readonly type = ActionTypes.WORK_ORDER_SETTING_DATA_LOADED;

  constructor(public payload: any) {}
}

export class GetWorkOrderSetting implements Action {
  readonly type = ActionTypes.GET_WORK_ORDER_SETTING;
  constructor(public recordId: number) {}
}

export class GetWorkOrderSettingCompleted implements Action {
  readonly type = ActionTypes.GET_WORK_ORDER_SETTING_COMPLETED;
  constructor(public payload: any) {}
}

export class AddWorkOrderSetting implements Action {
  readonly type = ActionTypes.ADD_WORK_ORDER_SETTING;
  constructor(public workOrderSetting: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddWorkOrderSettingCompleted implements Action {
  readonly type = ActionTypes.ADD_WORK_ORDER_SETTING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditWorkOrderSetting implements Action {
  readonly type = ActionTypes.EDIT_WORK_ORDER_SETTING;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditWorkOrderSettingCompleted implements Action {
  readonly type = ActionTypes.EDIT_WORK_ORDER_SETTING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteWorkOrderSetting implements Action {
  readonly type = ActionTypes.DELETE_WORK_ORDER_SETTING;
  constructor(public payload: number[]) {}
}

export class DeleteWorkOrderSettingCompleted implements Action {
  readonly type = ActionTypes.DELETE_WORK_ORDER_SETTING_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class ActivitiesDataLoading implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_LOADING;
  constructor(public activityIds: number[]) {}
}

export class ActivitiesDataCompleted implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_COMPLETED;
  constructor(public payload: ActivityCRUDInterface[]) {}
}

export class TasksDataLoading implements Action {
  readonly type = ActionTypes.TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class TasksDataCompleted implements Action {
  readonly type = ActionTypes.TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type WorkOrderSettingActions =
  | WorkOrderSettingLoading
  | WorkOrderSettingLoaded
  | GetWorkOrderSetting
  | GetWorkOrderSettingCompleted
  | AddWorkOrderSetting
  | AddWorkOrderSettingCompleted
  | EditWorkOrderSetting
  | EditWorkOrderSettingCompleted
  | DeleteWorkOrderSetting
  | DeleteWorkOrderSettingCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | ActivitiesDataLoading
  | ActivitiesDataCompleted
  | TasksDataLoading
  | TasksDataCompleted
  | FetchError;
