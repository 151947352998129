import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './custom-dashboard-content.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { of } from 'rxjs';
import { CustomDashboardContentService } from './custom-dashboard-content.service';
import { ColorService } from '../../../shared/service/color/color.service';

@Injectable()
export class CustomDashboardContentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: CustomDashboardContentService,
    private readonly colorService: ColorService,
  ) {}

  $fetchCustomDashboardData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardContentActionTypes.FetchCustomDashboardContents),
      switchMap((objectData: ObjectActions.FetchCustomDashboardContents) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getCustomDashboardContents(objectData.itemId).pipe(
          withLatestFrom(this.store.select('user', 'clientInfo')),
          switchMap(([customDashboardContentsResponse, client]) => {
            return of(
              new ObjectActions.FetchCustomDashboardContentsCompleted({
                customDashboardContents: customDashboardContentsResponse.data,
                unplannedDownTimeActivityColor: this.colorService.pickComponentColors('plannedDownTimeActivityGantt', {
                  allSites: [customDashboardContentsResponse.data.site],
                  visibleSiteIds: [customDashboardContentsResponse.data.site.id],
                  client,
                }),
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(
                ObjectActions.CustomDashboardContentActionTypes.FetchCustomDashboardContents,
              ),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(
          new ObjectActions.FetchError(ObjectActions.CustomDashboardContentActionTypes.FetchCustomDashboardContents),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  $saveCustomDashboardContentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CustomDashboardContentActionTypes.SaveCustomDashboardContent),
      switchMap((objectData: ObjectActions.SaveCustomDashboardContent) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.saveCustomDashboardContent(objectData.payload).pipe(
          switchMap(() => {
            return of(new ObjectActions.SaveCustomDashboardContentCompleted(), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
