import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LineViewRowsFormattedInterface } from '../../../store/line-view-work-order-summary/line-view-work-order-summary.model';
import {
  ILineCamera,
  ECameraViewSource,
  ICameraViewLineDropdown,
  ICameraViewTree,
  CameraList,
} from './camera-view-modal.model';
import { DomSanitizer } from '@angular/platform-browser';
import { SiteDataInterface } from '../../../store/global-view/global-view.model';
import { HelperService } from '../../service/helper.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { User } from '../../../store/user/model';
import { SiteViewCameraViewModalOpenInterface } from '../../../store/global-view/site-view/site-view.model';
import { ICamera } from '../../../view/settings/departments-lines-stations/lines/lines.model';

@Component({
  selector: 'app-camera-view-modal',
  templateUrl: './camera-view-modal.component.html',
  styleUrls: ['./camera-view-modal.component.scss'],
})
export class CameraViewModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('siteBasedTimeOfTheCameraRef') siteBasedTimeOfTheCameraRef: ElementRef;
  @ViewChild('imgFeed') imgFeed: ElementRef;
  @Input() public cameraViewData:
    | LineViewRowsFormattedInterface
    | SiteDataInterface
    | SiteViewCameraViewModalOpenInterface;
  @Input() public source: ECameraViewSource = ECameraViewSource.lineView;
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectRowData: ICameraViewTree[] = [];
  public defaultSelectRowData: ICameraViewTree[] = [];
  public selectedCamera: ILineCamera;
  public lineData: ICameraViewLineDropdown[] = [];
  public selectedLines: ICameraViewLineDropdown[] = [];
  public timer: Subscription;
  public searchText: string = '';
  public videoHasError: boolean = false;
  public videoCanPlayTrough: boolean = false;
  public isDemoCamera: boolean = false;
  private storeSubscriptions: Subscription[] = [];
  private dateTimeFormat$: string;

  constructor(
    private store: Store<OeeAppState>,
    private sanitizer: DomSanitizer,
    private helperService: HelperService,
    public translate: TranslateService,
    public zone: NgZone,
  ) {}

  public ngOnInit(): void {
    let lineName: string;
    let timezone: string;
    let cameraList: ILineCamera[];

    switch (this.source) {
      case ECameraViewSource.globalView:
        const { lines } = this.cameraViewData as SiteDataInterface;
        timezone = this.cameraViewData.timezone;
        this.lineData = lines
          ?.filter((line: { cameraList: ICamera[] }) => line.cameraList?.length)
          .map((line: { id: number; title: string; cameraList: ICamera[] }) => {
            return { timezone, id: line.id, name: line.title, cameraList: line.cameraList };
          });
        break;
      case ECameraViewSource.lineView:
        ({ lineName, timezone, cameraList } = this.cameraViewData as LineViewRowsFormattedInterface);
        this.selectRowData = [
          this.getCameraViewTreeData(
            this.translate.instant('cameraView.modal.cameras'),
            lineName,
            cameraList,
            timezone,
          ),
        ];

        this.setSelectedCameraData(this.selectRowData[0].cameras[0]);
        break;
      case ECameraViewSource.siteView:
        ({ lineName, timezone, cameraList } = this.cameraViewData as SiteViewCameraViewModalOpenInterface);
        this.selectRowData = [
          this.getCameraViewTreeData(
            this.translate.instant('cameraView.modal.cameras'),
            lineName,
            cameraList,
            timezone,
          ),
        ];

        this.setSelectedCameraData(this.selectRowData[0].cameras[0]);
        break;
    }

    this.storeSubscriptions.push(
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.dateTimeFormat$ = state.dateTimeFormat;
        }),
    );
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const oneSecondTimer: Observable<number> = timer(0, 1000);
      this.timer = oneSecondTimer.subscribe(() => {
        this.zone.run(() => {
          if (this.siteBasedTimeOfTheCameraRef?.nativeElement && this.selectedCamera?.timezone) {
            this.siteBasedTimeOfTheCameraRef.nativeElement.innerHTML = moment()
              .tz(this.selectedCamera.timezone)
              .format(this.dateTimeFormat$);
          }
        });
      });
    });
  }

  public getCameraDataOfLine(): void {
    this.selectRowData = [];

    if (this.selectedLines.length) {
      this.selectRowData = this.selectedLines.map((line: ICameraViewLineDropdown & { cameraList: ILineCamera[] }) => {
        return this.getCameraViewTreeData(line.name, line.name, line.cameraList, line.timezone);
      });

      this.selectRowData = this.selectRowData.sort(this.helperService.dropdownOptionCompareFunction);
      this.defaultSelectRowData = _.cloneDeep(this.selectRowData);
      this.setSelectedCameraData(this.selectRowData[0].cameras[0]);
    }

    this.searchText = null;
  }

  public searchEvent(search: string): void {
    if (this.defaultSelectRowData.length) {
      this.selectRowData = this.defaultSelectRowData.map((cameraViewTree: ICameraViewTree) => {
        return {
          ...cameraViewTree,
          cameras: cameraViewTree.cameras.filter((camera: ILineCamera) => {
            return camera.name.toLowerCase().includes(search.toLowerCase());
          }),
        };
      });
      return;
    }
  }

  public setSelectedCameraData(camera: ILineCamera, isMainTitle: boolean = false): void {
    if (isMainTitle) {
      return;
    }

    if (this.selectedCamera?.safeUrl && this.imgFeed?.nativeElement) {
      this.imgFeed.nativeElement.src = '';
      this.selectedCamera.safeUrl = '';
    }

    this.videoHasError = false;
    this.videoCanPlayTrough = false;
    this.isDemoCamera = false;
    this.selectedCamera = camera;
    let cameraUrl = this.selectedCamera.url;

    if (
      [
        'video1.mp4',
        'video2.mp4',
        'video3.mp4',
        'video4.mp4',
        'video5.mp4',
        'video6.mp4',
        'video7.mp4',
        'video8.mp4',
        'video9.mp4',
        'video10.mp4',
      ].includes(camera.url.toLowerCase())
    ) {
      this.isDemoCamera = true;
      cameraUrl = `assets/videos/${camera.url.toLowerCase()}`;
    }

    this.selectedCamera.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(cameraUrl);
  }

  public closeCameraViewModal(): void {
    if (this.selectedCamera?.safeUrl) {
      this.selectedCamera.safeUrl = '';
    }

    this.closeModalEvent.emit();
  }

  public handeImageError(): void {
    this.videoHasError = true;
  }

  public canPlayThrough(): void {
    this.videoCanPlayTrough = true;
  }

  private getCameraViewTreeData(
    name: string,
    lineName: string,
    cameraList: ILineCamera[],
    siteTimezone?: string,
  ): ICameraViewTree {
    const timezone: string = siteTimezone || 'UTC';
    const cameras: CameraList[] = cameraList.map((camera: ILineCamera) => {
      return {
        lineName,
        timezone,
        name: camera.name,
        url: camera.url,
      };
    });

    return { name, cameras };
  }

  public ngOnDestroy(): void {
    for (const subscription of this.storeSubscriptions) {
      subscription?.unsubscribe();
    }

    this.timer?.unsubscribe();
  }

  protected readonly ECameraViewSource = ECameraViewSource;
}
