import { Inject, Injectable } from '@angular/core';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnKeysInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import * as ObjectActions from './product-transition-matrix.actions';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import { ProductTransitionMatrixService } from './product-transition-matrix.service';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import { LineService } from '../../../shared/service/filter/line.service';
import { ProductService } from '../../../shared/service/filter/product.service';
import { SiteService } from '../../../shared/service/filter/site.service';
import {
  IAddEditProductTransitionMatrix,
  IAddEditProductTransitionMatrixExcel,
  IExcelFromToType,
  IGetExcelFormattedData,
  ILines,
  IProductFamily,
  IProducts,
  IProductTransitionMatrix,
  IProductTransitionMatrixExcelDropdownContent,
  IProductTransitionMatrixExcelExcelUploadResult,
  IProductTransitionMatrixExcelResponse,
  IProductTransitionMatrixResponse,
  ISimplifiedData,
  ISites,
} from './product-transition-matrix.model';
import {
  EProductTransitionMatrixFromToType,
  EProductTransitionMatrixFromToTypeExcel,
  EProductTransitionMatrixTypeExcel,
} from '../../../../constants';
import {
  IDownloadExcelFilters,
} from '../../../view/settings/product-transition-matrix/product-transition-matrix.model';
import { LineCRUDResponseInterface } from '../../../shared/service/filter/service.class';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { ProductCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ProductFamilyService } from '../product-family/product-family.service';

@Injectable({
  providedIn: 'root',
})
export class ProductTransitionMatrixExcelService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly excelUploadLimit: number = 1000;
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  private readonly routes = {
    sites: `${this.baseUrl}/sites`,
    lines: `${this.baseUrl}/lines`,
    bulkSave: `${this.baseUrl}/product-transition-matrix/bulk/save`,
  };

  constructor(
    public readonly excelHelper: ExcelHelperService,
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly productTransitionMatrixService: ProductTransitionMatrixService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly productService: ProductService,
    private readonly productFamilyService: ProductFamilyService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public async downloadExcel(
    withData: boolean,
    filters: IDownloadExcelFilters,
    withErrorColumn: boolean = false,
    data?: IProductTransitionMatrixExcelExcelUploadResult[],
  ): Promise<void> {
    const { fromType, toType } = this.findFromTypeAndToType(filters.excelType);
    const lineParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('fields', 'title')
      .set('limit', String(filters.limit));

    const productsParams: HttpParams = new HttpParams()
      .append('join', 'productFamily')
      .set('limit', '10000')
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));

    const productFamilyParams: HttpParams = new HttpParams()
      .set('limit', '1000')
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));

    const observables: [
      Observable<BaseOneResponseInterface<SiteCRUDInterface>>,
      Observable<LineCRUDResponseInterface>,
      Observable<ProductCRUDInterface>,
      Observable<GetManyResponseInterface<IProductFamily>>,
      Observable<BaseOneResponseInterface<IProductTransitionMatrixResponse>>?,
    ] = [
      this.siteService.getSite(filters.siteId, new HttpParams().set('fields', 'name,siteId')),
      this.lineService.getLines(lineParams),
      this.productService.getProducts(productsParams),
      this.productFamilyService.getProductFamily(productFamilyParams),
    ];

    if (withData && !data) {
      observables.push(
        this.productTransitionMatrixService.getProductTransitionMatrix({
          selectedSiteIds: [filters.siteId],
          selectedLineIds: [],
          page: 1,
          pageSize: filters.limit,
          fromObjType: fromType === EProductTransitionMatrixFromToTypeExcel.ALL ? null : Number(fromType),
          toObjType: toType === EProductTransitionMatrixFromToTypeExcel.ALL ? null : Number(toType),
        }),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      const site: ISimplifiedData = _.get(responseList, '0.data', []);
      const line: ILines[] = _.get(responseList, '1.data', []).sort(this.helperService.dropdownOptionCompareFunction);
      const product: IProducts[] = _.get(responseList, '2.data', []);
      const productFamily: IProductFamily[] = _.get(responseList, '3.data', []);
      const any: IExcelFromToType[] = [
        {
          id: EProductTransitionMatrixFromToTypeExcel.ANY,
          description: this.translate.instant('productTransitionMatrix.types.any'),
        },
      ];

      if (toType !== EProductTransitionMatrixFromToTypeExcel.ANY) {
        product.unshift({
          id: EProductTransitionMatrixFromToType.SAME,
          productId: this.translate.instant('productTransitionMatrix.types.same'),
        });

        productFamily.unshift({
          id: EProductTransitionMatrixFromToType.SAME,
          name: this.translate.instant('productTransitionMatrix.types.same'),
        });
      }

      const fromToData: IExcelFromToType[] = [
        {
          id: EProductTransitionMatrixFromToTypeExcel.PRODUCT,
          description: this.translate.instant('productTransitionMatrix.types.product'),
        },
        {
          id: EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY,
          description: this.translate.instant('productTransitionMatrix.types.productFamily'),
        },
        {
          id: EProductTransitionMatrixFromToTypeExcel.ANY,
          description: this.translate.instant('productTransitionMatrix.types.any'),
        },
      ];

      const excelTypeData: IExcelFromToType[] = [
        {
          id: EProductTransitionMatrixTypeExcel.ALL,
          description: this.translate.instant('productTransitionMatrix.types.all'),
        },
        {
          id: EProductTransitionMatrixTypeExcel.PRODUCT_TO_PRODUCT,
          description: this.translate.instant('productTransitionMatrixExcel.types.productToProduct'),
        },
        {
          id: EProductTransitionMatrixTypeExcel.PRODUCT_FAMILY_TO_PRODUCT_FAMILY,
          description: this.translate.instant('productTransitionMatrixExcel.types.productFamilyToProductFamily'),
        },
        {
          id: EProductTransitionMatrixTypeExcel.PRODUCT_TO_ANY,
          description: this.translate.instant('productTransitionMatrixExcel.types.productToAny'),
        },
        {
          id: EProductTransitionMatrixTypeExcel.PRODUCT_FAMILY_TO_ANY,
          description: this.translate.instant('productTransitionMatrixExcel.types.productFamilyToAny'),
        },
      ];

      const from: IExcelFromToType[] = [_.find(fromToData, { id: fromType })];
      const to: IExcelFromToType[] = [_.find(fromToData, { id: toType })];
      const excelType: IExcelFromToType = _.find(excelTypeData, { id: filters.excelType });

      const sheetTitle: string = this.translate.instant('excel.items.productTransitionMatrix');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

      const content: IProductTransitionMatrixExcelDropdownContent = {
        site,
        line,
        product,
        productFamily,
        from,
        to,
        any,
      };

      let excelOptions: CreateExcelInterface;
      if (filters.excelType === EProductTransitionMatrixTypeExcel.ALL) {
        excelOptions = this.getProductTypeAllExcelColumns(withErrorColumn);
      } else {
        excelOptions = this.getExcelColumns(content, withErrorColumn, excelType, fromType, toType);
      }

      if (withData) {
        if (filters.excelType === EProductTransitionMatrixTypeExcel.ALL) {
          excelOptions.data = (!data ? _.get(responseList, '4.data.rows', []) : data).map(
            (productTransitionMatrix: IProductTransitionMatrix) => {
              return this.getExcelFormattedDataForAllType(productTransitionMatrix, fromToData);
            },
          );
        } else {
          excelOptions.data = (!data ? _.get(responseList, '4.data.rows', []) : data).map(
            (productTransitionMatrix: IProductTransitionMatrix) => {
              return this.getExcelFormattedData(productTransitionMatrix, content);
            },
          );
        }
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          {
            withData,
            name: 'productTransitionMatrix',
            siteId: site?.id,
            type: filters.excelType,
          },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadProductTransitionMatrixExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getExcelFormattedDataForAllType(
    productTransitionMatrixData: IProductTransitionMatrix,
    fromToData: IExcelFromToType[],
  ): IProductTransitionMatrix {
    return {
      ...productTransitionMatrixData,
      lineName:
        productTransitionMatrixData.lineName === '0'
          ? this.translate.instant('productTransitionMatrix.types.any')
          : productTransitionMatrixData.lineName,
      fromObjType: _.find(fromToData, { id: productTransitionMatrixData.fromObjType.toString() }).description,
      toObjType: _.find(fromToData, { id: productTransitionMatrixData.fromObjType.toString() }).description,
      fromObjIdName:
        Number(productTransitionMatrixData.fromObjId) === EProductTransitionMatrixFromToType.ANY
          ? this.translate.instant('productTransitionMatrix.types.any')
          : Number(productTransitionMatrixData.fromObjId) === EProductTransitionMatrixFromToType.SAME
            ? this.translate.instant('productTransitionMatrix.types.same')
            : productTransitionMatrixData.fromObjIdName,
      toObjIdName:
        Number(productTransitionMatrixData.toObjId) === EProductTransitionMatrixFromToType.ANY
          ? this.translate.instant('productTransitionMatrix.types.any')
          : Number(productTransitionMatrixData.toObjId) === EProductTransitionMatrixFromToType.SAME
            ? this.translate.instant('productTransitionMatrix.types.same')
            : productTransitionMatrixData.toObjIdName,
    };
  }

  private getExcelFormattedData(
    productTransitionMatrixData: IProductTransitionMatrix,
    content: IProductTransitionMatrixExcelDropdownContent,
  ): IGetExcelFormattedData {
    return {
      ...productTransitionMatrixData,
      site: content.site,
      line: _.find(content.line, { id: productTransitionMatrixData.lineId }),
      fromObjId: _.find(
        productTransitionMatrixData.fromObjType === EProductTransitionMatrixFromToType.PRODUCT
          ? content.product
          : content.productFamily,
        { id: productTransitionMatrixData.fromObjId },
      ),
      toObjId: _.find(
        productTransitionMatrixData.toObjType === EProductTransitionMatrixFromToType.PRODUCT
          ? content.product
          : content.productFamily,
        { id: productTransitionMatrixData.toObjId },
      ),
      from: _.find(content.from, { id: productTransitionMatrixData.fromObjType.toString() }),
      to: _.find(content.to, { id: productTransitionMatrixData.toObjType.toString() }),
      scheduledPostRunDuration: this.decimalHelper.removeTrailingZeros(productTransitionMatrixData.fromPostTarget),
      scheduledPreRunDuration: this.decimalHelper.removeTrailingZeros(productTransitionMatrixData.toPreTarget),
    };
  }

  private getExcelColumns(
    content: IProductTransitionMatrixExcelDropdownContent,
    withErrorColumn: boolean,
    fromToType: IExcelFromToType = null,
    fromType: string = null,
    toType: string = null,
  ): CreateExcelInterface {
    const { fromIdColumn, toIdColumn } = this.getIdColumnAccordingToFromToType(content, fromType, toType);

    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('productTransitionMatrix.headers.site'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [content.site],
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('productTransitionMatrix.headers.line'),
          key: 'lineId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.line,
            prop: 'line.title',
            dataProperty: 'line.title',
            dataId: 'line.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: true,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('general.excel.column.from'),
          key: 'fromObjType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.from,
            prop: 'from.description',
            dataProperty: 'from.description',
            dataId: 'from.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        fromIdColumn,
        {
          header: this.translate.instant('general.excel.column.to'),
          key: 'toObjType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.to,
            prop: 'to.description',
            dataProperty: 'to.description',
            dataId: 'to.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        toIdColumn,
        {
          header: this.translate.instant('productTransitionMatrix.headers.fromPostTarget'),
          key: 'fromPostTarget',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
            prompt: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('productTransitionMatrix.headers.toPreTarget'),
          key: 'toPreTarget',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
            prompt: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: 'fromToType',
          key: 'fromToType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          hidden: true,
          dropdownOptions: {
            data: [fromToType],
            prop: 'fromToType.description',
            dataProperty: 'fromToType.description',
            dataId: 'fromToType.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  private getProductTypeAllExcelColumns(withErrorColumn: boolean): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('productTransitionMatrix.headers.site'),
          key: 'siteName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('productTransitionMatrix.headers.line'),
          key: 'lineName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.from'),
          key: 'fromObjType',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.fromProduct'),
          key: 'fromObjIdName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.to'),
          key: 'toObjType',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.toProduct'),
          key: 'toObjIdName',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('productTransitionMatrix.headers.fromPostTarget'),
          key: 'fromPostTarget',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('productTransitionMatrix.headers.toPreTarget'),
          key: 'toPreTarget',
          width: 20,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  private getIdColumnAccordingToFromToType(
    content: IProductTransitionMatrixExcelDropdownContent,
    fromType: string,
    toType: string,
  ): { fromIdColumn: ExcelColumnDefinitionInterface; toIdColumn: ExcelColumnDefinitionInterface } {
    const fromIdColumns = [
      {
        fromType: EProductTransitionMatrixFromToTypeExcel.PRODUCT,
        column: {
          header: this.translate.instant('general.excel.column.fromProduct'),
          key: 'fromObjId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.product,
            prop: 'product.id',
            dataProperty: 'fromObjId.productId',
            dataId: 'fromObjId.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      },
      {
        fromType: EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY,
        column: {
          header: this.translate.instant('general.excel.column.fromProductFamily'),
          key: 'fromObjId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.productFamily,
            prop: 'productFamily.name',
            dataProperty: 'fromObjId.name',
            dataId: 'fromObjId.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      },
    ];
    const toIdColumns = [
      {
        toType: EProductTransitionMatrixFromToTypeExcel.PRODUCT,
        column: {
          header: this.translate.instant('general.excel.column.toProduct'),
          key: 'toObjId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.product,
            prop: 'product.id',
            dataProperty: 'toObjId.productId',
            dataId: 'toObjId.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      },
      {
        toType: EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY,
        column: {
          header: this.translate.instant('general.excel.column.toProductFamily'),
          key: 'toObjId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.productFamily,
            prop: 'productFamily.name',
            dataProperty: 'toObjId.name',
            dataId: 'toObjId.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      },
      {
        toType: EProductTransitionMatrixFromToTypeExcel.ANY,
        column: {
          header: this.translate.instant('general.excel.column.toAny'),
          key: 'toObjId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.any,
            prop: 'any.description',
            dataProperty: 'toObjId.id',
            dataId: 'toObjId.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
          hidden: true,
        },
      },
    ];

    const fromId = _.find(fromIdColumns, { fromType: fromType })?.column;
    const toId = _.find(toIdColumns, { toType: toType })?.column;

    return { fromIdColumn: fromId, toIdColumn: toId };
  }

  public async getProductTransitionMatrixFromExcel(file: File): Promise<IProductTransitionMatrixExcelResponse | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const productTransitionMatrixSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('excel.items.productTransitionMatrix'),
    );
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const fromToTypeDataSheet: Worksheet = workbook.getWorksheet('fromToTypeDataSheet');
    const dataSheetsToBeValidated: Worksheet[] = [
      productTransitionMatrixSheet,
      siteIdDataSheet,
      fromToTypeDataSheet,
      workbook.getWorksheet('lineIdDataSheet'),
      workbook.getWorksheet('fromObjTypeDataSheet'),
      workbook.getWorksheet('fromObjIdDataSheet'),
      workbook.getWorksheet('toObjTypeDataSheet'),
      workbook.getWorksheet('toObjIdDataSheet'),
    ];

    if (!dataSheetsToBeValidated.every((dataSheet: Worksheet) => dataSheet)) {
      return null;
    }

    const sites: ISites[] = this.excelHelper.getExcelRowsFromWorkSheet<ISites>(siteIdDataSheet, {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    });

    if (!sites.length) {
      return null;
    }

    const fromToTypeValue: IExcelFromToType[] = this.excelHelper.getExcelRowsFromWorkSheet<IExcelFromToType>(
      fromToTypeDataSheet,
      {
        id: {
          key: 'id',
          type: ValueType.String,
          dataValidationType: CellTypes.LIST,
        },
        description: {
          key: 'description',
          type: ValueType.String,
          dataValidationType: CellTypes.LIST,
        },
      },
    );

    if (!fromToTypeValue.length) {
      return null;
    }

    const { fromType, toType } = this.findFromTypeAndToType(fromToTypeValue[0].id);

    const { columns }: CreateExcelInterface = this.getExcelColumns(
      {
        site: null,
        line: [],
        product: [],
        productFamily: [],
        from: [],
        to: [],
        any: [],
      },
      false,
      fromToTypeValue[0].id,
      fromType,
      toType,
    );

    const columnKeys: ExcelColumnKeysInterface = this.excelHelper.getSheetColumnKeys(columns);
    const excelProductTransitionMatrix: IAddEditProductTransitionMatrixExcel[] =
      this.excelHelper.getExcelRowsFromWorkSheet<IAddEditProductTransitionMatrixExcel>(
        productTransitionMatrixSheet,
        columnKeys,
        {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        },
      );

    const productTransitionMatrixRequestData: IAddEditProductTransitionMatrix[] = excelProductTransitionMatrix.map(
      (productTransitionMatrix: IAddEditProductTransitionMatrixExcel) => {
        return {
          ...(productTransitionMatrix.id ? { id: productTransitionMatrix.id } : {}),
          siteId: productTransitionMatrix.siteId,
          lineId: productTransitionMatrix.lineId ?? 0,
          fromObjType: Number(HelperService.cloneDeep(productTransitionMatrix.fromObjType)),
          fromObjId: productTransitionMatrix.fromObjId,
          toObjType: Number(HelperService.cloneDeep(productTransitionMatrix.toObjType)),
          toObjId:
            productTransitionMatrix.toObjType === EProductTransitionMatrixFromToTypeExcel.ANY
              ? 0
              : productTransitionMatrix.toObjId,
          fromPostTarget: productTransitionMatrix.fromPostTarget,
          toPreTarget: productTransitionMatrix.toPreTarget,
        };
      },
    );

    return {
      productTransitionMatrixData: {
        productTransitionMatrix: productTransitionMatrixRequestData,
      },
      siteData: sites,
    };
  }

  public uploadExcel(
    productTransitionMatrix: IAddEditProductTransitionMatrix[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.bulkSave, {
      productTransitionMatrices: productTransitionMatrix,
    });
  }

  public findFromTypeAndToType(excelType: string): { fromType: string; toType: string } {
    let fromType: EProductTransitionMatrixFromToTypeExcel = EProductTransitionMatrixFromToTypeExcel.PRODUCT;
    let toType: EProductTransitionMatrixFromToTypeExcel = EProductTransitionMatrixFromToTypeExcel.PRODUCT;

    switch (excelType) {
      case EProductTransitionMatrixTypeExcel.ALL:
        fromType = EProductTransitionMatrixFromToTypeExcel.ALL;
        toType = EProductTransitionMatrixFromToTypeExcel.ALL;
        break;
      case EProductTransitionMatrixTypeExcel.PRODUCT_TO_PRODUCT:
        fromType = EProductTransitionMatrixFromToTypeExcel.PRODUCT;
        toType = EProductTransitionMatrixFromToTypeExcel.PRODUCT;
        break;
      case EProductTransitionMatrixTypeExcel.PRODUCT_FAMILY_TO_PRODUCT_FAMILY:
        fromType = EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY;
        toType = EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY;
        break;
      case EProductTransitionMatrixTypeExcel.PRODUCT_TO_ANY:
        fromType = EProductTransitionMatrixFromToTypeExcel.PRODUCT;
        toType = EProductTransitionMatrixFromToTypeExcel.ANY;
        break;
      case EProductTransitionMatrixTypeExcel.PRODUCT_FAMILY_TO_ANY:
        fromType = EProductTransitionMatrixFromToTypeExcel.PRODUCT_FAMILY;
        toType = EProductTransitionMatrixFromToTypeExcel.ANY;
        break;
      default:
        break;
    }

    return { fromType, toType };
  }
}
