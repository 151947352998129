import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import echarts from 'echarts';
import { Subscription } from 'rxjs';
import { ITreeChartDefaultSettings } from '../task-tree-chart/task-tree-chart.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as ResourceGroupActions from '../../../../store/settings/product-resource-groups/product-resource-groups.actions';
import { ofType } from '@ngrx/effects';
import {
  IProductResourceGroup,
  IProductResourceGroupsFilters,
  ProductResourceGetManyPurpose,
  ProductResourceGroupTypes,
} from '../../../../store/settings/product-resource-groups/product-resource-groups.model';
import * as _ from 'lodash';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';
import { IChartLoader } from '../../../../view/reports/root-cause-analysis/charts/charts.model';
import { GenericHelperService } from '../../../service/generic.helper.service';
import { FilterCardOptionInterface } from '../../filter/filter.class';
import { Site } from '../../filter/filterable-objects.class';
import { FilterHelperService } from '../../../service/filter/filter.helper.service';
import { GenericCrudRequestConstructionParameters } from '../../../model/interface/generic-api-request.model';
import { defaultPageSort } from '../../../../../constants';

export interface IResourceGroupTreeChartNode {
  id: number;
  name: string;
  collapsed?: boolean;
  children?: IResourceGroupTreeChartNode[];
  isDisabled?: boolean;
  itemStyle?: object;
  parentId?: number;
  label?: object;
}

@OnDestroyDecorator
@Component({
  selector: 'product-resource-groups-tree-chart',
  templateUrl: './product-resource-groups-tree-chart.component.html',
})
export class ProductResourceGroupsTreeChartComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<OeeAppState>,
    public actionsSubject: ActionsSubject,
    private readonly genericHelper: GenericHelperService,
    private readonly filterHelper: FilterHelperService,
  ) {}

  public chartRef: echarts.ECharts;
  public pageDataCrudParameters: GenericCrudRequestConstructionParameters = {
    page: 1,
    perPage: 10,
    search: {
      searchedFields: ['line.title', 'name'],
      searchText: '',
    },
    join: ['site', 'parentResourceGroup', 'line'],
    sort: [defaultPageSort],
  };
  public selectedFilterSiteId: number | undefined;
  private readonly initialChartHeight: number = 0;
  private chartHeight: number = this.initialChartHeight;
  private expandOrCollapseModifiedIds: number[] = [];
  private readonly subscriptions: Subscription[] = [];
  private nodes: IResourceGroupTreeChartNode[] = [];
  private selectedNodeId: number = 0;
  public treeChartOption: echarts.EChartsOption = {};
  private treeChartDefaultSettings: ITreeChartDefaultSettings = {
    top: '1%',
    left: '10%',
    bottom: '1%',
    right: '20%',
    symbolSize: 10,
    animationDuration: 1,
    animationDurationUpdate: 1,
    fontSize: 11,
  };
  private isInitialAdd: boolean = true;
  private pageData$: IProductResourceGroup[] = [];
  public isTouchDevice: boolean = this.genericHelper.isTouchDevice;
  public filterConfiguration: FilterCardOptionInterface = {
    rows: [
      [
        this.filterHelper.getGenericDropdownFilter({ type: Site, name: 'siteId' }, 'col-md-3 m-t-4 m-b-5', {
          isRequired: true,
          singleSelection: true,
        }),
      ],
    ],
  };

  ngOnInit(): void {
    this.subscriptions.push(
      this.actionsSubject
        .pipe(ofType(ResourceGroupActions.ProductResourceGroupsActionTypes.GET_PRODUCT_RESOURCE_GROUPS_LOADED))
        .subscribe((payload: ResourceGroupActions.GetProductResourceGroupsLoaded) => {
          if (payload.purpose !== ProductResourceGetManyPurpose.treeChart) {
            return;
          }

          this.pageData$ = payload.payload.data;
          this.setChartLoadingState(false);
          this.addNodes(payload.payload.data);
        }),
    );
  }

  public onFiltersChanged($event: IProductResourceGroupsFilters): void {
    this.resetToInitialValues();
    this.setChartLoadingState(true);
    this.selectedFilterSiteId = $event.siteId[0];
    this.store.dispatch(
      new ResourceGroupActions.GetProductResourceGroupsLoading(
        {
          join: ['line'],
          filters: [{ field: 'siteId', ids: [this.selectedFilterSiteId] }],
          perPage: 5000,
        },
        ProductResourceGetManyPurpose.treeChart,
      ),
    );
  }

  private addNodes(data: IProductResourceGroup[]): void {
    let nodes: IResourceGroupTreeChartNode[] = data.map(
      (productResourceGroup: IProductResourceGroup): IResourceGroupTreeChartNode => {
        return {
          id: productResourceGroup.id,
          name:
            productResourceGroup.type === ProductResourceGroupTypes.LINE
              ? productResourceGroup.line?.title ?? ''
              : productResourceGroup.name,
          label: {
            color: 'rgb(255, 255, 255)',
            borderRadius: [4, 4, 4, 4],
            padding: [3, 3, 3, 3],
            backgroundColor: 'rgb(189, 189, 189)',
          },
          itemStyle: {
            color: 'rgb(189, 189, 189)',
          },
          parentId: productResourceGroup.parent,
        };
      },
    );

    nodes = this.initialAdd(nodes);
    this.setNodes(nodes);
    this.createTreeChart();
  }

  private setNodes(nodes: IResourceGroupTreeChartNode[]): void {
    const node: IResourceGroupTreeChartNode | null = this.findNode(this.selectedNodeId);

    if (!node) {
      return;
    }

    const children = node.children || [];
    node.children = nodes.length > 0 ? children.concat(nodes) : [];

    if (nodes.length > 0) {
      this.chartHeight = nodes.length * 44;
      this.chartRef.resize({
        height: this.chartHeight,
      });

      this.renderChart();
    }
  }

  private findNode(id: number, node?: IResourceGroupTreeChartNode): IResourceGroupTreeChartNode | null {
    const firstNode: IResourceGroupTreeChartNode = node || _.head(this.nodes);

    if (firstNode.id === id) {
      return firstNode;
    }

    if (firstNode.children) {
      let i;
      let result = null;

      for (i = 0; result === null && i < firstNode.children.length; i = i + 1) {
        result = this.findNode(id, firstNode.children[i]);
      }
      return result;
    }

    return null;
  }

  public renderChart(chartRef?: echarts.ECharts): void {
    if (chartRef) {
      this.chartRef = chartRef;
    }

    if (!this.chartRef || chartRef) {
      return;
    }

    this.chartRef.setOption(this.expandOrCollapseClickedNodes(), true);
  }

  private expandOrCollapseClickedNodes(): echarts.EChartsOption {
    const clonedOptions: echarts.EChartsOption = _.cloneDeep(this.treeChartOption);
    let queue = _.get(clonedOptions, 'series.0.data.0.children', []).slice();

    while (queue.length) {
      const child = queue.shift();
      queue = [...queue, ...(child.children ?? [])];

      if (this.expandOrCollapseModifiedIds.includes(child.id)) {
        child.collapsed = !child.collapsed;
      }
    }

    return clonedOptions;
  }

  private createTreeChart(): void {
    this.treeChartOption = {
      series: [
        {
          type: 'tree',
          edgeShape: 'polyline',
          data: this.nodes as echarts.TreeSeriesOption[],
          top: this.treeChartDefaultSettings.top,
          left: this.treeChartDefaultSettings.left,
          bottom: this.treeChartDefaultSettings.bottom,
          right: this.treeChartDefaultSettings.right,
          symbolSize: this.treeChartDefaultSettings.symbolSize,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
            fontSize: this.treeChartDefaultSettings.fontSize,
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
            },
          },
          expandAndCollapse: true,
          animationDuration: this.treeChartDefaultSettings.animationDuration,
          animationDurationUpdate: this.treeChartDefaultSettings.animationDurationUpdate,
          roam: 'move',
        },
      ],
    };
  }

  public onChartClick($event: unknown): void {
    const selectedNode: IResourceGroupTreeChartNode = _.get($event, 'data');
    this.selectedNodeId = selectedNode.id;

    if (selectedNode.isDisabled) {
      return;
    }

    const node: IResourceGroupTreeChartNode | null = this.findNode(this.selectedNodeId);
    const hasChildren: boolean = selectedNode.children?.length > 0;

    if (node) {
      node.collapsed = hasChildren;
    }

    if (this.expandOrCollapseModifiedIds.includes(this.selectedNodeId)) {
      _.pull(this.expandOrCollapseModifiedIds, this.selectedNodeId);
    } else {
      this.expandOrCollapseModifiedIds.push(this.selectedNodeId);
    }
  }

  private setChartLoadingState(isLoading: boolean): void {
    if (isLoading) {
      const params: IChartLoader = { type: 'default', options: { text: '' } };
      this.chartRef?.showLoading(params.type, params.options);

      return;
    }

    this.chartRef?.hideLoading();
  }

  private initialAdd(nodes: IResourceGroupTreeChartNode[]): IResourceGroupTreeChartNode[] {
    if (!this.isInitialAdd) {
      return nodes;
    }

    this.isInitialAdd = false;

    for (const node of nodes) {
      node.collapsed = false;

      if (node.parentId === null) {
        continue;
      }

      const parent = nodes.find((parentNode: IResourceGroupTreeChartNode) => parentNode.id === node.parentId);
      parent.children = [...(parent.children ?? []), node];
    }

    return nodes.filter((node: IResourceGroupTreeChartNode) => node.parentId === null);
  }

  private resetToInitialValues(): void {
    this.nodes = [
      {
        id: 0,
        name: '',
      },
    ];

    this.selectedNodeId = 0;
    this.isInitialAdd = true;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
