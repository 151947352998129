import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'scw-mat-wizard-step',
  templateUrl: './scw-mat-wizard-step.component.html',
  styleUrls: ['./scw-mat-wizard-step.component.scss'],
})
export class ScwMatWizardStepComponent {
  @Input() id: number;
  @Input() title: string;
  @Input() description: string;
  @Input() titleIcon: string;
  @Input() descriptionImagePath: string;
  @Input() link: any[] | string;
  @Input() linkButtonText: string;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() expanded: boolean = false;
  @Input() completed: boolean = false;

  @Output() onExpanded: EventEmitter<number> = new EventEmitter<number>();

  constructor(public readonly router: Router) {}

  public onClickCollapsedCard(id: number): void {
    if (this.disabled) {
      return;
    }

    this.onExpanded.emit(id);
  }

  public onClickLink(link: any[] | string): void {
    if (this.disabled) {
      return;
    }

    this.router.navigate([link]);
  }
}
