<ng-template #loading>
  <div class="card-block d-flex align-items-center justify-content-center h-100 w-100 m-0 p-0">
    <div class="loader-block">
      <svg id="loader2" viewBox="0 0 100 100">
        <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  </div>
</ng-template>

<app-card
  [hidden]="
    (isPerformanceChartLoading || (!isChartMissingEstimationData && !currentChartData)) && isDailyProductionPerformance
  "
>
  <div class="is-printable">
    <div class="d-flex flex-row">
      <scw-mat-informative-modal [modalHeader]="informativeModalHeader" [data]="informativeModalData">
        <scw-mat-button class="modal-icon" type="ghost" [iconButton]="true">
          <em class="fas fa-lg fa-info-circle"></em>
        </scw-mat-button>
      </scw-mat-informative-modal>
      <span class="d-flex justify-content-center chart-title">{{ 'performanceChart.chartTitle' | translate }}</span>
    </div>
    <div *ngIf="useGroupByAndChartMode" class="row m-b-10">
      <div class="col-10">
        <scw-mat-button-group
          [disabled]="(!currentChartData || isPerformanceChartLoading) && !isDailyProductionPerformance"
          [buttons]="performanceChartModeButtons"
          [inputModel]="chartMode"
          (inputModelChange)="onModeChanged($event)"
        ></scw-mat-button-group>
      </div>
      <div class="col-2 right-grp justify-content-end group-by-select-button">
        <scw-mat-select
          [disabled]="(!currentChartData || isPerformanceChartLoading) && !isDailyProductionPerformance"
          [clearAll]="false"
          [data]="chartGroupByData"
          [(inputModel)]="chartGroupBy"
          (inputModelChange)="updateChartDataWithGroupBy($event)"
          [autoPosition]="false"
        ></scw-mat-select>
      </div>
    </div>

    <div
      [hidden]="isDailyProductionPerformance && isChartMissingEstimationData"
      [ngClass]="chartMode === 'performance_based' ? 'hide-text' : 'show-text'"
    >
      <div class="col-12">
        {{
          !((!currentChartData || isPerformanceChartLoading) && !isDailyProductionPerformance)
            ? formattedStartDate + ' - ' + formattedEndDate
            : '-'
        }}
      </div>
      <div class="col-12 flex-row chart-input">
        <span
          class="chart-input-text">{{ isDailyProductionPerformance ? ('performanceChart.oeeTarget' | translate) : ('performanceChart.oeeTargetPerformanceDeepDive' | translate) }}</span>
        <scw-mat-input
          #effectiveRunTimeProduction
          id="effective-run-time-production-input"
          [disabled]="!currentChartData || isPerformanceChartLoading"
          [rules]="effectiveRunTimeProductionPercentageRules"
          [type]="'text'"
          [(inputModel)]="effectiveRunTimeProductionPercentage"
          [noPadding]="true"
          (inputModelChange)="onInputModelChange()"
          (isValidChange)="inputIsValid($event)"
        ></scw-mat-input>
      </div>
    </div>
    <ng-container *ngIf="!isPerformanceChartLoading || isDailyProductionPerformance; else loading">
      <ng-template [ngIf]="!currentChartData">
        <div class="col-12">
          {{ 'datatable.noData' | translate }}
        </div>
      </ng-template>
      <div class="w-100" *ngIf="currentChartData">
        <div
          echarts
          (chartInit)="onChartInit($event)"
          [options]="performanceChartOptions"
          [style.height]="'480px'"
        ></div>
      </div>
    </ng-container>
  </div>
</app-card>
