import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScwMatFormModule } from '../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { ScwMatButtonModule } from '../../shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatModalSeparatorModule } from '../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { ESelectionMode, IWorkOrderCardData } from './select-work-order-line-modal.model';
import { HttpClientModule } from '@angular/common/http';
import {
  ECardAssignmentObjectTypeEnum,
  IRfidLine,
  IRfidLineMapped,
  IRfidWorkOrder,
  IRfidWorkOrderMapped,
} from '../../shared/service/rfid-card-reader/rfid-card-reader.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import * as HomeActions from '../../store/home/home.actions';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { RfidCardReaderService } from '../../shared/service/rfid-card-reader/rfid-card-reader.service';
import { ToastHelperService } from '../../shared/service/toast/toast.helper.service';
import * as AppActions from '../../store/app/actions';
import { ActivityTypes } from '../../shared/model/enum/activity-types';
import * as _ from 'lodash';

@Component({
  selector: 'scw-select-work-order-line-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ScwMatButtonModule,
    ScwMatFormModule,
    ScwMatModalSeparatorModule,
    HttpClientModule,
  ],
  templateUrl: './select-work-order-line-modal.component.html',
  styleUrls: ['./select-work-order-line-modal.component.scss'],
})
export class SelectWorkOrderLineModalComponent implements OnInit, OnDestroy {
  public mode: ESelectionMode = ESelectionMode.WO_SELECTION;
  public modalData: IWorkOrderCardData;
  public possibleWorkOrders: IRfidWorkOrderMapped[] = [];
  public possibleLines: IRfidLineMapped[] = [];
  public selectedWorkOrderId: number;
  public selectedLineId: number;
  public singleWorkOrder: IRfidWorkOrder = null;
  public singleLine: IRfidLine = null;
  public isLoading: boolean = false;
  public showBackToQuestionButton: boolean = false;

  protected readonly cardAssignmentObjectTypeEnum = ECardAssignmentObjectTypeEnum;
  protected readonly ESelectionMode = ESelectionMode;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly rfidCardReaderService: RfidCardReaderService,
    private readonly toastHelperService: ToastHelperService,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.modeInit();

    this.subscriptions.push(
      this.actionsSubject.pipe(ofType(HomeActions.HomeActionTypes.ChangeActivityCompleted)).subscribe(() => {
        this.goToLine();
      }),
    );
  }

  public onFormSubmit(): void {
    switch (this.mode) {
      case ESelectionMode.GOTO_LINE:
        this.goToLine();
        break;
      case ESelectionMode.WO_SELECTION:
        this.changeActivity();
        break;
    }
  }

  public goToLine(): void {
    if (!this.selectedLineId) {
      return;
    }

    this.isLoading = true;
    this.store.dispatch(new AppActions.ShowLoader());

    const [{ siteId }] = this.modalData.lines;
    const lineId: number = this.selectedLineId;

    this.rfidCardReaderService.goToHome(siteId, lineId);
  }

  public changeActivity(): void {
    if (!this.selectedLineId || !this.selectedWorkOrderId) {
      return;
    }

    const [{ siteId }] = this.modalData.lines;
    const lineId: number = this.selectedLineId;
    const activityId: number | null = this.getSelectedLinesFirstRunActivity();
    const workOrderId: number = this.selectedWorkOrderId;

    if (!activityId) {
      this.toastHelperService.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('modal.selectWorkOrderLine.toast.noRunTimeActivity'),
      );

      return;
    }

    this.store.dispatch(
      new HomeActions.ChangeActivity(
        {
          siteId,
          lineId,
          activityId,
          workOrderId,
        },
        false,
      ),
    );
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public onClickWorkOrder(workOrder: IRfidWorkOrderMapped): void {
    if (!workOrder.selectable) {
      return;
    }

    this.selectedWorkOrderId = workOrder.id;
  }

  public onClickLine(line: IRfidLine): void {
    this.selectedLineId = line.id;
  }

  public changeMode(mode: ESelectionMode): void {
    if (mode === ESelectionMode.GOTO_LINE && this.singleLine) {
      this.goToLine();
    }

    this.showBackToQuestionButton = this.mode === ESelectionMode.QUESTION;

    if (this.showBackToQuestionButton) {
      this.selectedLineId = this.selectedWorkOrderId = undefined;
    }

    this.mode = mode;

    this.setWorkOrderAsSelectedWorkOrderIfAvailable();
    this.setLineAsSelectedLineIfAvailable();

    this.possibleLines = this.getPossibleLines();
    this.possibleLines = _.orderBy(this.possibleLines, 'selectable', 'desc');
    this.possibleWorkOrders = _.orderBy(this.possibleWorkOrders, 'selectable', 'desc');
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  private setWorkOrderAsSelectedWorkOrderIfAvailable(): void {
    const selectableWorkOrders: IRfidWorkOrderMapped[] = this.getSelectableWorkOrders();

    this.singleWorkOrder =
      [ECardAssignmentObjectTypeEnum.JOB, ECardAssignmentObjectTypeEnum.WORK_ORDER].includes(
        this.modalData.cardAssignmentObjectType,
      ) && selectableWorkOrders.length === 1
        ? selectableWorkOrders[0]
        : null;

    if (this.singleWorkOrder) {
      this.onClickWorkOrder(this.singleWorkOrder as IRfidWorkOrderMapped);
    }
  }

  private setLineAsSelectedLineIfAvailable(): void {
    this.singleLine =
      (this.mode === ESelectionMode.GOTO_LINE || this.mode === ESelectionMode.QUESTION) &&
      this.modalData.ongoingLines.length === 1
        ? this.modalData.ongoingLines[0]
        : null;

    if (this.singleLine) {
      this.onClickLine(this.singleLine);
    }
  }

  private getSelectedLinesFirstRunActivity(): number | null {
    const selectedLine: IRfidLine = this.modalData.lines.find((line) => line.id === this.selectedLineId);
    const linesActivities: string[] = selectedLine.activityIds.split(',');

    let firstRunActivityId: number | null = null;

    for (const lineActivity of linesActivities) {
      firstRunActivityId =
        this.modalData.activities.find(
          (activity) => activity.id.toString() === lineActivity && activity.activityType === ActivityTypes.RUN_TIME,
        )?.id ?? null;

      if (firstRunActivityId) {
        break;
      }
    }

    return firstRunActivityId;
  }

  private modeInit(): void {
    this.possibleWorkOrders = this.modalData.workOrders.map((workOrder: IRfidWorkOrder) => ({
      ...workOrder,
      selectable: workOrder.completed !== 1 && workOrder.hasOnGoingActivity !== 1,
    }));

    const selectableWorkOrders: IRfidWorkOrder[] = this.getSelectableWorkOrders();
    let mode: ESelectionMode = ESelectionMode.WO_SELECTION;

    if (selectableWorkOrders.length > 0 && this.modalData.ongoingLines.length > 0) {
      mode = ESelectionMode.QUESTION;
    } else if (selectableWorkOrders.length === 0 && this.modalData.ongoingLines.length > 0) {
      mode = ESelectionMode.GOTO_LINE;
    }

    this.changeMode(mode);
  }

  private getSelectableWorkOrders(): IRfidWorkOrderMapped[] {
    return this.possibleWorkOrders.filter((workOrder: IRfidWorkOrderMapped) => workOrder.selectable);
  }

  private getPossibleLines(): IRfidLineMapped[] {
    let output: IRfidLineMapped[] = [];

    switch (this.mode) {
      case ESelectionMode.WO_SELECTION:
        output = this.modalData.lines.map((line: IRfidLine) => ({
          ...line,
          selectable: !line.workOrderId,
        }));

        break;
      case ESelectionMode.GOTO_LINE:
        const selectableWorkOrders: number[] = this.possibleWorkOrders.map((workOrder) => workOrder.id);

        output = this.modalData.lines.map((line: IRfidLine) => ({
          ...line,
          selectable: selectableWorkOrders.includes(line.workOrderId),
        }));
        break;
    }

    return output;
  }
}
