import { Pipe, PipeTransform } from '@angular/core';
import { AsyncFunction, IDynamicComponentDirectiveContext } from '../../model/interface/generic.model';
import { Observable, Subscribable } from 'rxjs';

@Pipe({
  name: 'datatableCellFormatter',
})
export class DatatableCellFormatterPipe<T, Y, Z extends AsyncFunction<IDynamicComponentDirectiveContext>>
  implements PipeTransform
{
  transform(
    cellData: T,
    rowData: Y,
    formatter: Z,
  ):
    | Observable<IDynamicComponentDirectiveContext>
    | Subscribable<IDynamicComponentDirectiveContext>
    | Promise<IDynamicComponentDirectiveContext>
    | null
    | undefined {
    return formatter(cellData, rowData);
  }
}
