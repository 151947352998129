import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IBurst, IBurstRow } from '../../../../view/settings/opc-tag-automation-settings/burst/burst.model';

@Injectable({
  providedIn: 'root',
})
export class BurstService {
  private readonly BURST_URL = `${this.baseUrl}/opc-tag-automation-settings/burst`;

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<IBurst>> {
    return this.http.get<GetManyResponseInterface<IBurst>>(this.BURST_URL, {
      params,
    });
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<IBurst>> {
    return this.http.get<BaseOneResponseInterface<IBurst>>(`${this.BURST_URL}/${recordId}`, {
      params,
    });
  }

  public addOne(record: IBurstRow): Observable<BaseOneResponseInterface<IBurst>> {
    return this.http.post<BaseOneResponseInterface<IBurst>>(`${this.BURST_URL}`, record);
  }

  public editOne(recordId: number, record: IBurstRow): Observable<BaseOneResponseInterface<IBurst>> {
    return this.http.patch<BaseOneResponseInterface<IBurst>>(`${this.BURST_URL}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          burst: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.BURST_URL}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.BURST_URL}/${recordIds[0]}`);
  }
}
