import { TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EFilterDropdownElements, FilterConditionInterface } from '../../../../store/filter/filter.model';
import { FilterDataObjectTypes } from '../filter.class';
import { SqlOperators } from '../advanced-filter/advanced-filter.model';

export interface DropdownSettingsInterface extends CommonSettingsInterface {
  singleSelection?: boolean;
  selectAllText?: string;
  unSelectAllText?: string;
  filterSelectAllText?: string;
  filterUnSelectAllText?: string;
  searchBy?: string[];
  maxHeight?: number;
  badgeShowLimit?: number;
  classes?: string;
  limitSelection?: number;
  searchPlaceholderText?: string;
  groupBy?: string;
  noDataLabel?: string;
  searchAutofocus?: boolean;
  lazyLoading?: boolean;
  labelKey?: string;
  primaryKey?: string;
  position?: string;
  loading?: boolean;
  selectGroup?: boolean;
  addNewItemOnFilter?: boolean;
  addNewButtonText?: string;
  escapeToClose?: boolean;
  autoPosition?: boolean;
  clearAll?: boolean;
  defaultSelection?: DefaultSelectionInterface;
  enableServerSideSearch?: boolean;
  searchProps?: FilterConditionInterface[];
  server?: ApiServer;
  tagToBody?: boolean;
  searchMinimumCharacter?: number;
  clearSearchOnOpen?: boolean;
  changeSelectedSubject?: Subject<IDropdownChangeSelected>;
  selectAll?: boolean;
  enableFilterSelectAll?: boolean;
  dependProperties?: string[];
  dropdownDepends?: IDropdownDepend[];
  staticConditions?: IDropdownStaticCondition[];
  isStaticDropdown?: boolean;
  getInitData?: boolean;
  enableSearchFilter?: boolean;
  showCheckbox?: boolean;
  additionalLiveSearchProperties?: string[];
  itemTemplate$?: Observable<TemplateRef<unknown>>;
  showItemTemplateInSelectedValue?: boolean;
  resetSelectionWidth?: boolean;
}

export interface DefaultDropdownSettingsInterface extends DropdownSettingsInterface {
  enableSearchFilter?: boolean;
  enableCheckAll?: boolean;
  showCheckbox?: boolean;
}

export interface CommonSettingsInterface {
  isRequired: boolean;
  disabled?: boolean;
  text?: string;
  customOperations?: Function[];
}

export interface DefaultSelectionInterface {
  key: string;
  values: (string | number)[];
  initialDataToAddIntoDropdown?: FilterDataObjectTypes[];
  overrideData?: boolean;
  initialData?: FilterDataObjectTypes[];
}

export enum ApiServer {
  Phreeze = 'phreeze',
  NestJS = 'nest',
}

export type DependencyType =
  | {
      elementId: string;
      elementIdKey: string;
      searchBy: string;
      splitBy?: string;
      serverSideFilter?: true;
      server: ApiServer;
      isDataLocatedAtParent?: boolean;
      dataPathAtParent?: string;
    }
  | {
      elementId: string;
      elementIdKey: string;
      searchBy: string;
      splitBy?: string;
      serverSideFilter?: false | null;
      server?: never;
      isDataLocatedAtParent?: boolean;
      dataPathAtParent?: string;
    };

export interface IDropdownDepend {
  property: string;
  parentElementId: EFilterDropdownElements;
  parentProperty: string;
  conditionType: SqlOperators;
  isDataLocatedAtParent?: boolean;
  isDataLocatedAtCurrent?: boolean;
  isAllSelectedAndNoneSelectedDifferent?: boolean;
}

export interface IDropdownStaticCondition {
  property: string;
  conditionType: SqlOperators;
  value: string | number | any;
}

export interface DropdownFilterConfiguration {
  elementId: string;
  filteredElementId: string;
  filterListener: Subject<any>;
  submit: boolean;
  filterData?: any;
  isDataLocatedAtParent?: boolean;
  dataPathAtParent?: string;
}

export interface LazyLoadingInterface {
  startIndex: number;
  endIndex: number;
  scrollStartPosition: number;
  scrollEndPosition: number;
  maxScrollPosition: number;
  startIndexWithBuffer: number;
  endIndexWithBuffer: number;
}

export interface IDefaultDropdownSelection {
  [key: string]: {
    values: number[] | string[];
    key: string;
  };
}

export interface IDropdownChangeSelected {
  key: string | number;
  values: number[] | string[];
}

export interface ILazyLoadingDropdown {
  data: FilterDataObjectTypes[];
  totalCount: number;
  allowNewRequest: boolean;
  isLoading: boolean;
}

export interface ILazyLoadingConf {
  totalCount: number;
  currentPage: number;
  isDropdownOpened: boolean;
  allowNewRequest: boolean;
}
