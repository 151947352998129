import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EquipmentTaskOutputInterface,
  IEquipmentGroupTreeItem,
  ITaskGroupTreeItem,
  ITaskTreeBreadcrumb,
  ITaskTreeNode,
} from '../../../../store/equipment-task/equipment-task.model';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as EquipmentTaskActions from '../../../../store/equipment-task/equipment-task.actions';
import { IRootAddress } from '../../../../store/settings/tree-nodes/tree-nodes.model';
import { ScwMatButtonGroupButtons } from '../../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { ENodeType, ETaskListMode, INodeGroup } from './task-selection.model';
import * as _ from 'lodash';
import { ELoadStatus } from '../../../../../constants.model';
import { EquipmentTaskService } from '../../../../store/equipment-task/equipment-task.service';
import { ToastHelperService } from '../../../service/toast/toast.helper.service';
import { provideComponentStore } from '@ngrx/component-store';
import { TaskSelectionComponentStore } from './task-selection.component.store';

@Component({
  selector: 'task-selection',
  templateUrl: './task-selection.component.html',
  styleUrls: ['./task-selection.component.scss'],
  providers: [provideComponentStore(TaskSelectionComponentStore)],
})
export class TaskSelectionComponent implements OnInit, OnDestroy {
  @Input() rootAddress: IRootAddress;
  @Input() ignoreHidden: boolean | undefined;
  @Output() selectedTask = new EventEmitter<EquipmentTaskOutputInterface>();

  public nodeGroups: INodeGroup[] = [];
  public breadcrumbItems: ITaskTreeNode[] = [];
  public searchText: string = null;
  public listMode: ETaskListMode = ETaskListMode.TASK_BASED;
  public selectedEquipmentId: number;

  public isLoaded$: Observable<boolean> = this.store
    .select('equipmentTask', 'nodesDataStatus')
    .pipe(map((status) => status === ELoadStatus.Success));
  public listModeButtons: ScwMatButtonGroupButtons[] = [
    {
      value: ETaskListMode.EQUIPMENT_BASED,
      text: this.translate.instant('main.equipmentTaskSelection.equipmentBased'),
    },
    {
      value: ETaskListMode.TASK_BASED,
      text: this.translate.instant('main.equipmentTaskSelection.taskBased'),
    },
  ];
  public readonly nodeIcons: Record<string, string> = {
    [ENodeType.Equipment]: 'fas fa-cube',
    [ENodeType.Folder]: 'fas fa-folder',
    [ENodeType.TaskGroup]: 'fas fa-layer-group',
  };

  protected selectedItem: ITaskTreeNode;
  protected readonly ENodeType = ENodeType;
  protected readonly ETaskListMode = ETaskListMode;
  private nodes$: ITaskTreeNode[] = [];
  private searchTimeout: ReturnType<typeof setTimeout>;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly toastHelperService: ToastHelperService,
    private readonly store: Store<OeeAppState>,
    private readonly storeActions: ActionsSubject,
    private readonly componentStore: TaskSelectionComponentStore,
  ) {}

  public ngOnInit(): void {
    this.componentStore.getLineData({ lineId: this.rootAddress.lineId });

    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(EquipmentTaskActions.EquipmentTaskActionTypes.NodeDataLoaded))
        .subscribe((response: EquipmentTaskActions.NodeDataLoaded) => {
          const items: ITaskTreeNode[] = response.payload;

          if (items.length === 1 && items[0].type === ENodeType.Task && !this.searchText) {
            this.applyAutomaticSelection(items[0].customProperties, false);
            return;
          }

          this.breadcrumbItems = [...this.breadcrumbItems, ...response.breadcrumb];

          this.nodes$ = items;

          this.renderNodeList(this.nodes$);
        }),
      this.componentStore.lineData$.subscribe((lineData$) => {
        if (!lineData$.lineDataLoading && lineData$.lineDataLoaded) {
          this.listMode = lineData$.lineData.data.isEquipmentBasedModeForTaskSelect
            ? ETaskListMode.EQUIPMENT_BASED
            : ETaskListMode.TASK_BASED;
          this.getNodes(null);
        }
      }),
    );
  }

  public onNodeItemClicked(selectedNode: ITaskTreeNode): void {
    this.searchText = null;
    this.breadcrumbItems.push(selectedNode);

    switch (selectedNode.type) {
      case ENodeType.Task:
        this.selectedTask.emit(selectedNode.customProperties);
        break;

      case ENodeType.TaskGroup:
        const equipments: ITaskTreeNode[] = this.nodes$.filter(
          (node: ITaskTreeNode) => node.title === selectedNode.title && node.type === ENodeType.Task,
        );

        this.renderNodeList(equipments, selectedNode);
        break;

      case ENodeType.Equipment:
        this.selectedEquipmentId = selectedNode.id;
        this.getNodes(null, undefined);
        break;

      case ENodeType.Folder:
        this.getNodes(selectedNode.id, undefined);
        break;
    }
  }

  public onBreadcrumbItemClicked(selectedNode: ITaskTreeNode, index: number): void {
    const latestSelectedNode: ITaskTreeBreadcrumb | undefined = _.last(this.breadcrumbItems);

    if (latestSelectedNode && (!('id' in selectedNode) || latestSelectedNode.id === selectedNode.id)) {
      return;
    }

    this.breadcrumbItems.splice(index, this.breadcrumbItems.length);
    this.onNodeItemClicked(selectedNode);
  }

  public onSearched(): void {
    if (this.searchText?.length !== 0 && this.searchText?.trim().length < 3) {
      return;
    }

    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.listMode = this.searchText ? ETaskListMode.TASK_BASED : this.listMode;
      this.selectedEquipmentId = undefined;
      this.getNodes(this.searchText ? undefined : null, true, false);
    }, 600);
  }

  public onListModeChanged(mode: ETaskListMode): void {
    this.listMode = mode;
    this.onRootIconClicked();
  }

  public onRootIconClicked(): void {
    this.searchText = null;
    this.selectedEquipmentId = undefined;
    this.getNodes(null, true);
  }

  public getNodes(
    taskTreeParentId?: number | null,
    clearBreadcrumb: boolean = false,
    skipSingleOption: boolean = true,
  ): void {
    if (clearBreadcrumb) {
      this.breadcrumbItems = [];
    }

    this.store.dispatch(
      new EquipmentTaskActions.NodeDataLoad(
        this.rootAddress,
        this.listMode,
        this.selectedEquipmentId,
        this.searchText,
        taskTreeParentId,
        skipSingleOption,
        !!this.ignoreHidden,
      ),
    );
  }

  public renderNodeList(nodes: ITaskTreeNode[], selectedNode?: IEquipmentGroupTreeItem | ITaskGroupTreeItem): void {
    const formattedNodes: ITaskTreeNode[] = [];

    for (const node of nodes) {
      formattedNodes.push({
        ...node,
        ...{
          title:
            node.title === null && node.type === ENodeType.Equipment
              ? this.translate.instant('main.equipmentTaskSelection.noEquipment')
              : node.title,
        },
      });
    }

    this.nodeGroups = EquipmentTaskService.getNodeGroups(formattedNodes, this.listMode, selectedNode);
  }

  private applyAutomaticSelection(task: EquipmentTaskOutputInterface, isActivityLevelSelection: boolean = true): void {
    this.selectedTask.emit(task);

    const message: string = isActivityLevelSelection
      ? 'main.activityButtons.automaticTaskSelection'
      : 'main.taskButtons.automaticallySelected';

    this.toastHelperService.showToastMessage(
      true,
      this.translate.instant('general.success'),
      this.translate.instant(message),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
