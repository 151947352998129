import * as ChangeStationActivityActions from './change-station-activity.actions';
import { ChangeStationActivityState } from './change-station-activity.model';

export const changeActivityState: ChangeStationActivityState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getChangeStationActivityLoading: false,
  getChangeStationActivityLoaded: false,
  changeStationActivity: null,
  addChangeStationActivityLoading: false,
  addChangeStationActivityLoaded: false,
  editChangeStationActivityLoading: false,
  editChangeStationActivityLoaded: false,
  deleteChangeStationActivityLoading: false,
  deleteChangeStationActivityLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  stationsDataLoading: false,
  stationsDataLoaded: false,
  stationsData: [],
};

export function changeStationActivityReducer(
  state: ChangeStationActivityState = changeActivityState,
  action: ChangeStationActivityActions.ChangeStationActivityActions,
): ChangeStationActivityState {
  switch (action.type) {
    case ChangeStationActivityActions.ActionTypes.CHANGE_STATION_ACTIVITY_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case ChangeStationActivityActions.ActionTypes.CHANGE_STATION_ACTIVITY_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case ChangeStationActivityActions.ActionTypes.GET_CHANGE_STATION_ACTIVITY:
      return {
        ...state,
        ...{
          getChangeStationActivityLoading: true,
          getChangeStationActivityLoaded: false,
        },
      };
    case ChangeStationActivityActions.ActionTypes.GET_CHANGE_STATION_ACTIVITY_COMPLETED:
      return {
        ...state,
        ...{
          changeActivity: action.payload.data,
          getChangeStationActivityLoading: false,
          getChangeStationActivityLoaded: true,
        },
      };
    case ChangeStationActivityActions.ActionTypes.ADD_CHANGE_STATION_ACTIVITY:
      return {
        ...state,
        addChangeStationActivityLoading: true,
        addChangeStationActivityLoaded: false,
      };
    case ChangeStationActivityActions.ActionTypes.ADD_CHANGE_STATION_ACTIVITY_COMPLETED:
      return {
        ...state,
        addChangeStationActivityLoading: false,
        addChangeStationActivityLoaded: true,
      };
    case ChangeStationActivityActions.ActionTypes.EDIT_CHANGE_STATION_ACTIVITY:
      return {
        ...state,
        editChangeStationActivityLoading: true,
        editChangeStationActivityLoaded: false,
      };
    case ChangeStationActivityActions.ActionTypes.EDIT_CHANGE_STATION_ACTIVITY_COMPLETED:
      return {
        ...state,
        editChangeStationActivityLoading: false,
        editChangeStationActivityLoaded: true,
      };

    case ChangeStationActivityActions.ActionTypes.DELETE_CHANGE_STATION_ACTIVITY:
      return {
        ...state,
        deleteChangeStationActivityLoading: true,
        deleteChangeStationActivityLoaded: false,
      };
    case ChangeStationActivityActions.ActionTypes.DELETE_CHANGE_STATION_ACTIVITY_COMPLETED:
      return {
        ...state,
        deleteChangeStationActivityLoading: false,
        deleteChangeStationActivityLoaded: true,
      };
    case ChangeStationActivityActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case ChangeStationActivityActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case ChangeStationActivityActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case ChangeStationActivityActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case ChangeStationActivityActions.ActionTypes.STATIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          stationsDataLoading: true,
          stationsDataLoaded: false,
        },
      };
    case ChangeStationActivityActions.ActionTypes.STATIONS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          stationsData: action.payload.data,
          stationsDataLoading: false,
          stationsDataLoaded: true,
        },
      };
    case ChangeStationActivityActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
