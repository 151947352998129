import * as HeartbeatActions from './heartbeat.actions';
import { HeartbeatState } from './heartbeat.model';

export const heartbeatState: HeartbeatState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getHeartbeatLoading: false,
  getHeartbeatLoaded: false,
  heartbeat: null,
  addHeartbeatLoading: false,
  addHeartbeatLoaded: false,
  editHeartbeatLoading: false,
  editHeartbeatLoaded: false,
  deleteHeartbeatsLoading: false,
  deleteHeartbeatsLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  mailGroupsDataLoading: false,
  mailGroupsDataLoaded: false,
  mailGroupsData: [],
  mailTemplatesDataLoading: false,
  mailTemplatesDataLoaded: false,
  mailTemplatesData: [],
};

export function heartbeatReducer(
  state: HeartbeatState = heartbeatState,
  action: HeartbeatActions.HeartbeatActions,
): HeartbeatState {
  switch (action.type) {
    case HeartbeatActions.ActionTypes.HEARTBEATS_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.HEARTBEATS_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case HeartbeatActions.ActionTypes.GET_HEARTBEAT:
      return {
        ...state,
        ...{
          getHeartbeatLoading: true,
          getHeartbeatLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.GET_HEARTBEAT_COMPLETED:
      return {
        ...state,
        ...{
          heartbeat: action.payload.data,
          getHeartbeatLoading: false,
          getHeartbeatLoaded: true,
        },
      };
    case HeartbeatActions.ActionTypes.ADD_HEARTBEAT:
      return {
        ...state,
        addHeartbeatLoading: true,
        addHeartbeatLoaded: false,
      };
    case HeartbeatActions.ActionTypes.ADD_HEARTBEAT_COMPLETED:
      return {
        ...state,
        addHeartbeatLoading: false,
        addHeartbeatLoaded: true,
      };
    case HeartbeatActions.ActionTypes.EDIT_HEARTBEAT:
      return {
        ...state,
        editHeartbeatLoading: true,
        editHeartbeatLoaded: false,
      };
    case HeartbeatActions.ActionTypes.EDIT_HEARTBEAT_COMPLETED:
      return {
        ...state,
        editHeartbeatLoading: false,
        editHeartbeatLoaded: true,
      };

    case HeartbeatActions.ActionTypes.DELETE_HEARTBEATS:
      return {
        ...state,
        deleteHeartbeatsLoading: true,
        deleteHeartbeatsLoaded: false,
      };
    case HeartbeatActions.ActionTypes.DELETE_HEARTBEATS_COMPLETED:
      return {
        ...state,
        deleteHeartbeatsLoading: false,
        deleteHeartbeatsLoaded: true,
      };
    case HeartbeatActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case HeartbeatActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };

    case HeartbeatActions.ActionTypes.MAIL_GROUPS_DATA_LOADING:
      return {
        ...state,
        ...{
          mailGroupsDataLoading: true,
          mailGroupsDataLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.MAIL_GROUPS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          mailGroupsData: action.payload.data,
          mailGroupsDataLoading: false,
          mailGroupsDataLoaded: true,
        },
      };
    case HeartbeatActions.ActionTypes.MAIL_TEMPLATES_DATA_LOADING:
      return {
        ...state,
        ...{
          mailTemplatesDataLoading: true,
          mailTemplatesDataLoaded: false,
        },
      };
    case HeartbeatActions.ActionTypes.MAIL_TEMPLATES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          mailTemplatesData: action.payload.data,
          mailTemplatesDataLoading: false,
          mailTemplatesDataLoaded: true,
        },
      };

    case HeartbeatActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
