import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  DropdownOptionsInterface,
  IPlanManagement,
  ISimplifiedSiteAssignment,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityQueryParams,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  LineAvailabilityStateInterface,
  PlanInterface,
} from '../../../store/line-availability/line-availability.model';
import { ActionsSubject } from '@ngrx/store';
import { LineAvailabilityClass } from '../Scheduler/LineAvailability.class';
import {
  IPlanManagementForm,
  OnModalActionInterface,
} from '../../../view/line-availability/line-availability.model';
import { ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { SitesInterface } from '../../../store/work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import { HelperService } from '../../service/helper.service';
import { smallModal } from '../../../../constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StdTableItem } from '../../../view/settings/sites/sites.model';
import * as LineAvailabilityActions from '../../../store/line-availability/line-availability.actions';
import { BaseCrudResponse } from '../../model/interface/crud-response-interface.model';
import { ScwModalSize } from '../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { DatatableOutputParameterInterface } from '../datatable/datatable.model';
import { BorderColors } from '../scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { ScwMatSelectRule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ETableModalTypes } from '../../service/datatable/datatable.model';
import { FilterSiteState } from '../../../store/filter/site/site.reducer';
import { IInitialSiteLine } from '../../model/interface/generic.model';

@OnDestroyDecorator
@Component({
  selector: 'app-plan-management',
  templateUrl: './plan-management.component.html',
  styleUrls: [
    './plan-management.component.scss',
    '../../../../scss/custom.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PlanManagementComponent extends LineAvailabilityClass implements OnInit, OnDestroy {
  @ViewChild('bulk_delete_result_modal', { static: false }) BULK_DELETE_RESULT_MODAL_TEMPLATE: TemplateRef<void>;
  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  public plans$: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>;
  public planData$: StdTableItem<LineAvailabilityPlanDataInterface>[];
  @Output() onModalActionEmitter = new EventEmitter<OnModalActionInterface>();
  private selectedPlan$: LineAvailabilityPlanDataInterface;
  public sites$: SitesInterface[];
  public indexedSites: string[];
  private readonly storeSubscriptions: Subscription[] = [];
  public selectedPlans: number[] = [];
  public selectedPlan: IPlanManagement;
  public ETableModalTypes = ETableModalTypes;
  public openedModalType: ETableModalTypes;
  public addEditModalRef: NgbModalRef;
  public bulkErrorModalRef: NgbModalRef;
  public planManagementFormRules = this.getFormRules();
  public planManagementForm: IPlanManagementForm;
  public crudModalRef: NgbModalRef;
  public successfullyDeletedPlanCount: number = 0;
  public searchBoxText: string = '';
  public queryParams: LineAvailabilityQueryParams = {
    page: 1,
    pageSize: 1000,
    search: null,
  };
  public active: BorderColors = BorderColors.active;
  public inActive: BorderColors = BorderColors.deactive;
  public isOfficialData: DropdownOptionsInterface[] = [
    { id: 1, name: this.translate.instant('general.active') },
    { id: 0, name: this.translate.instant('general.inactive') },
  ];
  public initialPlanManagementForm: IPlanManagementForm = {
    name: { isChecked: false, value: null },
    siteIds: { isChecked: false, value: null },
    isOfficial: { isChecked: false, value: null },
  };
  public failedBulkDeletePlanNames: string[] = [];
  private selectedSiteIdsNumber: ISimplifiedSiteAssignment[] = [];

  constructor(
    private readonly planItemAction: ActionsSubject,
    public readonly checkBoxElementRef: ElementRef,
    public readonly helperService: HelperService,
    private readonly ngbModal: NgbModal,
  ) {
    super();
    this.addButtonStyle = {
      buttonClass: this.addButtonCls,
      disabled: false,
      iconClass: this.addButtonIconCls,
      text: this.translate.instant('lineAvailability.plan.add'),
    };
    this.tableHeaders = [
      {
        value: null,
        name: '',
        selected: true,
        sortable: false,
        width: '39px',
      },
      {
        value: 'sites',
        name: this.translate.instant('general.site'),
        sortable: false,
      },
      {
        value: 'name',
        name: this.translate.instant('lineAvailability.plan.planTable.planName'),
      },
      {
        value: 'isOfficial',
        name: this.translate.instant('lineAvailability.plan.planTable.status'),
      },
      {
        sortable: false,
        value: 'actionButton',
        name: '',
        width: '200px',
      },
    ];
  }

  ngOnInit(): void {
    this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityPlansData(...HelperService.argumentClone(this.tableQuery)));

    this.storeSubscriptions.push(
      this.store.select('lineAvailabilityScenarioStore').subscribe((state: LineAvailabilityStateInterface) => {
        this.isLoading$ = state.plansLoading;
        this.plans$ = HelperService.cloneDeep(state.plans);
        this.planData$ = HelperService.cloneDeep(state.plans.data) as StdTableItem<LineAvailabilityPlanDataInterface>[];
        this.selectedPlan$ = HelperService.cloneDeep(state.selectedPlan);

        this.helperService.closeModalsOnMissingIdentifier(this.selectedPlan, this.openedModalType, [
          this.crudModalRef,
          this.addEditModalRef,
        ]);
      }),

      this.planItemAction
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.DeletePlanCompleted))
        .subscribe((payload: { id: number }) => {
          if (this.selectedPlan$?.id === payload?.id) {
            this.store.dispatch(new LineAvailabilityActions.ClearSelectedPlan());
          }
          this.selectedPlans = [];
          this.helperService.selectOrUnselectAllCheckboxes(false, this.checkBoxElementRef, 'plan', this.plans$.data);
          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
        }),

      this.planItemAction
        .pipe(
          ofType(
            LineAvailabilityActions.LineAvailabilityActionTypes.CreatePlanLoaded,
            LineAvailabilityActions.LineAvailabilityActionTypes.EditPlanLoaded,
          ),
        )
        .subscribe((payload: LineAvailabilityActions.CreatePlanLoaded | LineAvailabilityActions.EditPlanLoaded) => {
          this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityPlansData(...HelperService.argumentClone(this.tableQuery)));
          this.selectedPlans = [];

          if (this.openedModalType === ETableModalTypes.edit) {
            this.helperService.selectOrUnselectAllCheckboxes(false, this.checkBoxElementRef, 'plan', this.plans$.data);
          }

          if (this.addEditModalRef) {
            this.addEditModalRef.dismiss();
          }

          if (payload.type === LineAvailabilityActions.LineAvailabilityActionTypes.EditPlanLoaded) {
            const haveAllSiteAssignmentsSaved = _.isEqual(
              _.map(this.selectedSiteIdsNumber, 'siteId').sort(),
              _.map(HelperService.cloneDeep(payload.payload.schedulerShiftPlansSiteAssignment), 'siteId').sort(),
            );

            if (!haveAllSiteAssignmentsSaved) {
              return;
            }
          }

          this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
        }),
      this.planItemAction
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.DeletePlansCompleted))
        .subscribe((objectData: { response: { data: BaseCrudResponse[] } }) => {
          const data: BaseCrudResponse[] = HelperService.cloneDeep(objectData.response.data);
          const checkedCostFactors: LineAvailabilityPlanDataInterface[] = this.planData$.filter(
            (costFactor: StdTableItem<LineAvailabilityPlanDataInterface>) => costFactor.isChecked,
          );

          if (data.some((response: BaseCrudResponse) => !response.success)) {
            this.successfullyDeletedPlanCount = data.filter((response: BaseCrudResponse) => response.success).length;

            this.failedBulkDeletePlanNames = data
              .map((response: BaseCrudResponse, index: number) => {
                return { ...response, index };
              })
              .filter((response: BaseCrudResponse) => !response.success)
              .map((response: BaseCrudResponse & { index: number }) => checkedCostFactors[response.index].name);

            this.bulkErrorModalRef = this.ngbModal.open(this.BULK_DELETE_RESULT_MODAL_TEMPLATE, {
              keyboard: false,
              backdrop: 'static',
              windowClass: ScwModalSize.small,
            });
          } else {
            this.helperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          }

          this.selectedPlans = [];
          this.helperService.selectOrUnselectAllCheckboxes(false, this.checkBoxElementRef, 'plan', this.plans$.data);
          this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityPlansData(...HelperService.argumentClone(this.tableQuery)));
        }),

      this.planItemAction.pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.FetchError)).subscribe(() => {
        this.planData$ = this.planData$.map((planDatum: StdTableItem<LineAvailabilityPlanDataInterface>) => {
          return {
            ...planDatum,
            isChecked: this.selectedPlans.includes(planDatum.id),
          };
        });
      }),

        this.store.select('siteFilter').subscribe((state: FilterSiteState) => {
          if (!state.isLoading && state.isLoaded) {
            this.sites$ = HelperService.cloneDeep(state.data).sort(this.helperService.dropdownOptionCompareFunction);
            const options = this.prepareSiteOptions(this.sites$);
            this.indexedSites = options.indexedSites;
          }
        }),
    );
  }

  setSelectedPlan(item: LineAvailabilityPlanDataInterface): void {
    const siteIds = _.map(item.schedulerShiftPlansSiteAssignment, 'siteId');
    const searchObject = { 'schedulerShiftsSiteAssignment.siteId': { $in: siteIds } };
    this.store.dispatch(
      new LineAvailabilityActions.LoadLineAvailabilityShiftsData(...HelperService.argumentClone(
        {
          searchObject,
          page: 1,
          pageSize: 1000,
          join: 'schedulerShiftsSiteAssignment',
        },
        true,
      )),
    );

    this.storeSubscriptions.push(
      this.planItemAction
        .pipe(ofType(LineAvailabilityActions.LineAvailabilityActionTypes.LineAvailabilityShiftsDataLoaded))
        .subscribe((response: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>) => {
          if (_.get(response, 'payload.success')) {
            this.onModalActionEmitter.emit({
              modalType: 'plan',
              data: {
                planId: item.id,
              },
            });
          }
        }),
    );
  }

  override onDataRequestHandler(params: DatatableOutputParameterInterface): void {
    this.selectedPlans = [];
    this.helperService.selectOrUnselectAllCheckboxes(false, this.checkBoxElementRef, 'plan', this.plans$.data);

    if (this.tableQuery.search.trim()) {
      params.search = this.tableQuery.search.trim();
    }

    super.onDataRequestHandler(params);
    this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityPlansData(...HelperService.argumentClone(this.tableQuery)));
  }

  private resetForm(): void {
    this.planManagementForm.siteIds.value = null;
    this.planManagementForm.isOfficial.value = null;
    this.planManagementForm.name.value = null;

    const siteLineSelection: IInitialSiteLine = this.helperService.getAddModalSiteLineSelection();
    this.planManagementForm.siteIds.value = siteLineSelection.site;
  }

  private castCheckedPlanToForm(): void {
    const schedulerShiftPlansSiteAssignments = this.selectedPlan?.schedulerShiftPlansSiteAssignment;

    if (schedulerShiftPlansSiteAssignments) {
      this.planManagementForm.siteIds.value = schedulerShiftPlansSiteAssignments.map(
        (schedulerShiftPlansSiteAssignment) =>
          this.sites$.find((data: SitesInterface) => data.id === schedulerShiftPlansSiteAssignment.siteId),
      );
    }

    this.planManagementForm.isOfficial.value = [
      this.isOfficialData.find((data: DropdownOptionsInterface) => data.id === Number(this.selectedPlan.isOfficial)),
    ];
    this.planManagementForm.name.value = this.selectedPlan.name;
  }

  public showPlanTransactionsModals(type: ETableModalTypes, templateRef: TemplateRef<any>): void {
    this.planManagementForm = this.initialPlanManagementForm;
    switch (type) {
      case ETableModalTypes.add:
        this.resetForm();
        break;
      case ETableModalTypes.edit:
        this.castCheckedPlanToForm();
        break;
    }

    this.openedModalType = type;
    this.addEditModalRef = this.ngbModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: `scw-modal-all-scrollable ${ScwModalSize.small}`,
    });
  }

  public showPlanDeleteModal(templateRef: TemplateRef<any>): void {
    this.crudModalRef = this.ngbModal.open(templateRef, smallModal);
    this.openedModalType = ETableModalTypes.delete;
  }

  public onDeleteSubmit(): void {
    const equipmentId: number = this.planData$.find((plan: StdTableItem<PlanInterface>) => plan.isChecked).id;
    this.store.dispatch(new LineAvailabilityActions.DeletePlan(equipmentId));
    this.crudModalRef.close();
  }

  public onBulkDeleteSubmit(): void {
    this.store.dispatch(new LineAvailabilityActions.DeletePlans(...HelperService.argumentClone(this.selectedPlans)));
    this.crudModalRef.close();
  }

  private getFormRules(): { name: ScwMatSelectRule[]; siteIds: ScwMatSelectRule[]; isOfficial: ScwMatSelectRule[] } {
    return {
      name: [
        {
          required: true,
        },
      ],
      isOfficial: [
        {
          required: true,
        },
      ],
      siteIds: [
        {
          required: true,
        },
      ],
    };
  }

  public onCloseModal(modal: NgbModalRef): void {
    this.resetEquipmentTypesForm();
    modal.dismiss();
  }

  private resetEquipmentTypesForm(): void {
    this.planManagementForm = _.cloneDeep(this.initialPlanManagementForm);
  }

  public onAddEditSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    const payload: PlanInterface = PlanManagementComponent.createPayloadFromForm(this.planManagementForm);

    if (this.openedModalType === ETableModalTypes.add) {
      this.store.dispatch(new LineAvailabilityActions.CreatePlan(...HelperService.argumentClone(payload)));
      return;
    }

    this.selectedSiteIdsNumber = payload.siteIds.map((data: number) => {
      return {
        siteId: Number(data),
      };
    });

    const planId: number = this.planData$.find((plan: StdTableItem<PlanInterface>) => plan.isChecked).id;
    this.store.dispatch(
      new LineAvailabilityActions.EditPlan(...HelperService.argumentClone(planId, {
        name: payload.name,
        isOfficial: payload.isOfficial,
        schedulerShiftPlansSiteAssignments: this.selectedSiteIdsNumber,
      })),
    );
  }

  private static createPayloadFromForm(planManagementForm: IPlanManagementForm): PlanInterface {
    const name: string = _.get(planManagementForm.name, 'value');

    return {
      name: name ? name.trim() : name,
      siteIds: _.get(planManagementForm.siteIds, 'value').map((site) => site.id),
      isOfficial: _.get(planManagementForm.isOfficial, 'value[0].id') === 1,
    };
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.tableQuery.search = JSON.stringify({
      $or: [{ name: { $cont: searchText.trim().toLowerCase() } }],
    });
    this.store.dispatch(new LineAvailabilityActions.LoadLineAvailabilityPlansData(...HelperService.argumentClone(this.tableQuery)));
    this.helperService.selectOrUnselectAllCheckboxes(false, this.checkBoxElementRef, 'plan', this.plans$.data);
    this.selectedPlans = [];
  }

  public onChangeTaskTableCheckboxes(event): void {
    const inputValue = Number(event.source.value);

    if (event.checked) {
      this.selectedPlans.push(inputValue);
    } else {
      const index = this.selectedPlans?.indexOf(inputValue);
      this.selectedPlans.splice(index, 1);
    }
    this.selectedPlan = _.find(this.planData$, { id: this.selectedPlans[0] });
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((value) => {
      value.unsubscribe();
    });
  }
}
