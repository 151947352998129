import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './fault-code-mapping.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';

export enum ActionTypes {
  FAULT_CODE_MAPPING_DATA_LOADING = '[FAULT_CODE_MAPPING] FAULT CODE MAPPING DATA LOADING',
  FAULT_CODE_MAPPING_DATA_LOADED = '[FAULT_CODE_MAPPING] FAULT CODE MAPPING DATA LOADED',
  GET_FAULT_CODE_MAPPING = '[FAULT_CODE_MAPPING] GET FAULT CODE MAPPING LOADING',
  GET_FAULT_CODE_MAPPING_COMPLETED = '[FAULT_CODE_MAPPING] GET FAULT CODE MAPPING COMPLETED',
  ADD_FAULT_CODE_MAPPING = '[FAULT_CODE_MAPPING] ADD FAULT CODE MAPPING',
  ADD_FAULT_CODE_MAPPING_COMPLETED = '[FAULT_CODE_MAPPING] ADD FAULT CODE MAPPING COMPLETED',
  EDIT_FAULT_CODE_MAPPING = '[FAULT_CODE_MAPPING] EDIT FAULT CODE MAPPING',
  EDIT_FAULT_CODE_MAPPING_COMPLETED = '[FAULT_CODE_MAPPING] EDIT FAULT CODE MAPPING COMPLETED',
  DELETE_FAULT_CODE_MAPPING = '[FAULT_CODE_MAPPING] DELETE FAULT CODE MAPPING',
  DELETE_FAULT_CODE_MAPPING_COMPLETED = '[FAULT_CODE_MAPPING] DELETE FAULT CODE MAPPING COMPLETED',
  SITES_DATA_LOADING = '[FAULT_CODE_MAPPING] SITES DATA LOADING',
  SITES_DATA_LOADED = '[FAULT_CODE_MAPPING] SITES DATA LOADED',
  LINES_DATA_LOADING = '[FAULT_CODE_MAPPING] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[FAULT_CODE_MAPPING] LINES DATA COMPLETED',
  ACTIVITIES_DATA_LOADING = '[FAULT_CODE_MAPPING] ACTIVITIES DATA LOADED',
  ACTIVITIES_DATA_COMPLETED = '[FAULT_CODE_MAPPING] ACTIVITIES DATA COMPLETED',
  TASKS_DATA_LOADING = '[FAULT_CODE_MAPPING] TASKS DATA LOADED',
  TASKS_DATA_COMPLETED = '[FAULT_CODE_MAPPING] TASKS DATA COMPLETED',
  FETCH_ERROR = '[FAULT_CODE_MAPPING] FETCH ERROR',
}

export class FaultCodeMappingLoading implements Action {
  readonly type = ActionTypes.FAULT_CODE_MAPPING_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class FaultCodeMappingLoaded implements Action {
  readonly type = ActionTypes.FAULT_CODE_MAPPING_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetFaultCodeMapping implements Action {
  readonly type = ActionTypes.GET_FAULT_CODE_MAPPING;
  constructor(public recordId: number) {}
}

export class GetFaultCodeMappingCompleted implements Action {
  readonly type = ActionTypes.GET_FAULT_CODE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddFaultCodeMapping implements Action {
  readonly type = ActionTypes.ADD_FAULT_CODE_MAPPING;
  constructor(public tagLogger: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddFaultCodeMappingCompleted implements Action {
  readonly type = ActionTypes.ADD_FAULT_CODE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditFaultCodeMapping implements Action {
  readonly type = ActionTypes.EDIT_FAULT_CODE_MAPPING;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditFaultCodeMappingCompleted implements Action {
  readonly type = ActionTypes.EDIT_FAULT_CODE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteFaultCodeMapping implements Action {
  readonly type = ActionTypes.DELETE_FAULT_CODE_MAPPING;
  constructor(public payload: number[]) {}
}

export class DeleteFaultCodeMappingCompleted implements Action {
  readonly type = ActionTypes.DELETE_FAULT_CODE_MAPPING_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class ActivitiesDataLoading implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_LOADING;
  constructor(public activityIds: number[]) {}
}

export class ActivitiesDataCompleted implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_COMPLETED;
  constructor(public payload: ActivityCRUDInterface[]) {}
}

export class TasksDataLoading implements Action {
  readonly type = ActionTypes.TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class TasksDataCompleted implements Action {
  readonly type = ActionTypes.TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type FaultCodeMappingActions =
  | FaultCodeMappingLoading
  | FaultCodeMappingLoaded
  | GetFaultCodeMapping
  | GetFaultCodeMappingCompleted
  | AddFaultCodeMapping
  | AddFaultCodeMappingCompleted
  | EditFaultCodeMapping
  | EditFaultCodeMappingCompleted
  | DeleteFaultCodeMapping
  | DeleteFaultCodeMappingCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | ActivitiesDataLoading
  | ActivitiesDataCompleted
  | TasksDataLoading
  | TasksDataCompleted
  | FetchError;
