import * as Actions from './custom-dashboard-content.actions';
import { ICustomDashboardContentState } from './custom-dashboard-content.model';
import { ELoadStatus } from '../../../../constants.model';

export const customDashboardContentState: ICustomDashboardContentState = {
  customDashboardContents: null,
  customDashboardContentsLoadStatus: ELoadStatus.Initial,
  unplannedDownTimeActivityColor: null,
};

export function customDashboardContentReducer(
  state: ICustomDashboardContentState = customDashboardContentState,
  action: Actions.TCustomDashboardContentActions,
): ICustomDashboardContentState {
  switch (action.type) {
    case Actions.CustomDashboardContentActionTypes.FetchCustomDashboardContents:
      return {
        ...state,
        customDashboardContentsLoadStatus: ELoadStatus.Loading,
      };
    case Actions.CustomDashboardContentActionTypes.FetchCustomDashboardContentsCompleted:
      return {
        ...state,
        customDashboardContentsLoadStatus: ELoadStatus.Success,
        customDashboardContents: action.payload.customDashboardContents,
        unplannedDownTimeActivityColor: action.payload.unplannedDownTimeActivityColor,
      };
    default:
      return state;
  }
}
