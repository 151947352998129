import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './sensor-id-mapping.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';

export enum ActionTypes {
  SENSOR_ID_MAPPING_DATA_LOADING = '[SENSOR_ID_MAPPING] SENSOR ID MAPPING DATA LOADING',
  SENSOR_ID_MAPPING_DATA_LOADED = '[SENSOR_ID_MAPPING] SENSOR ID MAPPING DATA LOADED',
  GET_SENSOR_ID_MAPPING = '[SENSOR_ID_MAPPING] GET SENSOR ID MAPPING LOADING',
  GET_SENSOR_ID_MAPPING_COMPLETED = '[SENSOR_ID_MAPPING] GET SENSOR ID MAPPING COMPLETED',
  ADD_SENSOR_ID_MAPPING = '[SENSOR_ID_MAPPING] ADD SENSOR ID MAPPING',
  ADD_SENSOR_ID_MAPPING_COMPLETED = '[SENSOR_ID_MAPPING] ADD SENSOR ID MAPPING COMPLETED',
  EDIT_SENSOR_ID_MAPPING = '[SENSOR_ID_MAPPING] EDIT SENSOR ID MAPPING',
  EDIT_SENSOR_ID_MAPPING_COMPLETED = '[SENSOR_ID_MAPPING] EDIT SENSOR ID MAPPING COMPLETED',
  DELETE_SENSOR_ID_MAPPING = '[SENSOR_ID_MAPPING] DELETE SENSOR ID MAPPING',
  DELETE_SENSOR_ID_MAPPING_COMPLETED = '[SENSOR_ID_MAPPING] DELETE SENSOR ID MAPPING COMPLETED',
  SITES_DATA_LOADING = '[SENSOR_ID_MAPPING] SITES DATA LOADING',
  SITES_DATA_LOADED = '[SENSOR_ID_MAPPING] SITES DATA LOADED',
  LINES_DATA_LOADING = '[SENSOR_ID_MAPPING] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[SENSOR_ID_MAPPING] LINES DATA COMPLETED',
  FETCH_ERROR = '[SENSOR_ID_MAPPING] FETCH ERROR',
}

export class SensorIdMappingLoading implements Action {
  readonly type = ActionTypes.SENSOR_ID_MAPPING_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class SensorIdMappingLoaded implements Action {
  readonly type = ActionTypes.SENSOR_ID_MAPPING_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetSensorIdMapping implements Action {
  readonly type = ActionTypes.GET_SENSOR_ID_MAPPING;
  constructor(public recordId: number) {}
}

export class GetSensorIdMappingCompleted implements Action {
  readonly type = ActionTypes.GET_SENSOR_ID_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddSensorIdMapping implements Action {
  readonly type = ActionTypes.ADD_SENSOR_ID_MAPPING;
  constructor(public SensorIdMapping: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddSensorIdMappingCompleted implements Action {
  readonly type = ActionTypes.ADD_SENSOR_ID_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditSensorIdMapping implements Action {
  readonly type = ActionTypes.EDIT_SENSOR_ID_MAPPING;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditSensorIdMappingCompleted implements Action {
  readonly type = ActionTypes.EDIT_SENSOR_ID_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteSensorIdMapping implements Action {
  readonly type = ActionTypes.DELETE_SENSOR_ID_MAPPING;
  constructor(public payload: number[]) {}
}

export class DeleteSensorIdMappingCompleted implements Action {
  readonly type = ActionTypes.DELETE_SENSOR_ID_MAPPING_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type SensorIdMappingActions =
  | SensorIdMappingLoading
  | SensorIdMappingLoaded
  | GetSensorIdMapping
  | GetSensorIdMappingCompleted
  | AddSensorIdMapping
  | AddSensorIdMappingCompleted
  | EditSensorIdMapping
  | EditSensorIdMappingCompleted
  | DeleteSensorIdMapping
  | DeleteSensorIdMappingCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | FetchError;
