import { Component, ContentChild, Input, TemplateRef, TrackByFunction } from '@angular/core';
import { AsyncFunction, IDynamicComponentDirectiveContext } from '../../model/interface/generic.model';
import { ITableHeader } from '../../../../constants.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';

type TContentHeader = Pick<ITableHeader, 'value'>;

@OnDestroyDecorator
@Component({
  selector: 'app-datatable-row',
  templateUrl: './datatable-row.component.html',
  styleUrls: ['./datatable-row.component.scss'],
})
export class DatatableRowComponent<T> {
  @Input() formatter!: AsyncFunction<IDynamicComponentDirectiveContext>;
  @Input() row: T;
  @Input() headers: ITableHeader[] = [];
  @Input() ngContentHeaders?: TContentHeader[] = [];
  @Input() isLoading: boolean = false;

  @ContentChild(TemplateRef, { static: false }) templateRef: TemplateRef<any>;

  public datatableHeaderTrackBy: TrackByFunction<any> = (_index: number, value: any) => {
    return value?.value;
  };

  public isHeaderExistsInContentHeaders(contentHeaders: TContentHeader[], targetHeader: TContentHeader): boolean {
    return contentHeaders.some((header: TContentHeader) => header.value === targetHeader.value);
  }
}
