import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class SensorIdMappingService {
  private readonly routes = {
    sensorIdMapping: `${this.baseUrl}/opc-tag-automation-settings/sensor-id-mapping`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<any>> {
    return this.http.get<GetManyResponseInterface<any>>(this.routes.sensorIdMapping, {
      params,
    });
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<any>> {
    return this.http.get<BaseOneResponseInterface<any>>(`${this.routes.sensorIdMapping}/${recordId}`, {
      params,
    });
  }

  public addOne(record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.post<BaseOneResponseInterface<any>>(`${this.routes.sensorIdMapping}`, record);
  }

  public editOne(recordId: number, record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.patch<BaseOneResponseInterface<any>>(`${this.routes.sensorIdMapping}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          sensorIdMappingIds: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.sensorIdMapping}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.sensorIdMapping}/${recordIds[0]}`);
  }
}
