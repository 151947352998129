import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ILines } from './lines.model';
import {
  ResponseArrayInterface,
  ResponseInterface,
} from '../../../../shared/model/interface/generic-api-response.model';
import { DatatableInterface, EColumnWidth } from '../../../../shared/service/datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { DatatableHeaderInterface } from '../../../../shared/component/datatable/datatable.model';
import { FilterCardOptionInterface, LineCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { DropdownComponent } from '../../../../shared/component/filter/dropdown/dropdown.component';
import { Site } from '../../../../shared/component/filter/filterable-objects.class';
import { LineAvailabilityPlanDataInterface } from '../../../line-availability/line-availability.model';
import { ILayout } from '../../home-page-display-settings/home-page-display-settings.model';
import { BaseOneResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';
import {
  IBulkEditLine,
  IFormattedLineForm,
  ILineCameraList,
} from '../../../../view/settings/departments-lines-stations/lines/lines.model';
import { EFilterDropdownElements } from '../../../filter/filter.model';
import { ILineSaveRequest } from '../../../../view/settings/hidden-settings/lines/lines.model';

@Injectable({
  providedIn: 'root',
})
export class LinesService {
  private routes = {
    lines: '/lines',
    shiftPlans: '/scheduler/shift-plans',
    layouts: '/layouts',
  };

  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'siteName',
        name: translate.instant('general.dataTable.header.site'),
        selected: true,
        sortable: false,
      },
      {
        value: 'departmentName',
        name: translate.instant('general.dataTable.header.departmentName'),
        sortable: true,
      },
      {
        value: 'title',
        name: translate.instant('general.dataTable.header.lineName'),
        selected: true,
      },
      {
        value: 'statusId',
        name: translate.instant('general.dataTable.header.status'),
        selected: true,
      },
      {
        value: 'formattedStandardSpeed',
        name: translate.instant('general.dataTable.header.speed'),
        selected: true,
      },
      {
        value: 'unitName',
        name: translate.instant('general.dataTable.header.unit'),
        selected: true,
        sortable: false,
      },
      {
        value: 'oeeName',
        name: translate.instant('general.dataTable.header.oee'),
        selected: true,
      },
      {
        value: 'layoutName',
        name: translate.instant('general.dataTable.header.homePageDisplaySettings'),
        selected: true,
        sortable: false,
      },
      {
        value: 'currentShiftPlanName',
        name: translate.instant('general.dataTable.header.currentLineAvailabilityPlan'),
        selected: true,
        sortable: false,
      },
      {
        value: 'nextShiftPlanName',
        name: translate.instant('general.dataTable.header.nextLineAvailabilityPlan'),
        selected: true,
        sortable: false,
      },
      {
        value: 'planEffectiveDate',
        name: translate.instant('settings.lines.lineForm.planEffectiveDate.label'),
        selected: true,
      },
      {
        value: 'microstopSensorType',
        name: translate.instant('settings.lines.lineForm.microstopSensorType.label'),
        selected: true,
        sortable: false,
      },
      {
        value: 'description',
        name: translate.instant('general.dataTable.header.description'),
        selected: true,
      },
      {
        value: 'cameraList',
        name: '',
        selected: true,
        sortable: false,
      },
    ];
  }

  public getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    return {
      rows: [
        [
          {
            type: DropdownComponent,
            cls: 'col-md-3 col-sm-4',
            object: Site,
            elementId: EFilterDropdownElements.siteMultiSelectDropdown,
            outputOptions: {
              filterObjectId: 'siteIds',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              isRequired: false,
              getInitData: true,
              text: translate.instant('filterCard.site.dropdownPlaceHolder'),
            },
          },
        ],
      ],
    };
  }

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  public getLines(params?: HttpParams): Observable<ResponseArrayInterface<ILines>> {
    return this.http.get<ResponseArrayInterface<ILines>>(`${this.api}${this.routes.lines}`, {
      params,
    });
  }

  public getLine(id: number, params?: HttpParams): Observable<BaseOneResponseInterface<ILines>> {
    return this.http.get<BaseOneResponseInterface<ILines>>(`${this.api}${this.routes.lines}/${id}`, {
      params,
    });
  }

  public getShiftPlans(params?: HttpParams): Observable<ResponseArrayInterface<LineAvailabilityPlanDataInterface>> {
    return this.http.get<ResponseArrayInterface<LineAvailabilityPlanDataInterface>>(
      `${this.api}${this.routes.shiftPlans}`,
      {
        params,
      },
    );
  }

  public getLayouts(params?: HttpParams): Observable<ResponseArrayInterface<ILayout>> {
    return this.http.get<ResponseArrayInterface<ILayout>>(`${this.api}${this.routes.layouts}`, {
      params,
    });
  }

  public addLine(line: IFormattedLineForm): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<LineCRUDInterface>>(`${this.api}${this.routes.lines}`, line);
  }

  public editLine(
    line: IFormattedLineForm | ILineCameraList,
    id: number,
  ): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<LineCRUDInterface>>(`${this.api}${this.routes.lines}/${id}`, line);
  }

  public deleteLine(id: number): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.delete<BaseOneResponseInterface<LineCRUDInterface>>(`${this.api}${this.routes.lines}/${id}`);
  }

  public deleteLines(id: number[]): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.delete<BaseOneResponseInterface<LineCRUDInterface>>(
      `${this.api}${this.routes.lines}/bulk/delete`,
      {
        body: { lines: id },
      },
    );
  }

  public editLines(lines: IBulkEditLine[]): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<LineCRUDInterface>>(`${this.api}${this.routes.lines}/bulk/edit`, {
      lines,
    });
  }

  public saveInternalLineData(
    id: number,
    payload: ILineSaveRequest,
  ): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    const url: string = `${this.api}/lines/${id}/save-internal-line-data`;
    return this.http.patch<BaseOneResponseInterface<LineCRUDInterface>>(url, payload);
  }
}
