<div class="page-body">
  <div class="row">
    <div class="col-12">
      <filter-card
        [mainGridWide]="isTouchDevice ? 10 : 8"
        [additionalGridWide]="isTouchDevice ? 2 : 4"
        [options]="filterConfiguration"
        [strictControlForSelected]="true"
        [autoApply]="true"
        (filterChange)="onFiltersChanged($event)"
      >
      </filter-card>
    </div>
  </div>
</div>
<div class="row position-relative">
  <div class="col-xl-12">
    <div
      id="activityTaskTreeChart"
      echarts
      [options]="treeChartOption"
      (chartInit)="renderChart($event)"
      (chartClick)="onChartClick($event)"
    ></div>
  </div>
</div>
