import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'src/app/shared/service/helper.service';

@Pipe({
  standalone: true,
  name: 'convertTimezone',
})
export class ConvertTimezonePipe implements PipeTransform {
  constructor(private readonly helperService: HelperService) {}

  public transform(date: string | undefined | null | Date, timezone?: string): any {
    if (date === undefined || date === null || date instanceof Date) {
      return date;
    }

    return this.helperService.convertFromISOFormatToGivenTimezone(date, timezone);
  }
}
