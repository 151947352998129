import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { WorkOrderCRUDInterface } from '../../shared/component/filter/filter.class';
import { NgClass } from '@angular/common';
import { EWorkOrderStatusIcon } from '../../view/reports/work-order-performance/work-order-performance.model';

@Component({
  selector: 'scw-filter-card-wo-dropdown',
  standalone: true,
  template: `
    <ng-template #filterWoTemplate let-woData="data">
      <div class="wo-filter-dropdown-label d-flex-space-between">
        <div>
          <span> {{ woData.woNumber }} </span>
          <div class="text-muted mt-1 wo-line-subtitle">{{ woData.lineName }}</div>
        </div>
        <em
          class="wo-icon-class"
          [class]="'wo-' + woData.statusKey"
          [ngClass]="eWorkOrderStatusIcons[woData.statusKey]"
        ></em>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .wo-filter-dropdown-label {
        align-items: center;
        margin-left: 32px;
        margin-top: -22px;
      }

      .wo-line-subtitle {
        font-size: 12px;
      }

      .wo-notStarted {
        color: #bdbdbd;
      }

      .wo-inProgress {
        color: #ffd600;
      }

      .wo-completed {
        color: #6fc764;
      }

      .wo-canceled {
        color: #ff726b;
      }
    `,
  ],
  styleUrls: ['../../../scss/custom.scss'],
  imports: [NgClass],
})
export class FilterCardWoDropdownComponent {
  @Input() woData!: WorkOrderCRUDInterface;
  @ViewChild('filterWoTemplate', { static: true }) templateRef!: TemplateRef<any>;
  public eWorkOrderStatusIcons: typeof EWorkOrderStatusIcon = EWorkOrderStatusIcon;
}
