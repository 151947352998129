import { ComponentColors, ComponentName, CustomColors, CustomColorsWithSource } from './color.model';
import * as _ from 'lodash';

export class ColorServiceHelper {
  /* c8 ignore start */ // Coverage ignored since the functions are simple property accessors
  public static readonly COMPONENT_COLORS: Record<ComponentName, (colors: CustomColors) => ComponentColors> = {
    plannedDownTimeActivityButton: (colors) => ({
      background: colors.activity.plannedDownTime.button,
      text: colors.activity.plannedDownTime.buttonText,
    }),
    plannedDownTimeActivityCard: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
    plannedDownTimeActivityGantt: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
    plannedDownTimeActivityGlobalReviewCard: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
    plannedDownTimeActivityInput: (colors) => ({
      background: colors.activity.plannedDownTime.button,
      text: colors.activity.plannedDownTime.buttonText,
    }),
    plannedDownTimeActivityReviewCard: (colors) => ({
      background: colors.activity.plannedDownTime.button,
      text: colors.activity.plannedDownTime.buttonText,
    }),
    plannedDownTimeActivityWaterfallChart: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
    plannedDownTimeActivityLineViewEnhanced: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
    plannedDownTimeActivityFloorPlanViewCard: (colors) => ({
      background: colors.activity.plannedDownTime.card,
      text: colors.activity.plannedDownTime.cardText,
    }),
  };
  /* c8 ignore end */

  public static readonly DEFAULT_COLORS: CustomColors = {
    activity: { plannedDownTime: { button: '#ff726b', buttonText: '#ff726b', card: '#ff726b', cardText: '#ffffff' } },
  };

  /* c8 ignore next */
  private constructor() {}

  /**
   * Picks custom colors based on the number of sites visible to the user. Returns default colors if no custom colors
   * are available.
   * @param selectedSiteIds The IDs of the sites that are currently visible to the user.
   * @param sitesColors Custom colors associated with all sites.
   * @param clientColors Custom colors associated with the client.
   * @returns The colors that should be used.
   */
  public static pickCustomColors(
    selectedSiteIds: readonly number[],
    sitesColors: Readonly<Record<number, CustomColors | undefined>>,
    clientColors: CustomColors | undefined,
  ): CustomColorsWithSource {
    const siteIds: number[] = ColorServiceHelper.getSelectedSiteIds(selectedSiteIds, sitesColors);
    const fallbackColors: CustomColorsWithSource = clientColors
      ? { ...clientColors, colorSource: 'client' }
      : { ...ColorServiceHelper.DEFAULT_COLORS, colorSource: 'default' };

    if (siteIds?.length !== 1) {
      return fallbackColors;
    }

    const siteId: number | undefined = siteIds[0];

    if (!Object.prototype.hasOwnProperty.call(sitesColors, siteId)) {
      console.error('Site colors must contain the given site ID');
      return fallbackColors;
    }

    const siteColors = sitesColors[siteId];

    if (!siteColors) {
      return fallbackColors;
    }

    return { ...siteColors, colorSource: 'site' };
  }
  private static getSelectedSiteIds(
    siteIds: readonly number[] | number,
    sitesColors: Record<number, CustomColors | undefined>,
  ): number[] {
    const isMissingSelectedSiteId: boolean = siteIds === -1 || (Array.isArray(siteIds) && siteIds.length === 0);
    let selectedSiteIds: number[] = _.cloneDeep(siteIds);

    if (isMissingSelectedSiteId && Object.keys(sitesColors).length === 1) {
      selectedSiteIds = [Number(Object.keys(sitesColors)[0])];
    }

    return selectedSiteIds;
  }
}
