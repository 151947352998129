<div
  class="page-header vis"
  [ngClass]="customHeaderStyles"
  [class.tab-page]="pageHeader.isTabPage"
  [style.padding-top]="pageHeaderPaddingTop"
>
  <div *ngIf="(pageHeader.title !== null && isHeaderVisible) || headerExceptionIndicator !== null" class="page-block">
    <div class="page-header-container">
      <div class="page-header-information">
        <h4>
          <em *ngIf="pageHeader.icon !== null" class="{{ pageHeader.icon }}"></em>
          <ng-container *ngIf="pageHeader.breadcrumbs?.length <= 1; else breadCrumbs">
            {{ headerExceptionIndicator != null ? headerExceptionIndicator.message : pageHeader.title }}
          </ng-container>
        </h4>
        <ng-template #breadCrumbs>
          <nav aria-label="breadcrumb" class="ml-2 d-inline-flex flex-wrap">
            <ol class="breadcrumb">
              <ng-container *ngFor="let breadCrumb of pageHeader.breadcrumbs">
                <li class="breadcrumb-item">
                  <a *ngIf="!breadCrumb.disabled; else disabledBreadCrumb" routerLink="{{ breadCrumb.url }}">
                    {{ breadCrumb.label }}
                  </a>
                  <ng-template #disabledBreadCrumb>
                    {{ breadCrumb.label }}
                  </ng-template>
                </li>
              </ng-container>
            </ol>
          </nav>
        </ng-template>
      </div>
      <div class="page-header-buttons" *ngIf="!hideHeaderActions">
        <a
          *ngIf="pageHeader.fullScreenButton"
          data-target="{{ pageHeader.fullScreenTargetElementId }}"
          appToggleFullScreen
        >
          <em class="text-white fas fa-expand-arrows-alt full-screen"></em>
        </a>
        <app-dashboard-mode-countdown *ngIf="mode" [pageHeader]="pageHeader"></app-dashboard-mode-countdown>
        <app-dashboard-mode-button *ngIf="showDashboardModeButton"></app-dashboard-mode-button>
        <app-filter-visibility-button *ngIf="showFilterBarVisibilityButton"></app-filter-visibility-button>
        <app-page-mode-switch-button
          *ngIf="showModeSwitchButton"
          [pageHeader]="pageHeader"
        ></app-page-mode-switch-button>
        <app-root-cause-analysis-comparison-button
          *ngIf="pageHeader.titleKey === 'root_cause_analysis_report'"
        ></app-root-cause-analysis-comparison-button>
        <app-station-activity-history-button
          *ngIf="pageHeader.titleKey === 'station_home'"
        ></app-station-activity-history-button>
        <app-element-visibility-button
          *ngIf="showElementVisibilityButton"
          [elementVisibilityTranslations]="elementVisibilityTranslations"
        ></app-element-visibility-button>
        <app-print-functionality-button *ngIf="showPrintFunctionalityButton"></app-print-functionality-button>
        <app-general-configuration *ngIf="showGeneralConfiguration"></app-general-configuration>
        <app-page-configuration *ngIf="showPageConfig" [pageHeader]="pageHeader"></app-page-configuration>
      </div>
    </div>
  </div>
</div>
