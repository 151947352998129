import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ColorServiceHelper } from './color.helper';
import {
  ComponentName,
  CustomColorParameters,
  CustomColors,
  IColorPaletteOption,
  InterpolateColorStop,
  RgbColor,
  EColorPalette,
  CustomColorsWithSource,
  ComponentColorsWithSource,
} from './color.model';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  /* c8 ignore start */
  /** @deprecated Should use {@link ColorService.pickComponentColors} instead. */
  public pickCustomColors(
    siteIds: readonly number[],
    sitesColors: Record<number, CustomColors | undefined>,
    clientColors: CustomColors | undefined,
  ): CustomColors {
    return ColorServiceHelper.pickCustomColors(siteIds, sitesColors, clientColors);
  }
  /* c8 ignore end */

  public pickComponentColors(componentName: ComponentName, params: CustomColorParameters): ComponentColorsWithSource {
    const sitesColors: Record<number, CustomColors | undefined> = _.fromPairs(
      params.allSites.map((site) => [site.id, site.configuration?.colors]),
    );
    const clientColors: CustomColors | undefined = params.client?.configuration?.colors;
    const customColors: CustomColorsWithSource = ColorServiceHelper.pickCustomColors(
      params.visibleSiteIds,
      sitesColors,
      clientColors,
    );

    return {
      ...ColorServiceHelper.COMPONENT_COLORS[componentName](customColors),
      colorSource: customColors.colorSource,
    };
  }

  public interpolateColor(input: number, colorStops: InterpolateColorStop[]): string {
    const sortedColorStops: InterpolateColorStop[] = colorStops.sort(
      (a: InterpolateColorStop, b: InterpolateColorStop) => a.value - b.value,
    );

    let clampedInput: number = Math.max(0, Math.min(100, input));
    let leftStop: InterpolateColorStop, rightStop: InterpolateColorStop;

    for (let i: number = 0; i < sortedColorStops.length; i++) {
      if (clampedInput <= sortedColorStops[i].value) {
        rightStop = sortedColorStops[i];

        if (i > 0) {
          leftStop = sortedColorStops[i - 1];
        }
        break;
      }
    }

    if (!leftStop) {
      leftStop = sortedColorStops[0];
      rightStop = sortedColorStops[0];
    } else if (!rightStop) {
      leftStop = sortedColorStops[sortedColorStops.length - 1];
      rightStop = sortedColorStops[sortedColorStops.length - 1];
    }

    const leftValue: number = leftStop.value;
    const rightValue: number = rightStop.value;
    const normalizedInput: number = (clampedInput - leftValue) / (rightValue - leftValue);

    const leftColor: string = leftStop.color;
    const rightColor: string = rightStop.color;

    return this.interpolateHexColors(leftColor, rightColor, normalizedInput);
  }

  public interpolateHexColors(leftColor: string, rightColor: string, t: number): string {
    const leftRGB: RgbColor = this.hexToRGB(leftColor);
    const rightRGB: RgbColor = this.hexToRGB(rightColor);

    const interpolatedR: number = Math.round(leftRGB.r + t * (rightRGB.r - leftRGB.r));
    const interpolatedG: number = Math.round(leftRGB.g + t * (rightRGB.g - leftRGB.g));
    const interpolatedB: number = Math.round(leftRGB.b + t * (rightRGB.b - leftRGB.b));

    return this.rgbToHex(interpolatedR, interpolatedG, interpolatedB);
  }

  public hexToRGB(hex: string): RgbColor {
    const parsed: number = parseInt(hex.replace(/^#/, ''), 16);
    const r: number = (parsed >> 16) & 255;
    const g: number = (parsed >> 8) & 255;
    const b: number = parsed & 255;

    return { r, g, b };
  }

  public rgbToHex(r: number, g: number, b: number): string {
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
  }

  public componentToHex(component: number): string {
    const hex: string = component.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  public static getColorDataFromColorPalette(colorIds: EColorPalette[]): IColorPaletteOption[] {
    const colorData: IColorPaletteOption[] = [];
    const colorPaletteData: IColorPaletteOption[] = this.getColorPaletteData();

    for (const id of colorIds) {
      colorData.push(colorPaletteData[id]);
    }

    return colorData;
  }

  private static getColorPaletteData(): IColorPaletteOption[] {
    return [
      { id: 0, backgroundColor: '#A52A2A', text: '#FFFFFF', hover: '#741D1D' },
      { id: 1, backgroundColor: '#DD332B', text: '#FFFFFF', hover: '#9F201A' },
      { id: 2, backgroundColor: '#FF726B', text: '#000000', hover: '#FF5047' },
      { id: 3, backgroundColor: '#FA6031', text: '#FFFFFF', hover: '#CC3405' },
      { id: 4, backgroundColor: '#FF9347', text: '#FFFFFF', hover: '#E45E00' },
      { id: 5, backgroundColor: '#FFD600', text: '#000000', hover: '#E6C100' },
      { id: 6, backgroundColor: '#D2B48C', text: '#000000', hover: '#BE935A' },
      { id: 7, backgroundColor: '#038A4C', text: '#FFFFFF', hover: '#026135' },
      { id: 8, backgroundColor: '#6FC764', text: '#000000', hover: '#429A38' },
      { id: 9, backgroundColor: '#ACE195', text: '#000000', hover: '#7ED15B' },
      { id: 10, backgroundColor: '#00DDCC', text: '#000000', hover: '#00C7B8' },
      { id: 11, backgroundColor: '#72B7F6', text: '#000000', hover: '#2E94F2' },
      { id: 12, backgroundColor: '#448AFF', text: '#FFFFFF', hover: '#0362FF' },
      { id: 13, backgroundColor: '#0000CD', text: '#FFFFFF', hover: '#000090' },
      { id: 14, backgroundColor: '#5E3FBE', text: '#FFFFFF', hover: '#422C85' },
      { id: 15, backgroundColor: '#B0A6E5', text: '#000000', hover: '#7A69D3' },
      { id: 16, backgroundColor: '#F4AFCA', text: '#000000', hover: '#EF8AB2' },
      { id: 17, backgroundColor: '#E54887', text: '#FFFFFF', hover: '#B81A5A' },
      { id: 18, backgroundColor: '#BDBDBD', text: '#FFFFFF', hover: '#979797' },
      { id: 19, backgroundColor: '#61616B', text: '#FFFFFF', hover: '#44444B' },
      { id: 20, backgroundColor: '#333333', text: '#FFFFFF', hover: '#1F1F1F' },
    ];
  }
}
