import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TGridItem } from './custom-dashboard-widget.interface';
import { ELoadStatus } from '../../../../../../constants.model';
import { combineLatest, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from '../../../../../shared/service/signalr/signalr.service';
import * as signalR from '@microsoft/signalr';
import { SignalrBroadcastInterface } from '../../../../../shared/service/signalr/signalr.model';
import { MessageAction } from '../../../../../shared/model/enum/message-action';
import { commonExceptionalCases } from '../widget-exceptional-case-information/widget-exceptional-case-information.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../../store/oee.reducer';

@Directive({ standalone: true })
export abstract class BaseCustomDashboardWidget implements OnInit, OnDestroy {
  public static readonly widgetHttpHeader = {
    'Skip-Error-Message-And-Redirect': 'true',
    'X-Request-Source': 'CustomDashboard',
  };
  protected readonly subscriptions: Subscription[] = [];

  protected readonly shouldDummyDataBeShown$ = combineLatest({
    isDummyMode: this.sharedInputs.isDummyMode,
    isMessageShown: this.sharedInputs.exceptionalCaseInformationSubject.pipe(map((message) => Boolean(message.length))),
  }).pipe(
    map(({ isDummyMode, isMessageShown }) => isDummyMode || isMessageShown),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private lastDataLoadInterval: NodeJS.Timer;
  private refreshInterval: NodeJS.Timeout;
  private signalRSubscriptions: string[] = [];
  private minutesPastSinceTheLastSuccess = 0;
  private readonly lastUpdatedLessThanAMinuteAgo: string = this.translate.instant(
    'customDashboards.widget.lastUpdated.lessThanAMinute',
  );

  protected constructor(
    public readonly sharedInputs: TGridItem['widgetInputs'],
    protected readonly translate: TranslateService,
    private readonly signalRService: SignalRService | undefined,
    private readonly errorStatusCode$: Observable<number | null>,
    private readonly ngZone: NgZone,
    protected readonly globalStore: Store<OeeAppState>,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.updateWidgetDataSubscription(),
      this.errorStatusCode$.subscribe(this.handleErrorStatus),
      this.sharedInputs.configuration.subscribe((configuration) => {
        if (!configuration) {
          this.addExceptionalCase(commonExceptionalCases.missingConfiguration);
        } else {
          this.removeExceptionalCase('missingConfiguration');
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    if (this.lastDataLoadInterval) {
      clearInterval(this.lastDataLoadInterval);
    }

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  /**
   @description Handles widget status updates and manages refresh timers.
   @param status - The new status of the widget.
   */
  public updateStatus(status: ELoadStatus): void {
    this.sharedInputs.dataLoadStatusSubject.next(status);

    switch (status) {
      case ELoadStatus.Loading:
      case ELoadStatus.Failure:
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }

        break;
      case ELoadStatus.Success:
        this.restartLastDataLoadEmit();
        this.restartDataRefreshInterval();
        break;
    }
  }

  protected async subscribeToSignalR(
    objectId: number,
    objectType: string = 'line',
    action: MessageAction,
  ): Promise<void> {
    let authorizedLines: number[] | null = null;

    this.globalStore
      .select('user', 'authorizedLines')
      .pipe(take(1))
      .subscribe((storeAuthorizedLines) => {
        authorizedLines = storeAuthorizedLines === '*' ? null : storeAuthorizedLines.split(',').map(Number);
      });

    if (
      this.signalRService === undefined ||
      objectId === undefined ||
      objectType === undefined ||
      action === undefined ||
      (objectType === 'line' && authorizedLines !== null && !authorizedLines.includes(objectId))
    ) {
      return;
    }

    const signalRKey: string = `${objectId}-${objectType}-${action}`;
    const isExistSubscription: boolean = this.signalRSubscriptions.includes(signalRKey);

    if (!isExistSubscription) {
      this.signalRSubscriptions.push(signalRKey);
      await this.startSignalRConnection(objectId, objectType, action);
    }
  }

  private async startSignalRConnection(
    objectId: number,
    objectType: string = 'line',
    action: MessageAction,
  ): Promise<void> {
    this.subscriptions.push(
      this.signalRService.broadcastMessage.subscribe((broadcastData: SignalrBroadcastInterface): void => {
        if (
          action === broadcastData.message && objectType === 'line'
            ? objectId === _.get(broadcastData, 'additionalData.lineId')
            : false
        ) {
          this.sharedInputs.refreshSubject.next();
        }
      }),
    );

    try {
      if (
        !this.signalRService?.hubConnection?.connectionId &&
        // @ts-ignore
        this.signalRService?.hubConnection?.connectionState !== signalR.HubConnectionState?.Connecting
      ) {
        await this.signalRService.startConnection();
      }

      this.signalRService.addToGroup(objectType, objectId);
    } catch (error) {
      const threeSeconds: number = 3000;
      setTimeout(() => this.signalRService.startConnection(), threeSeconds);
    }
  }

  protected handleErrorStatus = (status: number): void => {
    switch (status) {
      case null:
        this.removeExceptionalCase('unauthorizedError');
        this.removeExceptionalCase('loadingError');
        break;
      case 401:
      case 403:
        this.addExceptionalCase(commonExceptionalCases.unauthorizedError);
        break;
      default:
        this.addExceptionalCase(commonExceptionalCases.loadingError);
        break;
    }
  };

  protected addExceptionalCase(caseData: { translateKey: string; iconClass: string; id: string }): void {
    this.sharedInputs.exceptionalCaseInformationSubject.next([
      ...this.sharedInputs.exceptionalCaseInformationSubject.value,
      {
        id: caseData.id,
        iconClass: caseData.iconClass,
        message: this.translate.instant(caseData.translateKey),
      },
    ]);
  }

  protected removeExceptionalCase(id: string): void {
    this.sharedInputs.exceptionalCaseInformationSubject.next(
      this.sharedInputs.exceptionalCaseInformationSubject.value.filter((data) => data.id !== id),
    );
  }

  private restartLastDataLoadEmit(): void {
    if (this.lastDataLoadInterval) {
      clearInterval(this.lastDataLoadInterval);
    }

    this.minutesPastSinceTheLastSuccess = 0;
    this.emitLastDate();

    this.ngZone.runOutsideAngular(() => {
      this.lastDataLoadInterval = setInterval(this.emitLastDate, 1000 * 60);
    });
  }

  private restartDataRefreshInterval(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    if (this.sharedInputs.refreshInterval.value === -1) {
      return;
    }

    if (this.sharedInputs.refreshInterval.value !== 0) {
      this.ngZone.runOutsideAngular(() => {
        this.refreshInterval = setTimeout(() => {
          this.sharedInputs.refreshSubject.next();
        }, this.sharedInputs.refreshInterval.value * 1000);
      });
    }
  }

  /** @description refresh should be done after dummyMode true => false change or refreshSubject.next */
  private updateWidgetDataSubscription(): Subscription {
    return combineLatest({
      isDummyMode: this.sharedInputs.isDummyMode.pipe(distinctUntilChanged()),
      refresh: this.sharedInputs.refreshSubject,
    })
      .pipe(filter(({ isDummyMode }) => !isDummyMode))
      .subscribe((): void => {
        this.updateWidgetData();
      });
  }

  private readonly emitLastDate = (): void => {
    this.sharedInputs.lastDataLoadDateSubject.next(
      this.minutesPastSinceTheLastSuccess < 1
        ? this.lastUpdatedLessThanAMinuteAgo
        : this.translate.instant('customDashboards.widget.lastUpdated.moreThanAMinute', {
            count: this.minutesPastSinceTheLastSuccess,
          }),
    );
    this.minutesPastSinceTheLastSuccess += 1;
  };

  /**
   @description Updates the widget's data or state.
   @internal This method is intended to be called by {@link updateWidgetDataSubscription} and nowhere else, If data needs to be updated, please use this.sharedInputs.refreshSubject.next
   */
  protected abstract updateWidgetData(): void;
}
