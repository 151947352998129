import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOf',
})
export class IndexOfPipe implements PipeTransform {
  transform(value: any[], searchElement: any, fromIndex?: number): number {
    return value.indexOf(searchElement, fromIndex);
  }
}
