<ng-template #error_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ headerText }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <div>
        <h6 class="scw-header unordered-error-list-margin" *ngIf="excelTotalCountMessage">
          {{ excelTotalCountMessage }}
        </h6>
        <ul class="unordered-error-list">
          <li *ngIf="excelSuccessCountMessage">{{ excelSuccessCountMessage }}</li>
          <li *ngIf="excelFailCountMessage">{{ excelFailCountMessage}}</li>
        </ul>
        <br *ngIf="excelTotalCountMessage">
        <h6 class="scw-header">
          {{ errorMessage }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">
        {{ buttonText }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
