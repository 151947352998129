<input
  hidden
  #imageSelectionDialogInput
  type="file"
  accept=".png, .jpg, .jpeg, image/svg+xml, image/vnd.microsoft.icon"
  (change)="openCropModal($event, crop)"
  class="upload input-common"
  required
/>

<div class="check-in-item" (click)="onAvatarIsClicked(crop)">
  <div
    [ngClass]="{
      'mini-image': icon?.length > 2,
      'avatar-is-loading': !inputModel && avatarIsLoading
    }"
    [ngStyle]="{ 'background-image': inputModel ? 'url(' + inputModel + ')' : null }"
    class="check-in-image"
  >
    {{ inputModel ? '' : icon }}
  </div>
  <scw-mat-button class="check-in-icon" [iconButton]="true" size="xs" type="standard">
    <em [ngClass]="inputModel ? 'fas fa-pen' : 'fas fa-plus'"></em>
  </scw-mat-button>
</div>

<ng-template #crop let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'cropModal.title' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-crop-modal
    [file]="file"
    [previouslySelectedImage]="inputModel"
    (onSubmit)="updateInputModel($event)"
    (onMaxAllowedImageSizeExceeded)="showMaxAllowedImageSizeExceededWarning()"
    (onSelectNewClicked)="onAvatarIsClicked()"
    [resizeToWidth]="targetImageWidth"
    [resizeToHeight]="targetImageHeight"
  >
  </app-crop-modal>
</ng-template>
