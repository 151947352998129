import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IOption } from 'ng-select';

@Component({
  selector: 'app-download-data-modal',
  templateUrl: './download-data-modal.component.html',
  styleUrls: ['./download-data-modal.component.scss'],
})
export class DownloadDataModalComponent implements OnDestroy {
  @Input() headerFontSize: number = 24;

  @Input() downloadExcelModalOpenWithData: boolean = false;
  @Input() informationTextArray?: string[] | null = null;
  @Input() modalHeaderText?: string | null;

  @Input() showLimitOption: boolean = true;
  @Input() limitOptions: IOption[] = [];
  @Input() limit: [IOption] | null;

  @Input() showSiteOption: boolean = true;
  @Input() sitesLoading: boolean = false;
  @Input() siteOptions: IOption[] = [];
  @Input() site: [IOption] | null;

  @Input() excelDataLoading$: boolean = false;
  @Input() recordRangeOptions: IOption[];
  @Input() recordRange: [IOption] | null;

  @Output() siteChange = new EventEmitter<[IOption] | null>();
  @Output() limitChange = new EventEmitter<[IOption] | null>();
  @Output() recordChange = new EventEmitter<[IOption] | null>();
  @Output() downloadExcel = new EventEmitter<[IOption] | null>();
  @Output() closeModalEvent = new EventEmitter<boolean>();

  constructor() {}

  public downloadExcelOnClick(option: [IOption] | null): void {
    this.downloadExcel.emit(option);
  }

  public siteOnChange(option: [IOption] | null): void {
    this.recordRange = null;
    this.siteChange.emit(option);
  }

  public limitOnChange(option: [IOption] | null): void {
    this.recordRange = null;
    this.limitChange.emit(option);
  }

  public recordOnChange(option: [IOption] | null): void {
    this.recordRange = option;
    this.recordChange.emit(option);
  }

  public closeDownloadFilteredDataModal(): void {
    this.closeModalEvent.emit();
  }

  ngOnDestroy(): void {
    this.downloadExcel.complete();
    this.limitChange.complete();
    this.siteChange.complete();
    this.closeModalEvent.complete();
  }
}
