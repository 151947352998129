import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './change-station-activity.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';

export enum ActionTypes {
  CHANGE_STATION_ACTIVITY_DATA_LOADING = '[CHANGE_STATION_ACTIVITY] CHANGE ACTIVITY DATA LOADING',
  CHANGE_STATION_ACTIVITY_DATA_LOADED = '[CHANGE_STATION_ACTIVITY] CHANGE ACTIVITY DATA LOADED',
  GET_CHANGE_STATION_ACTIVITY = '[CHANGE_STATION_ACTIVITY] GET CHANGE ACTIVITY LOADING',
  GET_CHANGE_STATION_ACTIVITY_COMPLETED = '[CHANGE_STATION_ACTIVITY] GET CHANGE ACTIVITY COMPLETED',
  ADD_CHANGE_STATION_ACTIVITY = '[CHANGE_STATION_ACTIVITY] ADD CHANGE ACTIVITY',
  ADD_CHANGE_STATION_ACTIVITY_COMPLETED = '[CHANGE_STATION_ACTIVITY] ADD CHANGE ACTIVITY COMPLETED',
  EDIT_CHANGE_STATION_ACTIVITY = '[CHANGE_STATION_ACTIVITY] EDIT CHANGE ACTIVITY',
  EDIT_CHANGE_STATION_ACTIVITY_COMPLETED = '[CHANGE_STATION_ACTIVITY] EDIT CHANGE ACTIVITY COMPLETED',
  DELETE_CHANGE_STATION_ACTIVITY = '[CHANGE_STATION_ACTIVITY] DELETE CHANGE ACTIVITY',
  DELETE_CHANGE_STATION_ACTIVITY_COMPLETED = '[CHANGE_STATION_ACTIVITY] DELETE CHANGE ACTIVITY COMPLETED',
  SITES_DATA_LOADING = '[CHANGE_STATION_ACTIVITY] SITES DATA LOADING',
  SITES_DATA_LOADED = '[CHANGE_STATION_ACTIVITY] SITES DATA LOADED',
  LINES_DATA_LOADING = '[CHANGE_STATION_ACTIVITY] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[CHANGE_STATION_ACTIVITY] LINES DATA COMPLETED',
  STATIONS_DATA_LOADING = '[CHANGE_STATION_ACTIVITY] STATIONS DATA LOADED',
  STATIONS_DATA_COMPLETED = '[CHANGE_STATION_ACTIVITY] STATIONS DATA COMPLETED',
  FETCH_ERROR = '[CHANGE_STATION_ACTIVITY] FETCH ERROR',
}

export class ChangeStationActivityLoading implements Action {
  readonly type = ActionTypes.CHANGE_STATION_ACTIVITY_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class ChangeStationActivityLoaded implements Action {
  readonly type = ActionTypes.CHANGE_STATION_ACTIVITY_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetChangeStationActivity implements Action {
  readonly type = ActionTypes.GET_CHANGE_STATION_ACTIVITY;
  constructor(public recordId: number) {}
}

export class GetChangeStationActivityCompleted implements Action {
  readonly type = ActionTypes.GET_CHANGE_STATION_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddChangeStationActivity implements Action {
  readonly type = ActionTypes.ADD_CHANGE_STATION_ACTIVITY;
  constructor(public changeStationActivity: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddChangeStationActivityCompleted implements Action {
  readonly type = ActionTypes.ADD_CHANGE_STATION_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditChangeStationActivity implements Action {
  readonly type = ActionTypes.EDIT_CHANGE_STATION_ACTIVITY;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditChangeStationActivityCompleted implements Action {
  readonly type = ActionTypes.EDIT_CHANGE_STATION_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteChangeStationActivity implements Action {
  readonly type = ActionTypes.DELETE_CHANGE_STATION_ACTIVITY;
  constructor(public payload: number[]) {}
}

export class DeleteChangeStationActivityCompleted implements Action {
  readonly type = ActionTypes.DELETE_CHANGE_STATION_ACTIVITY_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class StationsDataLoading implements Action {
  readonly type = ActionTypes.STATIONS_DATA_LOADING;
  constructor(public lineId: number) {}
}

export class StationsDataCompleted implements Action {
  readonly type = ActionTypes.STATIONS_DATA_COMPLETED;
  constructor(public payload: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type ChangeStationActivityActions =
  | ChangeStationActivityLoading
  | ChangeStationActivityLoaded
  | GetChangeStationActivity
  | GetChangeStationActivityCompleted
  | AddChangeStationActivity
  | AddChangeStationActivityCompleted
  | EditChangeStationActivity
  | EditChangeStationActivityCompleted
  | DeleteChangeStationActivity
  | DeleteChangeStationActivityCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | StationsDataLoading
  | StationsDataCompleted
  | FetchError;
