import {
  ECardAssignmentObjectTypeEnum,
  IRfidActivity,
  IRfidJob,
  IRfidLine,
  IRfidWorkOrder,
} from '../../shared/service/rfid-card-reader/rfid-card-reader.model';

export enum ESelectionMode {
  QUESTION = 'question',
  GOTO_LINE = 'goto_line',
  WO_SELECTION = 'wo_selection',
}

export interface IWorkOrderCardData {
  cardAssignmentObjectType: ECardAssignmentObjectTypeEnum;
  ongoingLines: IRfidLine[];
  lines: IRfidLine[];
  activities: IRfidActivity[];
  workOrders: IRfidWorkOrder[];
  job?: IRfidJob;
}
