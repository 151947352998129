import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './change-activity.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';

export enum ActionTypes {
  CHANGE_ACTIVITY_DATA_LOADING = '[CHANGE_ACTIVITY] CHANGE ACTIVITY DATA LOADING',
  CHANGE_ACTIVITY_DATA_LOADED = '[CHANGE_ACTIVITY] CHANGE ACTIVITY DATA LOADED',
  GET_CHANGE_ACTIVITY = '[CHANGE_ACTIVITY] GET CHANGE ACTIVITY LOADING',
  GET_CHANGE_ACTIVITY_COMPLETED = '[CHANGE_ACTIVITY] GET CHANGE ACTIVITY COMPLETED',
  ADD_CHANGE_ACTIVITY = '[CHANGE_ACTIVITY] ADD CHANGE ACTIVITY',
  ADD_CHANGE_ACTIVITY_COMPLETED = '[CHANGE_ACTIVITY] ADD CHANGE ACTIVITY COMPLETED',
  EDIT_CHANGE_ACTIVITY = '[CHANGE_ACTIVITY] EDIT CHANGE ACTIVITY',
  EDIT_CHANGE_ACTIVITY_COMPLETED = '[CHANGE_ACTIVITY] EDIT CHANGE ACTIVITY COMPLETED',
  DELETE_CHANGE_ACTIVITY = '[CHANGE_ACTIVITY] DELETE CHANGE ACTIVITY',
  DELETE_CHANGE_ACTIVITY_COMPLETED = '[CHANGE_ACTIVITY] DELETE CHANGE ACTIVITY COMPLETED',
  SITES_DATA_LOADING = '[CHANGE_ACTIVITY] SITES DATA LOADING',
  SITES_DATA_LOADED = '[CHANGE_ACTIVITY] SITES DATA LOADED',
  LINES_DATA_LOADING = '[CHANGE_ACTIVITY] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[CHANGE_ACTIVITY] LINES DATA COMPLETED',
  ACTIVITIES_DATA_LOADING = '[CHANGE_ACTIVITY] ACTIVITIES DATA LOADED',
  ACTIVITIES_DATA_COMPLETED = '[CHANGE_ACTIVITY] ACTIVITIES DATA COMPLETED',
  TASKS_DATA_LOADING = '[CHANGE_ACTIVITY] TASKS DATA LOADED',
  TASKS_DATA_COMPLETED = '[CHANGE_ACTIVITY] TASKS DATA COMPLETED',
  EXTENDED_TASKS_DATA_LOADING = '[CHANGE_ACTIVITY] EXTENDED TASKS DATA LOADED',
  EXTENDED_TASKS_DATA_COMPLETED = '[CHANGE_ACTIVITY] EXTENDED TASKS DATA COMPLETED',
  FETCH_ERROR = '[CHANGE_ACTIVITY] FETCH ERROR',
}

export class ChangeActivityLoading implements Action {
  readonly type = ActionTypes.CHANGE_ACTIVITY_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class ChangeActivityLoaded implements Action {
  readonly type = ActionTypes.CHANGE_ACTIVITY_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetChangeActivity implements Action {
  readonly type = ActionTypes.GET_CHANGE_ACTIVITY;
  constructor(public recordId: number) {}
}

export class GetChangeActivityCompleted implements Action {
  readonly type = ActionTypes.GET_CHANGE_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddChangeActivity implements Action {
  readonly type = ActionTypes.ADD_CHANGE_ACTIVITY;
  constructor(public changeActivity: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddChangeActivityCompleted implements Action {
  readonly type = ActionTypes.ADD_CHANGE_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditChangeActivity implements Action {
  readonly type = ActionTypes.EDIT_CHANGE_ACTIVITY;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditChangeActivityCompleted implements Action {
  readonly type = ActionTypes.EDIT_CHANGE_ACTIVITY_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteChangeActivity implements Action {
  readonly type = ActionTypes.DELETE_CHANGE_ACTIVITY;
  constructor(public payload: number[]) {}
}

export class DeleteChangeActivityCompleted implements Action {
  readonly type = ActionTypes.DELETE_CHANGE_ACTIVITY_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class ActivitiesDataLoading implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_LOADING;
  constructor(public activityIds: number[]) {}
}

export class ActivitiesDataCompleted implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_COMPLETED;
  constructor(public payload: ActivityCRUDInterface[]) {}
}

export class TasksDataLoading implements Action {
  readonly type = ActionTypes.TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class TasksDataCompleted implements Action {
  readonly type = ActionTypes.TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class ExtendedTasksDataLoading implements Action {
  readonly type = ActionTypes.EXTENDED_TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class ExtendedTasksDataCompleted implements Action {
  readonly type = ActionTypes.EXTENDED_TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type ChangeActivityActions =
  | ChangeActivityLoading
  | ChangeActivityLoaded
  | GetChangeActivity
  | GetChangeActivityCompleted
  | AddChangeActivity
  | AddChangeActivityCompleted
  | EditChangeActivity
  | EditChangeActivityCompleted
  | DeleteChangeActivity
  | DeleteChangeActivityCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | ActivitiesDataLoading
  | ActivitiesDataCompleted
  | TasksDataLoading
  | TasksDataCompleted
  | ExtendedTasksDataLoading
  | ExtendedTasksDataCompleted
  | FetchError;
