import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ICustomDashboardSavePayload } from '../../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/custom-dashboard-widget.interface';
import { ICustomDashboard } from '../custom-dashboards/custom-dashboards.model';

export enum CustomDashboardRoutes {
  LINE = 'line',
  SAVE_CUSTOM_DASHBOARD = 'saveCustomDashboard',
  GET_LINE_INFORMATION = 'getLineInformation',
}

const basePath = 'custom-dashboard-items';

@Injectable({
  providedIn: 'root',
})
export class CustomDashboardContentService {
  private readonly routes: Record<CustomDashboardRoutes, string> = {
    [CustomDashboardRoutes.LINE]: `${this.baseUrl}/lines`,
    [CustomDashboardRoutes.SAVE_CUSTOM_DASHBOARD]: `${this.baseUrl}/${basePath}/reassign`,
    [CustomDashboardRoutes.GET_LINE_INFORMATION]: `${this.baseUrl}/${basePath}/reassign`,
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public saveCustomDashboardContent(
    payload: Partial<ICustomDashboardSavePayload>,
  ): Observable<BaseOneResponseInterface<unknown>> {
    return this.http.patch<BaseOneResponseInterface<unknown>>(
      this.routes[CustomDashboardRoutes.SAVE_CUSTOM_DASHBOARD],
      payload,
    );
  }

  public getCustomDashboardContents(id: number): Observable<BaseOneResponseInterface<ICustomDashboard>> {
    const params: HttpParams = new HttpParams()
      .set('fields', 'name,refreshInterval,siteId')
      .append('join', 'customDashboardItems||configuration,gridstackOptions,refreshInterval,subtitle,title,type')
      .append('join', 'site');

    return this.http.get<BaseOneResponseInterface<ICustomDashboard>>(`${this.baseUrl}/custom-dashboards/${id}`, {
      params,
    });
  }
}
