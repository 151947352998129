import * as ChangeActivityActions from './change-activity.actions';
import { ChangeActivityState } from './change-activity.model';

export const changeActivityState: ChangeActivityState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getChangeActivityLoading: false,
  getChangeActivityLoaded: false,
  changeActivity: null,
  addChangeActivityLoading: false,
  addChangeActivityLoaded: false,
  editChangeActivityLoading: false,
  editChangeActivityLoaded: false,
  deleteChangeActivityLoading: false,
  deleteChangeActivityLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  activitiesDataLoading: false,
  activitiesDataLoaded: false,
  activitiesData: [],
  tasksDataLoading: false,
  tasksDataLoaded: false,
  tasksData: [],
  extendedTasksDataLoading: false,
  extendedTasksDataLoaded: false,
  extendedTasksData: [],
};

export function changeActivityReducer(
  state: ChangeActivityState = changeActivityState,
  action: ChangeActivityActions.ChangeActivityActions,
): ChangeActivityState {
  switch (action.type) {
    case ChangeActivityActions.ActionTypes.CHANGE_ACTIVITY_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.CHANGE_ACTIVITY_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.GET_CHANGE_ACTIVITY:
      return {
        ...state,
        ...{
          getChangeActivityLoading: true,
          getChangeActivityLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.GET_CHANGE_ACTIVITY_COMPLETED:
      return {
        ...state,
        ...{
          changeActivity: action.payload.data,
          getChangeActivityLoading: false,
          getChangeActivityLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.ADD_CHANGE_ACTIVITY:
      return {
        ...state,
        addChangeActivityLoading: true,
        addChangeActivityLoaded: false,
      };
    case ChangeActivityActions.ActionTypes.ADD_CHANGE_ACTIVITY_COMPLETED:
      return {
        ...state,
        addChangeActivityLoading: false,
        addChangeActivityLoaded: true,
      };
    case ChangeActivityActions.ActionTypes.EDIT_CHANGE_ACTIVITY:
      return {
        ...state,
        editChangeActivityLoading: true,
        editChangeActivityLoaded: false,
      };
    case ChangeActivityActions.ActionTypes.EDIT_CHANGE_ACTIVITY_COMPLETED:
      return {
        ...state,
        editChangeActivityLoading: false,
        editChangeActivityLoaded: true,
      };

    case ChangeActivityActions.ActionTypes.DELETE_CHANGE_ACTIVITY:
      return {
        ...state,
        deleteChangeActivityLoading: true,
        deleteChangeActivityLoaded: false,
      };
    case ChangeActivityActions.ActionTypes.DELETE_CHANGE_ACTIVITY_COMPLETED:
      return {
        ...state,
        deleteChangeActivityLoading: false,
        deleteChangeActivityLoaded: true,
      };
    case ChangeActivityActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.ACTIVITIES_DATA_LOADING:
      return {
        ...state,
        ...{
          activitiesDataLoading: true,
          activitiesDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.ACTIVITIES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          activitiesData: action.payload,
          activitiesDataLoading: false,
          activitiesDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          tasksDataLoading: true,
          tasksDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          tasksData: action.payload,
          tasksDataLoading: false,
          tasksDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.EXTENDED_TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          extendedTasksDataLoading: true,
          extendedTasksDataLoaded: false,
        },
      };
    case ChangeActivityActions.ActionTypes.EXTENDED_TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          extendedTasksData: action.payload,
          extendedTasksDataLoading: false,
          extendedTasksDataLoaded: true,
        },
      };
    case ChangeActivityActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
