<div class="modal-header scw-modal-header">
  <h3
    class="modal-title scw-header"
    [style.font-size]="(headerFontSize | toString) + 'px' + '!important'"
    *ngIf="modalHeaderText; else defaultHeader"
  >
    {{ modalHeaderText }}
  </h3>
  <ng-template #defaultHeader>
    {{ (downloadExcelModalOpenWithData ? 'excel.downloadFilteredData' : 'excel.downloadTemplate') | translate }}
  </ng-template>
  <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeDownloadFilteredDataModal()">
    <span aria-hidden="true">
      <em class="fas fa-times"></em>
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="info-label" *ngIf="downloadExcelModalOpenWithData">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          <ng-container *ngFor="let informationText of informationTextArray; let last = last">
            {{ informationText }}<br *ngIf="!last" />
          </ng-container>
        </h6>
      </div>
    </div>
    <div class="col-12" *ngIf="showLimitOption">
      <scw-mat-select
        label="{{ 'excel.modal.limitOption.label' | translate }} *"
        labelKey="label"
        primaryKey="value"
        [data]="limitOptions"
        [(inputModel)]="limit"
        (inputModelChange)="limitOnChange($event)"
        data-automation-id="download-file-modal-limitOption-field"
      ></scw-mat-select>
    </div>
    <div class="col-12" *ngIf="showSiteOption">
      <mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="!sitesLoading"></mat-progress-bar>
      <scw-mat-select
        label="{{ 'general.input.siteName.placeholder' | translate }} *"
        labelKey="label"
        primaryKey="value"
        [data]="siteOptions"
        [enableSearchFilter]="true"
        [(inputModel)]="site"
        (inputModelChange)="siteOnChange($event)"
        data-automation-id="download-file-modal-siteName-field"
      ></scw-mat-select>
    </div>
    <div class="col-12" *ngIf="downloadExcelModalOpenWithData">
      <mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="!excelDataLoading$"></mat-progress-bar>
      <scw-mat-select
        label="{{ 'general.input.recordRange.placeholder' | translate }} *"
        labelKey="label"
        primaryKey="value"
        [disabled]="excelDataLoading$ || (showSiteOption && !site?.length) || (showLimitOption && !limit?.length)"
        [data]="recordRangeOptions"
        [(inputModel)]="recordRange"
        (inputModelChange)="recordOnChange($event)"
        data-automation-id="download-file-modal-recordRange-field"
      ></scw-mat-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="buttons-right gap-3">
    <scw-mat-button type="standard" (onClick)="closeDownloadFilteredDataModal()">
      {{ 'general.cancel' | translate }}
    </scw-mat-button>
    <scw-mat-button
      [disabled]="
        (!downloadExcelModalOpenWithData && !site?.length && showSiteOption) ||
        (downloadExcelModalOpenWithData &&
          ((!site?.length && showSiteOption) || (showLimitOption && !limit?.length) || !recordRange?.length)) ||
        excelDataLoading$
      "
      (onClick)="downloadExcelOnClick(recordRange)"
      data-automation-id="download-file-modal-download-btn"
    >
      {{ 'general.download' | translate }}
    </scw-mat-button>
  </div>
</div>
