import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  BulkEditRootCauseGroupInterface,
  IdNameInterface,
  RootCauseGroupBulkOperationResponseInterface,
  RootCauseGroupBulkSaveManyInterface,
  RootCauseGroupCreateInterface,
  RootCauseGroupCRUDDataInterface,
  RootCauseGroupDeleteResponseInterface,
  RootCauseGroupGetOneInterface,
  RootCauseGroupUpdateInterface,
  RootCauseTypeCreateUpdateInterface,
} from '../../../../store/settings/root-cause-group/root-cause-group.model';
import { AllSettledResult } from '../../../model/interface/generic-api-response.model';
import { RootCauseTypeCRUDInterface } from '../../../component/filter/filter.class';
import {
  RootCauseGroupDataRequestParameterInterface,
  RootCauseGroupExcelDataInterface,
} from '../../../../view/settings/root-cause-group/root-cause-group.model';
import { DatatableInterface } from '../../datatable/datatable.model';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  DownloadExcelFiltersInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../excel/excel-helper.service';
import * as ObjectActions from '../../../../store/settings/root-cause-group/root-cause-group.actions';
import { SiteService } from '../../filter/site.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { excelDateFormat, excelTimeFormat } from '../../../model/enum/excel-date-format';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { ExcelDropdownInterface } from '../../../../store/settings/users/users.model';

@Injectable({
  providedIn: 'root',
})
export class RootCauseGroupService {
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly uri = {
    MAIN: `${this.api}`,
    BULK_DELETE_URL: 'bulk/delete',
    BULK_EDIT_URL: 'bulk/edit',
  };

  constructor(
    public http: HttpClient,
    public readonly excelHelper: ExcelHelperService,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly siteService: SiteService,
    private readonly translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly routes = {
    ROOT_CAUSE_GROUP: '/root-cause-groups',
    ROOT_CAUSE_TYPE: '/root-cause-types',
  };

  public getRootCauseGroupData(
    data: RootCauseGroupDataRequestParameterInterface,
    getOnlyCount: boolean = false,
  ): Observable<GetManyResponseInterface<RootCauseGroupGetOneInterface>> {
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    const searchObject: object = {};
    let httpParams: HttpParams = new HttpParams();

    if (!getOnlyCount) {
      httpParams = httpParams.append('join', 'site').append('join', 'rootCause');
    }

    httpParams = httpParams.set('page', data.page.toString()).set('limit', data.pageSize.toString());

    if (data.orderBy && data.orderDesc !== undefined) {
      httpParams = httpParams.set('sort', `${data.orderBy},${data.orderDesc ? 'DESC' : 'ASC'}`);
    }

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if ((key === 'siteId' || key === 'rootCauseTypeId') && value !== -1) {
        _.set(searchObject, `${key}.$in`, value);
      } else if (key === 'search' && value !== null) {
        const orFilter = [];
        orFilter.push({ name: { $cont: value } });
        orFilter.push({ description: { $cont: value } });
        orFilter.push({ 'site.name': { $cont: value } });
        orFilter.push({ 'rootCauseType.name': { $cont: value } });
        queryParams.$and.push({ $or: orFilter });
      }
    });

    if (Object.keys(searchObject).length) {
      queryParams.$and.push(searchObject);
    }

    if (queryParams.$and.length) {
      httpParams = httpParams.set('s', JSON.stringify(queryParams));
    }

    return this.http.get<GetManyResponseInterface<RootCauseGroupGetOneInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_GROUP}`,
      { params: httpParams },
    );
  }

  public createRootCauseGroup(
    body: RootCauseGroupCreateInterface,
  ): Observable<BaseOneResponseInterface<RootCauseGroupCRUDDataInterface>> {
    return this.http.post<BaseOneResponseInterface<RootCauseGroupCRUDDataInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_GROUP}`,
      body,
    );
  }

  public updateRootCauseGroup(
    id: number,
    body: RootCauseGroupUpdateInterface,
  ): Observable<RootCauseGroupCRUDDataInterface> {
    return this.http.patch<RootCauseGroupCRUDDataInterface>(`${this.api}${this.routes.ROOT_CAUSE_GROUP}/${id}`, body);
  }

  public deleteRootCauseGroup(id: number): Observable<AllSettledResult> {
    return this.http.delete<AllSettledResult>(`${this.api}${this.routes.ROOT_CAUSE_GROUP}/${id}`);
  }

  public deleteRootCauseGroups(id: number[]): Observable<RootCauseGroupDeleteResponseInterface> {
    if (Array.isArray(id) && id.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          rootCauseGroups: id,
        },
      };
      return this.http.delete<RootCauseGroupDeleteResponseInterface>(
        `${this.uri.MAIN}${this.routes.ROOT_CAUSE_GROUP}/${this.uri.BULK_DELETE_URL}`,
        httpOptions,
      );
    }
    return this.http.delete<RootCauseGroupDeleteResponseInterface>(
      `${this.uri.MAIN}${this.routes.ROOT_CAUSE_GROUP}/${id[0]}`,
    );
  }

  public deleteRootCauseTypes(id: number[]): Observable<RootCauseGroupDeleteResponseInterface> {
    if (Array.isArray(id) && id.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          rootCauseTypes: id,
        },
      };
      return this.http.delete<RootCauseGroupDeleteResponseInterface>(
        `${this.uri.MAIN}${this.routes.ROOT_CAUSE_TYPE}/${this.uri.BULK_DELETE_URL}`,
        httpOptions,
      );
    }
    return this.http.delete<RootCauseGroupDeleteResponseInterface>(
      `${this.uri.MAIN}${this.routes.ROOT_CAUSE_TYPE}/${id[0]}`,
    );
  }

  public getRootCauseTypeData(
    data: DatatableInterface,
  ): Observable<GetManyResponseInterface<RootCauseTypeCRUDInterface>> {
    const searchObject = {};
    let httpParams: HttpParams = new HttpParams()
      .set('page', data.page.toString())
      .set('limit', data.pageSize.toString());

    if (data.search) {
      _.set(searchObject, 'name.$cont', data.search);
      httpParams = httpParams.set('s', JSON.stringify(searchObject));
    }

    if (data.orderBy && data.orderDesc !== undefined) {
      httpParams = httpParams.set('sort', `${data.orderBy},${data.orderDesc ? 'DESC' : 'ASC'}`);
    }

    return this.http.get<GetManyResponseInterface<RootCauseTypeCRUDInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_TYPE}`,
      { params: httpParams },
    );
  }

  public createRootCauseType(body: RootCauseTypeCreateUpdateInterface): Observable<RootCauseTypeCRUDInterface> {
    return this.http.post<RootCauseTypeCRUDInterface>(`${this.api}${this.routes.ROOT_CAUSE_TYPE}`, body);
  }

  public updateRootCauseType(
    id: number,
    body: RootCauseTypeCreateUpdateInterface,
  ): Observable<RootCauseTypeCRUDInterface> {
    return this.http.patch<RootCauseTypeCRUDInterface>(`${this.api}${this.routes.ROOT_CAUSE_TYPE}/${id}`, body);
  }

  public getRootCauseGroups(params: HttpParams): Observable<GetManyResponseInterface<RootCauseGroupGetOneInterface>> {
    return this.http.get<GetManyResponseInterface<RootCauseGroupGetOneInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_GROUP}`,
      {
        params,
      },
    );
  }

  public getRootCauseTypes(params: HttpParams): Observable<GetManyResponseInterface<RootCauseTypeCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<RootCauseTypeCRUDInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_TYPE}`,
      {
        params,
      },
    );
  }

  private getRootCauseGroupExcelColumns(
    sites: IdNameInterface[],
    rootCauseTypes: IdNameInterface[],
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const booleanDropdownOptions: ExcelDropdownInterface[] = this.excelHelper.getExcelBooleanDropdownOptions();
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [sites],
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.rootCauseGroupName'),
          key: 'name',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.rootCauseGroupType'),
          key: 'rootCauseTypeId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: rootCauseTypes,
            prop: 'rootCauseType.name',
            dataProperty: 'rootCauseType.name',
            dataId: 'rootCauseType.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.description'),
          key: 'description',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('settings.rootCauseGroup.field.isChangeover'),
          key: 'isChangeover',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'isChangeover.name',
            dataId: 'isChangeover.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public downloadRootCauseGroupExcel(
    withData: boolean,
    filters: DownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: RootCauseGroupGetOneInterface[],
  ): void {
    const httpParams: HttpParams = new HttpParams().set('limit', String(filters.limit));
    const rootCauseTypeParams: DatatableInterface = { page: 1, pageSize: 5000 };

    const observables: any = [this.siteService.getSite(filters.siteId, new HttpParams().set('fields', 'name,siteId')), this.getRootCauseTypeData(rootCauseTypeParams)];

    if (withData && !data) {
      observables.push(
        this.getRootCauseGroups(
          httpParams
            .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
            .set('page', filters.selectedDownloadOffset)
            .append('join', 'site||name')
            .append('join', 'rootCauseType||name'),
        ),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      const site = _.get(responseList, '0.data', []);
      const rootCauseType = _.get(responseList, '1.data', []);
      const sheetTitle = this.translate.instant('pageTitles.root_cause_groups');
      const excelName: string = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      let excelData: RootCauseGroupGetOneInterface[] = [];

      if (withData) {
        excelData = _.get(responseList, '2.data', []);

        if (data) {
          excelData = data;
        }

        for (const rootCauseGroup of excelData) {
          const rootCauseGroupType = rootCauseType.find(
            (line: IdNameInterface) => line.id === rootCauseGroup.rootCauseTypeId,
          );

          if (rootCauseGroup.siteId) {
            rootCauseGroup.site = site;
          }

          if (rootCauseGroupType) {
            rootCauseGroup.rootCauseType = rootCauseGroupType;
          }

          rootCauseGroup.isChangeover = !_.isNil(rootCauseGroup.isChangeover)
            ? _.find(this.excelHelper.getExcelBooleanDropdownOptions(), {
              id: String(Boolean(rootCauseGroup.isChangeover)),
            })
            : null;
        }
      }

      const excelOptions: CreateExcelInterface = this.getRootCauseGroupExcelColumns(
        site,
        rootCauseType,
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(
          excelName,
          {
            withData,
            name: 'rootCauseGroup',
            siteId: site?.id,
          },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadRootCauseGroupExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.RootCauseGroupFetchError([]));
          },
        );
    });
  }

  async getRootCauseGroupFromExcel(file: File): Promise<null | RootCauseGroupExcelDataInterface> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const rootCauseGroupSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('pageTitles.root_cause_groups'),
    );
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const rootCauseTypeIdDataSheet: Worksheet = workbook.getWorksheet('rootCauseTypeIdDataSheet');

    if (!rootCauseGroupSheet || !siteIdDataSheet || !rootCauseTypeIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getRootCauseGroupExcelColumns(null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      rootCauseGroupData: {
        rootCauseGroups: this.excelHelper.getExcelRowsFromWorkSheet<RootCauseGroupGetOneInterface>(
          rootCauseGroupSheet,
          columnKeys,
          {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          },
        ),
      },
      siteData: { sites },
    };
  }

  uploadRootCauseGroupExcel(
    rootCauseGroups: RootCauseGroupBulkSaveManyInterface,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      `${this.api}/${this.routes.ROOT_CAUSE_GROUP}/bulk/save`,
      rootCauseGroups,
    );
  }

  public bulkEditRootCauseGroup(
    rootCauseGroups: BulkEditRootCauseGroupInterface[],
  ): Observable<GetManyResponseInterface<RootCauseGroupBulkOperationResponseInterface>> {
    return this.http.patch<GetManyResponseInterface<RootCauseGroupBulkOperationResponseInterface>>(
      `${this.api}/${this.routes.ROOT_CAUSE_GROUP}/bulk/edit`,
      {
        rootCauseGroups,
      },
    );
  }

  public getRootCauseGroupsFilterData(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<RootCauseGroupGetOneInterface>> {
    return this.http.get<GetManyResponseInterface<RootCauseGroupGetOneInterface>>(
      `${this.api}${this.routes.ROOT_CAUSE_GROUP}`,
      {
        params,
      },
    );
  }
}
