import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserActions from './../../../store/user/actions';
import * as AppActions from './../../../store/app/actions';
import * as LineActions from './../../../store/line/actions';
import * as SiteLineSelectionActions from './../../../store/site-line-selection/site-line-selection.actions';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as ProductionReviewActions from '../../../store/production-review/production-review.actions';
import * as ProductionReviewListViewActions from '../../../store/production-review/production-review-list-view.actions';
import { OeeAppState } from '../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import * as HomeActions from './../../../store/home/home.actions';
import {
  ESiteLineSelectionMode,
  LineInterface,
  SiteInterface,
  SiteLineSelectionDataInterface,
  SiteLineSelectionInterface,
} from '../../../store/site-line-selection/site-line-selection.model';
import * as _ from 'lodash';
import { FloorPlanInterface } from '../../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import { ScwMatButtonGroupButtons } from '../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { FloorPlanPreviewModalComponent } from '../../../standalone/floor-plan-preview-modal/floor-plan-preview-modal.component';
import { ScwModalSize } from '../../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';

@Component({
  selector: 'app-site-line-selection',
  templateUrl: './site-line-selection.component.html',
  styleUrls: ['./site-line-selection.component.scss', '../../../../styles.scss'],
})
export class SiteLineSelectionComponent implements OnInit, OnDestroy {
  @Input() actionType: string;
  @Output() private readonly selectionMode: EventEmitter<ESiteLineSelectionMode> =
    new EventEmitter<ESiteLineSelectionMode>();

  public updateCurrentUserLoading$: boolean;
  public defaultSite$: number = null;
  public site$: number = null;
  public line$: number = null;
  public searchString: string = '';
  public selectedSiteId: number;
  public selectedSiteName: string;
  public selectedLineId: number;
  public selectedLineName: string;
  public selectedFloorPlanId: number;
  public selectedFloorPlanName: string;
  public linesOfSelectedSite = [];
  public lineTypesOfSelectedSite: Partial<SiteLineSelectionDataInterface>[] = [];
  public floorPlansOfSelectedSite: FloorPlanInterface[] = [];
  public errors: Partial<SiteLineSelectionInterface>[] = [];
  public sites: Partial<SiteInterface>[] = [];
  public linesAndLineTypes: SiteLineSelectionDataInterface[] = [];
  public floorPlans: FloorPlanInterface[] = [];
  public lineOrFloorPlanModeButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      text: this.translate.instant('siteLineSelection.lines'),
      value: ESiteLineSelectionMode.LINES,
    },
    {
      text: this.translate.instant('siteLineSelection.layoutPlans'),
      value: ESiteLineSelectionMode.FLOOR_PLANS,
    },
  ];
  public siteLineSelectionMode: ESiteLineSelectionMode = ESiteLineSelectionMode.LINES;
  public previousSiteLineSelectionMode: ESiteLineSelectionMode = ESiteLineSelectionMode.LINES;
  public selectedSiteHasFloorPlan: boolean = false;
  public ESiteLineSelectionMode = ESiteLineSelectionMode;

  private storeSubscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly router: Router,
    private readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
  ) {}

  public onClickSite(siteId: number, siteName: string): void {
    if (this.siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS) {
      this.searchString = '';
    }

    this.siteLineSelectionMode =
      this.selectedSiteId !== siteId ? ESiteLineSelectionMode.LINES : this.siteLineSelectionMode;
    this.previousSiteLineSelectionMode = this.siteLineSelectionMode;
    this.selectionMode.emit(this.siteLineSelectionMode);
    this.selectedSiteId = siteId;
    this.selectedSiteName = siteName;
    this.selectedLineId = null;
    this.selectedFloorPlanId = null;
    this.selectedFloorPlanName = null;
    this.lineTypesOfSelectedSite = [];
    this.errors = [];
    this.linesOfSelectedSite = [];

    this.linesAndLineTypes.forEach((lineType, lineTypeIndex) => {
      const linesOfSite = this.linesAndLineTypes[lineTypeIndex].line.filter((line) => line.siteId === siteId);
      this.linesOfSelectedSite = this.linesOfSelectedSite.concat(linesOfSite);

      if (linesOfSite.length > 0) {
        this.lineTypesOfSelectedSite.push({
          id: this.linesAndLineTypes[lineTypeIndex].id,
          lineType: this.linesAndLineTypes[lineTypeIndex].lineType,
          order: this.linesAndLineTypes[lineTypeIndex]?.order,
        });
      }
    });
    this.linesOfSelectedSite = this.sortLines(this.linesOfSelectedSite);
    this.lineTypesOfSelectedSite = this.sortLineTypes(this.lineTypesOfSelectedSite);
    this.floorPlansOfSelectedSite = this.floorPlans.filter(
      (floorPlan: FloorPlanInterface): boolean => floorPlan.siteId === siteId,
    );
    this.selectedSiteHasFloorPlan = this.floorPlansOfSelectedSite.length > 0;

    if (
      this.linesOfSelectedSite.length === 1 &&
      (this.searchString === '' ||
        this.linesOfSelectedSite[0].title.toLowerCase().includes(this.searchString.toLowerCase()))
    ) {
      this.onClickLine(this.linesOfSelectedSite[0].id, this.linesOfSelectedSite[0].title);
    }

    if (this.linesOfSelectedSite.length === 0) {
      this.errors.push(this.translate.instant('siteLineSelection.lineError'));
    }
  }

  public onClickLine(lineId: number, lineName: string): void {
    this.selectedLineId = lineId;
    this.selectedLineName = lineName;
  }

  public onClickFloorPlan(floorPlanId: number, floorPlanName: string): void {
    this.selectedFloorPlanId = floorPlanId;
    this.selectedFloorPlanName = floorPlanName;
  }

  public siteLineSelectionModeChange(): void {
    if (this.previousSiteLineSelectionMode === this.siteLineSelectionMode) {
      return;
    }

    this.previousSiteLineSelectionMode = this.siteLineSelectionMode;
    this.selectionMode.emit(this.siteLineSelectionMode);
    this.searchString = '';
    this.selectedFloorPlanId = null;
    this.selectedFloorPlanName = null;
    this.selectedLineId = null;
    this.selectedLineName = null;
    this.floorPlansOfSelectedSite = this.floorPlans.filter(
      (floorPlan: FloorPlanInterface): boolean => floorPlan.siteId === this.selectedSiteId,
    );

    if (this.floorPlansOfSelectedSite.length === 1) {
      this.onClickFloorPlan(this.floorPlansOfSelectedSite[0].id, this.floorPlansOfSelectedSite[0].name);
    }
  }

  public selectDestination(): void {
    switch (this.siteLineSelectionMode) {
      case ESiteLineSelectionMode.LINES:
        if (this.selectedSiteId !== null && this.selectedLineId !== null && this.updateCurrentUserLoading$ === false) {
          const hideLoader: boolean = !(this.router.url === '/home' && this.line$ !== this.selectedLineId);
          this.store.dispatch(
            new UserActions.UpdateCurrentUser(
              this.selectedSiteId,
              this.selectedLineId,
              this.router.url !== '/home',
              hideLoader,
            ),
          );
          this.store.dispatch(new HomeActions.HomeMetricSetItemsSetDefaultState());
          this.store.dispatch(new HomeActions.ResetPhaseDurationOnSiteLineSelection());
        }

        break;
      case ESiteLineSelectionMode.FLOOR_PLANS:
        if (this.selectedSiteId !== null && this.selectedFloorPlanId !== null) {
          this.router.navigate([`/layout-plan-view/${this.selectedFloorPlanId}`]).then();
        }

        break;
    }
  }

  public ngOnInit(): void {
    this.store.dispatch(new SiteLineSelectionActions.LoadSitesLines());
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.defaultSite$ = state.defaultSite !== null ? Number(state.defaultSite) : null;
        this.site$ = state.siteId;
        this.line$ = state.lineId;
        this.updateCurrentUserLoading$ = state.updateCurrentUserLoading;

        if (state.updateCurrentUserLoaded && !state.updateCurrentUserLoading) {
          if (this.router.url === '/home') {
            this.store.dispatch(new LineActions.UpdateActivity({ lineChanged: true }));
            this.ngbModal.dismissAll();
          } else {
            let redirectUrl = '/home';

            if (this.router.url !== '/select-site-and-line') {
              redirectUrl = this.router.url;
            }

            this.ngbModal.dismissAll();
            this.router.navigate([redirectUrl]).then(() => {
              this.store.dispatch(new AppActions.HideLoader());
            });
          }

          if (this.ngbModal !== undefined) {
            this.ngbModal.dismissAll();
          }

          this.store.dispatch(new ProductionReviewActions.ClearToState());
          this.store.dispatch(new ProductionReviewListViewActions.ClearState());
        }
      }),
      this.store.select('siteLineSelectionStore').subscribe((state) => {
        if (state.isLinesOfSiteLinesSelectionLoaded) {
          this.sites = state.sites;
          this.linesAndLineTypes = _(state.linesAndFloorPlans.linesAndLineTypes)
            .groupBy('lineType')
            .map((items, lineType) => {
              return {
                id: parseInt(lineType, 10),
                lineType: items[0].lineTypeName.lineType,
                line: _.map(items),
                order: items[0].lineTypeName.order,
              };
            })
            .value();
          this.floorPlans = state.linesAndFloorPlans.floorPlans;

          if (!_.isNil(this.site$) && !_.isNil(this.line$)) {
            const selectedSite = _.find(this.sites, { id: this.site$ });

            this.onClickSite(selectedSite?.id, selectedSite?.name);

            const selectedLine = _.find(this.linesOfSelectedSite, { id: this.line$ });

            this.onClickLine(selectedLine?.id, selectedLine?.title);

            return;
          }

          if (this.sites.length === 1) {
            this.onClickSite(this.sites[0].id, this.sites[0].name);
            this.selectDestination();
            return;
          }

          if (this.sites.length > 1 && this.defaultSite$ !== null) {
            const defaultSite = _.find(this.sites, { id: this.defaultSite$ });

            if (!_.isNil(defaultSite)) {
              this.onClickSite(defaultSite.id, defaultSite.name);
            }
          }
        }
      }),
    );
  }

  public onSearch(): void {
    this.selectedLineId = null;
    this.selectedLineName = null;
    this.selectedFloorPlanId = null;
    this.selectedFloorPlanName = null;

    if (this.siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS) {
      this.floorPlansOfSelectedSite =
        this.searchString === ''
          ? this.floorPlans.filter((floorPlan: FloorPlanInterface): boolean => floorPlan.siteId === this.selectedSiteId)
          : this.floorPlans.filter(
              (floorPlan: FloorPlanInterface) =>
                floorPlan.name.toLowerCase().includes(this.searchString.toLowerCase()) &&
                floorPlan.siteId === this.selectedSiteId,
            );
    }
  }

  public openFloorPlanPreviewModal(floorPlan: FloorPlanInterface): void {
    const floorPlanPreviewModal: NgbModalRef = this.ngbModal.open(FloorPlanPreviewModalComponent, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.xlarge,
    });
    floorPlanPreviewModal.componentInstance.modalTitle = this.translate.instant('siteLineSelection.layoutPlanPreview');
    floorPlanPreviewModal.componentInstance.canChangeModes = false;
    floorPlanPreviewModal.componentInstance.floorPlan = floorPlan;
    floorPlanPreviewModal.componentInstance.nodes = this.linesOfSelectedSite.map((line: LineInterface) => ({
      id: line.id,
      name: line.title,
    }));
  }

  private sortLines(lines: LineInterface[]): LineInterface[] {
    lines.sort((a, b) => {
      return a.title.localeCompare(b.title, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return lines;
  }

  private sortLineTypes(
    lineTypes: Partial<SiteLineSelectionDataInterface>[],
  ): Partial<SiteLineSelectionDataInterface>[] {
    return lineTypes.sort((a, b) => {
      const orderA = a.order ?? 0;
      const orderB = b.order ?? 0;

      if (orderA === orderB) {
        const lineTypeA = (a.lineType ?? '').toLocaleLowerCase();
        const lineTypeB = (b.lineType ?? '').toLocaleLowerCase();

        return lineTypeA.localeCompare(lineTypeB, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }

      return orderA - orderB;
    });
  }

  public ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
