<scw-mat-form (scwMatFormSubmit)="onFormSubmit()">
  <div class="modal-content">
    <div class="modal-header scw-modal-header">
      <h4 class="modal-title scw-header">
        {{ 'modal.selectWorkOrderLine.header' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="closeModal()">
        <i aria-hidden class="fas fa-times"></i>
      </button>
    </div>

    <div class="modal-body work-order-line-selection-container">
      <ng-container *ngTemplateOutlet="scannedCardInfo"></ng-container>

      <ng-template [ngIf]="mode === ESelectionMode.QUESTION">
        <ng-container *ngTemplateOutlet="question"></ng-container>
      </ng-template>

      <ng-template [ngIf]="mode === ESelectionMode.WO_SELECTION && singleWorkOrder === null">
        <ng-container *ngTemplateOutlet="woSelection"></ng-container>
      </ng-template>

      <ng-template [ngIf]="mode === ESelectionMode.WO_SELECTION || mode === ESelectionMode.GOTO_LINE">
        <ng-container *ngTemplateOutlet="lineSelection"></ng-container>
      </ng-template>
    </div>

    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button type="standard" (onClick)="closeModal()">
          {{ 'general.close' | translate }}
        </scw-mat-button>
        <scw-mat-button
          [className]="['ml-1']"
          *ngIf="showBackToQuestionButton"
          type="standard"
          (onClick)="changeMode(ESelectionMode.QUESTION)"
        >
          {{ 'general.back' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button
          *ngIf="mode === ESelectionMode.WO_SELECTION"
          (onClick)="changeActivity()"
          [isSubmitButton]="true"
          [disabled]="!selectedWorkOrderId || !selectedLineId || isLoading"
        >
          {{ 'modal.selectWorkOrderLine.buttons.select' | translate }}
        </scw-mat-button>
        <scw-mat-button
          *ngIf="mode === ESelectionMode.GOTO_LINE"
          (onClick)="goToLine()"
          [isSubmitButton]="true"
          [disabled]="!selectedLineId || isLoading"
        >
          {{ 'siteLineSelection.start' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </div>
</scw-mat-form>
<ng-template #question>
  <h3 class="mb-4 scw-header site-line-header">
    <i class="b-fa-solid b-fa-circle-question"></i>
    {{ 'modal.selectWorkOrderLine.label.finalizeWoOrGotoLineQuestion' | translate }}
  </h3>
  <div class="row">
    <div class="col-6">
      <scw-mat-button
        (onClick)="changeMode(ESelectionMode.GOTO_LINE)"
        class="select-wo-line-button"
        size="three-line-text"
        type="standard"
        [block]="true"
      >
        <div class="select-wo-line-label-with-badge">
          <div class="select-wo-line-button-label">
            {{ 'modal.selectWorkOrderLine.buttons.completeWo' | translate }}
          </div>
          <div class="select-wo-line-badge" *ngIf="singleLine">
            {{ singleLine.title }}
          </div>
        </div>
      </scw-mat-button>
    </div>
    <div class="col-6">
      <scw-mat-button
        (onClick)="changeMode(ESelectionMode.WO_SELECTION)"
        class="select-wo-line-button"
        size="three-line-text"
        type="standard"
        [block]="true"
      >
        <div class="select-wo-line-label-with-badge">
          <div class="select-wo-line-button-label">
            {{ 'modal.selectWorkOrderLine.buttons.startNewWo' | translate }}
          </div>
        </div>
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #woSelection>
  <h3 class="mb-4 scw-header site-line-header">
    <i class="fas fa-list-alt"></i> {{ 'modal.selectWorkOrderLine.title.selectWorkOrder' | translate }}
  </h3>
  <div class="row">
    <div *ngFor="let workOrder of possibleWorkOrders" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
      <scw-mat-button
        class="select-wo-line-button"
        size="three-line-text"
        type="standard"
        [block]="true"
        [className]="{ active: workOrder.id === selectedWorkOrderId }"
        [disabled]="!workOrder.selectable"
        (onClick)="onClickWorkOrder(workOrder)"
      >
        <div class="select-wo-line-label-with-badge">
          <div class="select-wo-line-button-label">{{ workOrder.woNumber }}</div>
        </div>
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #lineSelection>
  <h3 class="mb-4 scw-header site-line-header">
    <i class="fas fa-pallet"></i> {{ 'modal.selectWorkOrderLine.title.selectLine' | translate }}
  </h3>
  <div class="row">
    <div *ngFor="let line of possibleLines" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
      <scw-mat-button
        class="select-wo-line-button"
        size="three-line-text"
        type="standard"
        [block]="true"
        [className]="{ active: line.id === selectedLineId }"
        [disabled]="!line.selectable"
        (onClick)="onClickLine(line)"
      >
        <div class="select-wo-line-label-with-badge">
          <div class="select-wo-line-button-label">{{ line.title }}</div>
          <div class="select-wo-line-badge" [class.disabled]="!line.selectable">
            {{
              line.currentWorkOrder?.woNumber || ('modal.selectWorkOrderLine.label.noWorkOrder' | translate | uppercase)
            }}
          </div>
        </div>
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #scannedCardInfo>
  <h4 class="mb-4">
    <ng-template [ngIf]="modalData.cardAssignmentObjectType === cardAssignmentObjectTypeEnum.JOB && modalData.job">
      {{
        'modal.selectWorkOrderLine.label.cardIsScanned'
          | translate
            : {
                type: 'general.job' | translate,
                id: modalData.job.jobName
              }
      }}
    </ng-template>
    <ng-template
      [ngIf]="
        modalData.cardAssignmentObjectType === cardAssignmentObjectTypeEnum.WORK_ORDER && singleWorkOrder !== null
      "
    >
      {{
        'modal.selectWorkOrderLine.label.cardIsScanned'
          | translate
            : {
                type: 'general.workOrder' | translate,
                id: singleWorkOrder.woNumber
              }
      }}
    </ng-template>
  </h4>
  <h4
    class="mb-4"
    *ngIf="
      mode === ESelectionMode.WO_SELECTION &&
      modalData.cardAssignmentObjectType === cardAssignmentObjectTypeEnum.JOB &&
      singleWorkOrder
    "
  >
    {{
      'modal.selectWorkOrderLine.label.willBeLoaded'
        | translate
          : {
              type: 'general.workOrder' | translate,
              id: singleWorkOrder.woNumber
            }
    }}
  </h4>
</ng-template>
