import { Action } from '@ngrx/store';
import {
  IAddScenarioRequest,
  BulkEditScenarioRequestInterface,
  ScenarioInterface,
  IEditScenarioRequest,
} from './scheduler-scenario.model';
import { SiteCRUDInterface } from '../../shared/component/filter/filter.class';
import { UserGetManyCRUDDataInterface } from '../../shared/service/user/user.model';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
  IErrorMessageWitObjectId,
} from '../../shared/model/interface/crud-response-interface.model';
import { ISchedulerResourceSetting } from '../../view/scheduler-resource-settings/scheduler-resource-settings.model';
import { GenericCrudRequestConstructionParameters } from '../../shared/model/interface/generic-api-request.model';
import { MergedArrayDataInterface } from '../../../constants.model';

export enum SchedulerScenarioActionTypes {
  LoadSchedulerScenarios = '[SchedulerScenario] Load Scheduler Scenarios',
  SchedulerScenariosLoaded = '[SchedulerScenario] Scheduler Scenarios Loaded',
  CreateSchedulerScenario = '[SchedulerScenario] Create Scheduler Scenario',
  SchedulerScenarioCreated = '[SchedulerScenario] Scheduler Scenario Created',
  UpdateSchedulerScenario = '[SchedulerScenario] Update Scheduler Scenario',
  SchedulerScenarioUpdated = '[SchedulerScenario] Scheduler Scenario Updated',
  CloneSchedulerScenario = '[SchedulerScenario] Clone Scheduler Scenario',
  SchedulerScenarioCloned = '[SchedulerScenario] Scheduler Scenario Cloned',
  DeleteSchedulerScenario = '[SchedulerScenario] Delete Scheduler Scenario',
  DeleteSchedulerScenarioCompleted = '[SchedulerScenario] Delete Scheduler Scenario Completed',
  SchedulerSiteDataLoading = '[SchedulerScenario] Scheduler Site Data Loading',
  SchedulerSiteDataLoaded = '[SchedulerScenario] Scheduler Site Data Loaded',
  BulkEditScenario = '[SchedulerScenario] Bulk Edit Scheduler Scenario',
  BulkEditScenarioCompleted = '[SchedulerScenario] Bulk Edit Scheduler Scenario Completed',
  LoadScenarioUsers = '[SchedulerScenario] Load Scenario Users',
  ScenarioUsersLoaded = '[SchedulerScenario] Scenario Users Loaded',
  SetTableSettings = '[SchedulerScenario] Set Table Settings',
  ScenarioError = '[SchedulerScenario] Scenario Error',
  LoadSchedulerResourceSettings = '[SchedulerScenario] Load Scheduler Resource Settings',
  SchedulerResourceSettingsLoaded = '[SchedulerScenario] Scheduler Resource Settings Loaded',
  ClearSchedulerScenarioState = '[SchedulerScenario] Clear Scheduler Scenario State',
  FetchError = '[SchedulerScenario] FetchError',
}

export class LoadSchedulerScenarios implements Action {
  readonly type = SchedulerScenarioActionTypes.LoadSchedulerScenarios;

  constructor(public params: GenericCrudRequestConstructionParameters) {}
}

export class SchedulerScenariosLoaded implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerScenariosLoaded;

  constructor(public payload: GetManyResponseInterface<ScenarioInterface>) {}
}

export class CreateSchedulerScenario implements Action {
  readonly type = SchedulerScenarioActionTypes.CreateSchedulerScenario;

  constructor(public scenario: IAddScenarioRequest) {}
}

export class SchedulerScenarioCreated implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerScenarioCreated;

  constructor(public payload: BaseOneResponseInterface<ScenarioInterface>) {}
}

export class UpdateSchedulerScenario implements Action {
  readonly type = SchedulerScenarioActionTypes.UpdateSchedulerScenario;

  constructor(public id: number, public scenario: IEditScenarioRequest) {}
}

export class SchedulerScenarioUpdated implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerScenarioUpdated;

  constructor(public payload: BaseOneResponseInterface<ScenarioInterface>) {}
}

export class SetTableSettings implements Action {
  readonly type = SchedulerScenarioActionTypes.SetTableSettings;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class CloneSchedulerScenario implements Action {
  readonly type = SchedulerScenarioActionTypes.CloneSchedulerScenario;

  constructor(public id: number) {}
}

export class SchedulerScenarioCloned implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerScenarioCloned;

  constructor(public payload: BaseOneResponseInterface<ScenarioInterface>) {}
}

export class DeleteSchedulerScenario implements Action {
  readonly type = SchedulerScenarioActionTypes.DeleteSchedulerScenario;

  constructor(public payload: number | number[]) {}
}

export class DeleteSchedulerScenarioCompleted implements Action {
  readonly type = SchedulerScenarioActionTypes.DeleteSchedulerScenarioCompleted;

  constructor(public payload: IErrorMessageWitObjectId[]) {}
}

export class SchedulerSiteDataLoading implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerSiteDataLoading;
}

export class SchedulerSiteDataLoaded implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerSiteDataLoaded;

  constructor(public payload: SiteCRUDInterface[]) {}
}

export class BulkEditSchedulerScenario implements Action {
  readonly type = SchedulerScenarioActionTypes.BulkEditScenario;

  constructor(public scenarios: BulkEditScenarioRequestInterface[]) {}
}

export class BulkEditSchedulerScenarioCompleted implements Action {
  readonly type = SchedulerScenarioActionTypes.BulkEditScenarioCompleted;

  constructor(public payload: MergedArrayDataInterface[]) {}
}

export class LoadScenarioUsers implements Action {
  readonly type = SchedulerScenarioActionTypes.LoadScenarioUsers;

  constructor(public userId: number, public searchText: string = null, public siteId?: number) {}
}

export class ScenarioUsersLoaded implements Action {
  readonly type = SchedulerScenarioActionTypes.ScenarioUsersLoaded;

  constructor(public payload: UserGetManyCRUDDataInterface) {}
}

export class LoadSchedulerResourceSettings implements Action {
  readonly type = SchedulerScenarioActionTypes.LoadSchedulerResourceSettings;

  constructor(public siteId: number) {}
}

export class SchedulerResourceSettingsLoaded implements Action {
  readonly type = SchedulerScenarioActionTypes.SchedulerResourceSettingsLoaded;

  constructor(public payload: ISchedulerResourceSetting[]) {}
}

export class ClearSchedulerScenarioState implements Action {
  readonly type = SchedulerScenarioActionTypes.ClearSchedulerScenarioState;
}

export class ScenarioError implements Action {
  readonly type = SchedulerScenarioActionTypes.ScenarioError;
}

export class FetchError implements Action {
  readonly type = SchedulerScenarioActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type SchedulerScenarioActions =
  | LoadSchedulerScenarios
  | SchedulerScenariosLoaded
  | CreateSchedulerScenario
  | SchedulerScenarioCreated
  | UpdateSchedulerScenario
  | SchedulerScenarioUpdated
  | CloneSchedulerScenario
  | SchedulerScenarioCloned
  | DeleteSchedulerScenario
  | DeleteSchedulerScenarioCompleted
  | SchedulerSiteDataLoading
  | SchedulerSiteDataLoaded
  | BulkEditSchedulerScenario
  | BulkEditSchedulerScenarioCompleted
  | LoadScenarioUsers
  | ScenarioUsersLoaded
  | SetTableSettings
  | LoadSchedulerResourceSettings
  | SchedulerResourceSettingsLoaded
  | ClearSchedulerScenarioState
  | ScenarioError
  | FetchError;
