import * as WorkOrderSettingActions from './work-order-setting.actions';
import { WorkOrderSettingState } from './work-order-setting.model';

export const workOrderSettingState: WorkOrderSettingState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getWorkOrderSettingLoading: false,
  getWorkOrderSettingLoaded: false,
  workOrderSetting: null,
  addWorkOrderSettingLoading: false,
  addWorkOrderSettingLoaded: false,
  editWorkOrderSettingLoading: false,
  editWorkOrderSettingLoaded: false,
  deleteWorkOrderSettingLoading: false,
  deleteWorkOrderSettingLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  activitiesDataLoading: false,
  activitiesDataLoaded: false,
  activitiesData: [],
  tasksDataLoading: false,
  tasksDataLoaded: false,
  tasksData: [],
};

export function workOrderSettingReducer(
  state: WorkOrderSettingState = workOrderSettingState,
  action: WorkOrderSettingActions.WorkOrderSettingActions,
): WorkOrderSettingState {
  switch (action.type) {
    case WorkOrderSettingActions.ActionTypes.WORK_ORDER_SETTING_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.WORK_ORDER_SETTING_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.GET_WORK_ORDER_SETTING:
      return {
        ...state,
        ...{
          getWorkOrderSettingLoading: true,
          getWorkOrderSettingLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.GET_WORK_ORDER_SETTING_COMPLETED:
      return {
        ...state,
        ...{
          workOrderSetting: action.payload.data,
          getWorkOrderSettingLoading: false,
          getWorkOrderSettingLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.ADD_WORK_ORDER_SETTING:
      return {
        ...state,
        addWorkOrderSettingLoading: true,
        addWorkOrderSettingLoaded: false,
      };
    case WorkOrderSettingActions.ActionTypes.ADD_WORK_ORDER_SETTING_COMPLETED:
      return {
        ...state,
        addWorkOrderSettingLoading: false,
        addWorkOrderSettingLoaded: true,
      };
    case WorkOrderSettingActions.ActionTypes.EDIT_WORK_ORDER_SETTING:
      return {
        ...state,
        editWorkOrderSettingLoading: true,
        editWorkOrderSettingLoaded: false,
      };
    case WorkOrderSettingActions.ActionTypes.EDIT_WORK_ORDER_SETTING_COMPLETED:
      return {
        ...state,
        editWorkOrderSettingLoading: false,
        editWorkOrderSettingLoaded: true,
      };

    case WorkOrderSettingActions.ActionTypes.DELETE_WORK_ORDER_SETTING:
      return {
        ...state,
        deleteWorkOrderSettingLoading: true,
        deleteWorkOrderSettingLoaded: false,
      };
    case WorkOrderSettingActions.ActionTypes.DELETE_WORK_ORDER_SETTING_COMPLETED:
      return {
        ...state,
        deleteWorkOrderSettingLoading: false,
        deleteWorkOrderSettingLoaded: true,
      };
    case WorkOrderSettingActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.ACTIVITIES_DATA_LOADING:
      return {
        ...state,
        ...{
          activitiesDataLoading: true,
          activitiesDataLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.ACTIVITIES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          activitiesData: action.payload,
          activitiesDataLoading: false,
          activitiesDataLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          tasksDataLoading: true,
          tasksDataLoaded: false,
        },
      };
    case WorkOrderSettingActions.ActionTypes.TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          tasksData: action.payload,
          tasksDataLoading: false,
          tasksDataLoaded: true,
        },
      };
    case WorkOrderSettingActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
