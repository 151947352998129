import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ResponseArrayInterface, ResponseInterface } from '../../model/interface/generic-api-response.model';
import { IActivityHistory } from './activity-history.model';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../constants';
import {
  IActivityHistoryBulkCreateExcelData,
  IActivityHistoryBulkCreateExcelErrorData,
  IActivityHistoryBulkCreateExcelUserData,
  IActivityHistoryTableData,
  IWorkOrdersForExcelResponse,
  OngoingActivityValidationDataInterface,
  OverlappedActivitiesDataInterface,
  OverlappingDataInterface,
  WorkorderNumbersDataInterface,
  WorkOrderResponseDataInterface,
} from '../../../store/activity-history/activity-history.model';
import { ActivityHistoryBulkUpdateRequestModel } from '../../component/activity-form/activity-form.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { ActivityLogFormattedInterface } from '../../../store/reports/activity-logs/activity-logs.model';
import * as _ from 'lodash';
import { ActivityTypes } from '../../model/enum/activity-types';
import { EntityTranslatorService } from '../entity-translator/entity-translator.service';
import moment from 'moment/moment';
import { ICreateExcel, ICreateExcelSheet, IExcelColumnDefinition } from '../excel/excel.helper';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../excel/excel.enum';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../model/enum/excel-date-format';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { TimeZoneOptions } from '../../model/enum/timezone-list';
import { ExcelHelperService } from '../excel/excel.helper.service';
import * as ObjectActions from '../../../store/activity-history/activity-history.actions';

@Injectable({
  providedIn: 'root',
})
export class ActivityHistoryService {
  private timezone: string = TimeZoneOptions.UTC;
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly translatorService: EntityTranslatorService,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly excelHelper: ExcelHelperService,
  ) {
    const destroySubject: Subject<boolean> = new Subject<boolean>();

    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          destroySubject.next(true);
          destroySubject.complete();
        }
      });
  }

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    lines: `${this.api}/lines`,
    validateActivityOverlap: 'validate-activity-overlap',
    auditLog: `${this.api}/audit-logs/activity-history`,
    bulkCreate: 'bulk/create',
  };
  private readonly activityHistoryDefaultParams: HttpParams = new HttpParams()
    .append('join', 'task')
    .append('join', 'task.equipment')
    .append('join', 'activity')
    .append('join', 'workOrder')
    .append('join', 'workOrder.product');
  public timeEntryModalWorkOrdersSubject: BehaviorSubject<Partial<IActivityHistory>> = new BehaviorSubject<
    Partial<IActivityHistory>
  >(null);

  public getActivity(id: number): Observable<ResponseInterface<IActivityHistory>> {
    const params: HttpParams = this.activityHistoryDefaultParams.append('join', 'user').append('join', 'createdByUser');
    return this.http.get<ResponseInterface<IActivityHistory>>(`${this.routes.activityHistory}/${id}`, {
      params,
    });
  }

  public getActivities(
    search: object,
    page: number = 1,
    limit: number = STATIC_MAX_LIMIT_OF_CRUD,
    orderBy: string = null,
    orderDesc: boolean = null,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    let params: HttpParams = this.activityHistoryDefaultParams
      .append('join', 'workOrder.runningLine||productId')
      .append('join', 'workOrder.ongoingFinalizeOeeCalculation')
      .set('s', JSON.stringify(search))
      .set('page', String(page))
      .set('limit', String(limit));

    if (orderBy !== null && orderDesc !== null) {
      params = params.set('sort', `${orderBy},${orderDesc ? 'DESC' : 'ASC'}`);
    }

    return this.http.get<ResponseArrayInterface<IActivityHistory>>(this.routes.activityHistory, {
      params,
    });
  }

  public validateOngoingActivityOverlap(
    lineId: number,
    params: OngoingActivityValidationDataInterface,
  ): Observable<OverlappedActivitiesDataInterface> {
    return this.http.post<OverlappedActivitiesDataInterface>(
      `${this.routes.lines}/${lineId}/${this.routes.validateActivityOverlap}`,
      params,
    );
  }

  public validateActivityOverlap(requestData: OverlappingDataInterface): Observable<OverlappedActivitiesDataInterface> {
    return this.http.post<OverlappedActivitiesDataInterface>(
      `${this.routes.activityHistory}/${this.routes.validateActivityOverlap}`,
      requestData,
    );
  }

  public overlappingActivitiesActivityHistory(
    data: ActivityHistoryBulkUpdateRequestModel,
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      `${this.routes.activityHistory}/bulk/overlapping-activities`,
      data,
      { params },
    );
  }

  public deleteManyActivityHistoryData(data: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      body: { activityIds: data },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.activityHistory}/bulk/delete`, httpOptions);
  }

  public setActivityHistoryTaskDescription(lineTimeLine: any): void {
    if (lineTimeLine.activityGroupType === 'runTime') {
      lineTimeLine.taskDescription = lineTimeLine.productDescription;
    } else {
      lineTimeLine.taskDescription = lineTimeLine.taskName;
    }
  }

  public checkMissingDataConditions(
    selectedActivityIds: number[],
    data: IActivityHistoryTableData[] | ActivityLogFormattedInterface[],
  ): boolean[] {
    let missingWo: boolean = false;
    let activityWithoutWo: boolean = false;
    let missingTask: boolean = false;
    selectedActivityIds.forEach((id: number) => {
      const selectedActivity: IActivityHistoryTableData = _.find(data, { id });

      if (
        selectedActivity?.workOrder?.woNumber === '' ||
        selectedActivity?.workOrder?.woNumber === null ||
        selectedActivity?.workOrderNumber === '' ||
        selectedActivity?.workOrderNumber === null
      ) {
        activityWithoutWo = true;

        if (
          selectedActivity?.activity?.activityType !== ActivityTypes.IDLE_TIME ||
          selectedActivity?.activityType !== ActivityTypes.IDLE_TIME
        ) {
          missingWo = true;
        }
      }

      if (
        (selectedActivity?.activity?.activityType !== ActivityTypes.RUN_TIME ||
          selectedActivity?.activityType !== ActivityTypes.RUN_TIME) &&
        (selectedActivity?.taskName === '' || selectedActivity?.taskName === null)
      ) {
        missingTask = true;
      }
    });

    return [missingWo, activityWithoutWo, missingTask];
  }

  public formatActivityHistoryWorkOrderData(resData: WorkOrderResponseDataInterface): WorkOrderResponseDataInterface {
    const data: WorkorderNumbersDataInterface[] = _.cloneDeep(resData.data);
    data.forEach((row: WorkorderNumbersDataInterface) => {
      this.translatorService.translate(row);
      row.isCalculating = Number(row.completed) === 1 && row.goodCount === null;
    });

    return { ...resData, data };
  }

  public getActivityHistoryAuditLog(uuid: string): Observable<BulkResponseDataInterface> {
    return this.http.get<BulkResponseDataInterface>(`${this.routes.auditLog}/${uuid}`);
  }

  public createManyActivityHistory(
    data: ActivityHistoryBulkUpdateRequestModel,
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.routes.activityHistory}/${this.routes.bulkCreate}`, data, {
      params,
    });
  }

  public getWorkOrders(params: HttpParams): Observable<GetManyResponseInterface<IWorkOrdersForExcelResponse>> {
    return this.http.get<GetManyResponseInterface<IWorkOrdersForExcelResponse>>(`${this.api}/work-orders`, { params });
  }

  public downloadExcel(
    lineName: string,
    activityHistories: IActivityHistoryBulkCreateExcelData[],
    userData: IActivityHistoryBulkCreateExcelUserData[],
    exampleData: IActivityHistoryBulkCreateExcelData[],
    errorData: IActivityHistoryBulkCreateExcelErrorData[] = [],
  ): void {
    const pageTitle: string = `${this.translate.instant('pageTitles.activity_history')} ${lineName}`;
    const excelName: string =
      errorData.length === 0
        ? `${pageTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`
        : `${pageTitle} ${moment().tz(this.timezone).format(this.dateFormat$)} ${this.translate.instant(
            'general.error',
          )}`;
    let workSheets: ICreateExcelSheet[];

    if (errorData.length > 0) {
      const errorExcelOptions: ICreateExcel = {
        data: errorData,
        columns: this.getActivityHistoriesColumnsForExcel(true),
      };

      workSheets = this.getWorkSheets(
        {
          errorExcelOptions,
        },
        true,
      );
    } else {
      const activityHistoriesExcelOptions: ICreateExcel = {
        data: activityHistories,
        columns: this.getActivityHistoriesColumnsForExcel(),
      };
      const userActivitiesExcelOptions: ICreateExcel = {
        data: userData,
        columns: this.getActivityUserDataColumnsForExcel(),
      };
      const exampleActivityHistoriesExcelOptions: ICreateExcel = {
        data: exampleData,
        columns: this.getActivityHistoriesColumnsForExcel(),
      };

      workSheets = this.getWorkSheets({
        activityHistoriesExcelOptions,
        userActivitiesExcelOptions,
        exampleActivityHistoriesExcelOptions,
      });
    }

    this.excelHelper
      .createExcel(
        excelName,
        { name: 'activityHistory', withData: true },
        workSheets,
        this.timezone,
        this.dateFormat$,
        this.timeFormat$,
      )
      .then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadActivityHistoryExcelCompleted());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError([]));
        },
      );
  }

  private getWorkSheets(createExcelData: any, isErrorExcel: boolean = false): ICreateExcelSheet[] {
    if (isErrorExcel) {
      return [
        {
          sheetTitle: this.translate.instant('activityHistory.excel.sheet.activityHistoriesDataError.title'),
          withData: true,
          sheetType: EExcelSheetType.TABLE,
          params: createExcelData.errorExcelOptions,
        },
      ];
    }

    return [
      {
        sheetTitle: this.translate.instant('activityHistory.excel.readme.worksheetName'),
        sheetType: EExcelSheetType.ACTIVITY_HISTORY_README,
      },
      {
        sheetTitle: this.translate.instant('activityHistory.excel.sheet.activityHistoriesData.title'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: createExcelData.activityHistoriesExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('activityHistory.excel.sheet.userActivities.title'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: createExcelData.userActivitiesExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('activityHistory.excel.sheet.exampleActivityHistoriesData.title'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: createExcelData.exampleActivityHistoriesExcelOptions,
      },
    ];
  }

  private getActivityHistoriesColumnsForExcel(isErrorExcel: boolean = false): IExcelColumnDefinition[] {
    const columns: IExcelColumnDefinition[] = [
      {
        header: this.translate.instant('activityHistory.excel.startDate.header'),
        key: isErrorExcel ? 'start' : 'startDate',
        width: EExcelColumnWidth.DATE_TIME,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('activityHistory.excel.endDate.header'),
        key: isErrorExcel ? 'end' : 'endDate',
        width: EExcelColumnWidth.DATE_TIME,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('activityHistory.excel.activityName.header'),
        key: 'activityName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.taskName.header'),
        key: 'taskName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.equipmentName.header'),
        key: 'equipmentName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.phaseName.header'),
        key: 'phaseName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.workOrder.header'),
        key: 'workOrder',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
    ];

    if (isErrorExcel) {
      columns.push({
        header: this.translate.instant('excel.column.errorTitle'),
        key: 'errorMessages',
        width: EExcelColumnWidth.ERROR,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.CUSTOM,
          allowBlank: false,
          formulae: [],
          showErrorMessage: false,
          error: this.translate.instant('general.error'),
          errorStyle: 'Error',
          showInputMessage: false,
        },
      });
    }

    return columns;
  }

  private getActivityUserDataColumnsForExcel(): IExcelColumnDefinition[] {
    return [
      {
        header: this.translate.instant('activityHistory.excel.activityName.header'),
        key: 'activityName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.taskName.header'),
        key: 'taskName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.equipmentName.header'),
        key: 'equipmentName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('activityHistory.excel.phaseName.header'),
        key: 'phaseName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
    ];
  }

  public async getActivityHistoryFromExcel(file: File): Promise<IActivityHistoryBulkCreateExcelData[]> {
    const workbook: Workbook = await ExcelHelperService.getExcelWorkBookFromFile(file);
    const activityHistorySheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('activityHistory.excel.sheet.activityHistoriesData.title'),
    );

    if (!activityHistorySheet) {
      return null;
    }

    const activityHistoriesExcelOptions: ICreateExcel = {
      data: [],
      columns: this.getActivityHistoriesColumnsForExcel(),
    };

    const { columns } = activityHistoriesExcelOptions;
    const columnKeys = ExcelHelperService.getSheetColumnKeys(columns);
    return this.excelHelper.getExcelRowsFromWorkSheet(activityHistorySheet, columnKeys, {}, true);
  }
}
