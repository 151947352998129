import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {combineLatest, forkJoin, from, map, Observable, of} from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { switchMap } from 'rxjs/operators';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface, TaskFilterCRUDInterface
} from "../../../../shared/component/filter/filter.class";
import {
  IWorkOrderSettingRow
} from "../../../../view/settings/opc-tag-automation-settings/work-order-setting/work-order-setting.model";
import {SiteService} from "../../../../shared/service/filter/site.service";
import {LineService} from "../../../../shared/service/line/line.service";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderSettingService {
  private readonly routes = {
    workOrderSetting: `${this.baseUrl}/opc-tag-automation-settings/work-order-setting`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
  ) {}

  public getData(params: HttpParams): Observable<any> {
    return this.http.get<GetManyResponseInterface<any>>(this.routes.workOrderSetting, {
      params,
    });
  }

  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    workOrderSettingsRecord: BaseOneResponseInterface<IWorkOrderSettingRow>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    activities: ActivityCRUDInterface[];
    tasks: TaskFilterCRUDInterface[];
  }> {
    return this.http
      .get<BaseOneResponseInterface<IWorkOrderSettingRow>>(`${this.routes.workOrderSetting}/${recordId}`, {
        params,
      })
      .pipe(
        switchMap((workOrderSettingsRecord) => {
          const search = {
            statusId: 1,
            siteId: workOrderSettingsRecord.data.siteId,
          };
          const lineHttpParams = new HttpParams()
            .set('s', JSON.stringify(search))
            .set('limit', '1000')
            .set('join', 'lineTypeName');

          const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

          const searchTask = {
            statusId: 1,
            activityId: workOrderSettingsRecord.data.targetActivityId,
          };

          const tasksHttpParams = new HttpParams().set('s', JSON.stringify(searchTask)).set('limit', '1000');

          return combineLatest([
            this.lineService.getLines(lineHttpParams),
            this.siteService.getData(siteHttpParams),
            this.tasksService.getData(tasksHttpParams),
          ]).pipe(
            switchMap(([lineRecords, siteRecords, tasksRecords]) => {
              const selectedLine = _.find(lineRecords.data, { id: workOrderSettingsRecord.data.lineId });
              const activitiesSearch = {
                active: 1,
                id: { $in: selectedLine.activityIds.split(',') ?? [] },
              };

            const activitiesHttpParams = new HttpParams()
              .set('s', JSON.stringify(activitiesSearch))
              .set('limit', '1000');

            return from(this.activityService.getData(activitiesHttpParams)).pipe(switchMap((activityRecords) => {
              return of({
                    workOrderSettingsRecord,
                    sites: siteRecords,
                    lines: lineRecords,
                    activities: activityRecords,
                    tasks: tasksRecords,
                  });
            }));
          }),
        );
      }),
    );
  }

  public getOne(recordId: number, params?: HttpParams): Observable<any> {
    return this.http.get<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}/${recordId}`, { params }).pipe(
      switchMap((response) => {
        const httpParamsActivity = new HttpParams()
          .set('s', JSON.stringify({ active: 1, id: response.data.targetActivityId }))
          .set('limit', '1000');
        const httpParamsTask = new HttpParams()
          .set('s', JSON.stringify({ statusId: 1, id: response.data.targetTaskId }))
          .set('limit', '1000');

        const activityRequest = this.activityService.getData(httpParamsActivity);
        const taskRequest = this.tasksService.getData(httpParamsTask);

        return forkJoin([activityRequest, taskRequest]).pipe(
          map(([activityResponse, taskResponse]) => {
            return {
              ...response.data,
              activity: activityResponse,
              task: taskResponse,
            };
          }),
        );
      }),
    );
  }

  public addOne(record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.post<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}`, record);
  }

  public editOne(recordId: number, record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.patch<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          workOrderSettings: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.workOrderSetting}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.workOrderSetting}/${recordIds[0]}`);
  }
}
