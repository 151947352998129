import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {combineLatest, Observable, switchMap} from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  IHeartbeat,
  IHeartbeatRow,
} from '../../../../view/settings/opc-tag-automation-settings/heartbeats/heartbeats.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { map } from 'rxjs/operators';
import { NotificationGroupInterface } from '../../nofication-center/notification-groups/notification-groups.model';
import { INotificationTemplate } from '../../notification-center/notification-templates/notification-templates.model';
import { NotificationTemplateService } from '../../notification-center/notification-templates/notification-template.service';
import { NotificationGroupsService } from '../../nofication-center/notification-groups/notification-groups.service';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  private readonly HEARTBEAT_URL = `${this.baseUrl}/opc-tag-automation-settings/heartbeat`;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly notificationTemplateService: NotificationTemplateService,
    private readonly notificationGroupsService: NotificationGroupsService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<IHeartbeat>> {
    return this.http.get<GetManyResponseInterface<IHeartbeat>>(this.HEARTBEAT_URL, {
      params,
    });
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<IHeartbeat>> {
    return this.http.get<BaseOneResponseInterface<IHeartbeat>>(`${this.HEARTBEAT_URL}/${recordId}`, {
      params,
    });
  }

  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    heartbeat: BaseOneResponseInterface<IHeartbeat>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    notificationGroups: GetManyResponseInterface<Partial<NotificationGroupInterface>>;
    notificationTemplates: GetManyResponseInterface<Partial<INotificationTemplate>>;
  }> {
    return combineLatest([
      this.http.get<BaseOneResponseInterface<IHeartbeat>>(`${this.HEARTBEAT_URL}/${recordId}`, {
        params,
      }),
    ]).pipe(
      switchMap(([heartbeatRecord]) => {
        const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

        const lineSearch = {
          statusId: 1,
          siteId: heartbeatRecord['data']['siteId'],
        };
        const lineHttpParams = new HttpParams()
          .set('s', JSON.stringify(lineSearch))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return combineLatest([
          this.siteService.getData(siteHttpParams),
          this.lineService.getLines(lineHttpParams),
          this.notificationTemplateService.getNotificationTemplates(httpParams),
          this.notificationGroupsService.getNotificationGroups(httpParams),
        ]).pipe(
          map(([siteRecords, lineRecords, notificationTemplateRecords, notificationGroupRecords]) => {
            return {
              heartbeat: heartbeatRecord,
              sites: siteRecords,
              lines: lineRecords,
              notificationGroups: notificationGroupRecords,
              notificationTemplates: notificationTemplateRecords,
            };
          }),
        );
      }),
    );
  }

  public addOne(record: IHeartbeatRow): Observable<BaseOneResponseInterface<IHeartbeat>> {
    return this.http.post<BaseOneResponseInterface<IHeartbeat>>(`${this.HEARTBEAT_URL}`, record);
  }

  public editOne(recordId: number, record: IHeartbeatRow): Observable<BaseOneResponseInterface<IHeartbeat>> {
    return this.http.patch<BaseOneResponseInterface<IHeartbeat>>(`${this.HEARTBEAT_URL}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          heartbeats: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.HEARTBEAT_URL}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.HEARTBEAT_URL}/${recordIds[0]}`);
  }
}
