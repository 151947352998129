import { Component, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as AppActions from '../../../store/app/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { EErrorMessages } from '../../../../constants';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: [],
})
export class ErrorModalComponent {
  @ViewChild('error_modal') errorModalTemplateRef: ViewChildDecorator;
  public headerText: string = '';
  public errorMessage: string = '';
  public excelTotalCountMessage: string = '';
  public excelSuccessCountMessage: string = '';
  public excelFailCountMessage: string = '';
  public buttonText: string = '';
  public readonly fileCannotReadErrorMessage: string = `${this.translate.instant(
    EErrorMessages.FILE_CANNOT_READ_ERROR_TITLE,
  )}. ${this.translate.instant(EErrorMessages.FILE_CANNOT_READ_ERROR)}`;
  public readonly okMessage: string = this.translate.instant('general.ok');
  public readonly bulkUploadMessage: string = this.translate.instant('general.excel.dropdownElement.bulkUpload');
  private readonly scwModalSm: string = 'scw-modal-sm';

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
  ) {}

  public showError(
    errorMessage: string,
    header: string = this.bulkUploadMessage,
    buttonText: string = this.okMessage,
    totalCount?: number,
    failCount?: number,
    propertyName?: string,
  ): void {
    this.store.dispatch(new AppActions.HideLoader());
    this.headerText = header;
    this.errorMessage = errorMessage;
    this.excelTotalCountMessage = totalCount
      ? this.translate.instant('excel.messages.totalCount', {
          count: totalCount,
          items: propertyName,
        })
      : null;
    this.excelSuccessCountMessage =
      totalCount - failCount
        ? this.translate.instant('excel.messages.successCount', {
            count: totalCount - failCount,
          })
        : null;
    this.excelFailCountMessage = failCount
      ? this.translate.instant('excel.messages.failCount', {
          count: failCount,
        })
      : null;
    this.buttonText = buttonText;
    this.ngbModal.open(this.errorModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }
}
