import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ILookupData, ILookupType } from './lookups.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { BaseCustomDashboardWidget } from '../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/base-custom-dashboard-widget.class';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public readonly translate: TranslateService,
  ) {}

  private readonly routes = {
    lookups: '/lookups',
  };

  public getLookups(
    params: HttpParams,
    options: Parameters<HttpClient['get']>[1] = {},
  ): Observable<GetManyResponseInterface<ILookupData>> {
    return this.http.get<GetManyResponseInterface<ILookupData>>(`${this.api}${this.routes.lookups}`, {
      ...options,
      params,
    });
  }

  public getLookupsWithType(
    type: ILookupType,
    skipErrorMessageAndRedirect: boolean = false,
  ): Observable<GetManyResponseInterface<ILookupData>> {
    const params: HttpParams = new HttpParams().set('s', JSON.stringify({ type }));

    return this.getLookups(
      params,
      skipErrorMessageAndRedirect ? { headers: BaseCustomDashboardWidget.widgetHttpHeader } : {},
    ).pipe(
      map((response: GetManyResponseInterface<ILookupData>) => {
        const data: ILookupData[] = response.data.map((lookup: ILookupData) => {
          let lookupType: string = '';
          let postFixText: string = '';

          switch (lookup.type) {
            case ILookupType.MATERIAL_TYPE:
              lookupType = 'materialType';
              break;
            case ILookupType.PLANNING_TYPE:
              lookupType = 'planningType';
              break;
            case ILookupType.PRODUCT_UNIT:
              lookupType = 'unit';
              postFixText = '.title';
              break;
          }

          return {
            ...lookup,
            name: this.translate.instant(`products.lookups.${lookupType}.${_.camelCase(lookup.name)}${postFixText}`),
            key: `products.lookups.${lookupType}.${_.camelCase(lookup.name)}${postFixText}`,
          };
        });

        return {
          ...response,
          data,
        };
      }),
    );
  }
}
