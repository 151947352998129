import * as Actions from './root-cause-group.actions';
import { RootCauseGroupStateInterface } from './root-cause-group.model';

const initialState: RootCauseGroupStateInterface = {
  rootCauseGroupDataLoading: false,
  rootCauseGroupDataLoaded: false,
  rootCauseGroupCreateUpdateDeleteDataLoading: false,
  rootCauseGroupCreateUpdateDeleteDataLoaded: false,
  rootCauseTypeDataLoading: false,
  rootCauseTypeDataLoaded: false,
  rootCauseTypeCreateUpdateDeleteDataLoading: false,
  rootCauseTypeCreateUpdateDeleteDataLoaded: false,
  rootCauseGroup: null,
  rootCauseType: null,
  recordTotal: 1,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  rootCauseGroupsEditLoaded: false,
  rootCauseGroupsEditLoading: false,
  rootCauseGroupFilterDataLoading: false,
  rootCauseGroupFilterDataLoaded: false,
};

export function rootCauseGroupReducer(
  state: RootCauseGroupStateInterface = initialState,
  action: Actions.RootCauseGroupActions,
): RootCauseGroupStateInterface {
  switch (action.type) {
    case Actions.ROOT_CAUSE_GROUP_DATA_LOADING:
      return {
        ...state,
        rootCauseGroupDataLoading: true,
        rootCauseGroupDataLoaded: false,
      };
    case Actions.ROOT_CAUSE_GROUP_DATA_LOADED:
      return {
        ...state,
        rootCauseGroupDataLoading: false,
        rootCauseGroupDataLoaded: true,
        rootCauseGroup: action.data,
      };
    case Actions.ROOT_CAUSE_TYPE_DATA_LOADING:
      return {
        ...state,
        rootCauseTypeDataLoading: true,
        rootCauseTypeDataLoaded: false,
      };
    case Actions.ROOT_CAUSE_TYPE_DATA_LOADED:
      return {
        ...state,
        rootCauseTypeDataLoading: false,
        rootCauseTypeDataLoaded: true,
        rootCauseType: action.data,
      };
    case Actions.GET_ROOT_CAUSE_GROUP_COUNT_COMPLETED:
      return {
        ...state,
        recordTotal: action.data.total,
      };
    case Actions.DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_ROOT_CAUSE_GROUP_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    case Actions.UPDATE_ROOT_CAUSE_GROUPS:
      return {
        rootCauseGroupsEditLoading: true,
        rootCauseGroupsEditLoaded: true,
        ...state,
      };
    case Actions.CLEAR_STATE:
      return { ...initialState };
    case Actions.ROOT_CAUSE_GROUP_FILTER_DATA_LOADING:
      return {
        ...state,
        ...{
          rootCauseGroupDataLoading: false,
          rootCauseGroupDataLoaded: false,
          rootCauseGroupFilterDataLoading: true,
          rootCauseGroupFilterDataLoaded: false,
        },
      };
    case Actions.ROOT_CAUSE_GROUP_FILTER_DATA_LOADED:
      return {
        ...state,
        ...{
          rootCauseGroup: action.payload,
          rootCauseGroupFilterDataLoading: false,
          rootCauseGroupFilterDataLoaded: true,
        },
      };
    default:
      return state;
  }
}
