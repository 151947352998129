import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderInterface } from '../../../service/page-header/page-header.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { EPageMode } from './page-mode-switch-button.interface';
import { TranslateService } from '@ngx-translate/core';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { ofType } from '@ngrx/effects';
import { EPageHeaderEditModeOperation } from '../../../../store/page-header/page-header.model';

@Component({
  selector: 'app-page-mode-switch-button',
  templateUrl: './page-mode-switch-button.component.html',
  styleUrls: ['./page-mode-switch-button.component.scss'],
})
export class PageModeSwitchButtonComponent implements OnInit, OnDestroy {
  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };

  public pageModeEditTranslation: string;
  public pageModeSaveTranslation: string;
  public pageModeCancelTranslation: string;
  public pageModeAddTranslation: string;
  public ePageMode = EPageMode;
  public pageMode: EPageMode = EPageMode.VIEW;

  public editModeButtonsShownStatus = {
    isCancelButtonShown: false,
    isSaveButtonShown: false,
    isAddButtonShown: false,
  };

  public isAnyEditModeButtonsShown = false;

  private readonly subscriptions: Subscription[] = [];
  public readonly EPageHeaderEditModeOperation = EPageHeaderEditModeOperation;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.actionsSubject
        .pipe(ofType(PageHeaderActions.SET_PAGE_TO_EDIT_OR_VIEW_MODE))
        .subscribe((response: PageHeaderActions.SetPageToEditOrViewMode) => {
          this.pageMode = response.isEditMode ? EPageMode.EDIT : EPageMode.VIEW;
        }),
    );

    this.pageModeEditTranslation = this.translate.instant(
      `general.pageModeButton.edit.${this.pageHeader.titleKey}.label`,
    );
    this.pageModeSaveTranslation = this.translate.instant(
      `general.pageModeButton.save.${this.pageHeader.titleKey}.label`,
    );
    this.pageModeCancelTranslation = this.translate.instant(
      `general.pageModeButton.cancel.${this.pageHeader.titleKey}.label`,
    );
    this.pageModeAddTranslation = this.translate.instant(
      `general.pageModeButton.add.${this.pageHeader.titleKey}.label`,
    );

    this.editModeButtonsShownStatus.isSaveButtonShown = ['custom_dashboard_content'].includes(this.pageHeader.titleKey);
    this.editModeButtonsShownStatus.isCancelButtonShown = ['custom_dashboard_content'].includes(
      this.pageHeader.titleKey,
    );
    this.editModeButtonsShownStatus.isAddButtonShown = ['custom_dashboard_content'].includes(this.pageHeader.titleKey);
    this.isAnyEditModeButtonsShown = Object.values(this.editModeButtonsShownStatus).some(Boolean);
  }

  public onModeChange(nextMode: EPageMode, operation: EPageHeaderEditModeOperation): void {
    this.store.dispatch(new PageHeaderActions.SetPageToEditOrViewMode(nextMode === EPageMode.EDIT, operation));

    this.pageMode = nextMode;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
