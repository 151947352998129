import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, forkJoin, Observable, switchMap } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { map } from 'rxjs/operators';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import * as _ from 'lodash';
import { IStationStateMappingRow } from '../../../../view/settings/opc-tag-automation-settings/station-state-mapping/station-state-mapping.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';
import { LineStationService } from '../../../../shared/service/line-station/line-station.service';

@Injectable({
  providedIn: 'root',
})
export class StationStateMappingService {
  private readonly routes = {
    stationStateMapping: `${this.baseUrl}/opc-tag-automation-settings/station-state-mapping`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly stationService: LineStationService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<IStationStateMappingRow[]>> {
    return this.http.get<GetManyResponseInterface<IStationStateMappingRow[]>>(this.routes.stationStateMapping, {
      params,
    });
  }
  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    stationStateMappingRecord: BaseOneResponseInterface<IStationStateMappingRow>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
    activities: ActivityCRUDInterface[];
    tasks: TaskFilterCRUDInterface[];
    lineTasks: TaskFilterCRUDInterface[];
    extendedTasks: TaskFilterCRUDInterface[];
  }> {
    return combineLatest([
      this.http.get<BaseOneResponseInterface<IStationStateMappingRow>>(
        `${this.routes.stationStateMapping}/${recordId}`,
        {
          params,
        },
      ),
    ]).pipe(
      switchMap(([stationStateMappingRecord]) => {
        const search = {
          statusId: 1,
          siteId: stationStateMappingRecord['data']['siteId'],
        };
        const lineHttpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

        const searchTask = {
          statusId: 1,
          activityId: stationStateMappingRecord.data.activityId,
        };

        const tasksHttpParams = new HttpParams().set('s', JSON.stringify(searchTask)).set('limit', '1000');

        const searchLineTask = {
          statusId: 1,
          activityId: stationStateMappingRecord.data.lineActivityId,
        };

        const lineTasksHttpParams = new HttpParams().set('s', JSON.stringify(searchLineTask)).set('limit', '1000');

        const searchExtendedTask = {
          statusId: 1,
          activityId: stationStateMappingRecord.data.lineExtendedTargetActivityId,
        };

        const extendedTasksHttpParams = new HttpParams()
          .set('s', JSON.stringify(searchExtendedTask))
          .set('limit', '1000');

        return combineLatest([
          this.siteService.getData(siteHttpParams),
          this.lineService.getLines(lineHttpParams),
          this.stationService.getLineStationData(stationStateMappingRecord['data']['lineId']),
          this.tasksService.getData(tasksHttpParams),
          this.tasksService.getData(lineTasksHttpParams),
          this.tasksService.getData(extendedTasksHttpParams),
        ]).pipe(
          switchMap(
            ([siteRecords, lineRecords, stationsRecords, tasksRecords, lineTasksRecords, extendedTasksRecords]) => {
              const selectedLine = _.find(lineRecords.data, { id: stationStateMappingRecord.data.lineId });
              const activitiesSearch = {
                active: 1,
                id: { $in: selectedLine.activityIds.split(',') ?? [] },
              };

              const activitiesHttpParams = new HttpParams()
                .set('s', JSON.stringify(activitiesSearch))
                .set('limit', '1000');

              const observables = {
                activities: this.activityService.getData(activitiesHttpParams),
              };

              return forkJoin(observables).pipe(
                map(
                  (resolvedObservables: {
                    stationStateMappingRecord: BaseOneResponseInterface<IStationStateMappingRow>;
                    sites: SiteCRUDInterface[];
                    lines: GetManyResponseInterface<LineCRUDInterface>;
                    stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
                    activities: ActivityCRUDInterface[];
                    tasks: TaskFilterCRUDInterface[];
                    lineTasks: TaskFilterCRUDInterface[];
                    extendedTasks: TaskFilterCRUDInterface[];
                  }) => {
                    return {
                      stationStateMappingRecord: stationStateMappingRecord,
                      sites: siteRecords,
                      lines: lineRecords,
                      stations: stationsRecords,
                      tasks: tasksRecords,
                      lineTasks: lineTasksRecords,
                      extendedTasks: extendedTasksRecords,
                      activities: resolvedObservables.activities,
                    };
                  },
                ),
              );
            },
          ),
        );
      }),
    );
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<IStationStateMappingRow>> {
    return this.http.get<BaseOneResponseInterface<IStationStateMappingRow>>(
      `${this.routes.stationStateMapping}/${recordId}`,
      {
        params,
      },
    );
  }

  public addOne(record: IStationStateMappingRow): Observable<BaseOneResponseInterface<IStationStateMappingRow>> {
    return this.http.post<BaseOneResponseInterface<IStationStateMappingRow>>(
      `${this.routes.stationStateMapping}`,
      record,
    );
  }

  public editOne(
    recordId: number,
    record: IStationStateMappingRow,
  ): Observable<BaseOneResponseInterface<IStationStateMappingRow>> {
    return this.http.patch<BaseOneResponseInterface<IStationStateMappingRow>>(
      `${this.routes.stationStateMapping}/${recordId}`,
      record,
    );
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          stationStateMappingIds: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.stationStateMapping}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.stationStateMapping}/${recordIds[0]}`);
  }
}
