import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as _ from 'lodash';
import * as d3 from 'd3';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  DatatableHeaderInterface,
  DatatableOutputParameterInterface,
} from '../../../../shared/component/datatable/datatable.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { FilterCardOptionInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { ToastHelperService } from '../../../../shared/service/toast/toast.helper.service';
import { Subscription } from 'rxjs';
import { FilterSiteState } from '../../../../store/filter/site/site.reducer';
import {
  DepartmentsStateInterface,
  IDepartment,
} from '../../../../store/settings/departments-lines-stations/departments/departments.model';
import * as DepartmentActions from '../../../../store/settings/departments-lines-stations/departments/departments.actions';
import * as FloorPlanActions from '../../../../store/settings/departments-lines-stations/floor-plans/floor-plans.actions';
import { smallModal, StatusesEnum } from '../../../../../constants';
import { GenericHelperService } from '../../../../shared/service/generic.helper.service';
import {
  DepartmentWithNameInterface,
  DropdownOptionsInterface,
  ECardViewModalType,
  ECursorType,
  EFloorPlanCategoryForId,
  ELinePathOrderDetailLocationType,
  ELinePlanViewTypeEnum,
  FloorPlanBulkResponseInterface,
  FloorPlanConfigurationInterface,
  FloorPlanFormInterface,
  FloorPlanInterface,
  FloorPlanModalTypesEnum,
  FloorPlanStateInterface,
  IArrowPosition,
  IBoxPositionsWithId,
  ICardViewConfiguration,
  IDrawArrowProperties,
  ILinePathConfiguration,
  ILinePathOrderDetail,
  ILineViewConfiguration,
  IPositionElement,
  IPositionOutside,
  ISideListItem,
  IStationPathConfiguration,
  IStationPathOrderDetail,
  ITableQuery,
  LineInterface,
  MouseButtonTypeEnum,
  PlanItemFieldGElementDatumInterface,
  Point,
  PointDistanceData,
  PointDistanceDataWithIndex,
  SideListInterface,
  staticToleranceValues,
  StationInterface,
  TLineAndStationPathSettings,
  TLinePathSettings,
  TStationPathSettings,
  ZoomEvents,
} from '../../../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import { User } from '../../../../store/user/model';
import { ofType } from '@ngrx/effects';
import * as FloorPlanHelper from './floor-plan.helper';
import { DatatableService } from '../../../../shared/service/datatable/datatable.service';
import { DatatableInterface } from '../../../../shared/service/datatable/datatable.model';
import { GetManyResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../../../shared/service/helper.service';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { ColorService } from '../../../../shared/service/color/color.service';
import { EColorPalette, IColorPaletteOption } from '../../../../shared/service/color/color.model';
import { ScwMatSelectComponent } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.component';
import { IPoint } from '@swimlane/ngx-charts/lib/models/coordinates.model';
import { ScwMatSelectRule } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import * as AppActions from '../../../../store/app/actions';

@OnDestroyDecorator
@Component({
  selector: 'app-floor-plans',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.scss'],
})
export class FloorPlanComponent implements OnInit, OnDestroy {
  @ViewChild('crop') cropModal: TemplateRef<ElementRef>;
  @ViewChild('bulk_error_modal') bulkErrorModalTemplateRef: ViewChildDecorator;
  @ViewChild('unsaved_changes_modal_for_cardView', { static: false })
  unsavedChangesModalTemplateForCardView: TemplateRef<ElementRef>;
  @ViewChild('create_edit_modal_submit_button', { static: false }) createEditModalSubmitButton: ElementRef;
  @ViewChild('create_floor_plan_modal', { static: false }) createEditModal: ElementRef;
  @ViewChild('arrowTemplate', { read: TemplateRef }) arrowTemplate: TemplateRef<ElementRef>;
  @ViewChild('arrowTemplatePreview', { read: TemplateRef }) arrowTemplatePreview: TemplateRef<ElementRef>;
  @ViewChild('dragContainer') dragContainer!: ElementRef;

  @ViewChild('imageSelectionDialogInput') set imageInput(imageInput: ElementRef) {
    this.imageSelectionDialogInputElementRef = imageInput;
  }

  public readonly checkboxIdPrefix: string = 'checkbox-item-';
  public readonly tableHeaders: DatatableHeaderInterface[];
  public readonly filterOptions: FilterCardOptionInterface;
  public readonly ELinePlanViewTypeEnum = ELinePlanViewTypeEnum;

  public imageSelectionDialogInputElementRef: ElementRef;
  public isPlanForSite: boolean = true;

  public floorPlans$: FloorPlanInterface[] = [];
  public sites$: SiteCRUDInterface[] = [];
  public selectedSite: SiteCRUDInterface;
  public departmentsToDisplay: DepartmentWithNameInterface[] = [];
  public linesAsSideListItems$: ISideListItem[] = [];
  public areLinesLoading: boolean = false;
  public stations$: StationInterface[] = [];
  public sideListItems: SideListInterface[] = [];
  public modifiedSideListItems: SideListInterface[] = [];
  public areSideItemsLoading: boolean = false;
  public isSideListSearchBoxDisabled: boolean = true;
  public linesUsed: LineInterface[] = [];
  public stationsUsed: StationInterface[] = [];
  public linesForDropdown: DropdownOptionsInterface[] = [];
  public floorPlansCount$: number = 0;
  public selectedFloorPlanIds: number[] = [];
  public selectedFloorPlan: FloorPlanInterface = null;
  public isDatatableLoading: boolean = false;
  public sideListSearchTextInputModel: string = '';
  public sideListSearchTextInputModelForCardView: string = '';
  public searchBoxText: string = '';
  public isSideListDataNotAvailable: boolean = false;
  public isSideListExpanded: boolean = true;
  public previousFloorPlanCategory: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };

  public draggedPlanItem: HTMLDivElement = null;
  public draggedPlanItemBackgroundColor: string = '';
  public draggedPlanItemColor: string = '';

  public addFloorPlanModalRef: NgbModalRef;
  public editFloorPlanModalRef: NgbModalRef;
  public deleteFloorPlanModalRef: NgbModalRef;
  public bulkDeleteErrorModalRef: NgbModalRef;
  public previewFloorPlanModalRef: NgbModalRef;
  public cropModalRef: NgbModalRef;
  public unsavedFloorPlanModalRef: NgbModalRef;

  public tableQuery: ITableQuery;
  public floorPlanCategoryData: DropdownOptionsInterface[];
  public floorPlanDepartmentInputModel: DropdownOptionsInterface[] = [];
  public linePlanViewTypeGroupButtons: ScwMatButtonGroupButtons[] = [];
  public linePlanPreviewModalGroupButtons: ScwMatButtonGroupButtons[] = [];
  public linePlanViewType: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;
  public linePlanViewTypeForPreview: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;
  public hasAssigmentProductConfiguration: boolean = false;

  public isImageUploaded: boolean = false;
  public minZoom: number;
  public maxZoom: number;
  public currentZoom: number;
  public zoom: d3.ZoomBehavior<SVGGElement, any>;

  public previewMinZoom: number;
  public previewMaxZoom: number;
  public previewCurrentZoom: number;
  public previewZoom: d3.ZoomBehavior<SVGGElement, any>;

  public zoomButtonGroup: ScwMatButtonGroupButtons[];
  public zoomButtonGroupCardView: ScwMatButtonGroupButtons[];

  public floorPlanForm: FloorPlanFormInterface;
  public floorPlanImageFile: File;
  public isSelectedFloorPlanImageLoaded: boolean = false;
  public selectedFloorPlanImageBase64: string;
  public floorPlanToDisplay: FloorPlanInterface;
  public selectedModalType: FloorPlanModalTypesEnum;
  public FloorPlanModalTypesEnum: typeof FloorPlanModalTypesEnum = FloorPlanModalTypesEnum;
  public displayColorScheme: IColorPaletteOption[];
  public bulkDeleteErrorModalData: FloorPlanInterface[];
  public bulkDeleteErrorModalSuccessfulCount: number;
  public updateFloorPlanImageBase64: string = '';
  public colorIds: EColorPalette[] = this.getColorIds();

  public isCardViewMode: boolean = true;
  public drawArrowOptions: any = {
    isDrawStartPoint: true,
    drawStartLocation: { pathNodeId: 0, x: 0, y: 0, top: 0, bottom: 0, left: 0, right: 0 },
    drawEndLocation: { pathNodeId: 0, x: 0, y: 0, top: 0, bottom: 0, left: 0, right: 0 },
  };
  public arrowPositions: IArrowPosition[] = [];
  public selectedArrowElementId: string = '';
  public drawArrowIds: IDrawArrowProperties[] = [];
  public zoomLevel: number = 1;
  public dragElementBoundaries: any[] = [];
  public pathNodesUsedCardView: ISideListItem[] = [];

  public pathNameErrorMessage: string = null;
  public hasPath: boolean = false;
  public disableDatatable: boolean = false;
  public mapViewHasImage: boolean = false;
  public showCreatePathWarningMessage: boolean = false;
  public paths: TLineAndStationPathSettings[] = [];
  public jobCountLines: DropdownOptionsInterface[] = [];
  public workOrderCountStations: DropdownOptionsInterface[] = [];
  public duplicateLinePathName: string = '';
  public duplicateLinePathExists: boolean = false;
  public duplicateStationPathName: string = '';
  public hasDuplicatedStationPath: boolean = false;

  public set lines$(lines: LineInterface[]) {
    this._lines$ = HelperService.cloneDeep(lines);
    this.linesAsSideListItems$ = HelperService.cloneDeep(this.convertToSideListItem(lines));
  }

  public get lines$(): LineInterface[] {
    return this._lines$;
  }

  protected readonly editorInfoTooltipMessage: string = this.translateService.instant(
    'floorPlans.modal.editorTooltip.mainMessage',
  );
  protected readonly editorInfoTooltipLeftClickMessage: string = this.translateService.instant(
    'floorPlans.modal.editorTooltip.leftClickMessage',
  );
  protected readonly editorInfoTooltipRightClickMessage: string = this.translateService.instant(
    'floorPlans.modal.editorTooltip.rightClickMessage',
  );
  protected readonly editorInfoTooltipDoubleClickMessage: string = this.translateService.instant(
    'floorPlans.modal.editorTooltip.doubleClickMessage',
  );
  protected readonly linePlanViewTypeTooltip: string = this.translateService.instant(
    'floorPlan.modal.linePlanViewTypeTooltip',
  );
  protected readonly lineFloorPlanNotChangeableInfoTooltip: string = this.translateService.instant(
    'floorPlan.notChangeableInfoTooltip',
  );
  protected readonly siteFloorPlanNotChangeableInfoTooltip: string = this.translateService.instant(
    'floorPlan.notChangeableInfoTooltipSitePlan',
  );
  protected readonly floorPlanReadOnlyPathTooltip: string = this.translateService.instant(
    'floorPlan.readOnlyPathTooltip',
  );
  protected readonly requiredLabel: string = this.translateService.instant('scwMatForm.validation.required');
  protected readonly isNotUniqueLabel: string = this.translateService.instant(
    'scwMatForm.validation.pathNameIsNotUnique',
  );
  protected readonly sitePlanViewTypeTooltip: string = this.translateService.instant(
    'floorPlan.modal.sitePlanViewTypeTooltip',
  );
  protected readonly linePathJobCountDropdownInfo: string = this.translateService.instant(
    'floorPlan.modal.linePathJobCountDropdownInfo',
  );
  protected readonly stationPathWorkOrderCountDropdownInfo: string = this.translateService.instant(
    'floorPlan.modal.stationPathWorkOrderCountDropdownInfo',
  );

  protected isEditModeInitialImageLoading: boolean = false;

  private readonly largeFloorPlanModalOptions: NgbModalOptions = {
    keyboard: false,
    backdrop: 'static',
    windowClass: 'scw-modal-xl',
  };
  private readonly smallFloorPlanModalOptions: NgbModalOptions = {
    keyboard: false,
    backdrop: 'static',
    windowClass: 'scw-modal-sm',
  };
  private readonly defaultShapeWidth: number = 100;
  private readonly defaultShapeHeight: number = 100;
  private readonly defaultShapeStrokeWidth: number = 4;
  private readonly defaultCircleRadius: number = 12;

  private handleMouseMoveBound: (event: MouseEvent) => void = this.handleMouseMove.bind(this);

  private _lines$: LineInterface[] = [];
  private departments$: DepartmentWithNameInterface[] = [];

  private subscriptions: Subscription[] = [];
  private isFloorPlanDataRequested: boolean = false;

  private isPreviewModalOpen: boolean = false;
  private floorPlanSvg: d3.Selection<SVGSVGElement, any, HTMLElement, any>;
  private floorPlanSvgImage: d3.Selection<SVGImageElement, any, HTMLElement, any>;
  private isDraggingShape: boolean = false;
  private draggedShape: unknown;
  private isPlanItemAreaClickedOnce: boolean = false;
  private planItemAreaClickTimer: NodeJS.Timeout;

  private previousSite: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  private previousLine: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };

  private areChangesMade: boolean = false;

  private isFirstLoadOfTheCurrentImage: boolean = true;
  private imageNaturalWidth: number;
  private imageNaturalHeight: number;

  private defaultSite$: string;

  private dispatchedFromEditOrPreviewModal: boolean = false;
  private closeModals: boolean = false;

  private selectedDeleteArrow = null;

  private defaultArrowPosition: IArrowPosition = { pathNodeId: '0', startX: 0, startY: 0, endX: 0, endY: 0 };

  private mousePosition: IPoint = { x: 0, y: 0 };
  private isDragPositionChanged: boolean = false;
  private dragPositionChangeItemId: string = '';
  private selectedDragElementId: string = null;
  private previewZoomLevel: number = 1;
  private isPreviewModal: boolean = false;
  private previousLinePlanViewType: ELinePlanViewTypeEnum = ELinePlanViewTypeEnum.cardView;
  private floorPlanImageLoading$: boolean = false;

  constructor(
    public translateService: TranslateService,
    public helperService: HelperService,
    public toastHelperService: ToastHelperService,
    private store: Store<OeeAppState>,
    private datatableService: DatatableService,
    private readonly storeActions: ActionsSubject,
    private readonly checkBoxElementRef: ElementRef,
    private readonly ngbModal: NgbModal,
    private arrowTemplateRef: ViewContainerRef,
  ) {
    this.tableHeaders = FloorPlanHelper.getTableHeaders(this.translateService);
    this.filterOptions = FloorPlanHelper.getFilterOptions(this.translateService);
    this.zoomButtonGroup = FloorPlanHelper.getZoomButtonConfiguration();
    this.zoomButtonGroupCardView = FloorPlanHelper.getZoomButtonConfiguration();
    this.floorPlanForm = FloorPlanHelper.getFloorPlanFormConfiguration(this.translateService);
    this.floorPlanCategoryData = FloorPlanHelper.getFloorPlanCategoryData(this.translateService);
    this.displayColorScheme = ColorService.getColorDataFromColorPalette(this.colorIds);
    this.tableQuery = HelperService.cloneDeep(FloorPlanHelper.getDefaultTableQuery());
    this.linePlanViewTypeGroupButtons = FloorPlanHelper.getLinePlanViewTypeButtonData(this.translateService);
    this.linePlanPreviewModalGroupButtons = FloorPlanHelper.getLinePlanViewTypeButtonData(this.translateService);
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.isImageUploaded && !this.isFirstLoadOfTheCurrentImage) {
      if (this.isPreviewModalOpen) {
        this.initiateZoom(this.previewZoom);
      } else {
        this.initiateZoom(this.zoom);
      }
    }
  }

  public ngOnInit(): void {
    this.store.dispatch(
      new DepartmentActions.DepartmentsDataLoad({
        page: 1,
        pageSize: 500,
        status: [StatusesEnum.ACTIVE],
      }),
    );
    this.isFloorPlanDataRequested = true;
    this.subscriptions.push(
      this.store.select('siteFilter').subscribe((state: FilterSiteState): void => {
        if (!state.isLoading && state.isLoaded) {
          this.sites$ = HelperService.cloneDeep(state.data);

          if (this.sites$.length > 0) {
            if (this.floorPlans$.length) {
              this.addSiteAndLineNameToFloorPlanData();
            }
          }
        }
      }),
      this.store.select('departmentsStore').subscribe((state: DepartmentsStateInterface): void => {
        if (!state.departmentsDataLoading && state.departmentsDataLoaded) {
          this.departments$ = state.departments
            .map((item: IDepartment): DepartmentWithNameInterface => {
              return {
                ...item,
                name: item.lineType,
              };
            })
            .sort(GenericHelperService.dropdownOptionCompareFunction);

          if (this.departments$.length && this.lines$.length) {
            this.populateDepartmentsToDisplay();
          }
        }
      }),
      this.store.select('floorPlanStore').subscribe((state: FloorPlanStateInterface): void => {
        const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
        this.areLinesLoading = state.isLineDataLoading;
        this.floorPlanImageLoading$ = state.isFloorPlanImageLoading && !state.isFloorPlanImageLoaded;

        this.areSideItemsLoading =
          (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE && state.isStationDataLoading) ||
          (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE && state.isLineDataLoading);

        if (state.isFloorPlanDataLoaded && !state.isFloorPlanDataLoading && this.isFloorPlanDataRequested) {
          this.floorPlans$ = HelperService.cloneDeep(state.floorPlanData);
          this.isFloorPlanDataRequested = false;

          if (this.floorPlans$.length && this.sites$.length) {
            this.addSiteAndLineNameToFloorPlanData();
          }
        }

        if (state.isLineDataLoaded && !state.isLineDataLoading) {
          this.lines$ = HelperService.cloneDeep(state.lineData);

          if (this.isPreviewModalOpen) {
            return;
          }

          this.populateLinesForDropdown();
          this.onLinesToDisplayChange();

          if (this.departments$.length && this.lines$.length) {
            this.populateDepartmentsToDisplay();
          }
        }
      }),
      this.store.select('user').subscribe((state: User): void => {
        this.defaultSite$ = HelperService.cloneDeep(state.defaultSite);
      }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_CREATE_FLOOR_PLAN_COMPLETED))
        .subscribe((payload: FloorPlanActions.CreateFloorPlanCompleted): void => {
          if (this.floorPlanForm.image.value) {
            this.store.dispatch(new AppActions.ShowLoader());
            this.disableDatatable = true;
            this.store.dispatch(
              new FloorPlanActions.UploadFloorPlanImageLoading(
                payload.response.data.id,
                this.floorPlanForm.image.value,
              ),
            );
            return;
          }
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_STATION_DATA_LOADED))
        .subscribe((state: FloorPlanActions.StationDataLoaded): void => {
          this.stations$ =
            this.isPreviewModalOpen || !_.isNil(this.floorPlanForm.line.value)
              ? HelperService.cloneDeep(state.response.data)
              : [];

          this.stations$.forEach((station: StationInterface, index: number) => {
            station.colorSetting = this.getColorScheme(index);
            station.position = { x: 0, y: 0 };
            station.isNotDraggable = false;
          });
          this.modifiedSideListItems = _.cloneDeep(this.stations$);
          this.populateSideListItems();
          const categoryId: number =
            this.floorPlanToDisplay?.categoryForId ?? this.floorPlanForm.floorPlanCategory.value[0].id;

          if (this.dispatchedFromEditOrPreviewModal && categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
            this.dispatchedFromEditOrPreviewModal = false;

            if (!_.isNil(this.floorPlanToDisplay?.imagePath)) {
              this.store.dispatch(new FloorPlanActions.GetFloorPlanImageLoading(this.floorPlanToDisplay?.imagePath));
            }
          }

          if (
            categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE &&
            (_.isNil(this.floorPlanToDisplay) ||
              this.selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL)
          ) {
            document.getElementById('pathList')?.classList.add('show');
            this.arrowPositions = [];
            this.drawArrowIds = [];
            const arrowList: HTMLElement[] = Array.from(document.querySelectorAll('.arrow-container'));
            arrowList.forEach((arrow: HTMLElement) => {
              arrow.remove();
            });

            if (!this.isPreviewModal) {
              setTimeout(() => {
                this.floorPlanToDisplay = _.find(this.floorPlans$, {
                  lineId: _.get(this.floorPlanForm.line, 'value.0.id', null),
                });

                if (this.floorPlanToDisplay) {
                  this.generateEditCardViewDatas();
                }

                const lineId: number =
                  this.floorPlanToDisplay?.lineId ?? _.get(this.floorPlanForm.line, 'value.0.id', null);
                this.store.dispatch(new FloorPlanActions.FloorPlansStationPathDataLoading(lineId));
              });
            } else {
              this.isPreviewModal = false;
            }
          }

          if (this.isPreviewModalOpen && categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
            this.initializeFloorPlanCardViewPreview();
            this.store.dispatch(new AppActions.HideLoader());
          }
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_STATION_PATH_DATA_LOADED))
        .subscribe((payload: FloorPlanActions.FloorPlansStationPathDataLoaded) => {
          this.hasPath = payload.response.data.stationPaths.length > 0;

          if (!this.hasPath) {
            this.hasAssigmentProductConfiguration = false;
            document.getElementById('pathList')?.classList.remove('show');
            this.selectedModalType = FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL;
            return;
          }

          this.hasAssigmentProductConfiguration = !!payload.response.data.stationPaths.filter(
            (path: IStationPathConfiguration) => path?.lineProductStationPathAssignments?.length,
          )?.length;
          this.selectedModalType = FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL;
          this.areChangesMade = true;
          this.floorPlanToDisplay = HelperService.cloneDeep(payload.response.data.floorPlan);
          this.selectedFloorPlan = HelperService.cloneDeep(payload.response.data.floorPlan);
          this.floorPlanForm.floorPlanName.value = this.floorPlanToDisplay?.name;
          this.mapViewHasImage = !!(this.floorPlanToDisplay.imagePath ?? this.floorPlanForm.image.value);

          if (!_.find(this.floorPlans$, { lineId: this.floorPlanForm.line.value[0].id })) {
            this.generateEditCardViewDatas();
          }

          if (!_.isNil(this.floorPlanToDisplay.imagePath) && !this.floorPlanImageLoading$) {
            this.store.dispatch(new FloorPlanActions.GetFloorPlanImageLoading(this.floorPlanToDisplay?.imagePath));
          }

          const isUsedPathFromProductConfiguration: boolean =
            this.stations$.length &&
            this.pathNodesUsedCardView.length &&
            this.stations$.length !== this.pathNodesUsedCardView.length &&
            this.hasAssigmentProductConfiguration;

          if (
            (this.stations$.length && this.stations$.length === this.pathNodesUsedCardView.length) ||
            isUsedPathFromProductConfiguration
          ) {
            this.generateCollapseBoxDesign();
            this.generateStationPathsData(
              HelperService.cloneDeep(payload.response.data.stationPaths as TLineAndStationPathSettings[]),
            );
            this.setPathNodeListBoxCollapse();
            this.pathNodesUsedCardView.forEach((item: StationInterface) => {
              document.getElementById(item.id.toString()).style.removeProperty('border');
              this.setPathNodeBoxBorder(item.id.toString(), 'all', 'black', '#d9dfe7');
            });
            this.showCreatePathWarningMessage = false;

            if (this.stations$.length > 1) {
              this.collapseOrExpandPathNodeList();
            }

            this.convertNotDraggableElements(true);
            this.onClickSelectPathCard(this.paths.length - 1, true);
          } else {
            this.floorPlanToDisplay.configuration['cardViewConfiguration']['pathConfiguration'] = [];
          }
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_LINE_PATH_DATA_LOADED))
        .subscribe((response: FloorPlanActions.FloorPlansLinePathDataLoaded) => {
          this.hasPath = response.payload.length > 0;

          if (!this.hasPath) {
            this.hasAssigmentProductConfiguration = false;

            return;
          }

          this.hasAssigmentProductConfiguration = response.payload.some(
            (path: ILinePathConfiguration) => path.linePathProductConfigurations?.length,
          );
          this.selectedModalType = FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL;
          this.areChangesMade = true;
          this.selectedFloorPlan = this.floorPlanToDisplay;
          this.floorPlanForm.floorPlanName.value = this.floorPlanToDisplay?.name;
          this.mapViewHasImage = !!(this.floorPlanToDisplay.imagePath ?? this.floorPlanForm.image.value);

          this.generateCollapseBoxDesign();
          this.generateLinePathsData(HelperService.cloneDeep(response.payload));

          this.pathNodesUsedCardView.forEach((item: StationInterface) => {
            document.getElementById(item.id.toString()).style.removeProperty('border');
            this.setPathNodeBoxBorder(item.id.toString(), 'all', 'black', '#d9dfe7');
          });

          this.showCreatePathWarningMessage = false;
          this.isSideListExpanded = true;

          this.collapseOrExpandPathNodeList();
          this.convertNotDraggableElements(true);
          this.onClickSelectPathCard(this.paths.length - 1, true);
        }),
      this.storeActions
        .pipe(
          ofType(
            FloorPlanActions.FLOOR_PLANS_CREATE_FLOOR_PLAN_COMPLETED,
            FloorPlanActions.FLOOR_PLANS_UPDATE_FLOOR_PLAN_COMPLETED,
            FloorPlanActions.FLOOR_PLANS_DELETE_FLOOR_PLAN_COMPLETED,
          ),
        )
        .subscribe((): void => {
          if (!this.disableDatatable) {
            this.showSuccessThenRefresh();
          }
        }),
      this.storeActions
        .pipe(
          ofType(
            FloorPlanActions.FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADED,
            FloorPlanActions.FLOOR_PLANS_DELETE_FLOOR_PLAN_IMAGE_COMPLETED,
          ),
        )
        .subscribe((): void => {
          this.disableDatatable = false;
          this.showSuccessThenRefresh();
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADED))
        .subscribe((response: FloorPlanActions.GetFloorPlanImageLoaded): void => {
          this.isSelectedFloorPlanImageLoaded = true;
          this.selectedFloorPlanImageBase64 = `data:image/png;base64,${response.response.data}`;

          if (this.isPreviewModalOpen) {
            this.initializeFloorPlanPreviewSvg();
          } else {
            setTimeout(() => {
              this.updateFloorPlanImageInputModel(this.selectedFloorPlanImageBase64, true);
            });
          }
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_FLOOR_PLAN_DATA_LOADED))
        .subscribe((response: FloorPlanActions.FloorPlanDataLoaded): void => {
          this.disableDatatable = false;
          this.floorPlansCount$ = response.response.total;
          this.store.dispatch(new AppActions.HideLoader());
        }),
      this.storeActions
        .pipe(ofType(FloorPlanActions.FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_COMPLETED))
        .subscribe((objectData: { response: GetManyResponseInterface<FloorPlanBulkResponseInterface> }): void => {
          this.setBulkDeleteResults(objectData.response.data);
        }),
      this.storeActions.pipe(ofType(FloorPlanActions.FLOOR_PLANS_LINE_DATA_LOADED)).subscribe((): void => {
        const selectedFloorPlan: FloorPlanInterface = _.find(this.floorPlans$, { id: this.floorPlanToDisplay?.id });
        const categoryId: number = selectedFloorPlan?.categoryForId ?? this.floorPlanForm.floorPlanCategory.value[0].id;

        if (this.dispatchedFromEditOrPreviewModal && categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
          this.dispatchedFromEditOrPreviewModal = false;

          if (this.floorPlanToDisplay?.imagePath || selectedFloorPlan.imagePath) {
            this.store.dispatch(
              new FloorPlanActions.GetFloorPlanImageLoading(
                this.floorPlanToDisplay?.imagePath ?? selectedFloorPlan.imagePath,
              ),
            );
          }

          const isPlanForSite: boolean = categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;

          if (
            _.isNil(this.floorPlanToDisplay) ||
            this.selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL
          ) {
            document.getElementById('pathList')?.classList.add('show');
            this.arrowPositions = [];
            this.drawArrowIds = [];
            const arrowList: HTMLElement[] = Array.from(document.querySelectorAll('.arrow-container'));
            arrowList.forEach((arrow: HTMLElement) => {
              arrow.remove();
            });

            if (!this.isPreviewModal && isPlanForSite) {
              setTimeout(() => {
                this.floorPlanToDisplay = HelperService.cloneDeep(this.selectedFloorPlan);

                if (this.floorPlanToDisplay) {
                  this.generateEditCardViewDatas();
                }

                this.store.dispatch(new FloorPlanActions.FloorPlansLinePathDataLoading(this.floorPlanToDisplay.id));
              });
            } else if (isPlanForSite) {
              this.isPreviewModal = false;
            }
          }

          if (this.isPreviewModalOpen) {
            this.initializeFloorPlanCardViewPreview();
            this.store.dispatch(new AppActions.HideLoader());
          }
        }
      }),
      this.storeActions.pipe(ofType(FloorPlanActions.FLOOR_PLANS_FETCH_ERROR)).subscribe((): void => {
        this.refreshPageState();
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.dismissAllExistingModals();
  }

  public onDataRequestHandler(params: DatatableOutputParameterInterface): void {
    this.drawArrowIds = [];
    this.arrowPositions = [];
    this.floorPlanToDisplay = null;
    this.pathNodesUsedCardView = [];

    const tableQuery: DatatableInterface = this.datatableService.formatOutputParameters(params);
    this.tableQuery = {
      ...this.tableQuery,
      ...tableQuery,
    };

    if (!this.tableQuery.orderBy || (params.sort && params.sort.type === 'none')) {
      this.tableQuery.orderBy = 'id';
      this.tableQuery.orderDesc = true;
    }

    if (params.sort && params.sort.type !== 'none') {
      switch (params.sort.column) {
        case 'siteName':
          this.tableQuery.orderBy = 'site.name';
          break;
        case 'lineName':
          this.tableQuery.orderBy = 'line.title';
          break;
        case 'planFor':
          this.tableQuery.orderBy = 'categoryForId';
          break;
      }
    }

    this.selectedFloorPlanIds = [];
    this.isFloorPlanDataRequested = true;
    this.store.dispatch(new FloorPlanActions.FloorPlanDataLoading(...HelperService.argumentClone(this.tableQuery)));
  }

  public addSiteAndLineNameToFloorPlanData(): void {
    this.floorPlans$.forEach((floorPlan: FloorPlanInterface): void => {
      floorPlan.siteName = this.sites$.find((site: SiteCRUDInterface): boolean => site.id === floorPlan.siteId)?.name;
      floorPlan.lineName = floorPlan.line?.name ?? ' - ';
      floorPlan.planFor = this.floorPlanCategoryData.find(
        (category: DropdownOptionsInterface): boolean => category.id === floorPlan.categoryForId,
      )?.name;
    });
  }

  public applyZoom(event): void {
    if (event === ZoomEvents.ZOOM_IN) {
      this.zoomIn();
    } else {
      this.zoomOut();
    }
  }

  public zoomIn(): void {
    this.zoomBy(1.5);
  }

  public zoomOut(): void {
    this.zoomBy(1 / 1.5);
  }

  public uploadImage(crop?: TemplateRef<any>): void {
    this.updateFloorPlanImageBase64 = '';
    this.floorPlanImageFile = undefined;

    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    if (!this.floorPlanForm.image.value || !crop) {
      document.querySelector<HTMLInputElement>('#imageSelectionDialogInput').click();
      return;
    }

    this.cropModalRef = this.ngbModal.open(crop, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-xl',
    });
  }

  public replaceImage(): void {
    this.removePlanItemsFromFloorPlan();
    this.removeImage(true);
    this.uploadImage(this.cropModal);
  }

  public removeImage(isClick: boolean = false): void {
    this.removePlanItemsFromFloorPlan();
    this.updateFloorPlanImageBase64 = '';
    this.floorPlanSvg?.remove();
    this.floorPlanForm.image.value = null;
    this.changedMapViewData = null;
    this.selectedFloorPlanImageBase64 = null;
    this.isImageUploaded = false;
    this.areChangesMade = true;
    this.onSideListItemsChange();
    this.updateZoomButtonStatuses();
    d3.select('#floorPlanCard').style('height', '100%');

    if (isClick && this.floorPlanToDisplay) {
      this.floorPlanToDisplay.configuration['mapViewConfiguration'] = [];
      this.floorPlanToDisplay.imagePath = null;
    }
  }

  public removePlanItemsFromFloorPlan(isInitialOpening: boolean = false, isClickButton: boolean = false): void {
    this.floorPlanSvg?.selectAll<SVGGElement, any>('g').remove();
    this.linesUsed = [];
    this.stationsUsed = [];
    this.onSideListItemsChange();

    if (!isInitialOpening) {
      this.areChangesMade = true;
    }

    if (isClickButton && this.floorPlanToDisplay?.configuration?.hasOwnProperty('mapViewConfiguration')) {
      this.floorPlanToDisplay.configuration['mapViewConfiguration'] = [];
    }
  }

  public saveFloorPlan(): FloorPlanConfigurationInterface[] {
    const planItemAreaData: FloorPlanConfigurationInterface[] = [];
    this.floorPlanSvg.selectAll<SVGGElement, PlanItemFieldGElementDatumInterface>('g').each(function (): void {
      const thisSelection: d3.Selection<
        SVGGElement,
        PlanItemFieldGElementDatumInterface,
        SVGSVGElement,
        PlanItemFieldGElementDatumInterface
      > = d3.select(this);
      const points: Point[] = [];
      const thisSelectionDatum: PlanItemFieldGElementDatumInterface = thisSelection.datum();

      thisSelection.selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle').each(function (): void {
        points.push({
          x: Number(d3.select<SVGCircleElement, PlanItemFieldGElementDatumInterface>(this).attr('cx')),
          y: Number(d3.select<SVGCircleElement, PlanItemFieldGElementDatumInterface>(this).attr('cy')),
        });
      });

      planItemAreaData.push({
        points,
        itemId: Number(thisSelectionDatum.itemId),
        itemName: thisSelectionDatum.itemName,
        xOffset: Number(thisSelectionDatum.x),
        yOffset: Number(thisSelectionDatum.y),
      });
    });

    return planItemAreaData;
  }

  public loadFloorPlan(
    floorPlanConfiguration: FloorPlanConfigurationInterface[],
    svgElement: d3.Selection<SVGSVGElement, any, HTMLElement, any>,
    currentZoom: number,
    isEditModal: boolean = true,
  ): void {
    const mapViewConfiguration: FloorPlanConfigurationInterface[] = this.changedMapViewData ?? floorPlanConfiguration;
    this.isPlanForSite =
      (this.floorPlanToDisplay?.categoryForId ?? this.floorPlanForm.floorPlanCategory.value?.[0]?.id) ===
      EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;
    const pathNodeSourceList: ISideListItem[] = this.isPlanForSite ? this.linesAsSideListItems$ : this.stations$;
    const usedPathNodesList: (LineInterface | StationInterface)[] = this.isPlanForSite
      ? this.linesUsed
      : this.stationsUsed;

    mapViewConfiguration?.forEach((planItemConfig: FloorPlanConfigurationInterface, index: number): void => {
      const svgImageElement: d3.Selection<SVGImageElement, any, any, any> = svgElement.select<SVGImageElement>('image');
      const planItemIndex: number = _.findIndex(
        pathNodeSourceList,
        (pathNode: { id: number }): boolean => pathNode.id === planItemConfig.itemId,
      );
      const planItem: ISideListItem = pathNodeSourceList[planItemIndex];

      if (!planItem) {
        return;
      }

      const planItemAreaIndex: number = planItemIndex;
      const planItemName: string = planItem.name;
      usedPathNodesList.push(planItem);

      const newGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any> = svgElement
        .append('g')
        .datum({
          itemId: planItemConfig.itemId,
          itemName: planItemName ?? planItemConfig.itemName,
          x: planItemConfig.xOffset,
          y: planItemConfig.yOffset,
        })
        .attr('transform', (datum: PlanItemFieldGElementDatumInterface): string => {
          const translateX: number = datum.x * currentZoom + Number(svgImageElement.attr('offsetX'));
          const translateY: number = datum.y * currentZoom + Number(svgImageElement.attr('offsetY'));

          return `translate(${translateX},${translateY}) scale(${currentZoom})`;
        });

      this.createPlanItemAreaShape(newGElement, planItemConfig.points, planItemAreaIndex);
      this.createPlanItemAreaText(newGElement, !isEditModal);

      if (isEditModal) {
        this.createPlanItemAreaCircles(newGElement, planItemConfig.points, planItemAreaIndex);
      }
    });

    if (isEditModal) {
      if (this.isPlanForSite) {
        this.onLinesToDisplayChange();
      } else {
        this.populateSideListItems(null, true);
      }
    }
  }

  public selectOrUnselectAll(isSelectAll: boolean): void {
    if (this.checkBoxElementRef.nativeElement.querySelector(`[id^="${this.checkboxIdPrefix}"]`) !== null) {
      this.floorPlans$.forEach((floorPlan: FloorPlanInterface): void => {
        const checkbox = _.head(
          document.getElementById(`${this.checkboxIdPrefix}${floorPlan.id}`)?.getElementsByTagName('input'),
        );

        if ((isSelectAll && !checkbox?.checked) || (!isSelectAll && checkbox?.checked)) {
          checkbox?.click();
        }
      });
    }
  }

  public openModal(content: TemplateRef<any>, modalType: string, event = undefined): void {
    this.isPreviewModalOpen = false;
    this.changedMapViewData = null;
    this.isPreviewModal = false;
    this.updateFloorPlanImageBase64 = null;
    this.selectedFloorPlanImageBase64 = null;
    this.hasAssigmentProductConfiguration = false;

    switch (modalType) {
      case FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL:
        this.isPlanForSite = true;
        this.stations$ = [];
        this.lines$ = [];
        this.isCardViewMode = false;
        this.selectedModalType = modalType;
        this.openCreateFloorPlan(content);
        this.stationsUsed = [];
        this.linesUsed = [];
        this.selectedFloorPlan = null;
        this.isEditModeInitialImageLoading = false;
        this.isImageUploaded = false;
        this.sideListSearchTextInputModelForCardView = '';
        this.sideListSearchTextInputModel = '';
        break;
      case FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL:
        this.isPlanForSite = true;
        this.stations$ = [];
        this.lines$ = [];
        this.isCardViewMode = true;
        this.selectedModalType = modalType;
        this.sideListSearchTextInputModelForCardView = '';
        this.sideListSearchTextInputModel = '';

        if (_.isNil(event)) {
          this.selectedFloorPlan = _.find(this.floorPlans$, { id: this.selectedFloorPlanIds[0] });
          event = this.selectedFloorPlan;
        }

        this.openEditFloorPlan(content, event);
        this.stationsUsed = [];
        this.linesUsed = [];
        break;
      case FloorPlanModalTypesEnum.DELETE_FLOOR_PLAN_MODAL:
        this.deleteFloorPlanModalRef = this.ngbModal.open(content, {
          ...this.largeFloorPlanModalOptions,
          windowClass: 'scw-modal-sm',
        });
        break;
      case FloorPlanModalTypesEnum.CROP_IMAGE_MODAL:
        this.openCropModal(content, event);
        break;
      case FloorPlanModalTypesEnum.PREVIEW_FLOOR_PLAN_MODAL:
        this.store.dispatch(new AppActions.ShowLoader());
        this.selectedModalType = modalType;
        this.openFloorPlanPreviewModal(content, event);
    }
  }

  public generateEditCardViewDatas(): void {
    const cardViewConfiguration: ICardViewConfiguration = HelperService.cloneDeep(
      this.floorPlanToDisplay.configuration['cardViewConfiguration'],
    );
    this.drawArrowIds = cardViewConfiguration.drawArrowIds ?? [];
    const { boxPositions } = cardViewConfiguration;
    this.drawArrowIds.forEach((item: IDrawArrowProperties) => {
      this.arrowPositions.push({
        pathNodeId: `start${item.startBoxId}-end${item.endBoxId}`,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      });
    });
    this.modifiedSideListItems.forEach((item: StationInterface) => {
      const selectedBoxPositions: IBoxPositionsWithId = _.find(boxPositions, { boxId: item.id });

      if (selectedBoxPositions) {
        item.defaultPosition = selectedBoxPositions.position;
        this.pathNodesUsedCardView.push({ ...item, defaultPosition: selectedBoxPositions.position });
        this.doExpandOrCollapseWhenAllNodesUsed();
      }
    });

    this.reDrawUsedPathNodes();
  }

  public submitFloorPlanForm(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    this.selectedModalType = this.floorPlanToDisplay?.id
      ? FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL
      : FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL;
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    let lineId: number = null;
    let configuration: ILineViewConfiguration = null;

    switch (categoryId) {
      case EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE:
        if (this.isPathFormInvalid()) {
          this.setBottomScrollPosition();

          return;
        }

        lineId = this.floorPlanForm.line.value?.[0]?.id;
        configuration = this.prepareLineFloorPlanConfiguration();
        break;
      case EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE:
        if (
          !this.isCardViewMode &&
          ((this.pathNodesUsedCardView?.length && !this.paths?.length) || this.isPathFormInvalid())
        ) {
          this.toastHelperService.showToastMessage(
            false,
            null,
            this.translateService.instant('floorPlan.cardViewViolation'),
          );

          return;
        }

        if (this.isPathFormInvalid()) {
          this.setBottomScrollPosition();

          return;
        }

        configuration = this.prepareSiteFloorPlanConfiguration();
        break;
    }

    const floorPlansToSubmit: FloorPlanInterface = {
      lineId,
      configuration,
      name: this.floorPlanForm.floorPlanName.value.trim(),
      siteId: this.floorPlanForm.site.value[0].id,
      categoryForId: categoryId,
    };

    if (this.selectedModalType === FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL) {
      this.store.dispatch(new FloorPlanActions.CreateFloorPlan(floorPlansToSubmit));
    } else if (this.selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL) {
      if (_.isNil(this.selectedFloorPlan)) {
        this.selectedFloorPlan = _.find(this.floorPlans$, { lineId: floorPlansToSubmit.lineId });
      }

      this.store.dispatch(new FloorPlanActions.UpdateFloorPlan(this.selectedFloorPlan.id, floorPlansToSubmit));

      if (
        !_.isNil(this.floorPlanForm.image.value) &&
        (this.selectedFloorPlanImageBase64 !== this.floorPlanForm.image.value ||
          _.isNil(this.selectedFloorPlan.imagePath))
      ) {
        this.disableDatatable = true;
        this.store.dispatch(
          new FloorPlanActions.UploadFloorPlanImageLoading(this.selectedFloorPlan.id, this.floorPlanForm.image.value),
        );
      } else if (_.isNil(this.floorPlanForm.image.value) && this.mapViewHasImage) {
        this.disableDatatable = true;
        this.store.dispatch(new FloorPlanActions.DeleteFloorPlanImageStarted(this.selectedFloorPlan.id));
      }
    }

    this.dismissAllExistingModals();
  }

  public onChangeTableCheckboxes(event: MatCheckboxChange): void {
    const inputValue: number = Number(event.source.value);

    if (event.checked) {
      this.selectedFloorPlanIds.push(inputValue);
    } else {
      const indexToDelete: number = this.selectedFloorPlanIds.indexOf(inputValue);
      this.selectedFloorPlanIds = this.selectedFloorPlanIds.filter(
        (id: number, index: number): boolean => index !== indexToDelete,
      );
    }

    this.selectedFloorPlan = _.find(this.floorPlans$, { id: this.selectedFloorPlanIds[0] });
  }

  public updateFloorPlanImageInputModel($event: string, isEditModal: boolean = false): void {
    if (this.updateFloorPlanImageBase64 === $event) {
      if (this.cropModalRef) {
        this.cropModalRef.close();
      }

      return;
    }

    if (!isEditModal && !_.isNil($event)) {
      this.updateFloorPlanImageBase64 = $event;
    }

    const imageInput: HTMLInputElement = document.querySelector<HTMLInputElement>('#imageSelectionDialogInput');

    if (imageInput) {
      imageInput.value = '';
    }

    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    if (_.isNil($event)) {
      return;
    }

    this.floorPlanForm.image.value = $event;

    this.initializeFloorPlanEditor();

    const image: HTMLImageElement = new Image();

    image.onload = (): void => {
      this.isFirstLoadOfTheCurrentImage = true;
      this.imageNaturalWidth = image.naturalWidth;
      this.imageNaturalHeight = image.naturalHeight;

      this.floorPlanSvgImage = this.floorPlanSvg
        .append('image')
        .attr('xlink:href', this.floorPlanForm.image.value)
        .on('contextmenu', (event): void => {
          event.stopPropagation();
          event.preventDefault();
        });

      this.initiateZoom(this.zoom);
      this.isEditModeInitialImageLoading = false;
      this.isImageUploaded = true;
      this.updateZoomButtonStatuses();

      if (isEditModal) {
        this.loadFloorPlan(
          this.selectedFloorPlan?.configuration.mapViewConfiguration ?? this.changedMapViewData,
          this.floorPlanSvg,
          this.currentZoom,
        );
      }
    };
    image.src = this.floorPlanForm.image.value;
  }

  public showMaxAllowedImageSizeExceededWarning(): void {
    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    this.toastHelperService.showToastMessage(
      false,
      null,
      this.translateService.instant('cropModal.errorMessages.imageSizeIsTooLarge'),
    );
  }

  public onSiteInputModelChange(siteId, isFromSetDefaultSite: boolean = false): void {
    this.hasPath = false;
    this.duplicateLinePathExists = false;
    this.hasDuplicatedStationPath = false;

    if (this.selectedModalType !== FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL) {
      this.floorPlanToDisplay = null;
    }

    if (this.pathNodesUsedCardView.length > 0) {
      this.ngbModal.open(this.unsavedChangesModalTemplateForCardView, smallModal);
    } else {
      this.previousSite = _.cloneDeep(this.floorPlanForm.site);
      this.departmentsToDisplay = [];
      this.floorPlanDepartmentInputModel = [];

      if (
        _.get(this.floorPlanForm.floorPlanCategory, 'value.0.id', null) === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE
      ) {
        this.floorPlanForm.line.value = undefined;
        this.resetFormInputFieldsForUnselectedLine();
        this.discardAllData();
      }

      if (_.isNil(siteId)) {
        this.resetFormInputFieldsForUnselectedSite();
      } else {
        if (!isFromSetDefaultSite) {
          this.areChangesMade = true;
        }

        this.removePlanItemsFromFloorPlan(isFromSetDefaultSite);
        this.store.dispatch(new FloorPlanActions.LineDataLoading(siteId));
      }
    }
  }

  public onLinesToDisplayChange(): void {
    const searchText: string = this.isCardViewMode
      ? this.sideListSearchTextInputModelForCardView.toLowerCase()
      : this.sideListSearchTextInputModel.toLowerCase();

    if (this.isCardViewMode) {
      const filteredListCardView: ISideListItem[] = _.cloneDeep(this.linesAsSideListItems$).filter(
        (pathUnit: ISideListItem) =>
          pathUnit.name.toLowerCase().includes(searchText) &&
          !this.pathNodesUsedCardView.some((used: ISideListItem): boolean => used.id === pathUnit.id) &&
          this.floorPlanDepartmentInputModel.some(
            (selectedDepartment: DropdownOptionsInterface): boolean => selectedDepartment.id === pathUnit.lineType,
          ),
      );
      this.modifiedSideListItems = this.modifiedSideListItems.filter(
        (item: SideListInterface) =>
          filteredListCardView.some((pathUnit: ISideListItem) => pathUnit.id === item.id) ||
          this.pathNodesUsedCardView.some((used: ISideListItem) => used.id === item.id),
      );

      filteredListCardView.forEach((pathUnit: ISideListItem) => {
        if (!this.modifiedSideListItems.some((item: ISideListItem) => item.id === pathUnit.id)) {
          this.modifiedSideListItems.push(pathUnit);
        }
      });

      this.populateSideListItems(filteredListCardView);

      return;
    }

    this.populateSideListItems(
      this.linesAsSideListItems$.filter(
        (line: ISideListItem) =>
          line.name.toLowerCase().includes(searchText) &&
          !this.linesUsed.some((lineUsed: LineInterface): boolean => lineUsed.id === line.id) &&
          this.floorPlanDepartmentInputModel.some(
            (selectedDepartment: DropdownOptionsInterface): boolean => selectedDepartment.id === line.lineType,
          ),
      ),
    );
  }

  public sideListItemDropHandler(event: DragEvent): void {
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;

    this.areChangesMade = true;
    this.createInitialSideListArea(event, categoryId);

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      this.linesUsed.push(
        this.lines$.find((line: LineInterface): boolean => line.id === Number(this.draggedPlanItem.id)),
      );
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      this.stationsUsed.push(
        this.stations$.find((station: StationInterface): boolean => station.id === Number(this.draggedPlanItem.id)),
      );
    }

    this.onSideListItemsChange();
  }

  public sideListItemIncorrectDropHandler(event: DragEvent): void {
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    let dragErrorKey: string;

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      dragErrorKey = 'floorPlans.modal.dragError';
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      dragErrorKey = 'floorPlans.modal.dragErrorStation';
    }

    this.draggedPlanItem = null;
    this.draggedPlanItemBackgroundColor = '';
    this.draggedPlanItemColor = '';

    if (event?.dataTransfer?.dropEffect === 'none') {
      this.toastHelperService.showToastMessage(false, null, this.translateService.instant(dragErrorKey));
    }
  }

  public sideListItemDragStartHandler(event: DragEvent): void {
    const planItemColorSetting: IColorPaletteOption = _.find(this.sideListItems, {
      id: Number(_.get(event.target, 'id')),
    }).colorSetting;

    this.draggedPlanItemBackgroundColor = planItemColorSetting.backgroundColor;
    this.draggedPlanItemColor = planItemColorSetting.text;
    this.draggedPlanItem = event.target as HTMLDivElement;
  }

  public onClose(modal: NgbModalRef): void {
    this.updateFloorPlanImageBase64 = '';
    this.closeModals = true;

    if (this.areChangesMade) {
      this.unsavedFloorPlanModalRef = this.ngbModal.open(this.unsavedChangesModalTemplateForCardView, smallModal);

      return;
    }

    modal.dismiss();
    this.removeAllItems();
  }

  public dismissFloorPlanWithImage(modalRef: NgbModalRef, isPreviewModal: boolean = false): void {
    this.removeAllItems();
    modalRef.dismiss();
    this.isSelectedFloorPlanImageLoaded = false;
    this.isImageUploaded = false;

    if (!isPreviewModal) {
      this.floorPlanForm = FloorPlanHelper.getFloorPlanFormConfiguration(this.translateService);
      this.floorPlanImageFile = undefined;
      this.resetFormInputFieldsForUnselectedSite();
    }

    this.zoomButtonGroup.forEach((button: ScwMatButtonGroupButtons): boolean => (button.disabled = true));
  }

  public deleteFloorPlans(): void {
    if (_.isEmpty(this.selectedFloorPlanIds)) {
      return;
    }

    if (this.selectedFloorPlanIds.length > 1) {
      this.store.dispatch(new FloorPlanActions.BulkDeleteFloorPlans(this.selectedFloorPlanIds));
    } else {
      this.store.dispatch(new FloorPlanActions.DeleteFloorPlan(this.selectedFloorPlan.id));
    }

    this.deleteFloorPlanModalRef.dismiss();
  }

  public changesMade(): void {
    this.areChangesMade = true;
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.tableQuery.search = searchText.trim().toLowerCase();
    this.selectedFloorPlanIds = [];
    this.refreshPageState();
  }

  public onFiltersChanged({ siteIds, lineIds }): void {
    this.tableQuery.siteIds = siteIds === -1 ? [] : siteIds;
    this.tableQuery.lineIds = lineIds === -1 ? [] : lineIds;
    this.selectedFloorPlanIds = [];
    this.refreshPageState();
  }

  public onCategoryInputModelChange(
    categoryId: number,
    isEditMode: boolean = false,
    siteFieldReference?: ScwMatSelectComponent,
  ): void {
    this.hasPath = false;
    this.showCreatePathWarningMessage = false;
    this.duplicateLinePathExists = false;
    this.hasDuplicatedStationPath = false;

    if (this.pathNodesUsedCardView.length > 0) {
      this.ngbModal.open(this.unsavedChangesModalTemplateForCardView, smallModal);
    } else {
      this.previousFloorPlanCategory = HelperService.cloneDeep(this.floorPlanForm.floorPlanCategory);
      this.isSideListExpanded = true;

      if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
        this.isPlanForSite = false;

        this.discardAllData();

        if (this.linePlanViewType === ELinePlanViewTypeEnum.cardView) {
          this.isCardViewMode = true;
          this.addEventsListener();
        } else {
          this.isCardViewMode = false;
          this.removeEventsListener();
        }
      } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
        this.isPlanForSite = true;

        this.collapseOrExpandPathNodeList(true);
        this.discardAllData();
        this.resetFormInputFieldsForUnselectedLine();

        if (this.linePlanViewType === ELinePlanViewTypeEnum.cardView) {
          this.isCardViewMode = true;
          this.addEventsListener();
        } else {
          this.isCardViewMode = false;
          this.removeEventsListener();
        }
      } else if (!categoryId) {
        this.isPlanForSite = true;
        this.onCategoryInputModelDeselect();
        this.removeEventsListener();
      }

      if (categoryId && !isEditMode && this.defaultSite$ && _.isEmpty(this.floorPlanForm.site.value)) {
        this.setDefaultSite(this.defaultSite$);
        siteFieldReference?.clearErrorMessage();
      }

      if (!isEditMode) {
        this.populateSideListItems();
        this.removeImage();
        this.removePlanItemsFromFloorPlan();
      }
    }
  }

  public onLineInputModelChange(lineId: number): void {
    if (this.selectedModalType !== FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL) {
      this.floorPlanToDisplay = null;
    }

    this.hasPath = false;
    this.duplicateLinePathExists = false;
    this.hasDuplicatedStationPath = false;

    if (this.pathNodesUsedCardView.length > 0) {
      this.ngbModal.open(this.unsavedChangesModalTemplateForCardView, smallModal);
    } else {
      this.discardAllData();
      this.previousLine = _.cloneDeep(this.floorPlanForm.line);
      this.stations$ = [];

      if (_.isNil(lineId)) {
        this.resetFormInputFieldsForUnselectedLine();
      } else {
        this.removePlanItemsFromFloorPlan();
        this.store.dispatch(new FloorPlanActions.StationDataLoading(lineId));
      }
    }
  }

  public discardModalButtonsClick(event: string, templateModal: any): void {
    switch (event) {
      case 'close-modal':
        this.closeModals = false;
        this.floorPlanForm.site.value = _.cloneDeep(this.previousSite?.value);
        this.floorPlanForm.line.value = _.cloneDeep(this.previousLine?.value);
        this.floorPlanForm.floorPlanCategory.value = _.cloneDeep(this.previousFloorPlanCategory?.value);
        templateModal.close();

        break;
      case 'discard-modal':
        this.floorPlanToDisplay = null;
        const currentCategory: number = _.get(this.floorPlanForm.floorPlanCategory, 'value.0.id', null);
        const previousCategory: number = _.get(this.previousFloorPlanCategory, 'value.0.id', null);
        const currentSite: number = _.get(this.floorPlanForm.site, 'value.0.id', null);
        const previousSite: number = _.get(this.previousSite, 'value.0.id', null);
        const currentLine: number = _.get(this.floorPlanForm.line, 'value.0.id', null);
        const previousLine: number = _.get(this.previousLine, 'value.0.id', null);
        this.isPlanForSite = currentCategory ? this.isPlanForSite : true;
        this.isSideListExpanded = true;

        if (currentCategory && previousCategory && currentCategory !== previousCategory) {
          this.isPlanForSite = currentCategory === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;
          this.previousFloorPlanCategory.value = _.cloneDeep(this.floorPlanForm.floorPlanCategory?.value);
          this.selectedModalType = FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL;
          this.isCardViewMode = true;
          this.changedMapViewData = null;
          this.floorPlanForm.floorPlanName.value = null;
          this.floorPlanForm.line.value = null;
          this.stations$ = [];
          this.sideListItems = [];
          this.linePlanViewType = ELinePlanViewTypeEnum.cardView;
          _.set(this.selectedFloorPlan, 'imagePath', null);
          this.removeImage();
          this.removeAllItems();
          this.populateSideListItems();
          this.collapseOrExpandPathNodeList(true);
          templateModal.close();
          break;
        }

        if (this.isPlanForSite) {
          this.collapseOrExpandPathNodeList(true);
        }

        if (_.isNil(currentSite) && _.isNil(previousSite)) {
          this.ngbModal.dismissAll();
        }

        if (!_.isNil(currentSite) && previousSite !== currentSite) {
          this.floorPlanForm.line.value = undefined;
          this.modifiedSideListItems = [];
          this.stations$ = [];

          if (!this.isPlanForSite) {
            this.setPathNodeListBoxCollapse();
          }

          this.store.dispatch(new FloorPlanActions.LineDataLoading(currentSite));
        } else if (_.isNil(currentSite)) {
          this.floorPlanForm.line.value = undefined;
          this.modifiedSideListItems = [];
          this.resetFormInputFieldsForUnselectedSite();
        } else if (!_.isNil(currentLine) && previousLine !== currentLine) {
          this.store.dispatch(new FloorPlanActions.StationDataLoading(currentLine));
        } else if (
          (_.isNil(currentLine) && !this.stations$.length && !this.isPlanForSite) ||
          (_.isNil(currentSite) && !this.linesAsSideListItems$.length && this.isPlanForSite)
        ) {
          this.modifiedSideListItems = [];
          this.ngbModal.dismissAll();
        } else if (_.isNil(currentCategory) && !this.closeModals) {
          this.previousSite.value = null;
          this.floorPlanForm.site.value = null;
          this.previousFloorPlanCategory.value = null;
          this.floorPlanForm.floorPlanCategory.value = null;
          this.floorPlanForm.line.value = null;
          this.stations$ = [];
          this.sideListItems = [];
          this.departmentsToDisplay = [];
          this.floorPlanDepartmentInputModel = [];
        } else {
          this.ngbModal.dismissAll();
          this.closeModals = false;
          this.previousSite.value = null;
          this.previousFloorPlanCategory.value = null;
          this.floorPlanForm.site.value = null;
          this.floorPlanForm.floorPlanName.value = null;
          this.floorPlanForm.floorPlanCategory.value = null;
          this.floorPlanForm.line.value = null;
        }

        this.previousSite = _.cloneDeep(this.floorPlanForm.site);
        this.previousLine = _.cloneDeep(this.floorPlanForm.line);
        this.floorPlanForm.floorPlanName.value = null;
        this.discardAllData();
        this.removeAllItems();
        templateModal.close();
        break;
    }
  }

  public onStationsToDisplayChange(): void {
    const searchText: string = this.isCardViewMode
      ? this.sideListSearchTextInputModelForCardView.toLowerCase()
      : this.sideListSearchTextInputModel.toLowerCase();
    const filteredList: StationInterface[] = _.cloneDeep(this.stations$)
      .filter((station: StationInterface) => station.name.toLowerCase().includes(searchText))
      .filter(
        (station: StationInterface) =>
          !this.stationsUsed.some((used: StationInterface): boolean => used.id === station.id),
      );

    if (this.isCardViewMode) {
      const filteredListCardView: StationInterface[] = _.cloneDeep(this.stations$)
        .filter((station: StationInterface) => station.name.toLowerCase().includes(searchText))
        .filter(
          (station: StationInterface) =>
            !this.pathNodesUsedCardView.some((used: StationInterface): boolean => used.id === station.id),
        );
      this.modifiedSideListItems = this.modifiedSideListItems.filter(
        (item: SideListInterface) =>
          filteredListCardView.some((station: StationInterface) => station.id === item.id) ||
          this.pathNodesUsedCardView.some((used: StationInterface) => used.id === item.id),
      );

      filteredListCardView.forEach((station: StationInterface) => {
        if (!this.modifiedSideListItems.some((item: SideListInterface) => item.id === station.id)) {
          this.modifiedSideListItems.push(station);
        }
      });

      this.populateSideListItems(filteredListCardView);
      return;
    }

    this.populateSideListItems(filteredList);
  }

  public onSideListItemsChange(): void {
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      this.onStationsToDisplayChange();
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      this.onLinesToDisplayChange();
    }
  }

  public changedMapViewData: FloorPlanConfigurationInterface[] = null;

  public onLinePlanViewTypeChange(event: any): void {
    if (
      (this.isCardViewMode && event === ELinePlanViewTypeEnum.cardView) ||
      (!this.isCardViewMode && event === ELinePlanViewTypeEnum.mapView)
    ) {
      return;
    }

    this.isCardViewMode = event === ELinePlanViewTypeEnum.cardView;
    this.sideListItems = this.isPlanForSite ? this.linesAsSideListItems$ : this.stations$;
    this.selectedFloorPlanImageBase64 = this.selectedFloorPlanImageBase64 ?? this.updateFloorPlanImageBase64;

    if (this.selectedFloorPlanImageBase64 !== '') {
      this.changedMapViewData = this.saveFloorPlan();
    }

    if (this.isCardViewMode) {
      if (this.pathNodesUsedCardView.length) {
        this.modifiedSideListItems.forEach((pathNode: ISideListItem) => {
          pathNode.dragFlag = !!_.find(this.pathNodesUsedCardView, { id: pathNode.id });
        });
        this.dragElementBoundaries = [];
        this.reDrawUsedPathNodes();
      }

      this.addEventsListener();

      if (this.isPlanForSite) {
        this.onLinesToDisplayChange();
        setTimeout(() => {
          this.collapseOrExpandPathNodeList(this.isSideListExpanded);
        });
      } else {
        this.onStationsToDisplayChange();
      }

      return;
    }

    if (this.isPlanForSite) {
      this.onLinesToDisplayChange();
    }

    this.isEditModeInitialImageLoading = false;

    setTimeout(() => {
      if (
        this.selectedModalType !== FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL &&
        _.isNil(this.floorPlanToDisplay?.imagePath) &&
        _.isNil(this.floorPlanForm.image.value) &&
        !this.changedMapViewData?.length
      ) {
        this.isEditModeInitialImageLoading = false;
        this.removeImage();
        return;
      }

      if (!this.changedMapViewData?.length) {
        this.changedMapViewData = HelperService.cloneDeep(
          this.floorPlanToDisplay?.configuration['mapViewConfiguration'],
        );

        if (
          !this.selectedFloorPlanImageBase64 &&
          this.selectedFloorPlan &&
          !_.isNil(this.floorPlanToDisplay?.imagePath)
        ) {
          this.store.dispatch(new FloorPlanActions.GetFloorPlanImageLoading(this.floorPlanToDisplay?.imagePath));
          return;
        }
      }

      this.updateFloorPlanImageBase64 = null;

      if (
        (!_.isNil(this.selectedFloorPlanImageBase64) && this.selectedFloorPlanImageBase64 !== '') ||
        this.changedMapViewData?.length
      ) {
        this.updateFloorPlanImageInputModel(this.selectedFloorPlanImageBase64, true);
      }
    });
  }

  public onPreviewLinePlanViewTypeChange(): void {
    if (this.previousLinePlanViewType === this.linePlanViewTypeForPreview) {
      return;
    }

    this.previousLinePlanViewType = this.linePlanViewTypeForPreview;
    this.previewZoomLevel = 1;
    this.zoomLevel = 1;
    this.zoomButtonGroup[1].disabled = false;
    this.drawArrowIds = [];

    setTimeout(() => {
      if (this.linePlanViewTypeForPreview === ELinePlanViewTypeEnum.mapView && this.isSelectedFloorPlanImageLoaded) {
        this.initializeFloorPlanPreviewSvg();
      } else {
        this.initializeFloorPlanCardViewPreview();
      }
    });
  }

  public onDragStart(pathNodeId: string): void {
    this.selectedArrowElementId = pathNodeId;
    const container: HTMLElement = document.querySelector('.draggable-box');
    this.generateDraggedItemDesign(pathNodeId);

    if (_.some(this.pathNodesUsedCardView, { id: Number(pathNodeId) })) {
      this.dragPositionChangeItemId = pathNodeId;
      this.isDragPositionChanged = true;
      this.selectedDragElementId = pathNodeId;
      return;
    }

    this.pathNodesUsedCardView.push(_.find(this.modifiedSideListItems, { id: Number(pathNodeId) }));
    this.doExpandOrCollapseWhenAllNodesUsed();
    const draggedItem: SideListInterface = this.modifiedSideListItems?.find(
      (item: SideListInterface) => item.id === Number(pathNodeId),
    );

    draggedItem.position = {
      x: this.mousePosition.x ? this.mousePosition.x - 65 - 50 * this.zoomLevel : container.offsetWidth + 100,
      y: this.mousePosition.y ? this.mousePosition.y - 232 - 50 * this.zoomLevel : 100,
    };
  }

  public onDragEnd(event: any): void {
    this.selectedDragElementId = null;
    const targetPosition: DOMRect = this.dragContainer.nativeElement.getBoundingClientRect();
    const container: Element = document.querySelector('.draggable-box');
    const dragElement: HTMLElement = event.source.element.nativeElement;
    const offsetX: number = dragElement.getBoundingClientRect().x - targetPosition.left;
    dragElement.style.cursor = ECursorType.GRAB;
    const positionOutside: IPositionOutside = {
      x1: container.clientWidth < offsetX + dragElement.offsetWidth - 5,
      y1: dragElement.getBoundingClientRect().y < 227,
      x2: dragElement.getBoundingClientRect().x < 65,
      y2: targetPosition.height + 29 + 100 * (2 - this.zoomLevel) < dragElement.getBoundingClientRect().y,
    };

    this.setDefaultBoxPosition();

    if (positionOutside.x1 || positionOutside.y1 || positionOutside.x2 || positionOutside.y2) {
      this.changePosition(event, positionOutside);
    }

    this.isDragPositionChanged = false;
    this.areChangesMade = true;
    this.updateBoundaryAttribute(Number(dragElement.id));
    this.setDefaultBoxPosition();
    this.updateCardViewZoomButtonStatuses();
    this.zoomInZoomOutCheck();

    if (this.isPlanForSite && this.pathNodesUsedCardView.length === this.linesAsSideListItems$.length) {
      this.collapseOrExpandPathNodeList(false);
    }

    if (!this.isPlanForSite && this.pathNodesUsedCardView.length === this.stations$.length) {
      this.collapseOrExpandPathNodeList(false);
    }
  }

  public drawArrow($event: any): void {
    const selectedElementArrowCount: number = this.drawArrowIds.filter(
      (item: IDrawArrowProperties) =>
        item.startBoxId === $event.target.parentElement.id || item.endBoxId === $event.target.parentElement.id,
    )?.length;
    const menu: HTMLElement = document.getElementById('menu');

    if (selectedElementArrowCount === this.pathNodesUsedCardView.length - 1) {
      return;
    }

    if (this.drawArrowOptions.isDrawStartPoint) {
      this.drawArrowOptions.drawStartLocation = $event.target.parentElement.getBoundingClientRect();
      this.drawArrowOptions.drawStartLocation.pathNodeId = $event.target.parentElement.id;
      this.drawArrowOptions.isDrawStartPoint = false;
      this.createArrow(this.drawArrowOptions.drawStartLocation.pathNodeId);
      this.arrowPositions.push({
        ...this.defaultArrowPosition,
        pathNodeId: `start${this.drawArrowOptions.drawStartLocation.pathNodeId}`,
      });
      this.drawArrowIds.push({ startBoxId: this.drawArrowOptions.drawStartLocation.pathNodeId, endBoxId: '0' });
      this.setArrowPointDisabled(true);
      menu.style.left = `${this.mousePosition.x - 50}px`;
      menu.style.top = `${this.mousePosition.y - 220}px`;
      menu.style.display = 'block';
    } else {
      this.drawArrowOptions.drawEndLocation = $event.target.parentElement.getBoundingClientRect();
      this.drawArrowOptions.drawEndLocation.pathNodeId = $event.target.parentElement.id;

      const pathNodeIdWithStart: string = `start${this.drawArrowOptions.drawStartLocation.pathNodeId}`;
      const pathNodeIdWithEnd: string = `${pathNodeIdWithStart}-end${this.drawArrowOptions.drawEndLocation.pathNodeId}`;
      const selectedArrowIndex: number = this.arrowPositions.findIndex(
        (item: IArrowPosition): boolean => item.pathNodeId === pathNodeIdWithStart,
      );

      this.arrowPositions[selectedArrowIndex].pathNodeId = pathNodeIdWithEnd;
      this.drawArrowIds[this.drawArrowIds.length - 1].endBoxId = this.drawArrowOptions.drawEndLocation.pathNodeId;
      this.drawArrowOptions.isDrawStartPoint = true;
      document.getElementById(pathNodeIdWithStart).setAttribute('id', pathNodeIdWithEnd);
      this.setLinePositions(
        this.drawArrowOptions.drawStartLocation,
        this.drawArrowOptions.drawEndLocation,
        selectedArrowIndex,
      );

      const element: HTMLElement = document.getElementById(pathNodeIdWithEnd);
      const arrowLength: number = this.calculateArrowLength(selectedArrowIndex);
      const arrowId: string = `arrow${this.drawArrowOptions.drawStartLocation.pathNodeId}-arrow${this.drawArrowOptions.drawEndLocation.pathNodeId}`;
      menu.style.display = 'none';
      element.querySelector('marker').setAttribute('refX', (arrowLength / 5.9).toString());
      element.querySelector('marker').setAttribute('id', arrowId);
      element.querySelector('line').setAttribute('marker-end', `url(#${arrowId})`);
      this.setArrowPointDisabled(false);

      if (!this.isPlanForSite) {
        this.setPathNodeListBoxCollapse();
      }
    }
  }

  public deleteArrow(arrowElement?: HTMLElement): void {
    const arrowIdWithoutEndBoxId = _.find(this.drawArrowIds, { endBoxId: '0' });
    const arrowToDeleteId: string = arrowElement
      ? arrowElement.id
      : !this.selectedDeleteArrow || this.selectedDeleteArrow?.target?.parentElement?.id === ''
      ? `start${arrowIdWithoutEndBoxId?.startBoxId}`
      : this.selectedDeleteArrow.target.parentElement.id;
    const arrowToElementDelete: HTMLElement = arrowElement ?? document.getElementById(arrowToDeleteId);
    arrowToElementDelete.remove();
    this.setArrowPointDisabled(false);
    this.arrowPositions = this.arrowPositions.filter((item: IArrowPosition) => item.pathNodeId !== arrowToDeleteId);
    this.drawArrowIds = this.drawArrowIds.filter(
      (item: IDrawArrowProperties) =>
        item.startBoxId !== arrowToDeleteId?.split('-')[0]?.replace('start', '') ||
        (arrowToDeleteId?.split('-')[1]
          ? item.endBoxId !== arrowToDeleteId?.split('-')[1]?.replace('end', '')
          : item.endBoxId !== '0'),
    );
    this.drawArrowOptions.drawStartLocation.pathNodeId = this.selectedDeleteArrow?.target?.parentElement?.id
      .split('-')[0]
      .split('start')[1];
    this.drawArrowOptions.isDrawStartPoint = true;

    const menu: HTMLElement = document.getElementById('menu');
    menu.style.display = 'none';
    this.selectedDeleteArrow = null;

    if (this.isPlanForSite) {
      this.setPathNodeListBoxCollapse();
    }
  }

  private doExpandOrCollapseWhenAllNodesUsed(): void {
    if (this.isPlanForSite) {
      return;
    }

    if (this.stations$.length === this.pathNodesUsedCardView.length) {
      this.collapseOrExpandPathNodeList(this.sites$.length !== this.pathNodesUsedCardView.length);
    }
  }

  public deleteDraggedElement(event: any, pathNodeId: number): void {
    const elementToDelete: HTMLElement = event.target.parentElement;
    this.pathNodesUsedCardView.splice(_.findIndex(this.pathNodesUsedCardView, { id: Number(pathNodeId) }), 1);
    elementToDelete.classList.remove('dragged-element');
    const arrowElements: Element[] = Array.from(elementToDelete.querySelectorAll('.arrow-point'));
    arrowElements?.forEach((item: HTMLElement) => item.removeAttribute('style'));
    const container: Element = document.getElementById('pathNodeList');
    const selectedStation: SideListInterface = this.modifiedSideListItems?.find(
      (x: SideListInterface): boolean => x.id === Number(pathNodeId),
    );

    this.removeInitialAndGoodIcons(elementToDelete);

    if (selectedStation) {
      selectedStation.position = { x: 0, y: 0 };
    }

    event.target.removeAttribute('style');
    event.target.parentElement.style.removeProperty('width');
    event.target.parentElement.style.removeProperty('height');
    container.appendChild(elementToDelete);
    const svgElements: Element[] = Array.from(document.querySelectorAll(`svg[id*="${pathNodeId}"]`));
    svgElements.forEach((item: HTMLElement) => {
      this.deleteArrow(item);
    });

    this.updateBoundaryAttribute(Number(elementToDelete.id), document.querySelector('body'));
    this.onSideListItemsChange();
    this.updateCardViewZoomButtonStatuses();
    this.zoomInZoomOutCheck();
    this.doExpandOrCollapseWhenAllNodesUsed();

    if (!this.isPlanForSite) {
      this.setPathNodeListBoxCollapse();
    } else if (!this.isSideListExpanded) {
      this.collapseOrExpandPathNodeList(this.pathNodesUsedCardView.length < 2);
    }
  }

  public modalType: ECardViewModalType = null;

  public removeAllDraggedElements(modal: any = null): void {
    if (!modal) {
      this.removeDraggedBoxes();

      const arrowList: HTMLElement[] = Array.from(document.querySelectorAll('.arrow-container'));
      arrowList.forEach((arrow: HTMLElement) => {
        arrow.remove();
      });

      return;
    }

    this.modalType = ECardViewModalType.REMOVE_PATH_NODES;
    this.ngbModal.open(modal, smallModal);
  }

  public zoomInOutForCardView(zoomEvent: ZoomEvents): void {
    this.zoomLevel = this.zoomLevel + (zoomEvent === ZoomEvents.ZOOM_IN ? 0.1 : -0.1);
    const allBoxElements: HTMLElement[] = Array.from(document.querySelectorAll('.draggable-boundary .side-list-item'));
    allBoxElements.forEach((element: HTMLElement) => {
      if (_.some(this.pathNodesUsedCardView, { id: Number(element.id) })) {
        this.updateElementSize(element);
      }
    });
    setTimeout(() => {
      this.zoomChangePositions();
      this.zoomInZoomOutCheck();
      this.zoomButtonGroupCardView[1].disabled = this.zoomLevel < 0.7;
    });
  }

  public zoomChangePositions(): void {
    this.modifiedSideListItems?.forEach((item: StationInterface) => {
      const selectedStation: StationInterface = _.find(this.pathNodesUsedCardView, { id: item.id });

      if (!selectedStation) {
        return;
      }

      const xPosition: number = item.defaultPosition.x * this.zoomLevel;
      const yPosition: number = item.defaultPosition.y * this.zoomLevel;
      const boxPosition: IPoint = { x: xPosition, y: yPosition < -6 ? -6 : yPosition };
      item.position = boxPosition;
      selectedStation.position = boxPosition;
      this.selectedArrowElementId = item.id.toString();
      this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId'], true);
    });
  }

  public zoomInZoomOutCheck(): void {
    this.zoomButtonGroupCardView[0].disabled = !this.pathNodesUsedCardView.length;
    this.zoomButtonGroupCardView[1].disabled = !this.pathNodesUsedCardView.length;
    const container: HTMLElement = document.querySelector('.draggable-box');

    for (const item of this.pathNodesUsedCardView) {
      const defaultX: number = item.defaultPosition.x < 0 ? 0 : item.defaultPosition.x;
      const defaultY: number = item.defaultPosition.y < 0 ? 0 : item.defaultPosition.y;
      const offsetX: number = defaultX * (this.zoomLevel + 0.1) + 100 * (this.zoomLevel + 0.1);
      const offsetY: number = defaultY * (this.zoomLevel + 0.1) + 100 * (this.zoomLevel + 0.1);

      if (!this.zoomButtonGroupCardView[0].disabled) {
        this.zoomButtonGroupCardView[0].disabled =
          container.offsetWidth - 3 < offsetX || container.offsetHeight - 5 < offsetY;
      }

      if (!this.zoomButtonGroupCardView[1].disabled) {
        this.zoomButtonGroupCardView[1].disabled =
          defaultX * (this.zoomLevel - 0.1) < -2 || defaultY * (this.zoomLevel - 0.1) < -10;
      }

      if (this.zoomButtonGroupCardView[0].disabled && this.zoomButtonGroupCardView[1].disabled) {
        return;
      }
    }

    if (this.zoomLevel < 0.7) {
      this.zoomButtonGroupCardView[1].disabled = true;
    }
  }

  public reDrawUsedPathNodes(): void {
    setTimeout(() => {
      this.pathNodesUsedCardView.forEach((usedItem: StationInterface) => {
        this.generateDraggedItemDesign(usedItem.id.toString());
      });
      this.modifiedSideListItems.forEach((item: StationInterface) => {
        const findUsedBox: StationInterface = _.find(this.pathNodesUsedCardView, { id: item.id });

        if (!findUsedBox) {
          return;
        }

        const positionConfiguration: IPoint = {
          x: item.defaultPosition.x * this.zoomLevel > 0 ? item.defaultPosition.x * this.zoomLevel : 0,
          y: item.defaultPosition.y * this.zoomLevel > 0 ? item.defaultPosition.y * this.zoomLevel : -4,
        };

        item.position = positionConfiguration;
        findUsedBox.position = positionConfiguration;
      });
    });

    setTimeout(() => {
      this.pathNodesUsedCardView.forEach((item: ISideListItem) => {
        while (true) {
          const container: HTMLElement = document.querySelector('.draggable-box');
          const dragElement: HTMLElement = document.getElementById(item.id.toString());
          const offsetX: number = item.defaultPosition.x * this.zoomLevel - container.offsetLeft;
          const positionOutside: IPositionOutside = {
            x1: container.clientWidth < offsetX + dragElement.offsetWidth - 5,
            x2: false,
            y1: false,
            y2: container.offsetHeight < item.defaultPosition.y * this.zoomLevel + dragElement.offsetHeight + 6,
          };

          if (positionOutside.x1 || positionOutside.y2) {
            this.zoomInOutForCardView(ZoomEvents.ZOOM_OUT);
          } else {
            break;
          }
        }
      });
      this.arrowPositions.forEach((item: IArrowPosition) => {
        const startId: string = item.pathNodeId.split('-')[0].replace('start', '');
        const endId: string = item.pathNodeId.split('-')[1].replace('end', '');
        this.createArrow(startId, endId);
      });
      this.modifiedSideListItems.forEach((item: SideListInterface) => {
        this.selectedArrowElementId = item.id.toString();
        this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId']);
      });

      this.generateCollapseBoxDesign();
    });
  }

  public updateElementSize(boxElement: HTMLElement): void {
    const zoomedWidth: number = 100 * this.zoomLevel;
    const zoomedHeight: number = 100 * this.zoomLevel;
    boxElement.style.width = `${zoomedWidth}px`;
    boxElement.style.height = `${zoomedHeight}px`;
    boxElement.querySelector<HTMLElement>('.point-left').style.marginLeft = `${-100 * this.zoomLevel}px`;
    boxElement.querySelector<HTMLElement>('.point-right').style.marginLeft = `${100 * this.zoomLevel}px`;
    boxElement.querySelector<HTMLElement>('.point-top').style.marginTop = `${-100 * this.zoomLevel}px`;
    boxElement.querySelector<HTMLElement>('.point-bottom').style.marginTop = `${100 * this.zoomLevel}px`;
    const deleteButton: HTMLElement = boxElement.querySelector('.delete-button');
    deleteButton.style.marginTop = `${-80 * this.zoomLevel}px`;
    deleteButton.style.marginLeft = `${80 * this.zoomLevel}px`;
  }

  public updateBoundaryAttribute(
    pathNodeId: number,
    boundary: ElementRef | null | HTMLElement = this.dragContainer.nativeElement,
  ): void {
    const selectedBoundaryIndex: number = _.findIndex(this.dragElementBoundaries, { pathNodeId });
    this.dragElementBoundaries[selectedBoundaryIndex] = {
      pathNodeId,
      boundary,
    };
  }

  public getElementBoundary(pathNodeId: number): ElementRef {
    const selectedBoundaryIndex: number = _.findIndex(this.dragElementBoundaries, { pathNodeId });
    return this.dragElementBoundaries?.[selectedBoundaryIndex]?.boundary;
  }

  public setArrowPointDisabled(disabled: boolean): void {
    const { drawArrowOptions } = this;
    const { pathNodeId } = drawArrowOptions.drawStartLocation;

    const getPointElements = (elementId: string) => {
      const element: HTMLElement = document.getElementById(elementId);

      if (element) {
        const pointElements: HTMLElement[] = Array.from(element.querySelectorAll('.arrow-point'));
        pointElements.forEach((pointElement: HTMLElement) => {
          pointElement.style.pointerEvents = disabled ? 'none' : 'all';
        });
      }
    };

    const draggedItemPositions: IDrawArrowProperties[] = this.drawArrowIds.filter(
      (item: IDrawArrowProperties) =>
        item.startBoxId === pathNodeId?.toString() || item.endBoxId === pathNodeId?.toString(),
    );

    draggedItemPositions.forEach((item: IDrawArrowProperties) => {
      getPointElements(item.startBoxId);
      getPointElements(item.endBoxId);
    });
  }

  public openDeleteArrowIcon(event: unknown): void {
    this.selectedDeleteArrow = event;
    document.addEventListener('click', (mouseClickElement: MouseEvent) => {
      const isDrawStartArrow: boolean = (mouseClickElement.target as HTMLElement).classList.value.includes(
        'arrow-point',
      );

      if (mouseClickElement.target !== this.selectedDeleteArrow?.target && !isDrawStartArrow) {
        menu.style.display = 'none';
      }
    });

    const menu: HTMLElement = document.getElementById('menu');
    menu.style.left = `${this.mousePosition.x - 50}px`;
    menu.style.top = `${this.mousePosition.y - 220}px`;
    menu.style.display = 'block';
  }

  public changePosition(event, positionOutside: IPositionOutside): void {
    const container: Element = document.querySelector('.draggable-box');
    const dragElement: HTMLElement = event.source.element.nativeElement;
    const selectedStation: StationInterface = _.find(this.modifiedSideListItems, {
      id: Number(event.source.element.nativeElement.id),
    });
    const xPosition: number = positionOutside.x1
      ? container.clientWidth - dragElement.offsetWidth
      : positionOutside.x2
      ? 0
      : selectedStation.dragFlag
      ? selectedStation.position.x
      : selectedStation.defaultPosition.x;
    const yPosition: number = positionOutside.y1
      ? -6
      : positionOutside.y2
      ? container.clientHeight - 100 * this.zoomLevel - 2
      : selectedStation.dragFlag
      ? selectedStation.position.y
      : selectedStation.defaultPosition.y;
    selectedStation.position = {
      x: xPosition,
      y: yPosition,
    };

    setTimeout(() => {
      this.setDefaultBoxPosition();
      this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId']);
    });
  }

  public setDefaultBoxPosition(): void {
    this.pathNodesUsedCardView.forEach((item: StationInterface) => {
      const selectedStation: StationInterface = _.find(this.modifiedSideListItems, { id: item.id });
      const boxElementPosition: DOMRect = document.getElementById(item.id.toString()).getBoundingClientRect();
      const defaultPosition: IPoint = {
        x: (boxElementPosition.x - 67) / this.zoomLevel,
        y: (boxElementPosition.y - 239) / this.zoomLevel,
      };
      item.defaultPosition = defaultPosition;
      selectedStation.defaultPosition = defaultPosition;
    });
  }

  public collapseOrExpandPathNodeList(isExpand?: boolean): void {
    this.isSideListExpanded = isExpand ?? !this.isSideListExpanded;
    const pathNodeList: HTMLElement = document.querySelector('.path-node-list');

    if (!pathNodeList) {
      return;
    }

    this.showCreatePathWarningMessage = !this.paths.length && !this.isSideListExpanded;

    if (
      !this.isSideListExpanded ||
      (!this.isPlanForSite && this.pathNodesUsedCardView.length === this.stations$.length)
    ) {
      pathNodeList.style.height = '70px';
      document.getElementById('pathListCollapseButton').style.visibility = 'inherit';
      document.getElementById('pathListCollapseButton').style.position = 'inherit';

      return;
    }

    pathNodeList.style.height = 'calc(100vh - 550px)';
    document.getElementById('pathListCollapseButton').style.visibility = 'hidden';
    document.getElementById('pathListCollapseButton').style.position = 'absolute';
  }

  public createPath(): void {
    if (!this.isPathFormInvalid()) {
      document.getElementById('pathList').classList.add('show');
      const selectedPath: TStationPathSettings = _.find(this.paths, { isSelected: true });
      this.jobCountLines = [];
      this.workOrderCountStations = [];

      this.removeAllInitialAndGoodIcons();

      if (selectedPath) {
        selectedPath.isSelected = false;
      }

      const filteredColorScheme: IColorPaletteOption[] = this.displayColorScheme.filter(
        (color: IColorPaletteOption) => {
          return !this.paths.some(
            (path: TStationPathSettings) => path.colorSettings.backgroundColor === color.backgroundColor,
          );
        },
      );

      this.paths.push({
        id: null,
        name: '',
        selectedNodeIds: [],
        isDefaultPath: !this.paths.length,
        isSelected: true,
        isDuplicate: false,
        colorSettings: filteredColorScheme[0],
        errorSettings: {
          pathNameHasError: false,
          selectedPathNodeHasError: false,
          selectedPathJobInitialCountHasError: false,
          selectedPathJobGoodCountHasError: false,
          selectedPathWorkOrderInitialCountHasError: false,
          selectedPathWorkOrderGoodCountHasError: false,
        },
        stationPathOrderDetails: [
          {
            stationId: 0,
            isGoodCounter: false,
            isInitialCounter: false,
            isMidCounter: false,
          },
        ],
        initialCountLines: [],
        goodCountLines: [],
        initialCountStations: [],
        goodCountStations: [],
      });
      this.pathNodesUsedCardView.forEach((item: StationInterface) => {
        document.getElementById(item.id.toString()).style.removeProperty('border');
        this.setPathNodeBoxBorder(item.id.toString(), 'all', 'black', '#d9dfe7');
      });
      this.showCreatePathWarningMessage = false;
      this.areChangesMade = true;
      this.convertNotDraggableElements(true);
    }

    if (!this.isPlanForSite && this.pathNodesUsedCardView.length === this.stations$.length) {
      this.collapseOrExpandPathNodeList(false);
    }

    setTimeout(() => {
      this.setBottomScrollPosition();
    });
  }

  public setBottomScrollPosition(): void {
    const scrollBox: HTMLElement = document.getElementById('pathList');
    scrollBox.scrollTop = scrollBox.scrollHeight;
  }

  public proceedToDeletePath(modal: any): void {
    modal.close();
    this.paths = [];
    this.convertNotDraggableElements(false);
    this.showCreatePathWarningMessage = true;
    this.removeBoxModifications();
    const nodeListToUse: ISideListItem[] = this.isPlanForSite ? this.linesAsSideListItems$ : this.stations$;

    if (this.pathNodesUsedCardView.length < nodeListToUse.length) {
      this.collapseOrExpandPathNodeList(true);
      (document.querySelector('.path-node-list') as HTMLElement).style.removeProperty('height');
    }

    if (this.modalType === ECardViewModalType.REMOVE_PATH_NODES) {
      this.removeDraggedBoxes();
    }

    if (!this.isPlanForSite && this.pathNodesUsedCardView.length === this.stations$.length) {
      this.collapseOrExpandPathNodeList(false);
    }

    this.zoomInZoomOutCheck();
  }

  public isPathFormInvalid(
    isNameChange: boolean = false,
    isSelectedPathNodeChange: boolean = false,
    isJobCountDropdownCheck: boolean = true,
  ): boolean {
    if (
      (!this.isPlanForSite && this.paths.length >= 20) ||
      (this.isPlanForSite && this.pathNodesUsedCardView.length < 2 && this.isCardViewMode)
    ) {
      return true;
    }

    if (!this.paths.length) {
      return false;
    }

    const selectedPath: TLineAndStationPathSettings = _.find(this.paths, { isSelected: true });
    const pathName: string = selectedPath.name.trim();
    const isNameNotUnique: boolean =
      this.paths.filter((item: TLineAndStationPathSettings) => item.name.trim() === pathName).length > 1;

    if (((pathName === null || pathName === '') && !isSelectedPathNodeChange) || isNameNotUnique) {
      this.pathNameErrorMessage = isNameNotUnique ? this.isNotUniqueLabel : this.requiredLabel;
      selectedPath.errorSettings.pathNameHasError = true;
    } else if (!isSelectedPathNodeChange) {
      selectedPath.errorSettings.pathNameHasError = false;
    }

    if (!selectedPath.selectedNodeIds?.length && !isNameChange) {
      selectedPath.errorSettings.selectedPathNodeHasError = true;
    } else if (!isNameChange) {
      selectedPath.errorSettings.selectedPathNodeHasError = false;
    }

    if (!isNameChange && this.isPlanForSite && isJobCountDropdownCheck) {
      selectedPath.errorSettings.selectedPathJobInitialCountHasError = !selectedPath.initialCountLines.length;
      selectedPath.errorSettings.selectedPathJobGoodCountHasError = !selectedPath.goodCountLines.length;
    }

    if (!isNameChange && !this.isPlanForSite && isJobCountDropdownCheck) {
      selectedPath.errorSettings.selectedPathWorkOrderGoodCountHasError = !selectedPath.goodCountStations.length;
      selectedPath.errorSettings.selectedPathWorkOrderInitialCountHasError = !selectedPath.initialCountStations.length;
    }

    return (
      Object.values(selectedPath.errorSettings).some((item: boolean) => item) ||
      this.duplicateLinePathExists ||
      this.hasDuplicatedStationPath
    );
  }

  public openDeletePathConfirmationModal(modal: TemplateRef<any>): void {
    this.modalType = ECardViewModalType.DISCARD_CHANGES;
    this.ngbModal.open(modal, smallModal);
  }

  public onClickPathNodeBox($event: any): void {
    const selectedPath: TLineAndStationPathSettings = _.find(this.paths, { isSelected: true });

    if (
      selectedPath?.lineProductStationPathAssignments?.length ||
      selectedPath?.linePathProductConfigurations?.length ||
      !selectedPath
    ) {
      return;
    }

    let selectedBox: HTMLElement = $event.target;

    if (selectedBox.id === 'nodeName') {
      selectedBox = selectedBox.parentElement;
    }

    if (!selectedPath.selectedNodeIds.includes(Number(selectedBox.id)) && selectedBox.id !== '') {
      selectedBox.style.border = `3px solid ${selectedPath.colorSettings.backgroundColor}`;
      selectedPath.selectedNodeIds.push(Number(selectedBox.id));
    } else {
      selectedBox.style.removeProperty('border');
      this.removeInitialAndGoodIcons(selectedBox);
      selectedPath.selectedNodeIds = selectedPath.selectedNodeIds.filter(
        (item: number) => item !== Number(selectedBox.id),
      );
    }

    if (this.isPlanForSite) {
      this.filterPathsJobCountLines(selectedPath);
      this.fillJobCountLinesDropdown(selectedPath);
    } else {
      this.filterPathsStationPathOrderDetails(selectedPath);
      this.fillWorkOrderCountStationsDropdown(selectedPath);
    }

    this.highlightSelectedPathNodeBoxes();
    this.isPathFormInvalid(false, true, false);
    this.checkDuplicateLinePaths(selectedPath);
    this.checkDuplicateStationPaths(selectedPath);
  }

  public onClickSelectPathCard(index: number, isReDraw: boolean = false): void {
    if (this.paths[index].isSelected && !isReDraw) {
      return;
    }

    if (!isReDraw && this.isPathFormInvalid()) {
      setTimeout(() => {
        const scrollBox: HTMLElement = document.getElementById('pathList');
        scrollBox.scrollTop = scrollBox.scrollHeight;
      });

      return;
    }

    const selectedPath: TStationPathSettings = _.find(this.paths, { isSelected: true });
    selectedPath.isSelected = false;
    const newSelectedPath: TLineAndStationPathSettings = this.paths[index];
    newSelectedPath.isSelected = true;

    this.removeBoxModifications();

    newSelectedPath.selectedNodeIds?.forEach((nodeId: number) => {
      const selectedPathBox: HTMLElement = document.getElementById(nodeId.toString());

      if (!selectedPathBox) {
        return;
      }

      selectedPathBox.style.border = `3px solid ${this.paths[index].colorSettings.backgroundColor}`;

      this.setInitialAndGoodIconsVisibility(selectedPathBox, nodeId, newSelectedPath);
    });

    if (this.isPlanForSite) {
      this.fillJobCountLinesDropdown(newSelectedPath);
    } else {
      this.fillWorkOrderCountStationsDropdown(newSelectedPath);
    }

    this.highlightSelectedPathNodeBoxes();
  }

  public setAsDefaultPath(isDefault: boolean): void {
    if (!isDefault) {
      _.find(this.paths, { isDefaultPath: true }).isDefaultPath = false;
      _.find(this.paths, { isSelected: true }).isDefaultPath = true;
    }
  }

  public removeBoxModifications(): void {
    this.pathNodesUsedCardView.forEach((item: StationInterface) => {
      const pathNodeBox: HTMLElement = document.getElementById(item.id.toString());
      pathNodeBox.style.removeProperty('border');
      this.removeInitialAndGoodIcons(pathNodeBox);
    });
  }

  public deletePath(): void {
    this.paths = this.paths.filter((item: TStationPathSettings) => !item.isSelected);

    if (!this.paths.length) {
      this.showCreatePathWarningMessage = true;
      this.convertNotDraggableElements(false);
      this.removeBoxModifications();

      return;
    }

    if (!_.find(this.paths, { isDefaultPath: true })) {
      this.paths[0].isDefaultPath = true;
    }

    this.paths[this.paths.length - 1].isSelected = true;
    this.onClickSelectPathCard(this.paths.length - 1, true);
    this.duplicateLinePathExists = false;
    this.hasDuplicatedStationPath = false;
  }

  public highlightSelectedPathNodeBoxes(): void {
    this.pathNodesUsedCardView.forEach((item: StationInterface) => {
      this.setPathNodeBoxBorder(item.id.toString(), 'all', 'black', '#d9dfe7');
    });
  }

  public convertNotDraggableElements(isNotDraggable: boolean): void {
    this.modifiedSideListItems.forEach((item: SideListInterface) => {
      item.isNotDraggable = isNotDraggable;
    });

    const draggedElements: HTMLElement[] = Array.from(document.querySelectorAll('.dragged-element'));
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    const draggedElementsSource: ISideListItem[] = HelperService.cloneDeep(
      categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE ? this.stations$ : this.linesAsSideListItems$,
    );

    draggedElements.forEach((element: HTMLElement) => {
      const childItems: HTMLElement[] = Array.from(element.getElementsByTagName('span'));
      const selectedPathNode: ISideListItem = _.find(draggedElementsSource, { id: Number(element.id) });
      element.style.background = isNotDraggable ? '#d9dfe7' : selectedPathNode.colorSetting.backgroundColor;
      element.style.cursor = isNotDraggable ? ECursorType.POINTER : ECursorType.GRAB;
      element.style.color = isNotDraggable ? 'black' : selectedPathNode.colorSetting.text;
      element.classList.add('path-box');
      childItems.forEach((childItem: HTMLElement) => {
        if (childItem.id !== 'nodeName') {
          childItem.style.display = isNotDraggable ? 'none' : 'inline-block';
        }
      });

      if (!isNotDraggable) {
        element.style.pointerEvents = 'all';
      }
    });

    const arrowElements: HTMLElement[] = Array.from(document.querySelectorAll('.arrow'));
    arrowElements.forEach((arrowElement: HTMLElement) => {
      arrowElement.style.pointerEvents = isNotDraggable ? 'none' : 'all';
    });
  }

  public removeAllItems(): void {
    this.removeAllDraggedElements();
    this.changedMapViewData = null;
    this.pathNodesUsedCardView = [];
    this.modifiedSideListItems = [];
    this.paths = [];
    this.selectedDeleteArrow = null;
    this.drawArrowOptions = {
      isDrawStartPoint: true,
      drawStartLocation: { pathNodeId: 0, x: 0, y: 0, top: 0, bottom: 0, left: 0, right: 0 },
      drawEndLocation: { pathNodeId: 0, x: 0, y: 0, top: 0, bottom: 0, left: 0, right: 0 },
    };
    this.defaultArrowPosition = { pathNodeId: '0', startX: 0, startY: 0, endX: 0, endY: 0 };
    this.arrowPositions = [];
    this.selectedArrowElementId = '';
    this.drawArrowIds = [];
    this.mousePosition = { x: 0, y: 0 };
    this.isDragPositionChanged = false;
    this.dragPositionChangeItemId = '';
    this.zoomLevel = 1;
    this.previewZoomLevel = 1;
    this.dragElementBoundaries = [];
    this.showCreatePathWarningMessage = false;
    this.linePlanViewTypeForPreview = ELinePlanViewTypeEnum.cardView;
    this.hasAssigmentProductConfiguration = false;
  }

  public previewZoomForCardView($event: ZoomEvents): void {
    this.previewZoomLevel += $event === ZoomEvents.ZOOM_IN ? 0.1 : -0.1;
    this.zoomLevel = this.previewZoomLevel;
    this.modifiedSideListItems.forEach((item: StationInterface) => {
      document.getElementById(item.id.toString()).style.width = `${100 * this.previewZoomLevel}px`;
      document.getElementById(item.id.toString()).style.height = `${100 * this.previewZoomLevel}px`;
      item.position = {
        x: item.defaultPosition.x * this.previewZoomLevel,
        y: item.defaultPosition.y * this.previewZoomLevel,
      };
      _.find(this.modifiedSideListItems, { id: item.id }).position = item.position;

      this.selectedArrowElementId = item.id.toString();
      this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId'], true);
    });

    this.zoomButtonGroup[1].disabled = this.previewZoomLevel < 0.7;
  }

  public onJobCountLinesChange(
    selectedPath: TLinePathSettings,
    countType: 'initialCountLines' | 'goodCountLines',
  ): void {
    this.checkDuplicateLinePaths(selectedPath);

    for (const path of this.pathNodesUsedCardView) {
      const dragElement: HTMLElement = document.getElementById(String(path.id));
      const initialCountIcon: HTMLElement = dragElement.querySelector(
        countType === 'initialCountLines' ? '.fa-sign-in-alt' : '.fa-sign-out-alt',
      );
      initialCountIcon.style.display = 'none';
    }

    if (countType === 'initialCountLines') {
      selectedPath.errorSettings.selectedPathJobInitialCountHasError = !selectedPath.initialCountLines.length;

      for (const line of selectedPath.initialCountLines) {
        const dragElement: HTMLElement = document.getElementById(String(line.id));
        const initialCountIcon: HTMLElement = dragElement.querySelector('.fa-sign-in-alt');
        initialCountIcon.style.display = 'inline-block';
      }

      return;
    }

    selectedPath.errorSettings.selectedPathJobGoodCountHasError = !selectedPath.goodCountLines.length;

    for (const line of selectedPath.goodCountLines) {
      const dragElement: HTMLElement = document.getElementById(String(line.id));
      const goodCountIcon: HTMLElement = dragElement.querySelector('.fa-sign-out-alt');
      goodCountIcon.style.display = 'inline-block';
    }
  }

  public onWorkOrderCountStationsChange(
    selectedPath: TStationPathSettings,
    countType: 'initialCountStations' | 'goodCountStations',
  ): void {
    this.checkDuplicateStationPaths(selectedPath);

    for (const path of this.pathNodesUsedCardView) {
      const dragElement: HTMLElement = document.getElementById(String(path.id));
      const initialCountIcon: HTMLElement = dragElement.querySelector(
        countType === 'initialCountStations' ? '.fa-sign-in-alt' : '.fa-sign-out-alt',
      );
      initialCountIcon.style.display = 'none';
    }

    if (countType === 'initialCountStations') {
      selectedPath.errorSettings.selectedPathWorkOrderInitialCountHasError = !selectedPath.initialCountStations.length;

      for (const station of selectedPath.initialCountStations) {
        const dragElement: HTMLElement = document.getElementById(String(station.id));
        const initialCountIcon: HTMLElement = dragElement.querySelector('.fa-sign-in-alt');
        initialCountIcon.style.display = 'inline-block';
      }

      return;
    }

    selectedPath.errorSettings.selectedPathWorkOrderGoodCountHasError = !selectedPath.goodCountStations.length;

    for (const station of selectedPath.goodCountStations) {
      const dragElement: HTMLElement = document.getElementById(String(station.id));
      const goodCountIcon: HTMLElement = dragElement.querySelector('.fa-sign-out-alt');
      goodCountIcon.style.display = 'inline-block';
    }
  }

  public static createFloorPlanSvgOnSelection(
    selection: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
    isPreviewModal: boolean = false,
  ): d3.Selection<SVGSVGElement, any, HTMLElement, any> {
    return selection
      .append('svg')
      .attr('id', 'floorPlanSvg')
      .style('display', 'block')
      .style('height', isPreviewModal ? '100%' : `${selection.node()?.clientHeight}px`)
      .style('width', '100%')
      .style(
        'background',
        'repeating-linear-gradient( 45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 10px, rgba(190, 190, 190, 0.2) 10px, rgba(190, 190, 190, 0.2) 20px)',
      );
  }

  private static getClosestPointOnPlanItemInformation(
    point: Point,
    planItemEndPoints: [Point, Point],
  ): PointDistanceData {
    const pointXDifference: number = point.x - planItemEndPoints[0].x;
    const pointYDifference: number = point.y - planItemEndPoints[0].y;
    const planItemXDifference: number = planItemEndPoints[1].x - planItemEndPoints[0].x;
    const planItemYDifference: number = planItemEndPoints[1].y - planItemEndPoints[0].y;

    const dotProduct: number = pointXDifference * planItemXDifference + pointYDifference * planItemYDifference;
    const lengthSquared: number = Math.pow(planItemXDifference, 2) + Math.pow(planItemYDifference, 2);
    let param: number = -1;

    if (lengthSquared !== 0) {
      param = dotProduct / lengthSquared;
    }

    let closestXPoint: number;
    let closestYPoint: number;

    if (param < 0) {
      closestXPoint = planItemEndPoints[0].x;
      closestYPoint = planItemEndPoints[0].y;
    } else if (param > 1) {
      closestXPoint = planItemEndPoints[1].x;
      closestYPoint = planItemEndPoints[1].y;
    } else {
      closestXPoint = planItemEndPoints[0].x + param * planItemXDifference;
      closestYPoint = planItemEndPoints[0].y + param * planItemYDifference;
    }

    const xDistanceToClosestPoint: number = point.x - closestXPoint;
    const yDistanceToClosestPoint: number = point.y - closestYPoint;

    return {
      x: closestXPoint,
      y: closestYPoint,
      distance: Math.sqrt(Math.pow(xDistanceToClosestPoint, 2) + Math.pow(yDistanceToClosestPoint, 2)),
    };
  }

  private static getNormalizedPoint(
    pointerEvent: PointerEvent,
    imageElement: d3.Selection<SVGImageElement, any, HTMLElement, any>,
    currentZoom: number,
  ): Point {
    return {
      x: (pointerEvent.offsetX - Number(imageElement.attr('offsetX'))) / currentZoom,
      y: (pointerEvent.offsetY - Number(imageElement.attr('offsetY'))) / currentZoom,
    };
  }

  private static getAllDistancesToPlanItemsFromPoint(
    pointClicked: Point,
    offsetData: PlanItemFieldGElementDatumInterface,
    planItemPoints: Point[],
  ): PointDistanceDataWithIndex[] {
    const distances: PointDistanceDataWithIndex[] = [];

    for (let pointIndex: number = 0; pointIndex < planItemPoints.length; pointIndex += 1) {
      const normalizedPlanItemEndPoints: [Point, Point] = [
        {
          x: planItemPoints[pointIndex].x + offsetData.x,
          y: planItemPoints[pointIndex].y + offsetData.y,
        },
        {
          x: planItemPoints[(pointIndex + 1) % planItemPoints.length].x + offsetData.x,
          y: planItemPoints[(pointIndex + 1) % planItemPoints.length].y + offsetData.y,
        },
      ];

      distances.push({
        pointIndex,
        ...FloorPlanComponent.getClosestPointOnPlanItemInformation(pointClicked, normalizedPlanItemEndPoints),
      });
    }

    return distances;
  }

  private generateStationPathsData(stationPaths: TLineAndStationPathSettings[]): void {
    let index: number = -1;
    this.paths = stationPaths.map((path: TLineAndStationPathSettings) => {
      const initialCountStationIds: number[] = [];
      const goodCountStationIds: number[] = [];

      const initialCountStations: DropdownOptionsInterface[] = [];
      const goodCountStations: DropdownOptionsInterface[] = [];

      path.stationPathOrderDetails.forEach((stationPathOrderDetail: IStationPathOrderDetail) => {
        if (stationPathOrderDetail.isInitialCounter) {
          initialCountStationIds.push(stationPathOrderDetail.stationId);
        }

        if (stationPathOrderDetail.isGoodCounter) {
          goodCountStationIds.push(stationPathOrderDetail.stationId);
        }
      });

      for (const station of this.stations$) {
        if (initialCountStationIds.includes(station.id)) {
          initialCountStations.push({ id: station.id, name: station.name });
        }

        if (goodCountStationIds.includes(station.id)) {
          goodCountStations.push({ id: station.id, name: station.name });
        }
      }

      index += 1;
      return {
        ...path,
        ...{
          selectedNodeIds: HelperService.cloneDeep(
            path.stationPathOrderDetails.map((item: IStationPathOrderDetail) => item.stationId),
          ),
          initialCountStations: HelperService.cloneDeep(initialCountStations),
          goodCountStations: HelperService.cloneDeep(goodCountStations),
          isSelected: !!path.isDefaultPath,
          name: path.name,
          colorSettings: this.displayColorScheme[index],
          errorSettings: {
            pathNameHasError: false,
            selectedPathNodeHasError: false,
            selectedPathWorkOrderInitialCountHasError: false,
            selectedPathWorkOrderGoodCountHasError: false,
          },
        },
      };
    });
  }

  private generateLinePathsData(linePaths: ILinePathConfiguration[]): void {
    let index: number = -1;
    this.paths = linePaths.map((path: ILinePathConfiguration) => {
      const initialCountLines: DropdownOptionsInterface[] = [];
      const goodCountLines: DropdownOptionsInterface[] = [];

      for (const line of this.lines$) {
        if (path.initialCountLineIds?.includes(line.id)) {
          initialCountLines.push({ id: line.id, name: line.title });
        }

        if (path.goodCountLineIds?.includes(line.id)) {
          goodCountLines.push({ id: line.id, name: line.title });
        }
      }

      index += 1;
      return {
        ...path,
        selectedNodeIds: _.uniq(
          HelperService.cloneDeep(path.linePathOrderDetails?.map((item: ILinePathOrderDetail) => item.lineId)),
        ),
        initialCountLines: HelperService.cloneDeep(initialCountLines),
        goodCountLines: HelperService.cloneDeep(goodCountLines),
        isSelected: path.isDefaultPath,
        isDuplicate: false,
        name: path.name,
        colorSettings: this.displayColorScheme[index],
        errorSettings: {
          pathNameHasError: false,
          selectedPathNodeHasError: false,
          selectedPathJobInitialCountHasError: false,
          selectedPathJobGoodCountHasError: false,
        },
      };
    });
  }

  private refreshPageState(): void {
    this.disableDatatable = true;
    this.isSelectedFloorPlanImageLoaded = false;
    this.dismissAllExistingModals();
    this.selectOrUnselectAll(false);

    this.isFloorPlanDataRequested = true;
    this.drawArrowIds = [];
    this.arrowPositions = [];
    this.floorPlanToDisplay = null;
    this.pathNodesUsedCardView = [];
    this.store.dispatch(new FloorPlanActions.FloorPlanDataLoading(...HelperService.argumentClone(this.tableQuery)));
  }

  private dismissAllExistingModals(): void {
    if (this.ngbModal.hasOpenModals()) {
      this.removeAllItems();
      this.ngbModal.dismissAll();
    }
  }

  private showSuccessToast(): void {
    this.toastHelperService.showToastMessage(
      true,
      this.translateService.instant('general.success'),
      this.translateService.instant('general.changesSavedSuccessfully'),
    );
  }

  private showSuccessThenRefresh(): void {
    this.showSuccessToast();
    this.refreshPageState();
  }

  private setDefaultSite(defaultSiteId: string): void {
    this.floorPlanForm.site.value = [_.find(this.sites$, (site): boolean => site.id === defaultSiteId)];
    this.onSiteInputModelChange(defaultSiteId, true);
  }

  private populateDepartmentsToDisplay(): void {
    this.departmentsToDisplay = this.departments$.filter((department: DepartmentWithNameInterface) =>
      this.lines$.some((line: LineInterface): boolean => line.lineType === department.id),
    );

    this.floorPlanDepartmentInputModel = HelperService.cloneDeep(
      this.departmentsToDisplay.map(
        (department: DepartmentWithNameInterface): DropdownOptionsInterface => ({
          id: department.id,
          name: department.name,
        }),
      ),
    );
    this.onSideListItemsChange();
  }

  private initializeFloorPlanPreviewSvg(): void {
    const floorPlanPreviewSvg: d3.Selection<SVGSVGElement, any, HTMLElement, any> =
      FloorPlanComponent.createFloorPlanSvgOnSelection(d3.select('#floorPlanPreviewCard'), true);

    this.previewZoom = this.createZoomObject(floorPlanPreviewSvg);
    floorPlanPreviewSvg.call(this.previewZoom);

    const image: HTMLImageElement = new Image();
    let floorPlanPreviewSvgImage: d3.Selection<SVGImageElement, any, HTMLElement, any>;

    image.onload = (): void => {
      this.isFirstLoadOfTheCurrentImage = true;
      this.imageNaturalWidth = image.naturalWidth;
      this.imageNaturalHeight = image.naturalHeight;

      floorPlanPreviewSvgImage = floorPlanPreviewSvg
        .append('image')
        .attr('xlink:href', this.selectedFloorPlanImageBase64);

      this.isImageUploaded = true;
      const mapViewConfiguration: FloorPlanConfigurationInterface[] =
        this.floorPlanToDisplay.configuration.mapViewConfiguration;

      this.initiateZoom(this.previewZoom);
      this.loadFloorPlan(
        _.orderBy(mapViewConfiguration.slice(), ['itemId']),
        floorPlanPreviewSvg,
        this.previewCurrentZoom,
        false,
      );
      this.updateZoomButtonStatuses();
    };
    image.src = this.selectedFloorPlanImageBase64;
  }

  private initializeFloorPlanEditor(): void {
    this.floorPlanSvg = FloorPlanComponent.createFloorPlanSvgOnSelection(
      d3.select(this.isPreviewModalOpen ? '#floorPlanPreviewCard' : '#floorPlanCard'),
    );

    this.zoom = this.createZoomObject(this.floorPlanSvg);
    this.floorPlanSvg.call(this.zoom);
  }

  private createInitialSideListArea(pointerEvent: DragEvent, categoryId: number): void {
    const planItemFieldGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any> =
      this.floorPlanSvg
        .append('g')
        .attr('id', `${this.draggedPlanItem.id}-area`)
        .datum<PlanItemFieldGElementDatumInterface>(
          (): PlanItemFieldGElementDatumInterface => ({
            itemId: Number(this.draggedPlanItem.id.trim()),
            itemName: this.draggedPlanItem.textContent.trim(),
            x: Math.max(
              Math.min(
                (pointerEvent.offsetX - Number(this.floorPlanSvgImage.attr('offsetX'))) / this.currentZoom,
                this.imageNaturalWidth - this.defaultShapeWidth / this.currentZoom,
              ),
              0,
            ),
            y: Math.max(
              Math.min(
                (pointerEvent.offsetY - Number(this.floorPlanSvgImage.attr('offsetY'))) / this.currentZoom,
                this.imageNaturalHeight - this.defaultShapeHeight / this.currentZoom,
              ),
              0,
            ),
          }),
        )
        .attr('transform', (point: Point): string => {
          const translateX: number = point.x * this.currentZoom + Number(this.floorPlanSvgImage.attr('offsetX'));
          const translateY: number = point.y * this.currentZoom + Number(this.floorPlanSvgImage.attr('offsetY'));

          return `translate(${translateX},${translateY}) scale(${this.currentZoom})`;
        })
        .on('dblclick', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });

    const polygonPoints: Point[] = this.getPolygonPoints();
    let planItemAreaIndex: number;

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      planItemAreaIndex = _.findIndex(
        this.lines$,
        (line: LineInterface): boolean => line.id === Number(this.draggedPlanItem.id),
      );
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      planItemAreaIndex = _.findIndex(
        this.stations$,
        (station: StationInterface): boolean => station.id === Number(this.draggedPlanItem.id),
      );
    }

    this.createPlanItemAreaShape(planItemFieldGElement, polygonPoints, planItemAreaIndex);
    this.createPlanItemAreaCircles(planItemFieldGElement, polygonPoints, planItemAreaIndex);
    this.createPlanItemAreaText(planItemFieldGElement, false);
  }

  private getPolygonPoints(): Point[] {
    return [
      {
        x: 0,
        y: 0,
      },
      {
        x: this.defaultShapeWidth / this.currentZoom,
        y: 0,
      },
      {
        x: this.defaultShapeWidth / this.currentZoom,
        y: this.defaultShapeHeight / this.currentZoom,
      },
      {
        x: 0,
        y: this.defaultShapeHeight / this.currentZoom,
      },
    ];
  }

  private convertPointsArrayToPointsString(points: Point[]): string {
    return points.map((point: Point): string => `${point.x},${point.y}`).join(' ');
  }

  private createPlanItemAreaShape(planItemFieldGElement, polygonPoints: Point[], index?: number): void {
    let planItemColor: string;

    if (
      this.selectedModalType === FloorPlanModalTypesEnum.PREVIEW_FLOOR_PLAN_MODAL ||
      this.selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL ||
      this.selectedModalType === FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL
    ) {
      planItemColor = this.getColorScheme(index).backgroundColor;
    }

    const planItemFieldPolygonElement: d3.Selection<
      SVGPolygonElement,
      PlanItemFieldGElementDatumInterface,
      SVGGElement,
      PlanItemFieldGElementDatumInterface
    > = planItemFieldGElement
      .append('polygon')
      .attr('points', this.convertPointsArrayToPointsString(polygonPoints))
      .attr('width', '100')
      .attr('height', '100')
      .attr('stroke', planItemColor)
      .attr('stroke-width', `${this.defaultShapeStrokeWidth / this.previewCurrentZoom}px`)
      .attr('fill', planItemColor)
      .attr('fill-opacity', '0.6');

    if (
      this.selectedModalType === FloorPlanModalTypesEnum.CREATE_FLOOR_PLAN_MODAL ||
      this.selectedModalType === FloorPlanModalTypesEnum.EDIT_FLOOR_PLAN_MODAL
    ) {
      planItemFieldPolygonElement
        .attr('stroke', planItemColor)
        .attr('stroke-width', `${this.defaultShapeStrokeWidth / this.currentZoom}px`)
        .attr('fill', planItemColor)
        .style('cursor', 'move')
        .on('contextmenu', (event): void => {
          event.preventDefault();
        })
        .on('dblclick', (event) => {
          event.stopPropagation();
          event.preventDefault();
        })
        .on('pointerdown', (event, polygonDatum: PlanItemFieldGElementDatumInterface): void => {
          event.stopPropagation();
          event.preventDefault();

          if (event.button === MouseButtonTypeEnum.rightClick) {
            this.removePolygon(d3.select(event.target.parentElement));
          } else if (event.button === MouseButtonTypeEnum.leftClick) {
            if (this.isPlanItemAreaClickedOnce) {
              this.isPlanItemAreaClickedOnce = false;

              clearTimeout(this.planItemAreaClickTimer);

              const updatedPointsOfPolygon: Point[] = this.addNewPointToPolygonOnClosestPlanItem(
                event,
                polygonDatum,
                planItemFieldGElement,
              );
              this.updateCirclesOfPolygon(updatedPointsOfPolygon, planItemFieldGElement);
              this.isDraggingShape = false;
              this.draggedShape = null;
            } else {
              this.isPlanItemAreaClickedOnce = true;

              this.planItemAreaClickTimer = setTimeout(() => {
                this.isDraggingShape = true;
                this.draggedShape = event.target;
                d3.select<SVGGElement, PlanItemFieldGElementDatumInterface>(event.target.parentElement).raise();
                this.isPlanItemAreaClickedOnce = false;
              }, 150);
            }
          }

          this.areChangesMade = true;
        })
        .on('pointermove', (event): void => {
          if (!this.isDraggingShape || _.isNil(this.draggedShape)) return;

          event.stopPropagation();

          this.areChangesMade = true;

          const parentElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any> = d3.select<
            SVGGElement,
            PlanItemFieldGElementDatumInterface
          >(event.target.parentElement);
          const parentElementDatum: PlanItemFieldGElementDatumInterface = parentElement.datum();

          const datumWithNewLocation: PlanItemFieldGElementDatumInterface = {
            ...parentElementDatum,
            x: Math.max(
              Math.min(
                parentElementDatum.x + event.movementX / this.currentZoom,
                this.imageNaturalWidth - event.target.getBBox().width,
              ),
              0,
            ),
            y: Math.max(
              Math.min(
                parentElementDatum.y + event.movementY / this.currentZoom,
                this.imageNaturalHeight - event.target.getBBox().height,
              ),
              0,
            ),
          };

          parentElement.datum(datumWithNewLocation).attr('transform', (point: Point): string => {
            const translateX: number = point.x * this.currentZoom + Number(this.floorPlanSvgImage.attr('offsetX'));
            const translateY: number = point.y * this.currentZoom + Number(this.floorPlanSvgImage.attr('offsetY'));

            return `translate(${translateX},${translateY}) scale(${this.currentZoom})`;
          });
        })
        .on('pointerup', (event): void => {
          event.stopPropagation();

          clearTimeout(this.planItemAreaClickTimer);
          setTimeout(() => {
            this.isPlanItemAreaClickedOnce = false;
          }, 150);

          this.isDraggingShape = false;
          this.draggedShape = null;
        })
        .on('pointerleave', (event): void => {
          event.stopPropagation();
          this.isDraggingShape = false;
          this.draggedShape = null;
        });
    }
  }

  private removePolygon(svgGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>): void {
    this.areChangesMade = true;
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      this.linesUsed = this.linesUsed.filter((line: LineInterface): boolean => line.id !== svgGElement.datum().itemId);
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      this.stationsUsed = this.stationsUsed.filter(
        (station: StationInterface): boolean => station.id !== svgGElement.datum().itemId,
      );
    }

    this.onSideListItemsChange();
    svgGElement.remove();
  }

  private getPointArrayFromElementWithPointsAttribute(
    targetElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): Point[] {
    return targetElement
      .select<SVGPolygonElement>('polygon')
      .attr('points')
      .split(' ')
      .map((point): Point => {
        const [x, y] = point.split(',').map((point: string) => Number(point));
        return {
          x,
          y,
        };
      });
  }

  private addNewPointToPolygonOnClosestPlanItem(
    event,
    polygonDatum: PlanItemFieldGElementDatumInterface,
    parentGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): Point[] {
    this.areChangesMade = true;
    const normalizePointClicked: Point = FloorPlanComponent.getNormalizedPoint(
      event,
      this.floorPlanSvgImage,
      this.currentZoom,
    );
    const distances: PointDistanceDataWithIndex[] = FloorPlanComponent.getAllDistancesToPlanItemsFromPoint(
      normalizePointClicked,
      polygonDatum,
      event.target.points,
    );
    const closestPointIndex: number = distances.sort(
      (a: PointDistanceDataWithIndex, b: PointDistanceDataWithIndex) => a.distance - b.distance,
    )[0].pointIndex;
    const pointsOfPolygon: Point[] = this.getPointArrayFromElementWithPointsAttribute(parentGElement);
    const closestPoint: Point = {
      x:
        (pointsOfPolygon[closestPointIndex].x + pointsOfPolygon[(closestPointIndex + 1) % pointsOfPolygon.length].x) /
        2,
      y:
        (pointsOfPolygon[closestPointIndex].y + pointsOfPolygon[(closestPointIndex + 1) % pointsOfPolygon.length].y) /
        2,
    };

    pointsOfPolygon.splice(closestPointIndex + 1, 0, {
      x: closestPoint.x,
      y: closestPoint.y,
    });

    parentGElement
      .select<SVGPolygonElement>('polygon')
      .attr('points', this.convertPointsArrayToPointsString(pointsOfPolygon));
    parentGElement.selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle').remove();

    return pointsOfPolygon;
  }

  private updateCirclesOfPolygon(
    newPoints: Point[],
    parentGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): void {
    const { itemId } = parentGElement.datum();
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    let listToUse: LineInterface[] | StationInterface[];

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      listToUse = this.lines$;
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      listToUse = this.stations$;
    }

    const { backgroundColor } = this.getColorScheme(
      _.findIndex(listToUse, (item: LineInterface | StationInterface): boolean => item.id === Number(itemId)),
    );

    parentGElement
      .selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle')
      .data(newPoints)
      .enter()
      .append('circle')
      .attr('cx', (point: Point) => {
        return point.x;
      })
      .attr('cy', (point: Point) => point.y)
      .attr('r', this.defaultCircleRadius / this.currentZoom)
      .attr('fill', backgroundColor)
      .attr('is-handle', 'true')
      .style('cursor', 'pointer')
      .on('contextmenu', (event): void => {
        event.preventDefault();
      })
      .on('pointerdown', (event): void => {
        event.stopPropagation();

        if (event.button === MouseButtonTypeEnum.rightClick) {
          this.removeCircle(parentGElement, event);
        }
      })
      .on('pointermove', (event): void => {
        event.stopPropagation();
      })
      .on('pointerup', (event): void => {
        event.stopPropagation();
      })
      .on('dblclick', (event): void => {
        event.preventDefault();
        event.stopPropagation();
      })
      .call(this.createCircleDragBehaviour(parentGElement));
  }

  private removeCircle(planItemFieldGElement: d3.Selection<SVGGElement, any, SVGSVGElement, any>, event): void {
    this.areChangesMade = true;
    const selectedCircle: d3.Selection<SVGCircleElement, PlanItemFieldGElementDatumInterface, any, any> = d3.select<
      SVGCircleElement,
      PlanItemFieldGElementDatumInterface
    >(event.target);
    selectedCircle.remove();

    const newPoints: Point[] = [];
    let circle: d3.Selection<
      SVGCircleElement,
      PlanItemFieldGElementDatumInterface,
      SVGGElement,
      PlanItemFieldGElementDatumInterface
    >;
    const polygon: d3.Selection<
      SVGPolygonElement,
      PlanItemFieldGElementDatumInterface,
      SVGGElement,
      PlanItemFieldGElementDatumInterface
    > = planItemFieldGElement.select<SVGPolygonElement>('polygon');
    const circles: SVGCircleElement[] = planItemFieldGElement
      .selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle')
      .nodes();

    if (circles.length < 3) {
      this.removePolygon(planItemFieldGElement);
    } else {
      for (let circleIndex: number = 0; circleIndex < circles.length; circleIndex += 1) {
        circle = d3.select(circles[circleIndex]);
        newPoints.push({ x: Number(circle.attr('cx')), y: Number(circle.attr('cy')) });
      }

      polygon.attr('points', this.convertPointsArrayToPointsString(newPoints));

      this.updatePlanItemAreaText(planItemFieldGElement, false);
    }
  }

  private createCircleDragBehaviour(
    planItemFieldGElement: d3.Selection<SVGGElement, any, SVGSVGElement, any>,
  ): d3.DragBehavior<SVGCircleElement, PlanItemFieldGElementDatumInterface, any> {
    return d3
      .drag<SVGCircleElement, PlanItemFieldGElementDatumInterface>()
      .on('drag', (event): void => {
        const draggedCircle: d3.Selection<
          SVGCircleElement,
          PlanItemFieldGElementDatumInterface,
          HTMLElement,
          PlanItemFieldGElementDatumInterface
        > = d3.select<SVGCircleElement, PlanItemFieldGElementDatumInterface>(event.sourceEvent.target);
        const newPoints: Point[] = [];
        let circle: d3.Selection<
          SVGCircleElement,
          PlanItemFieldGElementDatumInterface,
          SVGGElement,
          PlanItemFieldGElementDatumInterface
        >;

        const polygon: d3.Selection<SVGPolygonElement, any, SVGSVGElement, any> =
          planItemFieldGElement.select<SVGPolygonElement>('polygon');
        const circles: SVGCircleElement[] = planItemFieldGElement
          .selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle')
          .nodes();

        draggedCircle
          .attr('cx', Number(draggedCircle.attr('cx')) + event.dx)
          .attr('cy', Number(draggedCircle.attr('cy')) + event.dy);

        for (let circleIndex: number = 0; circleIndex < circles.length; circleIndex += 1) {
          circle = d3.select<SVGCircleElement, PlanItemFieldGElementDatumInterface>(circles[circleIndex]);
          newPoints.push({ x: Number(circle.attr('cx')), y: Number(circle.attr('cy')) });
        }

        polygon.attr('points', this.convertPointsArrayToPointsString(newPoints));

        this.updatePlanItemAreaText(planItemFieldGElement, false);
      })
      .on('end', (): void => {});
  }

  private createPlanItemAreaCircles(
    planItemFieldGElement: d3.Selection<SVGGElement, any, SVGSVGElement, any>,
    polygonPoints: Point[],
    index?: number,
  ): void {
    const circleDragBehaviour: d3.DragBehavior<SVGCircleElement, PlanItemFieldGElementDatumInterface, any> =
      this.createCircleDragBehaviour(planItemFieldGElement);

    polygonPoints.forEach((point: Point): void => {
      planItemFieldGElement
        .append('circle')
        .attr('cx', point.x)
        .attr('cy', point.y)
        .attr('r', this.defaultCircleRadius / this.currentZoom)
        .attr(
          'fill',
          !_.isNil(index) ? this.getColorScheme(index).backgroundColor : this.draggedPlanItemBackgroundColor,
        )
        .attr('is-handle', 'true')
        .style('cursor', 'pointer')
        .on('contextmenu', (event): void => {
          event.preventDefault();
        })
        .on('pointerdown', (event): void => {
          if (event.button === MouseButtonTypeEnum.rightClick) {
            this.removeCircle(planItemFieldGElement, event);
          }
        })
        .on('pointermove', (event): void => {
          event.stopPropagation();
        })
        .on('pointerup', (event): void => {
          event.stopPropagation();
        })
        .on('dblclick', (event): void => {
          event.preventDefault();
          event.stopPropagation();
        })
        .call(circleDragBehaviour);
    });
  }

  private createPlanItemAreaText(
    planItemFieldGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
    isPreviewModal: boolean,
  ): void {
    const minZoom: number = isPreviewModal ? this.previewMinZoom : this.minZoom;
    const boundingBox: DOMRect = (
      planItemFieldGElement.select<SVGPolygonElement>('polygon').node() as SVGGraphicsElement
    )?.getBBox();

    if (!boundingBox) {
      return;
    }

    planItemFieldGElement
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dx', boundingBox.width / 2 + boundingBox.x)
      .attr('dy', boundingBox.height / 2 + boundingBox.y)
      .style(
        'font-size',
        `${
          (Math.max(this.imageNaturalWidth, this.imageNaturalHeight) *
            (this.imageNaturalHeight / this.imageNaturalWidth)) /
          60
        }px`,
      )
      .style('pointer-events', 'none')
      .text(planItemFieldGElement.datum().itemName)
      .call(this.getBoundingBox);

    planItemFieldGElement
      .insert('rect', 'text')
      .attr('x', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.x - 6 / minZoom;
      })
      .attr('y', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.y - 2 / minZoom;
      })
      .attr('rx', 4 / minZoom)
      .attr('width', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.width + 12 / minZoom;
      })
      .attr('height', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.height + 4 / minZoom;
      })
      .style('fill', 'white')
      .style('fill-opacity', '0.75');
  }

  private updatePlanItemAreaText(
    planItemFieldGElement: d3.Selection<SVGGElement, PlanItemFieldGElementDatumInterface, any, any>,
    isPreviewModal: boolean,
  ): void {
    const minZoom: number = isPreviewModal ? this.previewMinZoom : this.minZoom;
    const boundingBox: DOMRect = (
      planItemFieldGElement.select<SVGPolygonElement>('polygon').node() as SVGGraphicsElement
    ).getBBox();
    planItemFieldGElement
      .select('text')
      .attr('dx', boundingBox.width / 2 + boundingBox.x)
      .attr('dy', boundingBox.height / 2 + boundingBox.y)
      .call(this.getBoundingBox);

    planItemFieldGElement
      .select('rect')
      .attr('x', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.x - 6 / minZoom;
      })
      .attr('y', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.y - 2 / minZoom;
      })
      .attr('rx', 4 / minZoom)
      .attr('width', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.width + 12 / minZoom;
      })
      .attr('height', (d: PlanItemFieldGElementDatumInterface) => {
        return d.bbox.height + 4 / minZoom;
      });
  }

  private getBoundingBox(
    selection: d3.Selection<SVGGraphicsElement, PlanItemFieldGElementDatumInterface, any, any>,
  ): void {
    selection.each((d: PlanItemFieldGElementDatumInterface): void => {
      d.bbox = selection.node().getBBox();
    });
  }

  private createZoomObject(
    svgElement: d3.Selection<SVGSVGElement, any, any, any>,
  ): d3.ZoomBehavior<SVGGElement, PlanItemFieldGElementDatumInterface> {
    return d3.zoom<SVGGElement, any>().on('zoom', (event): void => {
      const imageElement: d3.Selection<SVGImageElement, any, HTMLElement, any> = svgElement
        .select<SVGImageElement>('image')
        .attr('transform', event.transform)
        .attr('offsetX', event.transform.x)
        .attr('offsetY', event.transform.y);

      svgElement
        .selectAll<SVGGElement, PlanItemFieldGElementDatumInterface>('g')
        .attr('transform', (d: Point): string => {
          const translateX: number = d.x * event.transform.k + Number(imageElement.attr('offsetX'));
          const translateY: number = d.y * event.transform.k + Number(imageElement.attr('offsetY'));

          return `translate(${translateX},${translateY}) scale(${event.transform.k})`;
        });

      svgElement.selectAll<SVGCircleElement, PlanItemFieldGElementDatumInterface>('circle').attr('r', () => {
        return this.defaultCircleRadius / event.transform.k;
      });

      svgElement
        .selectAll<SVGPolygonElement, PlanItemFieldGElementDatumInterface>('polygon')
        .attr('stroke-width', `${this.defaultShapeStrokeWidth / event.transform.k}px`);

      if (this.isPreviewModalOpen) {
        this.previewCurrentZoom = event.transform.k;
      } else {
        this.currentZoom = event.transform.k;
      }

      this.updateZoomButtonStatuses();
    });
  }

  private initiateZoom(zoom: d3.ZoomBehavior<SVGGElement, PlanItemFieldGElementDatumInterface>): void {
    const svgElement: d3.Selection<SVGSVGElement, any, HTMLElement, any> = d3.select('svg#floorPlanSvg');

    if (svgElement.empty()) {
      return;
    }

    const minZoom: number = Math.min(
      (svgElement.node().clientWidth || window.innerWidth) / this.imageNaturalWidth,
      (svgElement.node().clientHeight || window.innerHeight) / this.imageNaturalHeight,
    );
    const maxZoom: number = minZoom * 8;

    zoom.scaleExtent([minZoom, maxZoom]).translateExtent([
      [0, 0],
      [this.imageNaturalWidth, this.imageNaturalHeight],
    ]);

    const middleX: number = (svgElement.node().clientWidth - minZoom * this.imageNaturalWidth) / 2;
    const middleY: number = (svgElement.node().clientHeight - minZoom * this.imageNaturalHeight) / 2;
    const currentTransform: d3.ZoomTransform = d3.zoomTransform(svgElement.node());

    if (this.isPreviewModalOpen) {
      svgElement.style('height', `${minZoom * this.imageNaturalHeight}px`);
      this.previewMinZoom = minZoom;
      this.previewMaxZoom = maxZoom;
    } else {
      this.minZoom = minZoom;
      this.maxZoom = maxZoom;
    }

    d3.select(this.isPreviewModalOpen ? '#floorPlanPreviewCard' : '#floorPlanCard').style('height', 'fit-content');

    if (this.isFirstLoadOfTheCurrentImage) {
      this.isFirstLoadOfTheCurrentImage = false;
      zoom.transform(svgElement, d3.zoomIdentity.translate(middleX, middleY).scale(minZoom));
    } else {
      zoom.transform(
        svgElement,
        d3.zoomIdentity
          .translate(currentTransform.x, currentTransform.y)
          .scale(_.clamp(currentTransform.k, minZoom, maxZoom)),
      );
    }

    this.updateZoomButtonStatuses();
  }

  private zoomBy(scale: number): void {
    const zoomTarget: d3.Selection<SVGSVGElement, any, HTMLElement, any> = d3.select<SVGSVGElement, any>(
      'svg#floorPlanSvg',
    );
    zoomTarget
      .transition()
      .duration(1000)
      .call(this.isPreviewModalOpen ? this.previewZoom.scaleBy : this.zoom.scaleBy, scale)
      .on('end', this.updateZoomButtonStatuses.bind(this));
  }

  private updateZoomButtonStatuses(): void {
    if (this.linePlanViewTypeForPreview === ELinePlanViewTypeEnum.cardView) {
      return;
    }

    _.find(this.zoomButtonGroup, {
      value: 'zoomIn',
    }).disabled = !this.isImageUploaded
      ? true
      : this.isPreviewModalOpen
      ? this.previewCurrentZoom >= this.previewMaxZoom
      : this.currentZoom >= this.maxZoom;
    _.find(this.zoomButtonGroup, {
      value: 'zoomOut',
    }).disabled = !this.isImageUploaded
      ? true
      : this.isPreviewModalOpen
      ? this.previewCurrentZoom <= this.previewMinZoom
      : this.currentZoom <= this.minZoom;
  }

  private updateCardViewZoomButtonStatuses(): void {
    _.find(this.zoomButtonGroupCardView, {
      value: 'zoomIn',
    }).disabled = !this.pathNodesUsedCardView.length;
    _.find(this.zoomButtonGroupCardView, {
      value: 'zoomOut',
    }).disabled = !this.pathNodesUsedCardView.length;
  }

  private openCreateFloorPlan(content: TemplateRef<any>): void {
    this.resetFormInputFieldsForUnselectedSite();
    this.linePlanViewType = ELinePlanViewTypeEnum.cardView;

    this.areChangesMade = false;
    this.isImageUploaded = false;
    this.floorPlanImageFile = undefined;
    this.floorPlanForm = FloorPlanHelper.getFloorPlanFormConfiguration(this.translateService);

    this.addFloorPlanModalRef = this.ngbModal.open(content, this.largeFloorPlanModalOptions);
  }

  private openEditFloorPlan(content: TemplateRef<any>, selectedFloorPlan: FloorPlanInterface): void {
    this.dispatchedFromEditOrPreviewModal = true;
    this.resetFormInputFieldsForUnselectedSite();

    this.isSelectedFloorPlanImageLoaded = false;
    this.editFloorPlanModalRef = this.ngbModal.open(content, this.largeFloorPlanModalOptions);
    this.floorPlanToDisplay = _.find(
      this.floorPlans$,
      (floorPlan: FloorPlanInterface): boolean => floorPlan.id === selectedFloorPlan.id,
    );
    this.linePlanViewType = ELinePlanViewTypeEnum.cardView;

    this.floorPlanForm.floorPlanName.value = selectedFloorPlan.name;
    this.floorPlanForm.floorPlanCategory.isEnabled = false;
    this.floorPlanForm.floorPlanCategory.value = [
      _.find(
        this.floorPlanCategoryData,
        (floorPlanCategory: DropdownOptionsInterface): boolean =>
          floorPlanCategory.id === selectedFloorPlan.categoryForId,
      ),
    ];
    this.isCardViewMode = true;
    this.onCategoryInputModelChange(selectedFloorPlan.categoryForId, true);
    this.floorPlanForm.site.value = [{ id: selectedFloorPlan.site.id, name: selectedFloorPlan.site.name }];
    this.floorPlanForm.site.isEnabled = false;
    this.onSiteInputModelChange(selectedFloorPlan.siteId);

    if (this.isCardViewMode) {
      this.floorPlanForm.line.value = [
        {
          id: selectedFloorPlan.line.id,
          name: selectedFloorPlan.line.title ?? selectedFloorPlan.line.name,
        },
      ];
      this.floorPlanForm.line.isEnabled = false;
      this.onLineInputModelChange(selectedFloorPlan.line?.id);
    }

    this.areChangesMade = false;
    this.isImageUploaded = false;
    this.isEditModeInitialImageLoading = true;
    this.mapViewHasImage = !!(this.floorPlanToDisplay.imagePath ?? this.floorPlanForm.image.value);
  }

  private openFloorPlanPreviewModal(content: TemplateRef<any>, floorPlanId: number): void {
    this.isPreviewModal = true;
    this.dispatchedFromEditOrPreviewModal = true;
    this.isSelectedFloorPlanImageLoaded = false;
    this.isPreviewModalOpen = true;
    this.previewFloorPlanModalRef = this.ngbModal.open(content, this.largeFloorPlanModalOptions);
    this.floorPlanToDisplay = _.find(
      this.floorPlans$,
      (floorPlan: FloorPlanInterface): boolean => floorPlan.id === floorPlanId,
    );
    this.linePlanPreviewModalGroupButtons[1].disabled = !this.floorPlanToDisplay.imagePath;
    this.linePlanViewTypeForPreview = ELinePlanViewTypeEnum.cardView;
    this.previousLinePlanViewType = this.linePlanViewTypeForPreview;

    this.zoomButtonGroup.forEach((button: ScwMatButtonGroupButtons) => {
      button.disabled = false;
    });

    if (this.floorPlanToDisplay.categoryForId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      this.store.dispatch(new FloorPlanActions.LineDataLoading(this.floorPlanToDisplay.siteId));
    } else if (this.floorPlanToDisplay.categoryForId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      this.store.dispatch(new FloorPlanActions.StationDataLoading(this.floorPlanToDisplay.lineId));
    }
  }

  private openCropModal(crop: TemplateRef<any>, $event): void {
    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    this.floorPlanImageFile = _.first($event.target.files);
    document.querySelector<HTMLInputElement>('#imageSelectionDialogInput').value = '';

    this.cropModalRef = this.ngbModal.open(crop, {
      ...this.largeFloorPlanModalOptions,
      size: 'xl',
    });
  }

  private resetFormInputFieldsForUnselectedSite(): void {
    this.lines$ = [];
    this.departmentsToDisplay = [];
    this.floorPlanDepartmentInputModel = [];
    this.floorPlanForm.site.value = undefined;
    this.floorPlanForm.line.value = undefined;
    this.sideListSearchTextInputModel = '';
    this.sideListItems = [];
    this.resetFormInputFieldsForUnselectedLine();
    this.populateLinesForDropdown();
  }

  private getColorScheme(index: number): IColorPaletteOption {
    return HelperService.cloneDeep(this.displayColorScheme[index % this.displayColorScheme.length]);
  }

  private getColorIds(): EColorPalette[] {
    return [
      EColorPalette.RED_1,
      EColorPalette.INDIGO_2,
      EColorPalette.ORANGE_1,
      EColorPalette.INDIGO_1,
      EColorPalette.GREY_2,
      EColorPalette.BLUE_3,
      EColorPalette.LIME,
      EColorPalette.VIOLET_1,
      EColorPalette.BLUE_1,
      EColorPalette.VIOLET_2,
      EColorPalette.RED_2,
      EColorPalette.ORANGE_2,
      EColorPalette.YELLOW_2,
      EColorPalette.GREY_3,
      EColorPalette.GREEN_1,
      EColorPalette.RED_3,
      EColorPalette.BLUE_2,
      EColorPalette.YELLOW_1,
      EColorPalette.AQUA_2,
      EColorPalette.GREY_1,
      EColorPalette.GREEN_2,
    ];
  }

  private setBulkDeleteResults(responseData: FloorPlanBulkResponseInterface[]): void {
    const floorPlansFailedToBeDeleted: FloorPlanInterface[] = [];

    this.bulkDeleteErrorModalSuccessfulCount = responseData.filter(
      (data: FloorPlanBulkResponseInterface) => data.success,
    ).length;

    responseData.forEach((data: FloorPlanBulkResponseInterface, index: number): void => {
      if (!data.success) {
        floorPlansFailedToBeDeleted.push(_.find(this.floorPlans$, { id: this.selectedFloorPlanIds[index] }));
      }
    });

    if (!_.isEmpty(floorPlansFailedToBeDeleted)) {
      this.bulkDeleteErrorModalData = floorPlansFailedToBeDeleted;
      this.bulkDeleteErrorModalRef = this.ngbModal.open(
        this.bulkErrorModalTemplateRef,
        this.smallFloorPlanModalOptions,
      );
    } else {
      this.showSuccessToast();
    }

    this.selectedFloorPlanIds = [];
    this.isFloorPlanDataRequested = true;
    this.store.dispatch(new FloorPlanActions.FloorPlanDataLoading(...HelperService.argumentClone(this.tableQuery)));
  }

  private populateSideListItems(customList: ISideListItem[] = null, isEditModal: boolean = false): void {
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    this.isSideListDataNotAvailable = false;

    if (!categoryId) {
      this.sideListItems = [];
      return;
    }

    if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE) {
      const listToUse: StationInterface[] = (customList as StationInterface[]) ?? this.stations$;
      this.sideListItems = !isEditModal
        ? listToUse.map((station: StationInterface): SideListInterface => {
            if (!_.find(this.dragElementBoundaries, { id: station.id })) {
              this.dragElementBoundaries.push({
                pathNodeId: station.id,
                boundary: null,
              });
            }

            return {
              id: station.id,
              name: station.name,
              colorSetting: station.colorSetting,
              position: { x: 0, y: 0 },
              isNotDraggable: false,
            };
          })
        : _.differenceWith(
            this.stations$,
            this.isCardViewMode ? this.pathNodesUsedCardView : this.stationsUsed,
            (station: StationInterface, stationUsed: StationInterface): boolean => station.id === stationUsed.id,
          );

      if (!this.modifiedSideListItems.length) {
        this.modifiedSideListItems = _.cloneDeep(this.sideListItems);
      }

      this.isSideListSearchBoxDisabled = !this.stations$?.length;
    } else if (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE) {
      const listToUse: ISideListItem[] = customList ?? this.linesAsSideListItems$;

      this.sideListItems = !isEditModal
        ? listToUse.map((line: ISideListItem): SideListInterface => {
            if (!_.find(this.dragElementBoundaries, { id: line.id })) {
              this.dragElementBoundaries.push({
                pathNodeId: line.id,
                boundary: null,
              });
            }

            return {
              id: line.id,
              name: line.name,
              colorSetting: line.colorSetting,
              position: { x: 0, y: 0 },
              isNotDraggable: false,
            };
          })
        : _.differenceWith(
            this.linesAsSideListItems$,
            this.linesUsed,
            (line: LineInterface, lineUsed: LineInterface): boolean => line.id === lineUsed.id,
          );

      if (!this.modifiedSideListItems.length) {
        this.modifiedSideListItems = _.cloneDeep(this.sideListItems);
      }

      this.isSideListSearchBoxDisabled = !this.linesAsSideListItems$?.length;
    }

    this.isSideListDataNotAvailable =
      this.isSideListSearchBoxDisabled &&
      ((categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE && this.floorPlanForm.site.value?.length > 0) ||
        (categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE && this.floorPlanForm.line.value?.length > 0));
  }

  private handleMouseMove(event: any): void {
    this.mousePosition = { x: event.clientX, y: event.clientY };

    if (this.isDragPositionChanged && this.mousePosition.x) {
      const selectedDragElement: SideListInterface = _.find(this.modifiedSideListItems, {
        id: Number(this.selectedDragElementId),
        dragFlag: true,
      });

      if (selectedDragElement) {
        selectedDragElement.position = {
          x: this.mousePosition.x - 65 - 50 * this.zoomLevel,
          y: this.mousePosition.y - 232 - 50 * this.zoomLevel,
        };
      }

      setTimeout(() => {
        this.setNewLinePositions();
        this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId']);
      });
    }

    if (!this.drawArrowOptions.isDrawStartPoint) {
      const selectedArrowIndex: number = this.drawArrowIds.findIndex(
        (item: IDrawArrowProperties) => item.endBoxId === '0',
      );
      this.setNewLinePositions('drawStartLocation');
      this.setLinePositions(
        this.drawArrowOptions.drawStartLocation,
        { left: this.mousePosition.x, bottom: this.mousePosition.y },
        selectedArrowIndex,
        staticToleranceValues.mouseChange,
      );
    }
  }

  private addEventsListener(): void {
    window.addEventListener('mousemove', this.handleMouseMoveBound);
    window.addEventListener('touchstart', this.handleMouseMoveBound);
    window.addEventListener('touchmove', this.handleMouseMoveBound);
    window.addEventListener('touchend', this.handleMouseMoveBound);
  }

  private removeEventsListener(): void {
    window.removeEventListener('mousemove', this.handleMouseMoveBound);
    window.removeEventListener('touchstart', this.handleMouseMoveBound);
    window.removeEventListener('touchmove', this.handleMouseMoveBound);
    window.removeEventListener('touchend', this.handleMouseMoveBound);
  }

  private resetFormInputFieldsForUnselectedLine(): void {
    this.stations$ = [];
    this.floorPlanForm.line.value = undefined;
    this.sideListSearchTextInputModel = '';
    this.sideListItems = [];
    this.modifiedSideListItems = [];
  }

  private discardAllData(): void {
    this.isCardViewMode = true;
    this.hasPath = false;
    this.linePlanViewType = ELinePlanViewTypeEnum.cardView;
    this.updateFloorPlanImageBase64 = null;
    this.selectedFloorPlanImageBase64 = null;
    this.changedMapViewData = null;
    this.removeImage();
  }

  private populateLinesForDropdown(): void {
    this.linesForDropdown = this.lines$.map((line: LineInterface): DropdownOptionsInterface => {
      return {
        id: line.id,
        name: line.title,
      };
    });
  }

  private onCategoryInputModelDeselect(): void {
    this.floorPlanForm.floorPlanCategory.value = undefined;
    this.resetFormInputFieldsForUnselectedSite();
  }

  private generateCollapseBoxDesign(): void {
    if (this.paths.length) {
      this.convertNotDraggableElements(true);
      const selectedPathIndex: number = _.findIndex(this.paths, { isSelected: true });
      this.onClickSelectPathCard(selectedPathIndex, true);
      document.getElementById('pathList').classList.add('show');
      this.isSideListExpanded = true;
    }

    if (this.showCreatePathWarningMessage || this.paths.length) {
      (document.querySelector('.path-node-list') as HTMLElement).style.height = '70px';

      if (document.getElementById('pathNodeList').classList.value.includes('show')) {
        this.isSideListExpanded = false;
      }

      document.getElementById('pathListCollapseButton').style.visibility = 'inherit';
      document.getElementById('pathListCollapseButton').style.position = 'inherit';
    }
  }

  private createArrow(startArrowId: string, endArrowId: string = null, isPreviewModal: boolean = false): void {
    if (this.linePlanViewTypeForPreview !== ELinePlanViewTypeEnum.mapView) {
      const arrowId: string = `start${startArrowId}${endArrowId ? `-end${endArrowId}` : ''}`;
      const arrowPathId: string = `arrow${startArrowId}${endArrowId ? `-arrow${endArrowId}` : ''}`;
      const createdNewArrow: EmbeddedViewRef<unknown> = this.arrowTemplateRef?.createEmbeddedView(
        isPreviewModal ? this.arrowTemplatePreview : this.arrowTemplate,
      );
      createdNewArrow.rootNodes[0].setAttribute('id', arrowId);
      document.querySelector('.draggable-box')?.appendChild(createdNewArrow.rootNodes[0]);
      createdNewArrow.rootNodes[0].querySelector('marker').setAttribute('id', arrowPathId);
      createdNewArrow.rootNodes[0].querySelector('line').setAttribute('marker-end', `url(#${arrowPathId})`);
    }
  }

  private generateDraggedItemDesign(pathNodeId: string): void {
    const container: Element = document.querySelector('.draggable-box');
    const dragElement: HTMLElement = document.getElementById(pathNodeId);
    dragElement.style.cursor = ECursorType.GRABBING;

    if (!container.querySelector(`[id="${pathNodeId}"]`)) {
      container.appendChild(dragElement);
      const arrowPoints: HTMLElement[] = Array.from(dragElement.querySelectorAll('.arrow-point'));
      arrowPoints?.forEach((item: HTMLElement) => (item.style.display = 'inline-block'));
      dragElement.classList.add('dragged-element');
      (dragElement.querySelector('.delete-button') as HTMLElement).style.display = 'inline-block';
      this.updateElementSize(dragElement);
      this.updateBoundaryAttribute(Number(pathNodeId));
    }
  }

  private changeArrowPositionsAfterChangedBoxPosition(filterKeys: string[], isZoomChange: boolean = false): void {
    filterKeys.forEach((key: string) => {
      const draggedItemPositions: IDrawArrowProperties[] = this.drawArrowIds.filter(
        (item: IDrawArrowProperties) => item[key] === this.selectedArrowElementId,
      );
      draggedItemPositions.forEach((item: IDrawArrowProperties) => {
        const selectedArrowIndex: number = _.findIndex(this.arrowPositions, {
          pathNodeId: `start${item.startBoxId}-end${item.endBoxId}`,
        });
        let startRect: DOMRect | IPositionElement;
        let endRect: DOMRect | IPositionElement;

        if (!isZoomChange) {
          startRect = document.getElementById(item.startBoxId).getBoundingClientRect();
          endRect = document.getElementById(item.endBoxId)?.getBoundingClientRect();
          this.setLinePositions(startRect, endRect, selectedArrowIndex);
        } else {
          const startBoxPositions: IPoint = _.find(this.modifiedSideListItems, {
            id: Number(item.startBoxId),
          })?.position;
          const endBoxPositions: IPoint = _.find(this.modifiedSideListItems, { id: Number(item.endBoxId) })?.position;
          startRect = { right: startBoxPositions?.x, top: startBoxPositions?.y };
          endRect = { left: endBoxPositions?.x, bottom: endBoxPositions?.y };
          this.setLinePositions(startRect, endRect, selectedArrowIndex, staticToleranceValues.zoomChange, true);
        }
      });
    });
  }

  private setLinePositions(
    startLinePosition: DOMRect | IPositionElement,
    endLinePosition: DOMRect | IPositionElement,
    selectedArrowIndex: number,
    toleranceValues: IArrowPosition = staticToleranceValues.defaultChange,
    isZoomChange: boolean = false,
  ): void {
    const zoomRatio: number = (1 - this.zoomLevel) * 50;

    if (this.arrowPositions[selectedArrowIndex]) {
      this.arrowPositions[selectedArrowIndex].startX =
        startLinePosition.right + toleranceValues.startX + (isZoomChange ? -zoomRatio : zoomRatio);
      this.arrowPositions[selectedArrowIndex].startY = startLinePosition.top + toleranceValues.startY - zoomRatio;
      this.arrowPositions[selectedArrowIndex].endX = endLinePosition.left + toleranceValues.endX - zoomRatio;
      this.arrowPositions[selectedArrowIndex].endY =
        endLinePosition.bottom + toleranceValues.endY + (isZoomChange ? -zoomRatio : zoomRatio);
      const parentArrowElement: HTMLElement = document.getElementById(
        this.arrowPositions[selectedArrowIndex].pathNodeId,
      );
      const arrowElement: SVGLineElement = parentArrowElement?.querySelector('line');
      const isAttributeValid: boolean =
        this.arrowPositions[selectedArrowIndex].endX >= 0 &&
        this.arrowPositions[selectedArrowIndex].endY >= 0 &&
        this.arrowPositions[selectedArrowIndex].startX >= 0 &&
        this.arrowPositions[selectedArrowIndex].startY >= 0;

      if (isAttributeValid && arrowElement) {
        arrowElement.setAttribute('x1', this.arrowPositions[selectedArrowIndex].startX.toString());
        arrowElement.setAttribute('y1', this.arrowPositions[selectedArrowIndex].startY.toString());
        arrowElement.setAttribute('x2', this.arrowPositions[selectedArrowIndex].endX.toString());
        arrowElement.setAttribute('y2', this.arrowPositions[selectedArrowIndex].endY.toString());
        const arrowLength: number = this.calculateArrowLength(selectedArrowIndex);
        parentArrowElement.querySelector('marker').setAttribute('refX', (arrowLength / 5.9).toString());
      }
    }
  }

  private calculateArrowLength(selectedArrowIndex: number): number {
    const arrowWidth: number = Math.abs(
      this.arrowPositions[selectedArrowIndex].startX - this.arrowPositions[selectedArrowIndex].endX,
    );
    const arrowHeight: number = Math.abs(
      this.arrowPositions[selectedArrowIndex].startY - this.arrowPositions[selectedArrowIndex].endY,
    );
    const arrowLength: number = Math.sqrt(arrowWidth * arrowWidth + arrowHeight * arrowHeight);
    this.arrowPositions[selectedArrowIndex].arrowPosition = arrowLength;

    return arrowLength;
  }

  private setNewLinePositions(locationName: string = null): void {
    const selectedDrawOption: string =
      locationName ?? this.drawArrowOptions.drawStartLocation.pathNodeId === this.dragPositionChangeItemId
        ? 'drawStartLocation'
        : 'drawEndLocation';
    const parentElement: HTMLElement = document.getElementById(this.drawArrowOptions[selectedDrawOption].pathNodeId);

    if (parentElement) {
      const pointElementBounds: DOMRect = parentElement.getBoundingClientRect();
      this.drawArrowOptions[selectedDrawOption] = {
        x: pointElementBounds.x,
        y: pointElementBounds.y,
        top: pointElementBounds.top,
        right: pointElementBounds.right,
        bottom: pointElementBounds.bottom,
        left: pointElementBounds.left,
        pathNodeId: this.drawArrowOptions[selectedDrawOption].pathNodeId,
      };
    }
  }

  private setPathNodeListBoxCollapse(): void {
    const categoryId: number = this.floorPlanForm.floorPlanCategory.value?.[0]?.id;
    const isPlanForSite: boolean = categoryId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;
    const selectedPathNodeTypeItems: ISideListItem[] = isPlanForSite ? this.linesAsSideListItems$ : this.stations$;
    this.isPlanForSite = isPlanForSite;

    if (selectedPathNodeTypeItems.length === 1 && this.pathNodesUsedCardView.length === 1) {
      (document.querySelector('.path-node-list') as HTMLElement).style.height = '70px';
      document.getElementById('pathListCollapseButton').style.visibility = 'inherit';
      document.getElementById('pathListCollapseButton').style.position = 'inherit';
      this.showCreatePathWarningMessage = true;
      this.isSideListExpanded = false;

      return;
    }

    const notDrawArrow: boolean =
      selectedPathNodeTypeItems.length > 1 && this.pathNodesUsedCardView.length && !this.drawArrowIds.length;
    const drawLayoutInvalid: boolean =
      notDrawArrow || (this.pathNodesUsedCardView.length !== selectedPathNodeTypeItems.length && !this.isPlanForSite);
    const allNodesUsed: boolean = selectedPathNodeTypeItems.length === this.pathNodesUsedCardView.length;

    if ((drawLayoutInvalid && !this.isSideListExpanded) || (!isPlanForSite && !selectedPathNodeTypeItems.length)) {
      (document.querySelector('.path-node-list') as HTMLElement).style.height = isPlanForSite
        ? 'calc(100vh - 550px)'
        : 'calc(100vh - 470px)';
      this.isSideListExpanded = true;
    } else if (!drawLayoutInvalid && this.isSideListExpanded && isPlanForSite && allNodesUsed) {
      (document.querySelector('.path-node-list') as HTMLElement).style.height = '70px';
      this.isSideListExpanded = false;
    }

    if (
      !drawLayoutInvalid &&
      selectedPathNodeTypeItems.length &&
      (!isPlanForSite || !this.isSideListExpanded || allNodesUsed)
    ) {
      document.getElementById('pathListCollapseButton').style.visibility = 'inherit';
      document.getElementById('pathListCollapseButton').style.position = 'inherit';
      this.showCreatePathWarningMessage = true;

      return;
    }

    document.getElementById('pathListCollapseButton').style.visibility = 'hidden';
    document.getElementById('pathListCollapseButton').style.position = 'absolute';
    this.showCreatePathWarningMessage = false;
  }

  private setPathNodeBoxBorder(boxId: string, pointerEvents: string, color: string, background: string): void {
    const selectedBox: HTMLElement = document.getElementById(boxId);

    if (selectedBox) {
      selectedBox.style.pointerEvents = pointerEvents;
      selectedBox.style.color = color;
      selectedBox.style.background = background;
    }
  }

  private initializeFloorPlanCardViewPreview(): void {
    this.drawArrowIds = this.floorPlanToDisplay.configuration['cardViewConfiguration']?.drawArrowIds;
    this.arrowPositions = [];
    const isPlanForSite: boolean =
      this.floorPlanToDisplay?.categoryForId === EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE;
    const listToUse: SideListInterface[] = HelperService.cloneDeep(
      isPlanForSite ? this.linesAsSideListItems$ : this.stations$,
    );
    this.modifiedSideListItems = listToUse.reduce((acc: SideListInterface[], item: ISideListItem) => {
      const selectedBoxPosition = _.find(this.floorPlanToDisplay.configuration['cardViewConfiguration'].boxPositions, {
        boxId: item.id,
      });

      if (!selectedBoxPosition && isPlanForSite) {
        return acc;
      }

      item.position = selectedBoxPosition?.position;
      item.defaultPosition = selectedBoxPosition?.position;
      acc.push(item);
      return acc;
    }, []);

    this.zoomButtonGroupCardView.forEach((button: ScwMatButtonGroupButtons): boolean => (button.disabled = false));

    this.drawArrowIds?.forEach((item: IDrawArrowProperties) => {
      this.arrowPositions.push({
        pathNodeId: `start${item.startBoxId}-end${item.endBoxId}`,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      });
      const isNotHasArrow: boolean = !document.getElementById(`start${item.startBoxId}-end${item.endBoxId}`);

      if (isNotHasArrow) {
        setTimeout(() => {
          this.createArrow(item.startBoxId, item.endBoxId, true);
        });
      }
    });

    setTimeout(() => {
      this.modifiedSideListItems.forEach((box: ISideListItem) => {
        this.selectedArrowElementId = box.id.toString();
        this.changeArrowPositionsAfterChangedBoxPosition(['endBoxId', 'startBoxId'], true);
      });
    });
  }

  private convertToSideListItem(pathNodes: LineInterface[]): ISideListItem[] {
    return pathNodes.map((pathNode: LineInterface, index: number) => ({
      id: pathNode.id,
      name: pathNode.title,
      position: { x: 0, y: 0 },
      isNotDraggable: false,
      colorSetting: this.getColorScheme(index),
      lineType: pathNode.lineType,
    }));
  }

  private filterPathsJobCountLines(selectedPath: TLinePathSettings): void {
    selectedPath.initialCountLines = selectedPath.initialCountLines?.filter((line: DropdownOptionsInterface) =>
      selectedPath.selectedNodeIds.includes(line.id),
    );
    selectedPath.goodCountLines = selectedPath.goodCountLines?.filter((line: DropdownOptionsInterface) =>
      selectedPath.selectedNodeIds.includes(line.id),
    );
  }

  private fillJobCountLinesDropdown(selectedPath: TLineAndStationPathSettings): void {
    this.jobCountLines = [];

    if (!selectedPath) {
      return;
    }

    selectedPath.selectedNodeIds?.forEach((item: number) => {
      this.jobCountLines.push(_.find(this.linesForDropdown, { id: item }));
    });

    if (selectedPath.linePathOrderDetails?.length) {
      this.jobCountLines = this.jobCountLines.sort((a: DropdownOptionsInterface, b: DropdownOptionsInterface) => {
        const aOrder: number = _.find(selectedPath.linePathOrderDetails, { lineId: a.id })?.order;
        const bOrder: number = _.find(selectedPath.linePathOrderDetails, { lineId: b.id })?.order;
        return aOrder - bOrder;
      });
    }
  }

  private fillWorkOrderCountStationsDropdown(selectedPath: TLineAndStationPathSettings): void {
    this.workOrderCountStations = [];

    if (!selectedPath) {
      return;
    }

    selectedPath.selectedNodeIds?.forEach((item: number) => {
      this.workOrderCountStations.push(_.find(this.stations$, { id: item }));
    });

    if (selectedPath.linePathOrderDetails?.length) {
      this.workOrderCountStations = this.workOrderCountStations.sort(
        (a: DropdownOptionsInterface, b: DropdownOptionsInterface) => {
          const aOrder: number = _.find(selectedPath.stationPathOrderDetails, { stationId: a.id })?.order;
          const bOrder: number = _.find(selectedPath.stationPathOrderDetails, { stationId: b.id })?.order;
          return aOrder - bOrder;
        },
      );
    }
  }

  private filterPathsStationPathOrderDetails(selectedPath: TLineAndStationPathSettings): void {
    selectedPath.initialCountStations = selectedPath.initialCountStations?.filter((station: DropdownOptionsInterface) =>
      selectedPath.selectedNodeIds.includes(station.id),
    );
    selectedPath.goodCountStations = selectedPath.goodCountStations?.filter((station: DropdownOptionsInterface) =>
      selectedPath.selectedNodeIds.includes(station.id),
    );
  }

  private checkDuplicateLinePaths(selectedPath: TLinePathSettings): void {
    if (!selectedPath || !this.isPlanForSite) {
      return;
    }

    let duplicateLinePathCount: number = 0;
    let foundDuplicatePathName: string | null = null;

    for (const path of this.paths) {
      if (path === selectedPath) {
        continue;
      }

      const areNodeIdsEqual = _.isEqual(
        path.selectedNodeIds.sort((a, b) => a - b),
        selectedPath.selectedNodeIds.sort((a, b) => a - b),
      );
      const areInitialCountLinesEqual = _.isEqual(
        path.initialCountLines.map((p) => p.id).sort((a, b) => a - b),
        selectedPath.initialCountLines.map((p) => p.id).sort((a, b) => a - b),
      );
      const areGoodCountLinesEqual = _.isEqual(
        path.goodCountLines.map((p) => p.id).sort((a, b) => a - b),
        selectedPath.goodCountLines.map((p) => p.id).sort((a, b) => a - b),
      );

      if (areNodeIdsEqual && areInitialCountLinesEqual && areGoodCountLinesEqual) {
        duplicateLinePathCount++;
        if (foundDuplicatePathName === null) {
          foundDuplicatePathName = path.name;
        }
      }

      if (duplicateLinePathCount > 1) {
        break;
      }
    }

    selectedPath.isDuplicate = duplicateLinePathCount > 0;
    this.duplicateLinePathExists = selectedPath.isDuplicate;
    this.duplicateLinePathName = foundDuplicatePathName;
  }

  private checkDuplicateStationPaths(selectedPath: TStationPathSettings): void {
    if (!selectedPath || this.isPlanForSite) {
      return;
    }

    let duplicateStationPathCount: number = 0;
    let foundDuplicatePathName: string | null = null;

    for (const path of this.paths) {
      if (path === selectedPath) {
        continue;
      }

      const areNodeIdsEqual = _.isEqual(
        path.selectedNodeIds.sort((a, b) => a - b),
        selectedPath.selectedNodeIds.sort((a, b) => a - b),
      );
      const areInitialCountStationsEqual = _.isEqual(
        path.initialCountStations.map((s) => s.id).sort((a, b) => a - b),
        selectedPath.initialCountStations.map((s) => s.id).sort((a, b) => a - b),
      );
      const areGoodCountStationsEqual = _.isEqual(
        path.goodCountStations.map((s) => s.id).sort((a, b) => a - b),
        selectedPath.goodCountStations.map((s) => s.id).sort((a, b) => a - b),
      );

      if (areNodeIdsEqual && areInitialCountStationsEqual && areGoodCountStationsEqual) {
        duplicateStationPathCount++;
        if (foundDuplicatePathName === null) {
          foundDuplicatePathName = path.name;
        }
      }

      if (duplicateStationPathCount > 1) {
        break;
      }
    }

    selectedPath.isDuplicate = duplicateStationPathCount > 0;
    this.hasDuplicatedStationPath = selectedPath.isDuplicate;
    this.duplicateStationPathName = foundDuplicatePathName;
  }

  private removeDraggedBoxes(): void {
    const pathNodeList: LineInterface[] | StationInterface[] = this.isPlanForSite
      ? this.linesAsSideListItems$
      : this.stations$;
    const listToUse: { id: number }[] = this.isCardViewMode
      ? HelperService.cloneDeep(this.pathNodesUsedCardView)
      : pathNodeList;

    listToUse.forEach((item: ISideListItem) => {
      const element: HTMLElement = document.getElementById(item.id.toString());
      (element?.querySelector('.delete-button') as HTMLElement)?.click();
    });
  }

  private removeInitialAndGoodIcons(boxElement: HTMLElement | undefined): void {
    if (!boxElement) {
      return;
    }

    _.set(boxElement.querySelector('.fa-sign-in-alt'), 'style.display', 'none');
    _.set(boxElement.querySelector('.fa-sign-out-alt'), 'style.display', 'none');
  }

  private removeAllInitialAndGoodIcons(): void {
    for (const pathNode of this.pathNodesUsedCardView) {
      this.removeInitialAndGoodIcons(document.getElementById(String(pathNode.id)));
    }
  }

  private setInitialAndGoodIconsVisibility(
    boxElement: HTMLElement | undefined,
    boxNodeId: number,
    path: TLinePathSettings & TStationPathSettings,
  ): void {
    if (!boxElement) {
      return;
    }

    const initialLinesOrStations: DropdownOptionsInterface[] = [
      ...(path.initialCountLines ?? []),
      ...(path.initialCountStations ?? []),
    ];

    const goodLinesOrStations: DropdownOptionsInterface[] = [
      ...(path.goodCountLines ?? []),
      ...(path.goodCountStations ?? []),
    ];

    _.set(
      boxElement.querySelector('.fa-sign-in-alt'),
      'style.display',
      initialLinesOrStations.some((lineOrStation: DropdownOptionsInterface) => lineOrStation.id === boxNodeId)
        ? 'inline-block'
        : 'none',
    );
    _.set(
      boxElement.querySelector('.fa-sign-out-alt'),
      'style.display',
      goodLinesOrStations.some((lineOrStation: DropdownOptionsInterface) => lineOrStation.id === boxNodeId)
        ? 'inline-block'
        : 'none',
    );
  }

  private prepareSiteFloorPlanConfiguration(): ILineViewConfiguration {
    const mapViewConfiguration: FloorPlanConfigurationInterface[] = this.linesUsed.length ? this.saveFloorPlan() : [];
    const cardViewConfiguration: ICardViewConfiguration = {
      zoomLevel: this.zoomLevel,
      screenSize: window.innerWidth,
      boxPositions: this.pathNodesUsedCardView.map((item: StationInterface) => {
        return {
          boxId: item.id,
          position: item.defaultPosition,
        };
      }),
      drawArrowIds: this.drawArrowIds ?? [],
      pathConfiguration: [],
    };

    cardViewConfiguration.pathConfiguration = this.paths.map((path: TLinePathSettings): ILinePathConfiguration => {
      const pathConfiguration: ILinePathConfiguration = {
        id: path.id,
        name: path.name,
        isDefaultPath: path.isDefaultPath,
        initialCountLineIds: path.initialCountLines.map((line: DropdownOptionsInterface) => line.id),
        goodCountLineIds: path.goodCountLines.map((line: DropdownOptionsInterface) => line.id),
        linePathOrderDetails: [],
      };

      if (path.selectedNodeIds.length === 1) {
        pathConfiguration.linePathOrderDetails.push(
          { lineId: path.selectedNodeIds[0], locationType: ELinePathOrderDetailLocationType.START, order: 0 },
          { lineId: path.selectedNodeIds[0], locationType: ELinePathOrderDetailLocationType.END, order: 1 },
        );

        return pathConfiguration;
      }

      const arrowsInBetweenConnectedLines: IDrawArrowProperties[] = cardViewConfiguration.drawArrowIds.filter(
        (arrow: IDrawArrowProperties) =>
          path.selectedNodeIds.includes(Number(arrow.startBoxId)) &&
          path.selectedNodeIds.includes(Number(arrow.endBoxId)),
      );

      path.selectedNodeIds.forEach((lineId: number, order: number) => {
        pathConfiguration.linePathOrderDetails.push({
          lineId,
          order,
          locationType: this.getSelectedPathNodeLocationType(arrowsInBetweenConnectedLines, lineId),
        });
      });

      pathConfiguration.linePathOrderDetails = _.uniqWith(pathConfiguration.linePathOrderDetails, _.isEqual);

      return pathConfiguration;
    });

    return {
      mapViewConfiguration:
        mapViewConfiguration.length > 0
          ? mapViewConfiguration
          : _.isNil(this.floorPlanToDisplay)
          ? []
          : this.floorPlanToDisplay.configuration['mapViewConfiguration'],
      cardViewConfiguration,
    };
  }

  private getSelectedPathNodeLocationType(
    arrows: IDrawArrowProperties[],
    lineId: number,
  ): ELinePathOrderDetailLocationType {
    let hasInArrow: boolean = false;
    let hasOutArrow: boolean = false;

    for (const arrowLineIds of arrows) {
      if (Number(arrowLineIds.startBoxId) === lineId) {
        hasOutArrow = true;
      }

      if (Number(arrowLineIds.endBoxId) === lineId) {
        hasInArrow = true;
      }
    }

    if (hasOutArrow && hasInArrow) {
      return ELinePathOrderDetailLocationType.MIDDLE;
    }

    if (hasOutArrow) {
      return ELinePathOrderDetailLocationType.START;
    }

    if (hasInArrow) {
      return ELinePathOrderDetailLocationType.END;
    }

    return ELinePathOrderDetailLocationType.START;
  }

  private prepareLineFloorPlanConfiguration(): ILineViewConfiguration {
    const mapViewConfiguration: FloorPlanConfigurationInterface[] = this.stationsUsed.length
      ? this.saveFloorPlan()
      : [];
    const cardViewConfiguration: ICardViewConfiguration = {
      zoomLevel: this.zoomLevel,
      screenSize: window.innerWidth,
      boxPositions: this.pathNodesUsedCardView.map((item: StationInterface) => {
        return {
          boxId: item.id,
          position: item.defaultPosition,
        };
      }),
      drawArrowIds: this.drawArrowIds,
      pathConfiguration: [],
    };

    cardViewConfiguration.pathConfiguration = this.paths.map(
      (path: TStationPathSettings): IStationPathConfiguration => {
        const initialCountStationIds: number[] = path.initialCountStations.map(
          (station: DropdownOptionsInterface) => station.id,
        );
        const goodCountStationIds: number[] = path.goodCountStations.map(
          (station: DropdownOptionsInterface) => station.id,
        );

        const pathConfiguration: IStationPathConfiguration = {
          id: path.id,
          name: path.name,
          isDefaultPath: path.isDefaultPath,
          stationPathOrderDetails: [],
        };

        path.selectedNodeIds.forEach((stationId: number) => {
          const isInitialCounter: boolean = initialCountStationIds.includes(stationId);
          const isGoodCounter: boolean = goodCountStationIds.includes(stationId);
          const isMidCounter: boolean = !isInitialCounter && !isGoodCounter;

          pathConfiguration.stationPathOrderDetails.push({ stationId, isInitialCounter, isMidCounter, isGoodCounter });
        });

        return pathConfiguration;
      },
    );

    return {
      mapViewConfiguration:
        mapViewConfiguration.length > 0
          ? mapViewConfiguration
          : _.isNil(this.floorPlanToDisplay)
          ? []
          : this.floorPlanToDisplay.configuration['mapViewConfiguration'],
      cardViewConfiguration,
    };
  }
}
