import { Injectable, ErrorHandler, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MonitoringService } from './monitoring.service';
import * as moment from 'moment';
import { IErrorHandler } from './global-error-handler.interface';
import { ReplaySubject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler, OnDestroy {
  public static silentErrorSubject = new ReplaySubject<Error | unknown>(10, 30);

  private errorList: IErrorHandler[] = [];

  private silentErrorSubscription: Subscription = GlobalErrorHandlerService.silentErrorSubject.subscribe((value) => {
    if (!environment.production) {
      console.error('%c Silent Exception', 'color:orange; font-weight: bold;', value);
      return;
    }

    this.handleError(value as Error);
  });

  constructor(private monitoringService: MonitoringService) {}

  ngOnDestroy(): void {
    this.silentErrorSubscription?.unsubscribe();

    if (!GlobalErrorHandlerService.silentErrorSubject.closed) {
      GlobalErrorHandlerService.silentErrorSubject.complete();
    }
  }

  public handleError(error: Error | HttpErrorResponse): void {
    let isExistError: boolean = false;

    if (error.hasOwnProperty('message') && error.hasOwnProperty('stack') && error instanceof Error) {
      isExistError = this.isErrorExistWithinInterval(error);
    }

    if (isExistError) {
      return;
    }

    this.monitoringService.logException(error);
  }

  private isErrorExistWithinInterval(error: Error): boolean {
    const errorInterval: number = 30;
    const { stack, message } = error;
    const index: number = this.errorList.findIndex(
      (item: IErrorHandler) => item.message === message && item.stack === stack,
    );
    const isExist: IErrorHandler | undefined = this.errorList[index];
    const now: moment.Moment = moment();

    if (!isExist) {
      this.errorList.push({
        message: error.message,
        stack: stack,
        timestamp: now,
      });

      return false;
    }

    const diff: number = now.diff(isExist.timestamp, 'seconds');
    isExist.timestamp = now;

    return errorInterval >= diff;
  }
}
