import * as Actions from './page-header.actions';
import { PageHeaderStateInterface } from './page-header.model';

const initialState: PageHeaderStateInterface = {
  isDashboardMode: false,
  isFilterBarVisible: false,
  isPrintAllowed: false,
  isPrintButtonDisabled: false,
  lastTimeout: null,
  hideHeaderActions: false,
  isHeaderVisible: true,
  isEditMode: false,
  isRootCauseAnalysisComparisonMode: false,
  isCountdownWaitingToReset: false,
  isCountdownWaitingToStart: false,
  isCountdownWaitingToPause: false,
  isCountdownWaitingToDisable: false,
  isCountdownWaitingToEnable: false,
  isCountdownExpectedToStartWithReset: false,
  headerExceptionIndicator: null,
  isElementVisible: false,
  isVisibilityButtonDisabled: false,
  initialCountdown: null,
};

export function pageHeaderReducer(
  state: PageHeaderStateInterface = initialState,
  action: Actions.PageHeaderActions,
): PageHeaderStateInterface {
  switch (action.type) {
    case Actions.COUNTDOWN_TIMEOUT:
      return { ...state, lastTimeout: new Date() };
    case Actions.UPDATE_PAGE_MODE:
      return {
        ...state,
        isDashboardMode: action.isDashboardMode,
      };
    case Actions.UPDATE_FILTER_BAR_VISIBILITY:
      return {
        ...state,
        isFilterBarVisible: action.isVisible,
      };
    case Actions.RUN_PRINT_FUNCTIONALITY:
      return {
        ...state,
        isPrintAllowed: action.isPrintAllowed,
      };
    case Actions.CHANGE_PRINT_BUTTON_STATUS:
      return {
        ...state,
        isPrintButtonDisabled: action.isPrintButtonDisabled,
      };
    case Actions.UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY:
      return {
        ...state,
        ...action.isPageHeaderButtonsVisible,
      };
    case Actions.SET_PAGE_TO_EDIT_OR_VIEW_MODE:
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    case Actions.RESET_STORE_TO_DEFAULT:
      return {
        ...initialState,
      };
    case Actions.CHANGE_ROOT_CAUSE_ANALYSIS_MODE:
      return {
        ...state,
        isRootCauseAnalysisComparisonMode: action.isRootCauseAnalysisComparisonMode,
      };
    case Actions.COUNTDOWN_RESET:
      return {
        ...state,
        isCountdownWaitingToReset: action.isCountdownWaitingToReset,
        isCountdownExpectedToStartWithReset: action.isCountdownExpectedToStartWithReset,
      };
    case Actions.COUNTDOWN_START:
      return {
        ...state,
        isCountdownWaitingToStart: action.isCountdownWaitingToStart,
      };
    case Actions.COUNTDOWN_PAUSE:
      return {
        ...state,
        isCountdownWaitingToPause: action.isCountdownWaitingToPause,
      };
    case Actions.COUNTDOWN_DISABLE:
      return {
        ...state,
        isCountdownWaitingToDisable: action.isCountdownWaitingToDisable,
      };
    case Actions.COUNTDOWN_ENABLE:
      return {
        ...state,
        isCountdownWaitingToEnable: action.isCountdownWaitingToEnable,
      };
    case Actions.SET_HEADER_EXCEPTION_INDICATOR:
      return {
        ...state,
        headerExceptionIndicator: action.headerExceptionIndicator,
      };
    case Actions.UPDATE_ELEMENT_VISIBILITY:
      return {
        ...state,
        isElementVisible: action.isElementVisible,
        isVisibilityButtonDisabled: action.isVisibilityButtonDisabled,
      };
    case Actions.SET_INITIAL_COUNTDOWN:
      return {
        ...state,
        initialCountdown: action.countdown,
      };
    default:
      return state;
  }
}
