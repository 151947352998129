import * as TagDefinitionActions from './tag-definition.actions';
import { TagDefinitionState } from './tag-definition.model';

export const tagDefinitionState: TagDefinitionState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getTagDefinitionLoading: false,
  getTagDefinitionLoaded: false,
  tagDefinition: null,
  addTagDefinitionLoading: false,
  addTagDefinitionLoaded: false,
  editTagDefinitionLoading: false,
  editTagDefinitionLoaded: false,
  deleteTagDefinitionsLoading: false,
  deleteTagDefinitionsLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  stationsDataLoading: false,
  stationsDataLoaded: false,
  stationsData: [],
};

export function tagDefinitionReducer(
  state: TagDefinitionState = tagDefinitionState,
  action: TagDefinitionActions.TagDefinitionActions,
): TagDefinitionState {
  switch (action.type) {
    case TagDefinitionActions.ActionTypes.TAG_DEFINITIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case TagDefinitionActions.ActionTypes.TAG_DEFINITIONS_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case TagDefinitionActions.ActionTypes.GET_TAG_DEFINITION:
      return {
        ...state,
        ...{
          getTagDefinitionLoading: true,
          getTagDefinitionLoaded: false,
        },
      };
    case TagDefinitionActions.ActionTypes.GET_TAG_DEFINITION_COMPLETED:
      return {
        ...state,
        ...{
          tagDefinition: action.payload.data,
          getTagDefinitionLoading: false,
          getTagDefinitionLoaded: true,
        },
      };
    case TagDefinitionActions.ActionTypes.ADD_TAG_DEFINITION:
      return {
        ...state,
        addTagDefinitionLoading: true,
        addTagDefinitionLoaded: false,
      };
    case TagDefinitionActions.ActionTypes.ADD_TAG_DEFINITION_COMPLETED:
      return {
        ...state,
        addTagDefinitionLoading: false,
        addTagDefinitionLoaded: true,
      };
    case TagDefinitionActions.ActionTypes.EDIT_TAG_DEFINITION:
      return {
        ...state,
        editTagDefinitionLoading: true,
        editTagDefinitionLoaded: false,
      };
    case TagDefinitionActions.ActionTypes.EDIT_TAG_DEFINITION_COMPLETED:
      return {
        ...state,
        editTagDefinitionLoading: false,
        editTagDefinitionLoaded: true,
      };

    case TagDefinitionActions.ActionTypes.DELETE_TAG_DEFINITIONS:
      return {
        ...state,
        deleteTagDefinitionsLoading: true,
        deleteTagDefinitionsLoaded: false,
      };
    case TagDefinitionActions.ActionTypes.DELETE_TAG_DEFINITIONS_COMPLETED:
      return {
        ...state,
        deleteTagDefinitionsLoading: false,
        deleteTagDefinitionsLoaded: true,
      };
    case TagDefinitionActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case TagDefinitionActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case TagDefinitionActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case TagDefinitionActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case TagDefinitionActions.ActionTypes.STATIONS_DATA_LOADING:
      return {
        ...state,
        ...{
          stationsDataLoading: true,
          stationsDataLoaded: false,
        },
      };
    case TagDefinitionActions.ActionTypes.STATIONS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          stationsData: action.payload.data,
          stationsDataLoading: false,
          stationsDataLoaded: true,
        },
      };
    case TagDefinitionActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
