import * as Actions from './custom-dashboards.actions';
import { ICustomDashboardsState } from './custom-dashboards.model';

export const customDashboardsState: ICustomDashboardsState = {
  customDashboardData: null,
  customDashboardsLoading: false,
  customDashboardsLoaded: false,
};

export function customDashboardsReducer(
  state: ICustomDashboardsState = customDashboardsState,
  action: Actions.TCustomDashboardsActions,
): ICustomDashboardsState {
  switch (action.type) {
    case Actions.CustomDashboardsActionTypes.FetchCustomDashboardsData:
      return {
        ...state,
        customDashboardsLoading: true,
        customDashboardsLoaded: false,
      };
    case Actions.CustomDashboardsActionTypes.FetchCustomDashboardsDataCompleted:
      return {
        ...state,
        customDashboardsLoading: false,
        customDashboardsLoaded: true,
        customDashboardData: action.payload,
      };
    default:
      return state;
  }
}
