import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { smallModal } from '../../../../../constants';
import * as _ from 'lodash';
import { HelperService } from '../../../service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastHelperService } from 'src/app/shared/service/toast/toast.helper.service';

@Component({
  selector: 'scw-mat-image-picker',
  templateUrl: './scw-mat-image-picker.component.html',
  styleUrls: ['./scw-mat-image-picker.component.scss', '../../../../../scss/custom.scss'],
})
export class ScwMatImagePickerComponent implements OnInit, OnDestroy {
  @ViewChild('imageSelectionDialogInput') imageSelectionDialogInputElementRef: ElementRef;
  @Input() inputModel: string = '';
  @Input() icon: string = '';
  @Input() avatarIsLoading: boolean = false;
  @Input() isUploadDisabled: boolean = false;
  @Input() targetImageWidth: number | undefined;
  @Input() targetImageHeight: number | undefined;

  @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();
  public cropModalRef: NgbModalRef;
  public file: File;
  private static readonly VALID_IMAGE_MIMES = [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/vnd.microsoft.icon',
  ];

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly toastHelperService: ToastHelperService,
  ) {}

  ngOnInit(): void {}

  public openCropModal($event: any, crop: TemplateRef<any>): void {
    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    const file: File = _.first($event.target.files);

    const isProvidedImageTypeIsValid: boolean = this.isProvidedImageIsValid(file);

    if (!isProvidedImageTypeIsValid) {
      this.toastHelperService.showToastMessage(false, this.translate.instant('general.error'), this.translate.instant('excel.messages.fileCannotReadErrorTitle'));
      this.imageSelectionDialogInputElementRef.nativeElement.value = '';
      return;
    }

    if (['image/svg+xml', 'image/vnd.microsoft.icon'].includes(file.type)) {
      const reader: FileReader = new FileReader();

      reader.onload = () => {
        this.inputModel = reader.result as string;
        this.imageSelectionDialogInputElementRef.nativeElement.value = '';
        this.cropModalRef = this.ngbModal.open(crop, {
          ...smallModal,
          windowClass: 'scw-modal-sm scw-modal-all-scrollable',
        });
      };

      reader.readAsDataURL(file);
      return;
    }

    this.file = file;
    this.imageSelectionDialogInputElementRef.nativeElement.value = '';
    this.cropModalRef = this.ngbModal.open(crop, {
      ...smallModal,
      windowClass: 'scw-modal-sm scw-modal-all-scrollable',
    });
  }

  public updateInputModel($event: string): void {
    this.imageSelectionDialogInputElementRef.nativeElement.value = '';
    this.cropModalRef.close();
    this.inputModel = $event;
    this.inputModelChange.emit($event);
  }

  public onAvatarIsClicked(crop?: TemplateRef<any>): void {
    if (this.isUploadDisabled || this.avatarIsLoading) {
      return;
    }

    this.file = undefined;

    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    if (!this.inputModel || !crop) {
      this.imageSelectionDialogInputElementRef.nativeElement.click();
      return;
    }

    this.cropModalRef = this.ngbModal.open(crop, {
      ...smallModal,
      windowClass: 'scw-modal-sm scw-modal-all-scrollable',
    });
  }

  public showMaxAllowedImageSizeExceededWarning(): void {
    if (this.cropModalRef) {
      this.cropModalRef.close();
    }

    this.helperService.showToastMessage(
      false,
      null,
      this.translate.instant('cropModal.errorMessages.imageSizeIsTooLarge'),
    );
  }

  private isProvidedImageIsValid(file: File): boolean {
    if (!file.type) {
      return false;
    }

    return ScwMatImagePickerComponent.VALID_IMAGE_MIMES.includes(file.type);
  }

  public ngOnDestroy(): void {
    if (this.cropModalRef) {
      this.cropModalRef.close();
    }
  }
}
