import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { ICurrentLine, IOngoingActivityTotalDuration } from '../../../store/line/model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../component/filter/filter.class';
import { IRemoveWorkOrderRequestBody } from '../../../store/home/home.model';
import { IProductionStatusWidgetData } from '../../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/production-status-widget/production-status-widget.model';
import { ISortedTaskAndActivityDurationParams } from '../../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/current-activity-widget/current-activity-widget.interface';
import { ISortedTaskAndActivityDuration } from '../../../view/dashboards/custom-dashboards/custom-dashboard-content/widgets/longest-task-durations-widget/longest-task-durations-widget.model';

@Injectable({
  providedIn: 'root',
})
export class LineService {
  constructor(public readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    line: `${this.api}/lines`,
    ongoingActivityTotalDuration: `ongoing-activity-total-duration`,
  };

  public getLine(
    id: number,
    options: Parameters<HttpClient['get']>[1],
  ): Observable<BaseOneResponseInterface<LineCRUDInterface>> {
    return this.http.get<BaseOneResponseInterface<LineCRUDInterface>>(`${this.routes.line}/${id}`, options);
  }

  public getLines(httpParams?: HttpParams): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(this.routes.line, {
      params: httpParams,
    });
  }

  public updateLine(id: number, body: IRemoveWorkOrderRequestBody): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.routes.line}/${id}`, body);
  }

  public getOngoingActivityTotalDuration(
    lineId: number,
    httpParams?: HttpParams,
  ): Observable<BaseOneResponseInterface<IOngoingActivityTotalDuration>> {
    return this.http.get<BaseOneResponseInterface<IOngoingActivityTotalDuration>>(
      `${this.routes.line}/${lineId}/${this.routes.ongoingActivityTotalDuration}`,
      { params: httpParams },
    );
  }

  public getOngoingActivityOfLine(lineId: number): Observable<ResponseInterface<ICurrentLine>> {
    const params: HttpParams = new HttpParams()
      .append('join', 'currentActivity')
      .append('join', 'currentTask')
      .append('join', 'currentTask.equipment')
      .append('join', 'currentWorkOrder')
      .append('join', 'currentWorkOrder.product')
      .set('fields', 'title,timer,selectedDescription,selectedPhaseId');
    return this.http.get<ResponseInterface<ICurrentLine>>(`${this.routes.line}/${lineId}`, {
      params,
    });
  }

  public getProductionStatusData(
    lineId: number,
    options: Parameters<HttpClient['get']>[1] = {},
  ): Observable<BaseOneResponseInterface<IProductionStatusWidgetData>> {
    return this.http.get<BaseOneResponseInterface<IProductionStatusWidgetData>>(
      `${this.routes.line}/${lineId}/production-status`,
      options,
    );
  }

  public getSortedTaskAndActivityDuration(
    lineId: number,
    params: ISortedTaskAndActivityDurationParams,
    options: Parameters<HttpClient['get']>[1] = {},
  ): Observable<BaseOneResponseInterface<ISortedTaskAndActivityDuration[]>> {
    return this.http.get<BaseOneResponseInterface<ISortedTaskAndActivityDuration[]>>(
      `${this.routes.line}/${lineId}/get-sorted-task-and-activity-duration`,
      {
        ...options,
        params: {
          ...params,
        },
      },
    );
  }
}
