import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'get',
})
export class GetPipe implements PipeTransform {
  public transform(value: unknown, path: string, defaultValue?: unknown): unknown {
    return _.get(value, path, defaultValue);
  }
}
