import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './heartbeat.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  IHeartbeat,
  IHeartbeatRow,
} from '../../../../view/settings/opc-tag-automation-settings/heartbeats/heartbeats.model';
import { INotificationTemplate } from '../../notification-center/notification-templates/notification-templates.model';
import { NotificationGroupInterface } from '../../nofication-center/notification-groups/notification-groups.model';

export enum ActionTypes {
  HEARTBEATS_DATA_LOADING = '[HEARTBEATS] HEARTBEATS DATA LOADING',
  HEARTBEATS_DATA_LOADED = '[HEARTBEATS] HEARTBEATS DATA LOADED',
  GET_HEARTBEAT = '[HEARTBEATS] GET_HEARTBEAT LOADING',
  GET_HEARTBEAT_COMPLETED = '[HEARTBEATS] GET_HEARTBEAT COMPLETED',
  ADD_HEARTBEAT = '[HEARTBEATS] ADD_HEARTBEAT',
  ADD_HEARTBEAT_COMPLETED = '[HEARTBEATS] ADD_HEARTBEAT COMPLETED',
  EDIT_HEARTBEAT = '[HEARTBEATS] EDIT_HEARTBEAT',
  EDIT_HEARTBEAT_COMPLETED = '[HEARTBEATS] EDIT_HEARTBEAT COMPLETED',
  DELETE_HEARTBEATS = '[HEARTBEATS] DELETE_HEARTBEAT',
  DELETE_HEARTBEATS_COMPLETED = '[HEARTBEATS] DELETE_HEARTBEAT COMPLETED',
  SITES_DATA_LOADING = '[HEARTBEATS] SITES DATA LOADING',
  SITES_DATA_LOADED = '[HEARTBEATS] SITES DATA LOADED',
  LINES_DATA_LOADING = '[HEARTBEATS] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[HEARTBEATS] LINES DATA COMPLETED',
  MAIL_GROUPS_DATA_LOADING = '[HEARTBEATS] MAIL_GROUPS DATA LOADING',
  MAIL_GROUPS_DATA_COMPLETED = '[HEARTBEATS] MAIL_GROUPS DATA COMPLETED',
  MAIL_TEMPLATES_DATA_LOADING = '[HEARTBEATS] MAIL_TEMPLATES DATA LOADING',
  MAIL_TEMPLATES_DATA_COMPLETED = '[HEARTBEATS] MAIL_TEMPLATES DATA COMPLETED',
  FETCH_ERROR = '[HEARTBEATS] FETCH ERROR',
}

export class HeartbeatsLoading implements Action {
  readonly type = ActionTypes.HEARTBEATS_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class HeartbeatsLoaded implements Action {
  readonly type = ActionTypes.HEARTBEATS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IHeartbeat>) {}
}

export class GetHeartbeat implements Action {
  readonly type = ActionTypes.GET_HEARTBEAT;
  constructor(public recordId: number) {}
}

export class GetHeartbeatCompleted implements Action {
  readonly type = ActionTypes.GET_HEARTBEAT_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IHeartbeat>) {}
}

export class AddHeartbeat implements Action {
  readonly type = ActionTypes.ADD_HEARTBEAT;
  constructor(public heartbeat: IHeartbeatRow, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddHeartbeatCompleted implements Action {
  readonly type = ActionTypes.ADD_HEARTBEAT_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IHeartbeat>) {}
}

export class EditHeartbeat implements Action {
  readonly type = ActionTypes.EDIT_HEARTBEAT;
  constructor(public recordId: number, public record: IHeartbeatRow, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditHeartbeatCompleted implements Action {
  readonly type = ActionTypes.EDIT_HEARTBEAT_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<IHeartbeat>) {}
}

export class DeleteHeartbeats implements Action {
  readonly type = ActionTypes.DELETE_HEARTBEATS;
  constructor(public payload: number[]) {}
}

export class DeleteHeartbeatsCompleted implements Action {
  readonly type = ActionTypes.DELETE_HEARTBEATS_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class MailGroupsDataLoading implements Action {
  readonly type = ActionTypes.MAIL_GROUPS_DATA_LOADING;

  constructor() {}
}

export class MailGroupsDataLoaded implements Action {
  readonly type = ActionTypes.MAIL_GROUPS_DATA_COMPLETED;

  constructor(public payload: GetManyResponseInterface<Partial<NotificationGroupInterface>>) {}
}

export class MailTemplatesDataLoading implements Action {
  readonly type = ActionTypes.MAIL_TEMPLATES_DATA_LOADING;
  constructor() {}
}

export class MailTemplatesDataCompleted implements Action {
  readonly type = ActionTypes.MAIL_TEMPLATES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<INotificationTemplate>>) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type HeartbeatActions =
  | HeartbeatsLoading
  | HeartbeatsLoaded
  | GetHeartbeat
  | GetHeartbeatCompleted
  | AddHeartbeat
  | AddHeartbeatCompleted
  | EditHeartbeat
  | EditHeartbeatCompleted
  | DeleteHeartbeats
  | DeleteHeartbeatsCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | MailGroupsDataLoading
  | MailGroupsDataLoaded
  | MailTemplatesDataLoading
  | MailTemplatesDataCompleted
  | FetchError;
