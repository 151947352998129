import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as TagLoggerActions from './tag-logger.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { TagLoggerService } from './tag-logger.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';

@Injectable()
export class TagLoggerEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: TagLoggerService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.TAG_LOGGER_DATA_LOADING),
      switchMap((payload: TagLoggerActions.TagLoggerLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: payload.params.siteIds } });
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: payload.params.lineIds } });
        }

        if (payload.params?.searchText) {
          andConditions.push({
            $or: [
              {
                tagName: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
              {
                colorCode: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
            ],
          });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        if (payload.params.orderBy && payload.params.orderDesc !== undefined) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new TagLoggerActions.TagLoggerLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new TagLoggerActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagLoggerActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.GET_TAG_LOGGER),
      switchMap((objectData: TagLoggerActions.GetTagLogger) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOne(objectData.recordId, new HttpParams()).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            return of(new TagLoggerActions.GetTagLoggerCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.ADD_TAG_LOGGER),
      switchMap((objectData: TagLoggerActions.AddTagLogger) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.tagLogger).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new TagLoggerActions.AddTagLoggerCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.EDIT_TAG_LOGGER),
      switchMap((objectData: TagLoggerActions.EditTagLogger) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new TagLoggerActions.EditTagLoggerCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagLoggerActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.DELETE_TAG_LOGGER),
      switchMap((objectData: TagLoggerActions.DeleteTagLogger) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new TagLoggerActions.DeleteTagLoggerCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: TagLoggerActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            return of(new TagLoggerActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new TagLoggerActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagLoggerActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(TagLoggerActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: TagLoggerActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new TagLoggerActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new TagLoggerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagLoggerActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
