import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IQueryParams } from './station-state-mapping.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';

export enum ActionTypes {
  STATION_STATE_MAPPING_DATA_LOADING = '[STATION_STATE_MAPPING] CHANGE ACTIVITY DATA LOADING',
  STATION_STATE_MAPPING_DATA_LOADED = '[STATION_STATE_MAPPING] CHANGE ACTIVITY DATA LOADED',
  GET_STATION_STATE_MAPPING = '[STATION_STATE_MAPPING] GET CHANGE ACTIVITY LOADING',
  GET_STATION_STATE_MAPPING_COMPLETED = '[STATION_STATE_MAPPING] GET CHANGE ACTIVITY COMPLETED',
  ADD_STATION_STATE_MAPPING = '[STATION_STATE_MAPPING] ADD CHANGE ACTIVITY',
  ADD_STATION_STATE_MAPPING_COMPLETED = '[STATION_STATE_MAPPING] ADD CHANGE ACTIVITY COMPLETED',
  EDIT_STATION_STATE_MAPPING = '[STATION_STATE_MAPPING] EDIT CHANGE ACTIVITY',
  EDIT_STATION_STATE_MAPPING_COMPLETED = '[STATION_STATE_MAPPING] EDIT CHANGE ACTIVITY COMPLETED',
  DELETE_STATION_STATE_MAPPING = '[STATION_STATE_MAPPING] DELETE CHANGE ACTIVITY',
  DELETE_STATION_STATE_MAPPING_COMPLETED = '[STATION_STATE_MAPPING] DELETE CHANGE ACTIVITY COMPLETED',
  SITES_DATA_LOADING = '[STATION_STATE_MAPPING] SITES DATA LOADING',
  SITES_DATA_LOADED = '[STATION_STATE_MAPPING] SITES DATA LOADED',
  LINES_DATA_LOADING = '[STATION_STATE_MAPPING] LINES DATA LOADED',
  LINES_DATA_COMPLETED = '[STATION_STATE_MAPPING] LINES DATA COMPLETED',
  STATIONS_DATA_LOADING = '[STATION_STATE_MAPPING] STATIONS DATA LOADED',
  STATIONS_DATA_COMPLETED = '[STATION_STATE_MAPPING] STATIONS DATA COMPLETED',
  ACTIVITIES_DATA_LOADING = '[STATION_STATE_MAPPING] ACTIVITIES DATA LOADED',
  ACTIVITIES_DATA_COMPLETED = '[STATION_STATE_MAPPING] ACTIVITIES DATA COMPLETED',
  TASKS_DATA_LOADING = '[STATION_STATE_MAPPING] TASKS DATA LOADED',
  TASKS_DATA_COMPLETED = '[STATION_STATE_MAPPING] TASKS DATA COMPLETED',
  LINE_TASKS_DATA_LOADING = '[STATION_STATE_MAPPING] LINE TASKS DATA LOADED',
  LINE_TASKS_DATA_COMPLETED = '[STATION_STATE_MAPPING] LINE TASKS DATA COMPLETED',
  EXTENDED_TASKS_DATA_LOADING = '[STATION_STATE_MAPPING] EXTENDED TASKS DATA LOADED',
  EXTENDED_TASKS_DATA_COMPLETED = '[STATION_STATE_MAPPING] EXTENDED TASKS DATA COMPLETED',
  FETCH_ERROR = '[STATION_STATE_MAPPING] FETCH ERROR',
}

export class StationStateMappingLoading implements Action {
  readonly type = ActionTypes.STATION_STATE_MAPPING_DATA_LOADING;

  constructor(public params: IQueryParams) {}
}

export class StationStateMappingLoaded implements Action {
  readonly type = ActionTypes.STATION_STATE_MAPPING_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<any>) {}
}

export class GetStationStateMapping implements Action {
  readonly type = ActionTypes.GET_STATION_STATE_MAPPING;
  constructor(public recordId: number) {}
}

export class GetStationStateMappingCompleted implements Action {
  readonly type = ActionTypes.GET_STATION_STATE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class AddStationStateMapping implements Action {
  readonly type = ActionTypes.ADD_STATION_STATE_MAPPING;
  constructor(public stationStateMapping: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddStationStateMappingCompleted implements Action {
  readonly type = ActionTypes.ADD_STATION_STATE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class EditStationStateMapping implements Action {
  readonly type = ActionTypes.EDIT_STATION_STATE_MAPPING;
  constructor(public recordId: number, public record: any, public hideLoaderAfterSuccess: boolean = true) {}
}

export class EditStationStateMappingCompleted implements Action {
  readonly type = ActionTypes.EDIT_STATION_STATE_MAPPING_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<any>) {}
}

export class DeleteStationStateMapping implements Action {
  readonly type = ActionTypes.DELETE_STATION_STATE_MAPPING;
  constructor(public payload: number[]) {}
}

export class DeleteStationStateMappingCompleted implements Action {
  readonly type = ActionTypes.DELETE_STATION_STATE_MAPPING_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class SiteDataLoading implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADING;

  constructor() {}
}

export class SiteDataLoaded implements Action {
  readonly type = ActionTypes.SITES_DATA_LOADED;

  constructor(public payload: (SimplifiedDataInterface & Partial<SiteCRUDInterface>)[]) {}
}

export class LineDataLoading implements Action {
  readonly type = ActionTypes.LINES_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class LineDataCompleted implements Action {
  readonly type = ActionTypes.LINES_DATA_COMPLETED;
  constructor(public payload: GetManyResponseInterface<Partial<LineCRUDInterface>>) {}
}

export class StationsDataLoading implements Action {
  readonly type = ActionTypes.STATIONS_DATA_LOADING;
  constructor(public lineId: number) {}
}

export class StationsDataCompleted implements Action {
  readonly type = ActionTypes.STATIONS_DATA_COMPLETED;
  constructor(public payload: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) {}
}

export class ActivitiesDataLoading implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_LOADING;
  constructor(public activityIds: number[]) {}
}

export class ActivitiesDataCompleted implements Action {
  readonly type = ActionTypes.ACTIVITIES_DATA_COMPLETED;
  constructor(public payload: ActivityCRUDInterface[]) {}
}

export class TasksDataLoading implements Action {
  readonly type = ActionTypes.TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class TasksDataCompleted implements Action {
  readonly type = ActionTypes.TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class LineTasksDataLoading implements Action {
  readonly type = ActionTypes.LINE_TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class LineTasksDataCompleted implements Action {
  readonly type = ActionTypes.LINE_TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class ExtendedTasksDataLoading implements Action {
  readonly type = ActionTypes.EXTENDED_TASKS_DATA_LOADING;
  constructor(public activityId: number) {}
}

export class ExtendedTasksDataCompleted implements Action {
  readonly type = ActionTypes.EXTENDED_TASKS_DATA_COMPLETED;
  constructor(public payload: TaskFilterCRUDInterface[]) {}
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type StationStateMappingActions =
  | StationStateMappingLoading
  | StationStateMappingLoaded
  | GetStationStateMapping
  | GetStationStateMappingCompleted
  | AddStationStateMapping
  | AddStationStateMappingCompleted
  | EditStationStateMapping
  | EditStationStateMappingCompleted
  | DeleteStationStateMapping
  | DeleteStationStateMappingCompleted
  | SiteDataLoading
  | SiteDataLoaded
  | LineDataLoading
  | LineDataCompleted
  | StationsDataLoading
  | StationsDataCompleted
  | ActivitiesDataLoading
  | ActivitiesDataCompleted
  | TasksDataLoading
  | TasksDataCompleted
  | LineTasksDataLoading
  | LineTasksDataCompleted
  | ExtendedTasksDataLoading
  | ExtendedTasksDataCompleted
  | FetchError;
