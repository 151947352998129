<div class="row m-t-10 plans-list-table">
  <div class="col">
    <div class="datatable-buttons">
      <div class="row" [style.pointer-events]="isLoading$ ? 'none' : 'all'">
        <div class="col-lg-3">
          <div class="left-grp">
            <scw-mat-small-button
              (onClick)="
                helperService.selectOrUnselectAllCheckboxes(
                  planData$.length !== selectedPlans.length,
                  checkBoxElementRef,
                  'plan',
                  plans$.data
                )
              "
              [disabled]="planData$.length === 0"
            >
              {{
                planData$.length === selectedPlans.length && planData$.length !== 0
                  ? ('general.unselectAll' | translate)
                  : ('general.selectAll' | translate)
              }}
            </scw-mat-small-button>
            <scw-mat-small-button
              [iconButton]="true"
              (onClick)="showPlanTransactionsModals(ETableModalTypes.edit, add_edit_modal)"
              [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1"
            >
              <em class="fas fa-pen"></em>
            </scw-mat-small-button>
            <scw-mat-small-button
              [iconButton]="true"
              (onClick)="showPlanDeleteModal(selectedPlans.length > 1 ? bulk_delete_modal : delete_modal)"
              [disabled]="selectedPlans.length === 0"
            >
              <em class="fas fa-trash"></em>
            </scw-mat-small-button>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="right-grp">
            <scw-mat-search
              class="search-input"
              placeholder="{{ 'general.search' | translate }}"
              behavior="delayed"
              [minLength]="0"
              size="xs"
              [block]="true"
              [(inputModel)]="searchBoxText"
              (onSearch)="onKeyUpSearchBox($event)"
            ></scw-mat-search>
            <scw-mat-small-button (onClick)="showPlanTransactionsModals(ETableModalTypes.add, add_edit_modal)">
              <em class="fas fa-plus"></em>
              {{ 'lineAvailability.addPlan' | translate }}
            </scw-mat-small-button>
          </div>
        </div>
      </div>
    </div>
    <datatable
      paginationSize="sm"
      noDataText="{{ 'datatable.noData' | translate }}"
      [serverSide]="true"
      [headers]="tableHeaders"
      [items]="planData$"
      [itemsCount]="plans$.total"
      [currentPage]="currentPage"
      [rowsPerPage]="rowsPerPage"
      [stickyLeadColumn]="true"
      [isLoading]="isLoading$"
      [shouldPaginate]="false"
      (onDataRequest)="onDataRequestHandler($event)"
      heightMode="fill"
    >
      <ng-template let-item let-i=" index">
        <td
          *ngFor="let header of tableHeaders; index as i"
          [class.sticky-left-col]="i === 0"
          [class.text-center]="header.value === 'actionButton'"
          [class.planManagementStatusBorderColor]="['isOfficial'].indexOf(header.value) !== -1"
        >
          <scw-mat-checkbox
            *ngIf="i === 0"
            [id]="'plan-checkbox-' + item.id"
            [value]="item.id"
            [(inputModel)]="item.isChecked"
            (onChange)="onChangeTaskTableCheckboxes($event)"
          >
          </scw-mat-checkbox>
          <ng-container *ngIf="i !== 0">
            <ng-container *ngIf="header.value === 'sites'">
              <ng-template
                [ngIf]="
                  indexedSites &&
                  item.schedulerShiftPlansSiteAssignment &&
                  item.schedulerShiftPlansSiteAssignment.length === 1
                "
                [ngIfElse]="displaySelectedCount"
              >
                {{ indexedSites[item.schedulerShiftPlansSiteAssignment[0].siteId] }}
              </ng-template>
              <ng-template #displaySelectedCount>
                {{ item.schedulerShiftPlansSiteAssignment.length }} {{ 'lineAvailability.selected' | translate }}
              </ng-template>
            </ng-container>
            <ng-container *ngIf="header.value === 'isOfficial'">
              <ng-template [ngIf]="item.isOfficial" [ngIfElse]="inactivePlan">
                <scw-mat-border-coloring [type]="active" [text]="isOfficialData[0].name"></scw-mat-border-coloring>
              </ng-template>
              <ng-template #inactivePlan>
                <scw-mat-border-coloring [type]="inActive" [text]="isOfficialData[1].name"></scw-mat-border-coloring>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="header.value === 'actionButton'">
              <scw-mat-small-button
                [disabled]="item.schedulerShiftPlansSiteAssignment.length === 0"
                (onClick)="setSelectedPlan(item)"
              >
                {{ 'lineAvailability.managePlan.title' | translate }}
              </scw-mat-small-button>
            </ng-container>
            <ng-container *ngIf="(['sites', 'isOfficial', 'actionButton'] | indexOf: header.value) === -1">
              {{ item | get: header.value }}
            </ng-container>
          </ng-container>
        </td>
      </ng-template>
    </datatable>
  </div>
</div>

<ng-template #add_edit_modal let-modal>
  <scw-mat-modal [isForm]="true" (onClickClose)="onCloseModal(modal)" (onFormSubmit)="onAddEditSubmit($event)">
    <h3 title class="modal-title scw-header">
      <ng-template [ngIf]="openedModalType === ETableModalTypes.add" [ngIfElse]="saveChanges">
        {{ 'lineAvailability.addPlan' | translate }}
      </ng-template>
      <ng-template #saveChanges>
        {{ 'modal.header.editPlan' | translate }}
      </ng-template>
    </h3>

    <div body>
      <h6 class="scw-header"><em class="fas fa-link"></em>&nbsp;{{ 'general.subtitles.assignment' | translate }}</h6>
      <scw-mat-select
        class="scw_mat-add_edit_modal-item-input"
        label="{{ 'lineAvailability.site.label' | translate }}*"
        [rules]="planManagementFormRules.siteIds"
        [data]="sites$"
        [singleSelection]="false"
        [showCheckbox]="true"
        [(inputModel)]="planManagementForm.siteIds.value"
        [enableSearchFilter]="true"
        [enableCheckAll]="true"
        searchBehavior="live"
      ></scw-mat-select>
      <hr class="scw-hr" />
      <h6 class="scw-header">
        <em class="fas fa-file-alt"></em>&nbsp;{{ 'general.subtitles.generalInformation' | translate }}
      </h6>
      <scw-mat-input
        label="{{ 'planManagement.planName.label' | translate }}*"
        [rules]="planManagementFormRules.name"
        [(inputModel)]="planManagementForm.name.value"
      >
      </scw-mat-input>
      <scw-mat-select
        class="scw_mat-add_edit_modal-item-input"
        label="{{ 'planManagement.statusName.label' | translate }}*"
        position="top"
        [autoPosition]="false"
        [rules]="planManagementFormRules.isOfficial"
        [data]="isOfficialData"
        [(inputModel)]="planManagementForm.isOfficial.value"
      ></scw-mat-select>
    </div>

    <div footer class="modal-footer-buttons">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          <ng-template [ngIf]="openedModalType === ETableModalTypes.add" [ngIfElse]="saveChangesButton">
            {{ 'lineAvailability.addPlan' | translate }}
          </ng-template>
          <ng-template #saveChangesButton>
            {{ 'general.saveChanges' | translate }}
          </ng-template>
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #delete_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deletePlan' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.deletePlan' | translate: { name: selectedPlan?.name }"
      ></scw-mat-modal-text-with-icon>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onDeleteSubmit()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #bulk_delete_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deletePlans' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.deletePlans' | translate: { count: selectedPlans.length }"
      ></scw-mat-modal-text-with-icon>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button type="standard" (onClick)="onCloseModal(modal)">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onBulkDeleteSubmit()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>

<ng-template #bulk_delete_result_modal let-modal>
  <scw-mat-modal (onClickClose)="onCloseModal(modal)">
    <h3 title class="modal-title scw-header">{{ 'modal.header.deletePlans' | translate }}</h3>
    <div body>
      <scw-mat-modal-text-with-icon
        [icon]="'fas fa-exclamation-circle'"
        [text]="'modal.info.bulkPlansResult' | translate: { count: successfullyDeletedPlanCount }"
      ></scw-mat-modal-text-with-icon>

      <scw-mat-modal-failed-items
        [failText]="'modal.info.bulkPlansFailedResult' | translate"
        [failedItems]="failedBulkDeletePlanNames"
      ></scw-mat-modal-failed-items>
    </div>
    <div footer class="modal-footer-buttons">
      <scw-mat-button (onClick)="onCloseModal(modal)">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </scw-mat-modal>
</ng-template>
