import { createSelector, MemoizedSelector } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { EWeekday, FirebaseCredentials, TCommonUserData, User } from './model';
import { distinctCreateSelector } from '../../shared/helper/ngrx-helper';

export const selectUserDateTimeFormat: MemoizedSelector<OeeAppState, string> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.dateTimeFormat,
);

export const selectUserFirebaseConfig: MemoizedSelector<OeeAppState, FirebaseCredentials> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.firebase,
);

export const selectUserTimeFormat: MemoizedSelector<OeeAppState, string | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.timeFormatHourAndMinute,
);

export const selectUserDefaultSiteId: MemoizedSelector<OeeAppState, number | null> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => (user.defaultSite ? Number(user.defaultSite) : null),
);

export const selectUserFirstWeekday: MemoizedSelector<OeeAppState, EWeekday | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.firstWeekday,
);

export const selectUserLocale: MemoizedSelector<OeeAppState, string | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.locale,
);

export const selectUserTimezone: MemoizedSelector<OeeAppState, string | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.timezone,
);

export const selectUserLanguage: MemoizedSelector<OeeAppState, string | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.language,
);

export const selectUserSite: MemoizedSelector<OeeAppState, number | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.siteId,
);
export const selectCommonUserData: MemoizedSelector<OeeAppState, TCommonUserData> = distinctCreateSelector(
  (state: OeeAppState) => ({
    siteId: state.user.siteId,
    defaultSite: state.user.defaultSite,
    timezone: state.user.timezone,
    language: state.user.language,
    locale: state.user.locale,
    dateTimeFormat: state.user.dateTimeFormat,
    firstWeekday: state.user.firstWeekday,
    clientNumber: state.user.clientNumber,
    dateFormat: state.user.dateFormat,
  }),
  (user: User): TCommonUserData => ({
    siteId: user.siteId,
    defaultSite: user.defaultSite,
    timezone: user.timezone,
    language: user.language,
    locale: user.locale,
    dateTimeFormat: user.dateTimeFormat,
    firstWeekday: user.firstWeekday,
    clientNumber: user.clientNumber,
    dateFormat: user.dateFormat,
  }),
);
