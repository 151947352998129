<div class="device-status-container row px-3 pt-3 pb-2">
  <div class="col-12 d-flex align-items-center justify-content-between device-header">
    <span class="f-w-600">{{ data.deviceName ?? ('sensorStatusIndicator.otherSensors.label' | translate) }}</span>
    <ng-container *ngTemplateOutlet="extendCollapseBadgesButton"></ng-container>
  </div>
  <div class="row ml-1" *ngIf="data.deviceStatus">
    <div class="col-12">
      <ng-container
        *ngTemplateOutlet="deviceStatusText; context: { status: data.deviceStatus, name: data.deviceName }"
      ></ng-container>
    </div>
  </div>
  <ul class="d-block sensor-list">
    <li *ngFor="let sensor of data.sensorDetails" class="sensor-list-item">
      <span class="icon" *ngIf="sensor.effectiveSensorData; else effectiveIconPlaceholder">
        <span class="line">
          <span class="circle" [ngClass]="{ offline: data.deviceStatus !== 'online' && data.deviceName }"> </span>
        </span>
      </span>

      <span class="f-w-600">{{
        sensor.parentSensorType
          ? ('sensorTypes.sensor_type_' + sensor.parentSensorType | translate)
          : ('sensorTypes.sensor_type_' + sensor.sensorType | translate)
      }}</span>
      <span> - </span>
      <span>{{ sensor.sensorIdWithoutMac }}</span>
      <span> - </span>
      <span>{{ sensor.lastCommDate ?? ('sensorStatusIndicator.sensor.noMessageReceived' | translate) }}</span>
      <span *ngIf="sensor.sensorType === SensorTypeEnum.SUB_SENSOR">
        ({{ 'sensorTypes.sensor_type_' + SensorTypeEnum.SUB_SENSOR | translate }})
      </span>

      <div
        class="container badges-container"
        *ngIf="sensorInfoBadges[sensor.id].length > 0"
        [ngClass]="isBadgesExtended ? 'badges-extended' : 'badges-collapsed'"
      >
        <div class="row">
          <div>
            <span
              class="sensor-badge"
              *ngFor="let badge of sensorInfoBadges[sensor.id]"
              [ngbTooltip]="badge.tooltip"
              triggers="click:blur"
            >
              <i class="fa {{ badge.icon }} icon-font-size"></i>&nbsp;
              {{ badge.text }}
            </span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-template #effectiveIconPlaceholder>
  <span class="icon-placeholder"></span>
</ng-template>

<ng-template #deviceStatusText let-status="status" let-name="name">
  <div [ngClass]="status === 'online' ? 'online-status' : 'offline-status'">
    <i
      [class.fas]="true"
      [class.fa-check]="status === 'online'"
      [class.fa-times]="status !== 'online' && name"
      aria-hidden="true"
    ></i>
    {{ status ? ('sensorStatusIndicator.deviceStatus.' + status | translate) : '' }}
  </div>
</ng-template>

<ng-template #extendCollapseBadgesButton>
  <scw-mat-button
    [className]="['text-button']"
    [hidden]="!isDeviceHasBadge"
    type="ghost"
    size="md"
    (onClick)="onExtendOrCollapseBadgesClick()"
    [iconButton]="true"
  >
    <em
      class="fas"
      [class.fa-angle-down]="!isBadgesExtended"
      [class.fa-angle-up]="isBadgesExtended"
      aria-hidden="true"
    ></em>
  </scw-mat-button>
</ng-template>
