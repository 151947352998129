import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BulkResponseDataInterface } from '../../model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../error-message.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ToastHelperService {
  private successTitle: string;
  private changesSavedSuccessfullyMessage: string;
  private errorTitle: string;

  constructor(
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly errorMessageService: ErrorMessageService,
  ) {
    this.translate
      .stream(['general.success', 'general.changesSavedSuccessfully', 'general.error'])
      .subscribe((data) => {
        this.successTitle = data['general.success'];
        this.changesSavedSuccessfullyMessage = data['general.changesSavedSuccessfully'];
        this.errorTitle = data['general.error'];
      });
  }

  public showToastMessage(success: boolean, title: string | undefined, message: string): void {
    const defaultTitle: string = success ? this.successTitle : this.errorTitle;
    const type: string = success ? 'success' : 'error';

    this.toast[type](message, title ?? defaultTitle, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
  }

  public showInfoMessage(title: string, message: string): void {
    this.toast.info(message, title, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
  }

  public showPersistentToastMessage(
    success: boolean,
    title: string | undefined,
    message: string,
    link?: { url: string; label: string },
  ): void {
    const defaultTitle: string = success ? this.successTitle : this.errorTitle;
    const type: string = success ? 'success' : 'error';

    let finalMessage = message;

    if (link) {
      finalMessage = `<a href="${link.url}" target="_blank">${link.label} ${message}</a>`;
    }

    this.toast[type](finalMessage, title ?? defaultTitle, {
      closeButton: true,
      progressBar: false,
      disableTimeOut: true,
      positionClass: 'toast-bottom-right',
      timeOut: 0,
      enableHtml: true,
      messageClass: 'toast-message link-message',
    });
  }

  public toastBulkErrors(response: BulkResponseDataInterface): void {
    if (response && response.data) {
      this.errorMessageService.getTranslatedErrorMessage(response.data);

      for (const message of response.data) {
        if (!message.success) {
          this.showToastMessage(
            message.success,
            this.translate.instant('general.failed'),
            (message.message || []).join(', '),
          );
        }
      }
    }
  }

  public showGenericChangesSavedSuccessfullyToastMessage(message: string = this.changesSavedSuccessfullyMessage): void {
    this.showToastMessage(true, undefined, message);
  }

  public showWaitMessage(): void {
    this.toast.info(this.translate.instant('general.pleaseWaitForLongProcess'), '', {
      closeButton: false,
      progressBar: false,
      positionClass: 'toast-bottom-right',
    });
  }

  public static hideToastMessage(mainToast: ToastrService, toastToHide: ToastrService): void {
    const isToastMessageCurrentlyActive: boolean = _.includes(mainToast.toasts, toastToHide);
    const toastMessageId: number = _.get(toastToHide, 'toastId');

    if (mainToast.toasts.length && isToastMessageCurrentlyActive) {
      mainToast.remove(toastMessageId);
    }
  }

  public showWarningMessage(title: string, message: string): void {
    this.toast.warning(message, title, {
      closeButton: false,
      progressBar: false,
      positionClass: 'toast-bottom-right',
    });
  }
}
