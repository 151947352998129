import * as SensorIdMappingActions from './sensor-id-mapping.actions';
import { SensorIdMappingState } from './sensor-id-mapping.model';

export const sensorIdMappingState: SensorIdMappingState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: null,
    pageCount: null,
    total: null,
  },
  getSensorIdMappingLoading: false,
  getSensorIdMappingLoaded: false,
  sensorIdMapping: null,
  addSensorIdMappingLoading: false,
  addSensorIdMappingLoaded: false,
  editSensorIdMappingLoading: false,
  editSensorIdMappingLoaded: false,
  deleteSensorIdMappingLoading: false,
  deleteSensorIdMappingLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
};

export function sensorIdMappingReducer(
  state: SensorIdMappingState = sensorIdMappingState,
  action: SensorIdMappingActions.SensorIdMappingActions,
): SensorIdMappingState {
  switch (action.type) {
    case SensorIdMappingActions.ActionTypes.SENSOR_ID_MAPPING_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case SensorIdMappingActions.ActionTypes.SENSOR_ID_MAPPING_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case SensorIdMappingActions.ActionTypes.GET_SENSOR_ID_MAPPING:
      return {
        ...state,
        ...{
          getSensorIdMappingLoading: true,
          getSensorIdMappingLoaded: false,
        },
      };
    case SensorIdMappingActions.ActionTypes.GET_SENSOR_ID_MAPPING_COMPLETED:
      return {
        ...state,
        ...{
          SensorIdMapping: action.payload.data,
          getSensorIdMappingLoading: false,
          getSensorIdMappingLoaded: true,
        },
      };
    case SensorIdMappingActions.ActionTypes.ADD_SENSOR_ID_MAPPING:
      return {
        ...state,
        addSensorIdMappingLoading: true,
        addSensorIdMappingLoaded: false,
      };
    case SensorIdMappingActions.ActionTypes.ADD_SENSOR_ID_MAPPING_COMPLETED:
      return {
        ...state,
        addSensorIdMappingLoading: false,
        addSensorIdMappingLoaded: true,
      };
    case SensorIdMappingActions.ActionTypes.EDIT_SENSOR_ID_MAPPING:
      return {
        ...state,
        editSensorIdMappingLoading: true,
        editSensorIdMappingLoaded: false,
      };
    case SensorIdMappingActions.ActionTypes.EDIT_SENSOR_ID_MAPPING_COMPLETED:
      return {
        ...state,
        editSensorIdMappingLoading: false,
        editSensorIdMappingLoaded: true,
      };

    case SensorIdMappingActions.ActionTypes.DELETE_SENSOR_ID_MAPPING:
      return {
        ...state,
        deleteSensorIdMappingLoading: true,
        deleteSensorIdMappingLoaded: false,
      };
    case SensorIdMappingActions.ActionTypes.DELETE_SENSOR_ID_MAPPING_COMPLETED:
      return {
        ...state,
        deleteSensorIdMappingLoading: false,
        deleteSensorIdMappingLoaded: true,
      };
    case SensorIdMappingActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case SensorIdMappingActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case SensorIdMappingActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case SensorIdMappingActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case SensorIdMappingActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
