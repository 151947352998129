<button
  type="button"
  class="btn btn-icon-sm"
  data-toggle="tooltip"
  data-placement="left"
  title
  [disabled]="isDisabled"
  (click)="onRefreshPageClicked()"
>
  <span *ngIf="countdownInitial !== -1" class="countdown-text" [ngClass]="countdown >= 1000 ? 'countdown-text-over-thousand' : undefined" #countdownText>{{ countdown }}</span>
  <em *ngIf="countdownInitial === -1" class="b-fa-solid b-fa-arrows-rotate"></em>
</button>
