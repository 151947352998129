<ng-template [ngIf]="pageMode === ePageMode.VIEW">
  <scw-mat-small-button (onClick)="onModeChange(ePageMode.EDIT, EPageHeaderEditModeOperation.ModeChange)">
    <em class="fas fa-pen mr-1"></em>
    {{ pageModeEditTranslation }}
  </scw-mat-small-button>
</ng-template>
<ng-template [ngIf]="pageMode === ePageMode.EDIT && isAnyEditModeButtonsShown">
  <div class="buttons-container">
    <scw-mat-small-button
      *ngIf="editModeButtonsShownStatus.isSaveButtonShown"
      class="save-button"
      (onClick)="onModeChange(ePageMode.VIEW, EPageHeaderEditModeOperation.Save)"
    >
      <em class="fas fa-save mr-1"></em>
      {{ pageModeSaveTranslation }}
    </scw-mat-small-button>
    <scw-mat-small-button
      *ngIf="editModeButtonsShownStatus.isCancelButtonShown"
      (onClick)="onModeChange(ePageMode.VIEW, EPageHeaderEditModeOperation.Cancel)"
    >
      {{ pageModeCancelTranslation }}
    </scw-mat-small-button>
    <scw-mat-small-button
      *ngIf="editModeButtonsShownStatus.isAddButtonShown"
      (onClick)="onModeChange(ePageMode.EDIT, EPageHeaderEditModeOperation.Add)"
    >
      {{ pageModeAddTranslation }}
    </scw-mat-small-button>
  </div>
</ng-template>
