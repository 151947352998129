import * as FaultCodeMappingActions from './fault-code-mapping.actions';
import { FaultCodeMappingState } from './fault-code-mapping.model';

export const faultCodeMappingState: FaultCodeMappingState = {
  tableDataLoading: false,
  tableDataLoaded: false,
  tableData: [],
  tablePageInfo: {
    page: 1,
    pageCount: 0,
    total: 0,
  },
  getFaultCodeMappingLoading: false,
  getFaultCodeMappingLoaded: false,
  faultCodeMapping: [],
  addFaultCodeMappingLoading: false,
  addFaultCodeMappingLoaded: false,
  editFaultCodeMappingLoading: false,
  editFaultCodeMappingLoaded: false,
  deleteFaultCodeMappingLoading: false,
  deleteFaultCodeMappingLoaded: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineDataLoading: false,
  lineDataLoaded: false,
  lineData: null,
  activitiesDataLoading: false,
  activitiesDataLoaded: false,
  activitiesData: [],
  tasksDataLoading: false,
  tasksDataLoaded: false,
  tasksData: [],
};

export function faultCodeMappingReducer(
  state: FaultCodeMappingState = faultCodeMappingState,
  action: FaultCodeMappingActions.FaultCodeMappingActions,
): FaultCodeMappingState {
  switch (action.type) {
    case FaultCodeMappingActions.ActionTypes.FAULT_CODE_MAPPING_DATA_LOADING:
      return {
        ...state,
        ...{
          tableDataLoading: true,
          tableDataLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.FAULT_CODE_MAPPING_DATA_LOADED:
      return {
        ...state,
        ...{
          tableData: action.payload.data,
          tablePageInfo: {
            page: action.payload.page,
            pageCount: action.payload.pageCount,
            total: action.payload.total,
          },
          tableDataLoading: false,
          tableDataLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.GET_FAULT_CODE_MAPPING:
      return {
        ...state,
        ...{
          getFaultCodeMappingLoading: true,
          getFaultCodeMappingLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.GET_FAULT_CODE_MAPPING_COMPLETED:
      return {
        ...state,
        ...{
          faultCodeMapping: action.payload.data,
          getFaultCodeMappingLoading: false,
          getFaultCodeMappingLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.ADD_FAULT_CODE_MAPPING:
      return {
        ...state,
        addFaultCodeMappingLoading: true,
        addFaultCodeMappingLoaded: false,
      };
    case FaultCodeMappingActions.ActionTypes.ADD_FAULT_CODE_MAPPING_COMPLETED:
      return {
        ...state,
        addFaultCodeMappingLoading: false,
        addFaultCodeMappingLoaded: true,
      };
    case FaultCodeMappingActions.ActionTypes.EDIT_FAULT_CODE_MAPPING:
      return {
        ...state,
        editFaultCodeMappingLoading: true,
        editFaultCodeMappingLoaded: false,
      };
    case FaultCodeMappingActions.ActionTypes.EDIT_FAULT_CODE_MAPPING_COMPLETED:
      return {
        ...state,
        editFaultCodeMappingLoading: false,
        editFaultCodeMappingLoaded: true,
      };

    case FaultCodeMappingActions.ActionTypes.DELETE_FAULT_CODE_MAPPING:
      return {
        ...state,
        deleteFaultCodeMappingLoading: true,
        deleteFaultCodeMappingLoaded: false,
      };
    case FaultCodeMappingActions.ActionTypes.DELETE_FAULT_CODE_MAPPING_COMPLETED:
      return {
        ...state,
        deleteFaultCodeMappingLoading: false,
        deleteFaultCodeMappingLoaded: true,
      };
    case FaultCodeMappingActions.ActionTypes.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.LINES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.ACTIVITIES_DATA_LOADING:
      return {
        ...state,
        ...{
          activitiesDataLoading: true,
          activitiesDataLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.ACTIVITIES_DATA_COMPLETED:
      return {
        ...state,
        ...{
          activitiesData: action.payload,
          activitiesDataLoading: false,
          activitiesDataLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          tasksDataLoading: true,
          tasksDataLoaded: false,
        },
      };
    case FaultCodeMappingActions.ActionTypes.TASKS_DATA_COMPLETED:
      return {
        ...state,
        ...{
          tasksData: action.payload,
          tasksDataLoading: false,
          tasksDataLoaded: true,
        },
      };
    case FaultCodeMappingActions.ActionTypes.FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
